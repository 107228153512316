import firebase from '../firebase'
import { ref } from 'vue'
// Fetch a list of all metas.
// Use concentated string as meta name as in "crm/EMailTags"

export const useGetAllMetas = (meta: string) => {
    
    const isLoadingMeta = ref(false)
    const metaList = ref<any>([])

    const metaCollection = firebase.firestore().collection(`metas/${meta}`)

    const fetchAllMetas = async () => {
        isLoadingMeta.value = true
        
        try {
        metaCollection.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                metaList.value.push(
                    {
                        id: doc.id,
                        ...doc.data()
                    }
                )
            })
            });
        } catch (error) {
            alert(`Bilgiler getirilirken bir hata oluştu: ${error}`)
        }

        isLoadingMeta.value = false
    }

    fetchAllMetas()

    return { metaList, isLoadingMeta };
    
}

// Add or update a meta field

export const addOrUpdateMeta = async (field: string, data: any, existingMetaId?: any) => {
    if(existingMetaId) {
        const docRef = firebase.firestore().collection(field).doc(existingMetaId)
        docRef.update(data).catch((error) => {
            alert("Öge güncellenirken bir hata oluştu. Eğer bu hata devam ederse lütfen yazılım ekibiniz ile paylaşın: " + error)
        })
    } else {
        try {
            const collection = firebase.firestore().collection(field)
            const doc = collection.add(data).then((docRef) => {
                return doc.id
            })
        } catch (error) {
            alert("Öge eklenirken bir hata oluştu. Eğer bu hata devam ederse lütfen yazılım ekibiniz ile paylaşın: " + error)
        }

    }
    
}

export const deleteMetaField = async (field: string, id: string) => {
    const confirmation = confirm("Bu öğeyi silmek istediğinize emin misiniz?")
    if(!confirmation) return
    const docRef = firebase.firestore().collection(field).doc(id)
    docRef.delete().catch((error) => {
        alert("Öge silinirken bir hata oluştu. Eğer bu hata devam ederse lütfen yazılım ekibiniz ile paylaşın: " + error)
    })
}
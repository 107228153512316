<template>

<div @click="componentClicked" id="copperPrice" >
<ac-loader id="copper-ac-loader" class="module-container" v-if="isLoading"></ac-loader>
<div v-else class="module-container flex items-center flex-row">      
      <div class="flex flex-col items-center">
        <h5 class="text-2xl"> CU - USD</h5>
          <p v-if="copperPrices[1].price" class="text-gray-800 currency-value font-bold text-4xl"> ${{copperPrices[0].price}}</p>
          <svg  class="pt-5" v-if="copperPriceChange === 'Equal'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20" height="30" xml:space="preserve"> <path class="path2" fill="gray" stroke-width="8" stroke="gray" d="M0 0 l1120 0"/></svg>
          <svg v-else-if="copperPriceChange" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /></svg>
          <svg v-else viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" width="30" height="30" ><path d="m345.44 248.29-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373" fill="#cc4040" transform="matrix(-.00013 .03541 .03541 .00013 3.02 2.988)"/></svg>
      </div>
      <div class="flex flex-col items-center">
        <h5 class="text-2xl "> EUR - USD </h5>
          <p class="text-gray-800 currency-value font-bold text-4xl"> {{calculateReverse(currentUSDEUR)}}</p>
          <svg class="pt-5" v-if="eurUSDChange === 'Equal'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25" height="30" xml:space="preserve"> <path class="path2" fill="gray" stroke-width="8" stroke="gray" d="M0 0 l1120 0"/></svg>
          <svg  v-else-if="eurUSDChange" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /></svg>
          <svg v-else viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" width="30" height="30" ><path d="m345.44 248.29-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373" fill="#cc4040" transform="matrix(-.00013 .03541 .03541 .00013 3.02 2.988)"/></svg>
      </div>
      <div class="flex flex-col items-center">
        <h5 class="text-2xl"> USD - TRY </h5>
          <p class="text-gray-800 currency-value font-bold text-4xl">  {{currentUSDTRY}}</p>
          <svg class="pt-5" v-if="usdTryChange === 'Equal'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25" height="30" xml:space="preserve"> <path class="path2" fill="gray" stroke-width="8" stroke="gray" d="M0 0 l1120 0"/></svg>
          <svg  v-else-if="usdTryChange" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /> f</svg>
          <svg v-else viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" width="30" height="30" ><path d="m345.44 248.29-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373" fill="#cc4040" transform="matrix(-.00013 .03541 .03541 .00013 3.02 2.988)"/></svg>
      </div>
    </div> 
</div>


</template>

<script>
import axios from 'axios'
import acLoader from '../reusable/acLoader.vue'

export default {
  name: 'MetalValue',
  data(){ 
      return {
      isLoading: false,
      copperPrices: [],
      currentRatesUsdEur: [],
      currentRatesUsdTry: [],
      }
  },
  computed: {
    currentUSDTRY(){
      return this.currentRatesUsdTry[0].price.toFixed(2)
    },
    usdTryChange(){
      if (this.currentRatesUsdTry[0].price === this.currentRatesUsdTry[this.currentRatesUsdTry.length -1].price) {
        return "Equal"
      } else {
        return (this.currentRatesUsdTry[0].price > this.currentRatesUsdTry[this.currentRatesUsdTry.length -1].price)
      }
    },
    currentUSDEUR(){
      return this.currentRatesUsdEur[0].price.toFixed(2)
    },
    eurUSDChange(){
       if (this.currentRatesUsdEur[0].price === this.currentRatesUsdEur[this.currentRatesUsdEur.length -1].price) {
        return "Equal" 
        } else {
          return (this.currentRatesUsdEur[0].price < this.currentRatesUsdEur[this.currentRatesUsdEur.length -1].price)
        }
    },
    currentCopperPrice(){
      return this.copperPrices[0].price
    },
    copperPriceChange(){
      
      if (this.copperPrices[0].price === this.copperPrices[1].price) {
        return "Equal"
      } else {
         return (this.copperPrices[0].price > this.copperPrices[1].price)
      }
    }
  },

  methods: {
    async getCopperPrice(){
      this.isLoading = true
      const baseURL = "https://dansuk-dashboard-api.herokuapp.com/metals?"
      const limit = "2"
      const finalURL = `${baseURL}limit=${limit}`
      axios.get(finalURL)
        .then((response) => {
          const data = response.data
          const values = []
          console.log("Response from server:", data)
          for (let value of data) {
            const price = value
            values.push(price)
          }
          this.copperPrices = values
          this.isLoading = false
        }).catch((error) => {
          console.error("Something went wrong with the copper price request", error)}
        )
    },
    calculateReverse(value){
      return (1 / value).toFixed(2)
    },  
    async getCurrencyRates(){
      const baseURL = "https://dansuk-dashboard-api.herokuapp.com/currency-rates?"
      const limit = "8"
      const finalURL = `${baseURL}limit=${limit}`
      axios.get(finalURL)
        .then((response) => {
          response.data.forEach((rate) => {
            if(rate.name === "TRY") {
              this.currentRatesUsdTry.push({
                id: rate.id,
                name: rate.name,
                date: rate.date,
                price: rate.price
              })
            } else if(rate.name === "EUR") {
                this.currentRatesUsdEur.push({
                  id: rate.id,
                  name: rate.name,
                  date: rate.date,
                  price: rate.price
                })
            } else {
              console.info("Unrecognized entry found in JSON response.")
            }
          })

        }).catch((error) => {
          console.info("An error occured with the currency rate request", error)
          })  
    },

    componentClicked(){
      this.$emit("componentClicked")
      },
    },
    
    components: {
      acLoader
    },

  created(){
    this.getCopperPrice()
    this.getCurrencyRates()
  }
}

</script>

<style lang="scss" scoped>
h5{
    font-family: 'Encode Sans SC';
}

#copper-ac-loader.module-container{
  height: 12.5rem !important;
  padding-bottom: 5rem;
}

.currency-value {
      font-family: 'Encode Sans SC';
}
</style>


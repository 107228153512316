<script setup lang="ts">

import type { ByEvent } from "@/types";
import type { EventCategory } from "@/types";

import { ref, reactive, onBeforeMount, computed } from "vue"

import { deleteData, updateData } from "../../mixins/modularFirebaseOperations"
import { modalClosed } from "../../mixins/visualFormatters"
import { mapEventCatsToColorSchemes, getAllEvents, useGetEmployeeBirthdays } from "../../mixins/calendarOperations"
import { useRoute } from "vue-router"
import { Qalendar } from "qalendar"

import acMediumModal from "../reusable/teleports/acMediumModal.vue";
import addEvent from "../reusable/teleports/addEvent.vue";
import acLoader from "../reusable/acLoader.vue";


const isLoadingEvents = ref(false)
const events = ref<ByEvent[]>([])
const editedEvent = ref<ByEvent | undefined>(undefined)
const eventCats = ref<EventCategory[]>([])
const isAddingEvent = ref(false)

const { birthdays, isLoadingBirthdays } = useGetEmployeeBirthdays()

const newlyAddedEvents = ref<ByEvent[]>([])



const displayedEvents = computed(() => {
    return [...events.value, ...newlyAddedEvents.value, ...birthdays.value]
})

const handleEventAdded = function (newEvent: ByEvent) {
    newlyAddedEvents.value.push({
        id: newEvent.id,
        title: newEvent.title,
        description: newEvent.description,
        colorScheme: newEvent.category,
        time: newEvent.time,
        isEditable: true,
    })
    isAddingEvent.value = false
    modalClosed()
}

const handleEditEventInitiated = (e: string) => {
    const index = findEditedEventIndex(e)
    if (index) {
        if (index.existing) {
            editedEvent.value = events.value[index.index]
        } else {
            editedEvent.value = newlyAddedEvents.value[index.index]
        }
    }
}

const handleUpdateEventCompleted = (updatedEvent: ByEvent) => {
    const eventIndex = findEditedEventIndex(updatedEvent.id)

    if (eventIndex) {
        if (eventIndex.existing) {
            events.value[eventIndex.index] = updatedEvent
        } else {
            newlyAddedEvents.value[eventIndex.index] = updatedEvent
        }
    }

    editedEvent.value = undefined
    modalClosed()

}

const handleDeleteEvent = async (e: string) => {
    await deleteData("events", e)
    events.value = events.value.filter((event) => event.id !== e)
    
}

const handleDragEvent = async (e) => {
    await updateData("events", e.id, e)
}

const findEditedEventIndex = (e: string) => {
    let index = events.value.findIndex((event) => event.id === e)
    if (index !== -1) {
        return {
            index,
            existing: true,
        }
    } else {
        index = newlyAddedEvents.value.findIndex((event) => event.id === e)
        if (index !== -1) {
            return {
                index,
                existing: false,
            }
        } else {
            return undefined
        }
    }
}

const calendarConfig = reactive({
    week: {
        startsOn: "monday",
        scrollToHour: 6,
    },
    locale: "tr-TR",
    style: {
        fontFamily: "sans-serif",
        colorSchemes: {},
    },
    month: {
        // Hide leading and trailing dates in the month view (defaults to true when not set)
        showTrailingAndLeadingDates: false,
    },
    defaultMode: "month",
    showCurrentTime: true,
    isSilent: true,
})

const route = useRoute()
const selectedDate = route.query.day ? new Date(route.query.day) : new Date()

onBeforeMount( async () => {
    isLoadingEvents.value = true
    await mapEventCatsToColorSchemes(eventCats, calendarConfig, isLoadingEvents)
    await getAllEvents(events.value, isLoadingEvents)

})

</script>

<template>
    <div class="mt-4 px-4">
        <div class="action-row col-start-1 col-end-13 flex w-full items-center justify-end mb-4">
            <router-link to="/calendar/event-categories" class="ac-add-button mr-6">
                <img class="mr-4" width="20" src="../../assets/icons/forward.png" alt="">
                Etkinlik Kategorilerini Düzenle
            </router-link>
            <button type="button" class="ac-add-button" @click="isAddingEvent = true">
                <img class="mr-4" width="20" src="../../assets/icons/add.png" alt="">
                Yeni Etkinlik Ekle
            </button>
        </div>

        <div class="calendar-container w-full min-h-[90vh] max-h-[90vh] relative col-start-1 col-end-13 overflow-y-scroll ">
            <Qalendar class="min-h-[85vh]" 
            v-if="!isLoadingEvents && !isLoadingBirthdays" 
            :selectedDate="selectedDate" 
            :events="displayedEvents" 
            :config="calendarConfig" 
            @edit-event="handleEditEventInitiated($event)"
            @delete-event="handleDeleteEvent($event)"
            @event-was-dragged="handleDragEvent($event)"
            @event-was-resized="handleDragEvent($event)"/>
            <ac-loader class="!top-[50%] h-full left-[50%] !absolute" v-else></ac-loader>
        </div>


        <teleport to="#app">
            <transition appear type="animation" enter-active-class="animate__animated animate__fadeIn animate__fast"
                leave-active-class="animate__animated animate__fadeOut animate__faster">
                <ac-medium-modal class="lg:!top-44 overflow-y-scroll" @modal-closed="isAddingEvent = false; editedEvent = undefined"
                    v-if="isAddingEvent || editedEvent" height="85vh" width="70vw" title="Yeni Ekle">
                    <add-event :edited-event="editedEvent" @event-added="handleEventAdded($event)" @event-updated="handleUpdateEventCompleted($event)"/>
                </ac-medium-modal>
            </transition>
        </teleport>
    </div>
</template>

<style lang="css">
@import "../../../node_modules/qalendar/dist/style.css";

.calendar-container {
    height: 100%;
}

.calendar-root-wrapper{
    background-color: white;
}

.calendar-month__weekday{
    cursor: pointer;
    transition: all .2s;
}
.calendar-month__weekday:hover{
    background-color: rgb(237, 237, 237) !important;
}

.calendar-header__period-name {
    font-size: 1.4rem !important;
}

.week-timeline__day-name {
    font-size: 1rem !important;
}



.week-timeline__event {
    height: min-content !important;
    font-size: 1.2rem !important;

}

.week-timeline__date {
    font-size: 1.2rem !important;
}

.calendar-header__mode-picker,
.date-picker__value-display-text {
    font-size: 1.4rem !important;
}


/* Flyout sizes */

.event-flyout__row.is-title {
    font-size: 1.6rem !important;
}

.event-flyout__row.is-time {
    font-size: 1.5rem !important;
}

.event-flyout__row {
    font-size: 1.2rem !important;
}
.calendar-month__event{
    color: #4c4c4c !important;

    font-size: 1.5rem !important;
}

/* Events on the calendar */
.calendar-week__event-row.is-title {
    font-size: 1.7rem !important;
}

.calendar-week__event-row.is-time {
    font-size: 1.4rem !important;
    align-items: center !important;
}

.calendar-week__event-row.is-with {
    font-size: 1.4rem !important;
    align-items: center !important;
    margin-top: 1rem !important;

}

.calendar-week__event-row.is-description {
    font-size: 1.4rem !important;
    margin-top: 1rem !important;
}
</style>
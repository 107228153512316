<template>
    <input @input="inputtedSearchTerm" v-model="finalSearchTerm" type="text" class="doc-filter flex justify-evenly col-start-1 col-end-4 border border-gray-400 focus:border-gray-500 text-2xl py-2 px-5 rounded-2xl">
</template>

<script>
export default {
    name: "DocuementFilterWidget",
    data(){
        return {
            finalSearchTerm: this.searchTerm
        }
    },

    props: ["searchTerm"],
        

    methods: {
        inputtedSearchTerm(term){
            term = this.finalSearchTerm
            this.$emit("searchOccured", term)
        }
    }
    
}
</script>

<style scoped>
input.doc-filter{
    background-image: url("../../assets/icons/search.png");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: left 5% center;
    text-indent: 25px;
}
input.doc-filter:focus{
    background-image: url("../../assets/icons/search-active.png");
    outline:none;

}
</style>
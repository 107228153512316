import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";

export default {
    methods: {

        // Upload only a single file to firebase storage

        async uploadSingleToStorage(file, uploadTarget) {

                // The drag and drop uploader returns a file array. So if the type of the "file" argument is an array, we need to access the first element.

                let singleFile
                if(Array.isArray(file)){
                    singleFile = file[0]
                } else {
                    singleFile = file
                }

                const storageRef = firebase.storage().ref()
                const folderRef = storageRef.child(`files/${uploadTarget}/`)
                const originalExtension = singleFile.name.split('.').pop();
                console.log(originalExtension)
                const imageName = `${uuidv4()}.${originalExtension}`;
                console.log(imageName)
                const fileRef = folderRef.child(imageName)
    
                try {
                    await fileRef.put(singleFile)
                    const downloadUrl = await fileRef.getDownloadURL()
                    return downloadUrl
                } catch (error) {
                    console.error(error)
                }
            },
        // Upload files to firebase storage

        async uploadFilesToStorage(files, uploadTarget, databaseField,) {

            const storageRef = firebase.storage().ref()
            const timeStamp = Date.now().toString()
            const folderRef = storageRef.child(`files/${uploadTarget}/${timeStamp}/`)

            try {
                for (const file of files) {
                    const originalExtension = file.name.split('.').pop();
                    const imageName = `${uuidv4()}.${originalExtension}`;
                    const fileRef = folderRef.child(imageName)
                    await fileRef.put(file)
                    const downloadUrl = await fileRef.getDownloadURL()
                    databaseField.push({
                        src: downloadUrl
                    })
                  }
            } catch (error) {
                console.error(error)
            }
        },

        // WIP 
        async getDataFromCollection(mainCollection, mainDoc, subCollection, orderBy) {
            let response = []
            const dbBase = firebase.firestore().collection(mainCollection).doc(mainDoc).collection(subCollection)
            let db
            if(orderBy){
                db = dbBase.orderBy(orderBy)
            } else {
                db = dbBase
            }
    
            db.get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        response.push(doc.data())
                    })

            }).catch((error) => {
                console.log(error)
            })
            return response
          },


      postData(dataToBeSent, mainCollection, document, subCollection) {

        let db = ""
       
        if(document === undefined && subCollection === undefined){
            db = firebase.firestore().collection(mainCollection)    
        } else {
            db = firebase.firestore().collection(mainCollection).doc(document).collection(subCollection)
        }
        
       db
          .add(dataToBeSent)
          .then((doc) => {
              return doc.id
          })
          .catch((error) => {
              console.error("Error writing document: ", error)
              alert("Bir hata oluştu. \n \n Hata mesajı: ", error )
          })
      },

    deleteImage(objectContainingImageList, imageListName, imageIndex){
        // Remove image locally 
        objectContainingImageList[imageListName].splice(imageIndex, 1)

    }
  },
}
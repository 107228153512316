<template>
    <div class="main-container">
        <p>Maintenance Goals Draft</p>
    </div>
</template>

<script>



export default {
  name: 'MaintenanceGoals',
}
</script>

<style scoped lang="scss">


</style>

<script setup>

import { defineProps, defineEmits, } from "vue";

defineProps({
    posts: {
        type: Array,
        required: true
    },
});

const emits = defineEmits(["postDisplayed"]);

const emitPostDisplayed = (post) => {
    emits("postDisplayed", post)
}



const monthNames = {
    "01": "Ocak",
    "02": "Şubat",
    "03": "Mart",
    "04": "Nisan",
    "05": "Mayıs",
    "06": "Haziran",
    "07": "Temmuz",
    "08": "Ağustos",
    "09": "Eylül",
    "10": "Ekim",
    "11": "Kasım",
    "12": "Aralık"
}
</script>

<template>
    <div class="calendar gap-x-6 gap-y-6 grid col-start-1 col-end-13 lg:grid-cols-4 md:grid-cols-3 sm-grid-cols-1 w-full">
        <div class="month" v-for="(posts, month) in posts" :key="month">
            <h2 class="ac-subtitle mb-4 underline">{{ monthNames[month.split("-")[1]] }}</h2>
            <ul>
                <li @click="emitPostDisplayed(post)" class="text-2xl mb-8 cursor-pointer hover:underline" :class="{'line-through': post.status === 'rejected'}" v-for="(post, index) in posts" :key="post.id">
                {{ index + 1 }} - {{ post.name}}
                </li>
            </ul>
            </div>
    </div>
</template>

<style scoped lang="scss">

@import "../../../styles/core/typography.scss";

.month{
    border: 1px solid black;
    padding: 1rem;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.842);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

</style>


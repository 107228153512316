<template>
  <div class="training-container">
    <div class="go-back-container">
          <router-link class="text-2xl" to="/training">&#8592; Tüm Eğitimler </router-link>
      </div>
      <iframe :src="selectedVideo" class="w-full h-full lg:w-4/5" frameborder="0"></iframe>
      <aside class="training-list-container w-full lg:w-1/5 bg-white h-full border-t-2 lg:overflow-y-scroll">
        <div class="flex w-full justify-around items-center">
            <h3 class="font-sans text-3xl py-5 px-1 cursor-pointer font-semibold"  v-for="training, index in trelloTrainings" :key="training.id" :class="(selectedTab === index) ? 'border-b-2 border-black' : 'border-none' " 
            @click="changeSelectedTab(index)" >
                {{training.title}}
            </h3>
        </div>
        <div class="px-3 flex flex-col mt-8 space-y-5 lg:overflow-y-scroll">
            <div class="cursor-pointer" v-for="training, index in trelloTrainings[selectedTab].videos" :key="training.id" @click="changeSRC(index)">
                <h4 class="font-semibold mb-23 text-3xl">
                    {{training.title}}
                </h4>
                <p class="text-2xl py-4 text-gray-500 border-b-2">
                    {{training.desc}}
                </p>
            </div>
        </div>
      </aside>
  </div>
</template>

<script>



export default {
  name: 'TrelloTraining',
  data(){
      return {
          selectedVideo: 0,
          selectedTab: 0,  
          trelloTrainings: [
              {
                id: 1,
                title: "Temel",
                videos: [
                    {id: 1,
                    title: "Trello Temel 1 - Kavramlar",
                    desc: "Bu video ile, Trello'nun temel kurulumunu ve arayüzünü daha yakından tanıyabilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/Y2PHH5AkkNI",                    
                    },
                    {id: 2,
                    title: "Trello Temel 2 - Kapsamlı Konular",
                    desc: "Bu video ile, Trello'nun kullanımı hakkında biraz daha kapsamlı bilgiler edinebilir, kart ve listelerin daha detaylı özelliklerini inceleyebilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/R4rEdNmLmLQ",                    
                    },
                    {id: 3,
                    title: "Trello Temel 3 - Mobil Uygulama",
                    desc: "Günümüzdeki pek çok yazılım gibi, Trello'nun da mobil uygulaması bulunuyor. Bu mobil uygulamanın bilgisayara göre sunduğu birkaç ek özelliği bu videoda inceleyebilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/bMRo38vudfc",                    
                    },
                ],
              },
              {
                id: 2,
                title: "İleri Düzey",
                videos: [
                    {id: 1,
                    title: "Kart Renklendirme",
                    desc: "Trello'da, kartların üst bölümünü veya tamamını renklendirerek vurgulayabilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/RH4wlZf0Mhs",                    
                    },
                    {id: 2,
                    title: "Power Up Yetkilendirme",
                    desc: "Sizden onay ve otorizasyon isteyen power up'ları nasıl kullanabilirsiniz? Nasıl yetkilendirme yapabilirsiniz?",
                    videoURL: "https://www.youtube.com/embed/zP9_ctlUuXk",                    
                    },
                    {id: 3,
                    title: "Masaüstünden Kart Yaratma",
                    desc: "Trello bilgisayarınızda yüklü olduğu sürece, programı açmadan, o anda çalıştığınız herhangi bir programdan (veya masaüstünüzden) Trello'ya kartlar yaratabilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/lVTzv1sow8g",                    
                    },
                    {id: 4,
                    title: "Kartları Bağlama",
                    desc: "Trello kullanırken iki kartı birbirine nasıl bağlayabilirsiniz?",
                    videoURL: "https://www.youtube.com/embed/bsqI0OqZy_s",                    
                    },
                    {id: 5,
                    title: "Önemli Özellikler",
                    desc: "Trello içinde pek çok faydalı ve kapsamlı 'power-up' yer alır. Ancak bunların çoğunun kullanımı kartlara tarih ve kişilerin atanmış olmasını gerektirir. Kartlara tarih ve kişileri nasıl atayabilirsiniz?",
                    videoURL: "https://www.youtube.com/embed/Dncyu3U-Or4",                    
                    },
                ],
              },
              {
                id: 3,
                title: "Power Up",
                videos: [
                    {id: 1,
                    title: "Approvals for Trello",
                    desc: "Trello panoları içinde kullanılabilecek bir onay sistemi yaratır. Bu sayede işler yöneticiler tarafından onaylanabilir veya reddedilebilir.",
                    videoURL: "https://www.youtube.com/embed/CaTRE4Bc7kY",                    
                    },
                    {id: 2,
                    title: "Calendar",
                    desc: "Kartlarınızı teslim tarihlerine göre bir takvim üzerinde görüntüler ve düzenlemeye imkan tanır.",
                    videoURL: "https://www.youtube.com/embed/Dncyu3U-Or4",                    
                    },
                    {id: 3,
                    title: "Card Dependencies",
                    desc: "Trello gibi sistemlerde her işe bir kart atayıp bunlar üzerinden çalışmak çok ideal gözükür. Ancak gerçek hayatta işler çoğu zaman bu kadar 'tekdüze' ilerlemez - farklı işler birbirini engelleyebilir ve aynı anda onlarca iş devam edebilir. Bu eklenti, kartlar arasında daha kompleks ilişkiler yaratmanızı sağlar.",
                    videoURL: "https://www.youtube.com/embed/GK3oDmkz-jQ",                    
                    },
                    {id: 4,
                    title: "Custom Fields",
                    desc: "Trello gibi sistemlerde her işe bir kart atayıp bunlar üzerinden çalışmak çok ideal gözükür. Ancak gerçek hayatta işler çoğu zaman bu kadar 'tekdüze' ilerlemez - farklı işler birbirini engelleyebilir ve aynı anda onlarca iş devam edebilir. Bu eklenti, kartlar arasında daha kompleks ilişkiler yaratmanızı sağlar.",
                    videoURL: "https://www.youtube.com/embed/dANOFZBiWJA",                    
                    },
                    {id: 5,
                    title: "List Limit",
                    desc: "Her bir listeye ekleyebileceğiniz kart sayısını sınırlar. Örneğin 'Acil İşler' gibi bir listeniz varsa bu listeye en fazla beş - altı kart eklemek isteyebilirsiniz.",
                    videoURL: "https://www.youtube.com/embed/8XB3KsuEz6Y",                    
                    },
                    {id: 6,
                    title: "Gmail - Bölüm 1",
                    desc: "Mailinize gelen e-postaları Trello'ya entegre edebilmenizi sağlar.",
                    videoURL: "https://www.youtube.com/embed/z5IyHCy7L2o",                   
                    },
                    {id: 7,
                    title: "Gmail - Bölüm 2",
                    desc: "Mailinize gelen e-postaları Trello'ya entegre edebilmenizi sağlar.",
                    videoURL: "https://www.youtube.com/embed/ThhiXRXSOiI",                    
                    },
                    {id: 8,
                    title: "Google Drive - Bölüm 1",
                    desc: "Panolara doğrudan Google Drive'dan dosya ve klasör eklemenizi sağlar.",
                    videoURL: "https://www.youtube.com/embed/lS2sV0D6hdo",                    
                    },
                    {id: 9,
                    title: "Google Drive - Bölüm 2",
                    desc: "Panolara doğrudan Google Drive'dan dosya ve klasör eklemenizi sağlar.",
                    videoURL: "https://www.youtube.com/embed/WNdUV29Lm0Y",                    
                    },
                ],
              },

        ],
    }
  },
  methods: {
      changeSelectedTab: function(index){
          this.selectedTab = index
      },
      changeSRC: function(index){
          this.selectedVideo = this.trelloTrainings[this.selectedTab].videos[index].videoURL
      }
  },
  components: {
  },
  mounted() {
    this.selectedVideo = this.trelloTrainings[0].videos[0].videoURL
  }
}
</script>

<style scoped lang="scss">
  @import "../../styles/modules/training.scss";

</style>

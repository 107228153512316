<template>
    <div class="main-container">
        <div id="login-container" class="mb-40 col-start-4 col-end-10 bg-white rounded-md px-16 border-gray-300 self-center md:h-full border-2"> 
            <img width="175" class="mx-auto mb-20 mt-20" src="../assets/images/dansuk-logo.png" alt="dansuk logo">
            <p class="text-3xl text-center mb-20 mt-20" >Lütfen Dansuk Mühendislik hesabınızla giriş yapın.</p>

            <button class="bg-white flex justify-center whitespace-nowrap items-center mb-20 m-auto w-10/12 text-2xl md:text-3xl px-4 md:px-10 py-4 rounded-md border-black border-2" @click="login">
                <img width="20" class="mr-3 md:mr-10" src="../assets/images/google-logo.png" alt="dansuk logo">

                Google ile giriş yap
            
            </button>
            
            <div v-if="loggedIn" class="logged-in-container flex flex-col items-center justify-center">
                <p class="text-2xl text-center mb-10"> Giriş yaptığınız için teşekkür ederiz. Otomatik olarak yönlendirilmezseniz, aşağıdaki buton ile Dashboard'a devam edebilirsiniz. </p>
                <a class="border-2 px-10 py-5 border-black rounded-md text-2xl" href="/" >
                    Dashboard'a Devam Et
                </a>
            </div>

        </div>

    </div>

</template>

<script>

import { mapGetters } from "vuex"

export default {

    name: "SignIn",
    data() {
        return {
            isLoggedIn: false
        }
    },
    computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
            loggedIn: "loggedIn"
        })
    },
    methods: {
        login(){
            this.$store.dispatch("user/login")
        },
    },

    created(){
    }

}
</script>
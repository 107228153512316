<template>
    <table class="table-auto w-full border-2 border-gray-200 rounded-3xl shadow-sm bg-white">
        <thead>
            <tr class="bg-gray-500">
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Kapak </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Dosya Adı </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Dil </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Dosya Türü</th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Yıl</th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> İndirme Linki</th>
            </tr>
        </thead>

        <tr class="cursor-pointer hover:bg-gray-100" v-for="doc in documentData" :key="doc.id">
                <td @click="copyURL(doc.url)" class="message-content__data font-bold py-4 px-8 text-2xl text-center">
                    <img loading="lazy" width="300" :src="doc.image"  alt="doküman kapak görseli">
                </td>
                <td @click="copyURL(doc.url)" class="message-content__data py-4 px-8 font-bold text-2xl text-center">{{doc.title}}</td>
                <td @click="copyURL(doc.url)" class="message-content__data py-4 px-8 text-2xl text-center">
                    <img :src="require(`../../assets/decors/flag-${doc.lang}.png`)" width="30" alt="flag">
                </td>
                <td @click="copyURL(doc.url)" class="message-content__data py-4 px-8 text-center text-2xl">{{doc.fileType}}</td>
                <td @click="copyURL(doc.url)" class="message-content__data py-4 px-8 text-center text-2xl">{{doc.year}}</td>                                
                <td class="message-content__data py-4 px-8 text-left text-blue-600 text-xl">
                    <a target="_blank" :href="doc.url">
                        Dosyayı görüntülemek için tıklayın.
                    </a>
                </td>                
        </tr>
    </table>
</template>

<script>

import removeBackground from "../../mixins/removeBackground"
import resetBackground from "../../mixins/resetBackground"


export default {
    
    name: 'DocumentTableView',

    props:{
        documentData: Array
    },

    methods: {
        copyURL : function(link) {
            navigator.clipboard.writeText(link)
            this.$emit("codeCopied")
        }
    },
    
    mixins: [removeBackground, resetBackground],

    created(){
        this.removeBackground()
    },

    beforeDestroy(){
        this.resetBackground()
    }
}

</script>
<template>
   <div @click="filterActivated" :class="{'border-gray-600': isActive}" class="preset-filter bg-white whitespace-nowrap rounded-xl border border-gray-400 text-2xl py-2 flex justify-center px-8 cursor-pointer">
       <img class="mr-4" v-if="!isActive" width="16" src="../../assets/icons/filter.png" alt="filter">
       <img class="mr-4" v-else width="16"  src="../../assets/icons/filter-full.png" alt="unfilter">
      {{text}}
   </div>
</template>

<script>
export default {
    name: "PreparedFilter",
    data(){
        return {
            finalSearchTerm: this.searchTerm,
            filterIndex: this.index

        }
    },

    computed: {
        isActive() {
            return this.active
        }
    },

    props: ["text", "searchTerm", "active", "index" ],
        

    methods: {
        filterActivated(term, index){
            term = this.finalSearchTerm
            index = this.filterIndex
            this.$emit("filterActivated", term, index)
        }
    }
    
}
</script>

<style scoped>

</style>
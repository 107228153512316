<template>  
    <form class="add-bms-task">

        <h3 class="single-line-label">Logolar</h3>

        <ac-loader v-if="isLoading"></ac-loader>

        <div class="flex flex-wrap overflow-scroll w-full">
            <img @dblclick="selectLogo(logo)" v-for="logo in logos" :key="logo.name"  class="logo-picker__img border p-3 mr-6 cursor-pointer mb-3" :class="{'border-black': logo === selectedLogo, 'border-gray-200': logo != selectedLogo}"   @click="selectedLogo = logo" :src="logo.src" alt="logo">
        </div>
        
        <button type="button" role="button" @click="selectLogo(selectedLogo)" class="text-2xl col-start-1 w-1/6 mt-6 border border-gray-500 bg-black text-white px-3 py-3 hover:bg-gray-700 hover:text-white active:-scale-90">Ekle</button>


    </form>
</template>

<script>
import initializeBMS from "../../../../mixins/initializeOtherFirebase"
import acLoader from "../../../reusable/acLoader.vue"
import modalOperations from "../../../../mixins/modalOperations.js"


export default {
    
    name: 'bmsLogoPicker',
    data(){
        return {
            isLoading: false,
            logos: [],
            selectedLogo: "",
        }
    },

    components: {
        acLoader
        },

    mixins: [modalOperations, initializeBMS],

    props: ["task"],

    methods: {

        fetchLogos(){
            this.isLoading = true
            const storageFolderRef = this.initializeBMS().storage().ref().child("logos")
            storageFolderRef.listAll().then( (res) => {
                res.items.forEach((item) => {
                    item.getDownloadURL().then((url) => {
                        this.logos.push({
                            name: item.name,
                            src: url
                        })
                    })
                })
                        this.isLoading = false

            }
            ).catch((error) => {
                console.log("Error getting files", error)
            })

        },

        selectLogo(logo){
            this.selectedLogo = logo
            this.$emit("logoSelected", logo)
            this.$emit("modalClosed")
            this.modalClosed()
            
        },

        cancelLogoSelection(){
            this.selectedLogo = "",
            this.$emit("modalClosed")
        }
    },

    created(){
        this.fetchLogos()
    }
}

</script>

<style lang="scss" scoped>
.logo-picker__img{
    width: 15%;
    max-height: 10%;
    object-fit: contain;
}
</style>
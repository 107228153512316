<template>
    <div class="main-container">
        <inner-navigation class="grid-order-1" link="/digital/dss/add-project" title="Yeni DSS Projesi Ekle" imgURL="add.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/digital/dss/projects" title="DSS Projeleri" imgURL="dss-request.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-1" link="/digital/dss/service-requests" title="Servis Talepleri" imgURL="dss-request.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/digital/dss/service-records" title="Servis Kayıtları" imgURL="dss-service.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-3" link="/digital/dss/all" title="Tüm Kayıtlar" imgURL="dss-all.png" navStyle=1></inner-navigation>   
    </div>
</template>

<script>

import InnerNavigation from "../../InnerNavigation.vue"


export default {
  name: 'DSSHome',
  components: {
      InnerNavigation: InnerNavigation
  },
}
</script>


<template>
  <div class="main-container">
      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="/surveys/forward-response" title="Anket Sonucu İlet" imgURL="forward.png" navStyle="1"></inner-navigation>
      <inner-navigation class="grid-order-1" link="/surveys/dansuk" title="Dansuk Mühendislik Anketleri" imgURL="dansuk-surveys.png" navStyle="1"></inner-navigation>
      <inner-navigation class="grid-order-2" link="/surveys/outbound" title="Müşteri Anketleri" imgURL="outbound-surveys.png" navStyle="21"></inner-navigation>
  </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'


export default {
  name: 'SurveysHome',
    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      })
  },

  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">


</style>

<template>
    <div class="main-container">
    <acca-vue-notification v-if="isReferenceSubmissionSent" message="Referansınız alındı, teşekkür ederiz!"></acca-vue-notification>

    <h2 class="form-title">Web Sitesi - Referans Bilgisi</h2>

    <form class="dashboard-form" @submit.prevent="sendreferenceSubmission()" >

    <label class="single-line-label" for="title">Proje </label>

    <input
        required
        type="text"
        id="title"
        class="dashboard-form__input"
        v-model="referenceSubmission.title"
    >

    <label class="single-line-label" for="submittedBy">Gönderen</label>
    <input
        required
        readonly
        type="text"
        id="submittedBy"
        class="dashboard-form__input bg-gray-100"
        v-model="userProfile.name"
    >

    <label class="single-line-label" for="date">Proje Tamamlanma tarihi
        <vue-tooltip explanation="Sadece ay veya sadece yıl olarak ekleyebilirsiniz.">?</vue-tooltip>    
    </label>

    <input
        required
        type="text"
        id="date"
        class="dashboard-form__input"
        v-model="referenceSubmission.date"
    >

    <label class="single-line-label" for="systemType">Kullanılan Sistemler
        <vue-tooltip explanation="Projede kullanılan sistemleri buradan aktarabilirsiniz; VRF, Heat Pump, PV gibi...">?</vue-tooltip>    
    </label>

    <input
        required
        type="text"
        id="systemType"
        class="dashboard-form__input"
        v-model="referenceSubmission.systemType"
    >

    <div class="w-full flex space-between mt-5 mb-5">
            <div class="mr-5 w-full">
                <h4 class="text-2xl mb-5 font-semibold">İç Ünite Sayısı</h4>
                <input
                    type="number"
                    id="indoorUnits"
                    class="dashboard-form__input w-full mr-5"
                    v-model="referenceSubmission.indoorUnits"
                >
            </div>
            <div class="mr-5 w-full">
                <h4 class="text-2xl mb-5 font-semibold">Dış Ünite Sayısı</h4>
                <input
                    type="number"
                    id="outdoorUnits"
                    class="dashboard-form__input w-full mr-5"
                    v-model="referenceSubmission.outdoorUnits"
                >
            </div>
            <div class="w-full">
                <h4 class="text-2xl mb-5 font-semibold">Toplam kW</h4>
                <input
                    type="number"
                    id="outdoorUnits"
                    class="dashboard-form__input w-full mr-5"
                    v-model="referenceSubmission.capacity"
                >
            </div>


    </div>


    <label class="single-line-label" for="mention">Paylaşılacak Bilgiler
        <vue-tooltip explanation="Eğer bu proje ile ilgili dikkat çeken, sitede paylaşılması gereken bilgiler varsa, buradan paylaşabilirsiniz.">?</vue-tooltip>    
    </label>

    <textarea
            id="mention"
            rows="5"
            class="medium-message mb-10"
            v-model="referenceSubmission.mention">
    </textarea>

    <label class="single-line-label" for="doNotMention">Yazılmaması Gerekenler 
        <vue-tooltip explanation="Eğer bu projeye özgü bir durumdan dolayı, sitede bahsedilmemesi gereken bir durum varsa, buradan paylaşabilirsiniz.">?</vue-tooltip>    
    </label>

    <textarea
            id="doNotMention"
            rows="5"
            class="medium-message mb-10"
            v-model="referenceSubmission.doNotMention">
    </textarea>


            <label class="text-3xl font-semibold mb-5" for="images">Görseller
                <vue-tooltip explanation="Elinizde bu paylaşım için kullanabileceğimiz görseller veya örnek dosyalar varsa, buradan iletebilirsiniz. Lütfen yalnızca görsel dosyaları yükleyin, sistem diğer dosyaları görmezden gelecektir.">?</vue-tooltip>    
            </label>

            <acca-dn-d-upload class="mb-8" :allow="['.jpeg', '.jpg', '.png', '.heic', '.tiff', '.webp']"  @filesChanged="uploadFilesToStorage($event, 'digital/referenceSubmissions', referenceSubmission.images)" />


        <div class="button-row">
            <button type="submit" @click="validateData" class="dashboard-form__submit">Gönder</button>
            <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
        </div>
    </form>
    </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import VueTooltip from '../reusable/VueTooltip.vue'
import { mapGetters } from 'vuex'
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"


export default {
  name: 'ReferenceSubmission',
  data() {
      return {
          isReferenceSubmissionSent: false,
          referenceSubmission: {
              title: "",
              date: "",
              systemType: "",
              indoorUnits: "",
              outdoorUnits: "",
              capacity: "",
              mention: "",
              doNotMention: "",
              submittedBy: "",
              created: new Date(),
              images: [],
          }
      }
  },

    computed: {
          ...mapGetters('user', {
              userProfile: 'userProfile',
          })
      },

  methods: {
      goBack(){
          this.$router.go(-1)
      },

      validateData(){
          if(this.referenceSubmission.images.length === 0){
              alert("Referansın siteye görsel bir şekilde eklenebilmesi için, lütfen en az bir görsel ekleyin.")
          } else {
              this.referenceSubmission.submittedBy = this.userProfile.name
              this.postData(this.referenceSubmission, 'digital', 'references', 'referenceSubmissions')
          }
      },


      sendreferenceSubmission() {
          this.isReferenceSubmissionSent = true
          setTimeout(() => {
              this.isReferenceSubmissionSent = false
              this.goBack()
          }, 3000)
      }
  },
    components: {
      accaVueNotification: accaVueNotification,
      VueTooltip: VueTooltip,
      accaDnDUpload: accaDnDUpload,


  },

  mixins: [postData, firebaseOperations],
}


</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";



  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<template>
    <header v-if="loggedIn" class="dansuk-dashboard-header">
        <div class="dansuk-dashboard-header__container">
            <router-link class="header_logo" to="/">
                <img src="../assets/images/dansuk-logo.png" alt="Dansuk Logo" class="header_logo_image">
            </router-link>
            <div class="quick-links">
                <a href="https://www.dansuk.com.tr/" class="quick-links__link hidden lg:block">
                    <img  src="../assets/icons/dansuk-website.png" target="_blank" width="32" height="32" alt="" class="quick-links__image">
                </a>
                <a href="https://drive.google.com/" class="quick-links__link hidden lg:block">
                    <img  src="../assets/icons/googledrive-icon.png" target="_blank" width="32" height="32" alt="" class="quick-links__image">
                </a>
                <a href="https://www.linkedin.com/company/dansuk-muhendislik/" class="quick-links__link hidden lg:block">
                    <img  src="../assets/icons/linkedin.png" target="_blank" width="32" height="32" alt="" class="quick-links__image">
                </a>
                <a href="https://www.xing.com/pages/dansukengineering" class="quick-links__link hidden lg:block">
                    <img  src="../assets/icons/xing.png" target="_blank" width="32" height="32" alt="" class="quick-links__image">
                </a>

                <button v-if="userProfile.allowsEdit" type="button" @click="createMessageInit" class="quick-links__link">
                    <img src="../assets/icons/create-message.png" width="45" height="45" alt="" class="quick-links__image">
                </button>
                <div @click="isDisplayingContextMenu = true" class="quick-links__link !relative outline-none">
                    <img :src="userProfile.picture" width="40" height="40" class="rounded-full" alt="photoURL">
                    <ac-context-menu displays-triangle="true" @menu-done="isDisplayingContextMenu = false" class="!outline-none !text-xl bg-white !z-50 shadow-lg !whitespace-nowrap !absolute right-[-80%] !top-[120%]" :items="menuItems" v-if="isDisplayingContextMenu"/>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import { mapGetters } from 'vuex'
import acContextMenu from "./reusable/acContextMenu.vue";

export default {
  name: 'DashboardHeader',
  data(){
      return {
            isDisplayingContextMenu: false,
            menuItems: [
                {name: "Çıkış Yap", action: () => {this.$store.dispatch('user/logout'); window.location.reload() } }
            ]
      }
  },
  methods: {
      createMessageInit() {
          this.$emit("createMessageInitiated")
      }
  },

  components: {
        acContextMenu: acContextMenu
  },
    computed:{
        ...mapGetters( 'user', {
                userProfile: 'userProfile',
                loggedIn: "loggedIn"
            })
    },
  props: {
    title: String,
    imgURL: String,
    link: String
  }
}
</script>

<style scoped lang="scss">
  @import "../styles/core/variables.scss";
  
  header.dansuk-dashboard-header{
      height: $header-height;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: $header-background;
      box-shadow: 0 0 1px rgba($color: #9490908f, $alpha: 1.0)
  }

  .dansuk-dashboard-header__container{
      width: 96%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }


  .header_logo_image{
     width: 15rem;
     cursor: pointer;
  }

  .quick-links {

      display: flex;
      align-self: center;
      align-items: center;

      &__link:not(:last-child){
          margin-right: 2rem;
      }

      & button {
          border: none;
          background-color: transparent;
          transition: all .2s;
          outline: none;

          &:hover {
              transform: rotate(-25deg);
          }
      }
  }

  .quick-links__link {
        cursor: pointer;
  }
  
  .quick-links__image{

      cursor: pointer;
      
      &:hover{
          filter: brightness(70%);
      }
  }
  

</style>

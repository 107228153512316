<template>  
    <form class="add-bms-task">

        <h3 class="single-line-label">İkon</h3>
        <div class="flex flex-wrap w-full">
            <img class="w-24 border p-3 mr-6 cursor-pointer mb-3" :class="{'border-black': icon === newTask.icon, 'border-gray-200': icon != newTask.icon}"  v-for="icon in bmsIcons" :key="icon"  @click="newTask.icon = icon" :src="require(`../../../../assets/icons/bms/${icon}.png`)" alt="ikon">
        </div>
        <div class="flex flex-col w-full ">
            <label  for="name" class="single-line-label">Görev Adı</label>
            <input
            type="text"
            required
            id="name"
            class="dashboard-form__input"
            v-model="newTask.title"
            />
      </div>
    
        <label class="text-3xl font-semibold mb-20" for="isNumeric">Bu görevin tamamlanma durumunu nasıl takip etmek istersiniz?</label>
        <div class="flex mb-10">
            <div class="flex items-center">
                <input @click="newTask.isNumeric = false" v-model="newTask.isNumeric" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="percentage" name="percentage" :value="false">
            <label @click="newTask.isNumeric = false" class="text-2xl mr-7" for="percentage">Yüzde Bazında</label>
            </div>
            <div class="flex items-center">
                <input @click="newTask.isNumeric = true" v-model="newTask.isNumeric" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="isNumeric" name="isNumeric" :value="true">
                <label @click="newTask.isNumeric = true" class="text-2xl" for="isNumeric">Sayı Bazında</label>
            </div>
        </div>

        <div class="flex flex-col w-full" v-if="!newTask.isNumeric">
            <div class="flex w-full">
                <input class="bnw-input-range" type="range" name="projectCompletion" id="projectCompletion" v-model="newTask.completionRate" min="0" max="100">
                <input class="supporting-numeric-input text-2xl" type="number" name="projectCompletion" id="projectCompletion" v-model="newTask.completionRate" min="0" max="100">
            </div>
      </div>

        <div v-else class="flex w-full mt-2 numeric-completion text-3xl items-center">
            <input  class="mr-5 border border-gray-500 pl-5 text-center" type="number" name="completedItems" id="completedItems" v-model="newTask.completedItems" min="0" :max="newTask.targetItems">
            <span class="">/</span>
            <input class="ml-5 border border-gray-500 text-center " type="number" name="targetedItems" id="targetedItems" v-model="newTask.targetItems" min="1">
            <span class="text-gray-500 ml-5 text-2xl"> Tamamlanan Miktar / Hedeflenen Miktar </span>
        </div>

        <button type="button" role="button" @click="submitTask" class="text-2xl col-start-1 w-1/6 mt-6 border border-gray-500 bg-black text-white px-3 py-3 hover:bg-gray-700 hover:text-white active:-scale-90">Ekle</button>

    </form>
</template>

<script>
// import initializeBMS from "../../../mixins/initializeOtherFirebase"
import modalOperations from "../../../../mixins/modalOperations.js"
let uuid = require('uuid')


export default {
    
    name: 'BMSAddTask',
    data(){
        return {
            newTask: {
                id: uuid.v4(),
                title: "",
                icon: "",
                completionRate: 0,
                isNumeric: false,
                targetItems: 0,
                completedItems: 0,
            },
            bmsIcons: ["indoor", "outdoor", "operations", "megaphone", "analysis", "computer", "copper", "energy", "engineer", "pipe", "renewable", "solar", "task", "activate"]
        }
    },

    mixins: [modalOperations],

    props: ["task"],

    methods: {

        submitTask(){
            this.$emit("taskPosted", this.newTask)
            this.$emit("modalClosed")
            this.modalClosed()

        },

        fillExistingTaskDetails(){
            if(this.task){
                this.newTask.title = this.task.title
                this.newTask.icon = this.task.icon,
                this.newTask.completionRate = this.task.completionRate
            }
        }
    },

    created(){
        if(this.task){
            this.newTask = this.task
        }
    }
}

</script>

<template>
    <div @click="componentClicked" :style="selectedGradient" id="weatherToday" class="module-container px-4 flex items-center flex-row">
        <div class="flex h-full flex-col items-center justify-center w-1/2">
        
            <div id="weatherDegrees" class="text-7xl  font-bold">{{cleanWeatherData(weatherData.main.temp)}}&#176;</div>
            <div id="weatherTown" class="text-2xl">{{displayedTown}}</div>
        </div>

        <div class="flex items-center justify-center w-1/2">
            <img width="100" :src="require(`../../assets/icons/weather-icons/${weatherData.weather[0].icon}.png`)" alt="">
        </div>
   </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'WeatherToday',
  data(){ 
      return {
        weatherData: "",
        displayedTown: "Istanbul, Türkiye",
        selectedGradient: "",
        gradients: {
            day: {
                '01d': "background: radial-gradient(circle, rgba(255,255,255,1) 68%, rgba(246,251,203,1) 100%);",
                '02d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(251,253,232,1) 86%, rgba(209,209,209,1) 86%, rgba(246,251,203,1) 100%);",
                '03d': "background: radial-gradient(circle, rgba(255,255,255,1) 83%, rgba(252,252,223,1) 100%);",
                '04d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(209,209,209,1) 100%);",
                '09d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(103,135,255,1) 100%);",
                '10d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(103,135,255,1) 100%);",
                '11d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(103,135,255,1) 100%);",
                '13d': "background: radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(103,220,255,0.4500175070028011) 100%);",
            },

            night: {
                default: "background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(133,141,164,1) 0%, rgba(0,18,65,1) 100%); color: white;",
                clear: "background: linear-gradient(90deg, rgba(255,255,255,1) 68%, rgba(254,255,168,1) 100%);"
            },

        }
      }
  },

  methods: {    
    cleanWeatherData(weather){
          return weather.toFixed()
      },
      fetchData() {
      const accessKey = "ab08cd45ed2d559d56fbb3971780a415"
      const baseURL = "https://api.openweathermap.org/data/2.5/weather"
      const city = "Istanbul"
      const units = "metric"
      const finalURL = `${baseURL}?appid=${accessKey}&q=${city}&units=${units}`
      axios
        .get(finalURL)
        .then(
          (response) => {
            this.weatherData = response.data
            this.determineGradient()
          }
        )
    },

    determineGradient(){
       if(this.weatherData.weather[0].icon.slice(-1) === "n"){
           this.selectedGradient = this.gradients.night.default
       } else{
            this.selectedGradient = this.gradients.day[this.weatherData.weather[0].icon]

       }
    },
      
      componentClicked(){
          this.$emit("componentClicked")
      }
  },

  created(){
      this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
    #weatherDegrees{
        font-family: 'Encode Sans SC';
        font-weight: 600
    }
</style>
<script setup>


const isImage = (file) => {
    return file.type?.startsWith("image/") && !file.type?.endsWith("tiff")
}

defineProps(
        {
            file: {
                type: Object,
                required: true
            },
            previewImage : {
                type: Boolean,
                required: false,
                default: false
            }
        }
    )
</script>

<template>
<!--     <a class="h-full block w-full cursor-pointer relative z-20" v-if="file.type === 'text/html'" :href="file.url" target="_blank">
        <iframe :src="file.url" class="w-full h-full relative z-10"></iframe>
    </a> -->
    <a v-if="isImage(file) && previewImage" target="_blank" :href="file.url" class="w-full">
        <img :src="file.url" alt="gösel">
    </a>
    <a v-else :href="file.url" target="_blank" class="flex flex-row items-center">
        <img class="w-12 mr-3" v-if="isImage(file)" :src="file.url" alt="gösel">
        <img class="w-12 mr-3" w-12 mr-3 v-else-if="file.type === 'text/html'" src="../../assets/icons/html.png" alt="HTML Dosyası">
        <img class="w-12 mr-3" w-12 mr-3 v-else-if="file.type === 'application/pdf'" src="../../assets/icons/pdf.png" alt="PDF">
        <img class="w-12 mr-3" w-12 mr-3 v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword'" src="../../assets/icons/word.png" alt="Word Dosyası">
        <img class="w-12 mr-3" w-12 mr-3 v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel' " src="../../assets/icons/excel.png" alt="Excel Dosyası">
        <img class="w-12 mr-3" w-12 mr-3 v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.ms-powerpoint'" src="../../assets/icons/powerpoint.png" alt="Powerpoint Dosyası">
        <img class="w-12 mr-3" w-12 mr-3 v-else src="../../assets/icons/file-colored.png" alt="generic">
        
        <p class="text-2xl">{{ file.name }}</p>

    </a>
</template>
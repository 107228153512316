import firebase from "../firebase"

import type { metaField } from "../types/metas"
import { Ref } from "vue"

// Post data to firestore. This function should be preferred instead of the one in firebaseOperations.js

export const postData = async (dataToBeSent: any, mainCollection: string , document?: string, subCollection?: string): Promise<string | undefined> => {

    /* 
    
    Firebase references are accessible as "collection/doc/collection" and so on.
    There should rarely be need to specify a subCollection at the function arguments. 
    
    */

    let db
   
    if(document === undefined && subCollection === undefined){
        db = firebase.firestore().collection(mainCollection)    
    } else {
        db = firebase.firestore().collection(mainCollection).doc(document).collection(subCollection)
    }
    
    try {
        const docRef = await db.add(dataToBeSent);
        console.log(docRef.id);
        return docRef.id;
    } catch (error) {
        console.error("Error writing document: ", error);
        alert(`Bir hata oluştu: ${error}`);
        return undefined;
    }
  }

  // Delete document from firestore

    export const deleteData = async (col: string, document: string) => {
        const db = firebase.firestore().collection(col).doc(document)
        try {
            await db.delete()
        } catch (error) {
            console.error("Error deleting document: ", error);
            alert(`Bir hata oluştu: ${error}`);
        }
    }

    // Update document in firestore

    export const updateData = async (col: string, document: string, data: any) => {
        const db = firebase.firestore().collection(col).doc(document)
        try {
            await db.update(data)
        } catch (error) {
            console.error("Error updating document: ", error);
            alert(`Bir hata oluştu: ${error}`);
        }
    }

// Function get a list of all metas from the database.

export const getMetas = async (
    meta: string, 
    metasArray: metaField[], 
    isLoading: Ref<boolean>,
    orderByField?: string
) => {
    isLoading.value = true;
    const metaCollectionRef = firebase.firestore().collection(meta);

    const q = orderByField ? metaCollectionRef.orderBy(orderByField) : metaCollectionRef;

    // Return the promise
    return q.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            metasArray.push({
                id: doc.id,
                ...doc.data()
            });
        });
    }).finally(() => {
        // Set loading to false after the data is fetched
        isLoading.value = false;
    });
}


// Specific function for updating a "meta" entry in the database.

export const updateMeta = async (meta: string, id: string, data: any) => {
    const docRef = firebase.firestore().collection(`${meta}`).doc(id)
    try {
        await docRef.update(data)
    } catch (error) {
        alert(`Bir hata oluştu. Eğer bu hata devam ederse, lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    
    }
}

export const updateSingleField = async(documentPath: string, field: string, value: any) => {
    const docRef = firebase.firestore().doc(documentPath)
    try {
        docRef.update({ [field]: value })
     } catch (error) {
        alert(`Bir hata oluştu. Eğer bu hata devam ederse, lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    }
}

export const removeSingleArrayItem = async (documentPath: string, field: string, value: any) => {
    const docRef = firebase.firestore().doc(documentPath)
    try {
        docRef.update({ [field]: firebase.firestore.FieldValue.arrayRemove(value)})
     } catch (error) {
        alert(`Bir hata oluştu. Eğer bu hata devam ederse, lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    }
}

export const addSingleArrayItem = async (documentPath: string, field: string, value: any) => {
    const docRef = firebase.firestore().doc(documentPath)

    // Skip if the value already exists in the array

    const docSnap = docRef.get().then((doc) => {
        const data = doc.data()
        if(data[field].includes(value)){
            return
        }
    })

    try {
        docRef.update({ [field]: firebase.firestore.FieldValue.arrayUnion(value)})
     } catch (error) {
        alert(`Bir hata oluştu. Eğer bu hata devam ederse, lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    }
}

// Delete a single meta entry from the database.

export const deleteMeta = async (meta: string, id: string, localArray:any[]) => {
    const confirmation = confirm(`Bu ögeyi silmek istediğinize emin misiniz?`)
    if(!confirmation) return
    const db = firebase.firestore().collection(meta)
    const docRef = db.doc(id)
    docRef.delete().then(() => {
    const index = localArray.findIndex((item: any) => item.id === id)
    localArray.splice(index, 1)
    }).catch((error) => {
        alert(`Bir hata oluştu. Eğer bu hata devam ederse, lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    })
}

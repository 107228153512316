<template>
    <div class="main-container">
      <acca-vue-notification v-if="isGoalSubmitted" message="Hedef oluşturuldu!"></acca-vue-notification>
      
      <h2 class="form-title text-gray-800">Yeni Hedef Oluştur</h2>

      <step-navigation :currentStep="currentStep" :stepDetails="goalFormSteps"  class="col-start-3 col-end-10"></step-navigation>

      <component 
        @step1Complete="step1Complete"
        @decrementStep="decrementStep" 
        @step2Complete="step2Complete"
        :goal="goal"
        :goalCategories="goalCategories"

        class="dashboard-form" :is="stepComponents[currentStep]"></component>    </div>
</template>

<script>

import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import VueTooltip from '../reusable/VueTooltip.vue'
import stepNavigation from "../reusable/stepNavigation.vue"

import goalFormStep1 from "../reusable/form-widgets/goalsFormStep1.vue"
import goalFormStep2 from "../reusable/form-widgets/goalsFormStep2.vue"

import SingleGoalReviewer from "./goalWidgets/SingleGoalReviewer.vue"

import { mapGetters } from "vuex"

export default {
  name: 'AdminCreateGoal',
  data(){
    return {
      isAddingManuallyCompleted: false,
      currentStep: 0,
      goalCategories: [],
      stepComponents: [goalFormStep1, goalFormStep2, SingleGoalReviewer],
      goalFormSteps: ["Hedef Bilgileri", "Hedef Detay ve Türü", "Gözden Geçir"],
      isGoalSubmitted: false,
      goal: {
        name: "",
        startDate: new Date().toISOString().substr(0,10),
        expirationDate: "",
        type: "",
        period: "",
        goalType: "",
        category: "",
        target: "",
        description: "",
        completed: [],
        manualCompleted: 0,
        isPrimary: false,
        createdBy: "",
        cretead: new Date(),
        currencySymbol: "",
      },

    }
  },

  computed: {

    expirationDate(){

      console.log("Parent working")
      if(this.goal.period === ""){
        return null
      } else {
        
        // Period is a string, so we parse it to an integer

        let period = this.goal.period
        const periodParsed = period.split(" ")[0]
        const periodNumber = parseInt(periodParsed, 10)

        // Get current date (hours don't matter in this case)

        const goalStartDate = this.goal.startDate
        const startDateSplit = goalStartDate.split("-")

        // Format of startDateSplit is going to be Y - M - D

        const startDate = new Date(startDateSplit[0], startDateSplit[1] - 1, startDateSplit[2])

        // Add specified months to the date:

        let goalExpiration = new Date(startDate.setMonth(startDate.getMonth() + periodNumber))

        return goalExpiration
        }
    },

      ...mapGetters("user", {
            userProfile: "userProfile",
        })


  },

  mixins: [postData],

  components: {
    accaVueNotification: accaVueNotification,
    VueTooltip: VueTooltip,
    stepNavigation,
    goalFormStep1,
    goalFormStep2,
    SingleGoalReviewer,
  },

  methods: {

    parseDate(value){
      try{
        return Intl.DateTimeFormat('tr-TR').format(value)
      } catch {
        console.log("Error converting date")
      }
    },
    
    step1Complete(goalStep1){
      this.goal.name = goalStep1.name
      this.goal.startDate = goalStep1.startDate
      this.goal.period = goalStep1.period
      this.goal.expirationDate = goalStep1.expirationDate
      this.goal.goalType = goalStep1.goalType
      this.goal.target = goalStep1.target
      this.goal.description = goalStep1.description
      this.goal.isPrimary = goalStep1.isPrimary

      this.currentStep = 1

    },

  step2Complete(goalStep2, goalCategories){

      // Fetch categories to display them nicely on the summary page:

      this.goalCategories = goalCategories

      this.goal.category = goalStep2.category
      this.goal.goalType = goalStep2.goalType
      this.goal.target = goalStep2.target
      this.goal.target = goalStep2.target
      this.goal.currencySymbol = goalStep2.currencySymbol
      this.goal.manualCompleted = goalStep2.manualCompleted

      this.currentStep = 2
    },





    determineGoalType(goalType){
      this.goal.goalType = goalType
    },

    setExpirationDate(){
      if(this.goal.period != ""){
        console.log("Setting expiration date")
        this.goal.expirationDate = this.expirationDate
      }
      else {
        return
      }
    },

    submitGoal(){
      // If the goal type is not income, the currency value should be empty.
      if(this.goal.goalType != "totalPrice"){
        this.goal.currencySymbol = ""
      }

      this.goal.createdBy = this.userProfile.name



      try{
        this.isGoalSubmitted = true
        setTimeout(() => {
          this.isGoalSubmitted = false
        }, 1500)
        this.goBack()
      } catch {
        alert("İşlem tamamlanamadı. Lütfen bu hatayı yazılım ekibi ile paylaşın: Hedef için belirlenen bitiş tarihi sunucuya iletilemedi.")
      }
    },


    decrementStep(){
      this.currentStep = this.currentStep - 1
    },

      goBack(){
          this.$router.go(-1)
      }
  },




}
</script>

<style scoped lang="scss">

.acca_slider{
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 5px;
  height: 1rem;
  transition: all .2s;

  &::slider-thumb{
    width: 4rem;
    height: 4rem;
    background-color: rgb(51, 49, 49);
    cursor: pointer;
  }

  &::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgb(51, 49, 49);
    cursor: pointer;
    }

  }

  .acca-radio{
    transform: scale(150%);
  }

  nav.steps-container{
    font-family: "Encode Sans SC"
  }


</style>

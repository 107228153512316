<template>
    <div class="main-container charts-container !h-[90vh]">      
         <ac-loader class="col-start-1 col-end-13 bg-white w-full ac-loader" v-if="isLoadingData"></ac-loader>
          <apexchart
            v-else
            class="col-start-1 col-end-13 pb-6 bg-white rounded-2xl apexchart"
            :options="chartOptionsTotal"
            :series="seriesTotal"
            height="90%"
            type="bar"
        >
          </apexchart>
        </div>
</template>

<script>
import googleSheetOperations from "../../mixins/googleSheetOperations";
import VueApexCharts from 'vue3-apexcharts'
import acLoader from "../reusable/acLoader.vue"
import axios from 'axios'


export default {
  name: 'YearlyStats',
  data(){
    return {
      isLoadingData: false,
      usdRate: "",
      eurRate: "",
      chartOptionsTotal: {
         title: {
            
            text: "Yıllık Gelir-Gider Karşılaştırması",
            align: "center",
            offsetY: 5,
            style: {
                fontSize: "16px",
                
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          plotOptions: {
            bar: {
                distributed: false,
                backgroundBarColors: ["#40CC86", "#CC4040"],
            dataLabels: {
                position: "top",
            },
            }
          },
          dataLabels: {
            formatter: function(val){
                return val.toLocaleString() + " ₺"
            },
            enabled: true,
            offsetY: -23,
            style: {
                fontSize: "14px",
                colors: ["#000"]
            }
          },
        colors: ["#40CC86", "#CC4040"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
          yaxis: {            
            labels: {
            formatter: (val) => val.toLocaleString(),
            },
            seriesName: "TRY",
          },
          xaxis: {
              categories: ["2022", "2023", "2024"],
              labels: {
                style:{
                  fontSize: "16px",
                },
                offsetX: -2,
              },
          },
      },
        seriesTotal: [{
            name: "Gelir",
            data: [],
            },
            {
            name: "Gider",
            data: [],
            }
        ],
    }
  },

    components:{
      'apexchart': VueApexCharts,
      acLoader,
  },

  methods: {

    localizer(value){
        return value.toLocaleString()
    },

    manageMultiRangeData(response, range) {

    for (let i = 0; i < range.length; i++) {
        let tryIncome = 0;
        let tryExpense = 0;
        let usdIncome = 0;
        let usdExpense = 0;
        let eurIncome = 0;
        let eurExpense = 0;

        const currentRange = range[i];

        tryIncome += parseFloat(response[currentRange][5]);
        tryExpense += parseFloat(response[currentRange][2]);
        usdIncome += parseFloat(response[currentRange][3]);
        usdExpense += parseFloat(response[currentRange][0]);
        eurIncome += parseFloat(response[currentRange][4]);
        eurExpense += parseFloat(response[currentRange][1]);

        this.calculateTotals({ tryIncome, usdIncome, eurIncome }, { tryExpense, usdExpense, eurExpense });
        console.log(this.seriesTotal[0].data);

    }
    },
    async populateData(){
      this.isLoadingData = true
      const response = await this.getDataFromGS("1ib97WO0FvT2Lnk2kQo-vmXZXoNa2hgdA0nh1POSiPXc", "Sheet1", "A2:F20")

      // When the year changes, add the new row index to the range. 
      // With the current structure, all we need to do is to add the new range, like 19, 20, 21...

        this.manageMultiRangeData(response, [0, 17, 18])
      // Currency (income, expense)
      


   // await this.calculateTotals(allIncome, allExpense)
    this.isLoadingData = false

    },



  async fetchCurrencyDetails() {
    try {
      const response = await axios.get("https://hasanadiguzel.com.tr/api/kurgetir");
      const currenciesArray = response.data["TCMB_AnlikKurBilgileri"];
      const usdData = currenciesArray.find(currency => currency.CurrencyName === "US DOLLAR");
      const eurData = currenciesArray.find(currency => currency.CurrencyName === "EURO");
      this.usdRate = usdData["ForexSelling"];
      this.eurRate = eurData["ForexSelling"];
    } catch (error) {
      console.error("Error fetching currency details:", error);
    }
},

  async calculateTotals(income, expense){
    this.isLoadingData = true
    let totalIncome = 0
    let totalExpense = 0

    // Convert USD to TRY

    totalIncome = income["usdIncome"] * this.usdRate
    totalExpense = expense["usdExpense"] * this.usdRate

    //Convert EUR to TRY
    
    totalIncome += income["eurIncome"] * this.eurRate
    totalExpense += expense["eurExpense"] * this.eurRate

    // Add Try

    totalIncome += income["tryIncome"]
    totalExpense += expense["tryExpense"]

    //Send to Graph

    this.seriesTotal[0].data.push(totalIncome)
    this.seriesTotal[1].data.push(totalExpense)

  },
},

  mixins: [googleSheetOperations],

async created(){
  this.isLoadingData = true
  try {
    await this.fetchCurrencyDetails();
    await this.populateData();
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    this.isLoadingData = false;
  }
}
}

</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

.ac-loader{
    height: 80vh !important;
    align-items: center;
}

@media screen and (max-width: 950px){
  .charts-container{
    display: flex;
    flex-direction: column;
    width:100%;
  }

  .charts-container .apexchart{
    width: 100%;

  }

}

</style>

<script setup>

import { defineProps, onBeforeMount, onMounted, ref } from 'vue';

const props = defineProps({
    existingText: {
        type: String
    },
    placeHolder: {
        type: String,
    },
    isEditing: {
        type: Boolean,
        default: false
    }
})

const text = ref("")
const textArea = ref(null)

const emits = defineEmits(['completeEdit', 'cancelEdit', 'inputChanged'])


const handleNewLine = ((e) => {

    const editableDiv = document.getElementById("acEditor")

    if (e.keyCode === 13) {
        e.preventDefault();
        const selection = window.getSelection();
        const cursorPosition = selection.focusOffset;
        const text = editableDiv.textContent;
        const newText = text.slice(0, cursorPosition) + '\n' + text.slice(cursorPosition);
        editableDiv.textContent = newText;
        const newCursorPosition = cursorPosition + 1;
        const newRange = document.createRange();
        newRange.setStart(editableDiv.childNodes[0], newCursorPosition);
        newRange.setEnd(editableDiv.childNodes[0], newCursorPosition);
        selection.removeAllRanges();
        selection.addRange(newRange);
    }
})

const completeEdit = () => {
    text.value = textArea.value.textContent
    emits('completeEdit', text.value)
}

const cancelEdit = () => {
    text.value = ""
    emits('cancelEdit', text.value)
}

onBeforeMount(() => {
    if (props.existingText) {
        text.value = props.existingText
    }
})

onMounted(() => {
    if(textArea.value){
        textArea.value.focus()
    }
})

</script>

<template>
    <div class="editor-container">
        <div  id="acEditor" tabIndex="0" 
            :contenteditable="isEditing" 
            ref="textArea"
            @keyup.esc="cancelEdit"
            @blur="completeEdit" @keydown.enter="handleNewLine" class="ac-editor" :class="{ 'desc-editing': isEditing, }">
            {{ existingText }}
        </div>
    </div>
</template>

<style>
.editor-container {
    min-height: 6rem;
    display: flex;
    flex-direction: column;
}

.ac-editor {
    padding-top: .3rem;
    font-size: 1.4rem;
    line-height: 145%;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
    cursor: text;
    height: 100%;
    flex-grow: 1;
}

.ac-editor:focus {
    outline: none;
}
</style>
<template>
    <div class="ac-video-card">
        <a target="_blank" class="ac-video-card__link" :href="url">
            <img :src="thumbnail" :alt="`Video Görseli: ${title}`">
        </a>
        <img :src="require(`../../assets/icons/youtube-icon.png`)" alt="" class="logo-overlay">
        <div class="ac-video-card__overlay">
            <h2>{{title}}</h2>
        </div>
    </div>
</template>
<script>
export default {
  data(){
      return{
      }
  },

  props:["title", "url", "thumbnail"]
}
</script>

<style scoped>
    .ac-video-card{
        position:relative;
        width: 100%;
        cursor: pointer;
        border-radius: 10px;
    }

    .ac-video-card__link img {
        width: 100%;
        filter: brightness(.9);
        object-fit: cover;
        height: 25rem;
        border-radius: 10px;


    }

    .logo-overlay{
        position: absolute;
        top: 30%;
        left: 40%;
    }

    .ac-video-card__link img:hover {
        filter: brightness(.7)
    }

    .ac-video-card__overlay{
        position: absolute;
        bottom: 0;
        font-size: 1.6rem;
        color: white;
        background-color: rgba(0,0,0,.7);
        padding: 2rem 0 2rem 1rem;
        width: 100%;
    }
</style>
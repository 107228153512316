<template>
    <div id="ac-block-picker" class="flex">
        <div @click="selectBlock(block)" v-for="block in blocks" :key="block.id" class="ac-block" :class="{'underline': block.id === selectedBlock.id}">
            {{block.name}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'bmsBlockPicker',
    props: ["blocks", "selectedBlock"],
    methods: {
        selectBlock(block){
            this.$emit("blockChanged", block)
        }
    }
}
</script>

<style lang="scss" scoped>
    #ac-block-picker{
        padding: 1rem 2rem;
        font-size: 1.8rem;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ac-block{
        border-right: 1px;
        border-right-color: gray;
        border-right-style: solid;
        padding-right: 10px;
        margin-right: 1rem;
        cursor: pointer;
        box-sizing: border-box;
        text-underline-offset: 3px;

        &:hover{
            text-decoration: underline;
            text-decoration-color: #e3e3e3;
        }
    }

    .ac-block:last-of-type{
        margin-right: 0; 
        border-right: none;
        padding-right: 0;
    }

</style>
<template>
    <div class="main-container mt-10 relative">
      <accounting-links v-if="userProfile.department === 'finance'" />
      <general-links v-else/>
    </div>
</template>

<script>

import generalLinks from './bookmarks/generalLinks.vue';
import accountingLinks from './bookmarks/accountingLinks.vue';
import { mapGetters } from 'vuex'


export default {
  name: 'UsefulLinks',
  components: {
      generalLinks: generalLinks,
      accountingLinks: accountingLinks
  },

  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },

}
</script>

<style lang="scss">
  
</style>

<template>
    <div class="outer-container">
        <acca-modal-medium class="modal-long-screen" v-if="isAddingTask" @modalClosed="isAddingTask = false"
            title="Görev Ekle" height="60vh" width="50vw">
            <bms-add-task @modalClosed="isAddingTask = false" @taskPosted="addNewTask">
            </bms-add-task>
        </acca-modal-medium>

        <acca-modal-medium class="overflow-scroll" v-if="isPickingIcon" @modalClosed="isPickingIcon = false"
            title="Logo Seç" height="50vh" width="55vw">
            <bms-logo-picker @modalClosed="isPickingIcon = false" @logoSelected="selectExistingLogo" />
        </acca-modal-medium>

        <div class="main-container mt-10">

            <acca-vue-notification selectedType="success" v-if="isProjectCreated"
                message="Proje başarıyla oluşturuldu."></acca-vue-notification>


            <section id="add-project" class="col-start-1 col-end-13">



                <form class="dashboard-form" @submit.prevent="createProject">

                    <label class="single-line-label" for="title">Proje Adı
                        <vue-tooltip
                            explanation="Kullanıcılar tarafından görüntüleneceği için, buraya projenin resmi adını girmenizi, kısaltma veya Dansuk Mühendislik içinde kullanılan proje adlarını kullanmamanızı rica ederiz. Örnek: 'BJK Stadyum' yerine 'Vodafone Park' gibi.">?</vue-tooltip>
                    </label>
                    <input required type="text" id="title" class="dashboard-form__input" v-model="newProject.title">

                    <label class="text-3xl font-semibold mb-10" for="hasBlocks">Proje takibinde, iş listesi blok / bölüm /
                        faz bazında takip edilecek mi?</label>


                    <div class="flex mb-10">
                        <div class="flex items-center">
                            <input @click="newProject.blocks = []" v-model="newProject.hasBlocks"
                                class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="noBlocks"
                                name="noBlocks" :value="false">
                            <label @click="newProject.blocks = []" class="text-3xl mr-7" for="noBlocks">Hayır, proje bir
                                bütün olarak takip edilecek.</label>
                        </div>
                        <div class="flex items-center">
                            <input v-model="newProject.hasBlocks" class="flex text-xl mr-3 dashboard-form__input-radio"
                                type="radio" id="hasBlocks" name="hasBlocks" :value="true">
                            <label class="text-3xl" for="hasBlocks">Evet, tüm görevler blok bazında takip edilecek.</label>
                        </div>
                    </div>

                    <div v-if="newProject.hasBlocks" class="mb-10">
                        <label class="single-line-label b-5" for="images">Blok Listesi
                            <vue-tooltip
                                explanation="Lütfen kendi içinde iş takibi yapılacak tüm blok, aşama, faz veya bölümleri ekleyin. Blokları 'enter'a basarak veya virgül koyarak ayırabilirsiniz. 'Blok' kelimesi burada yalnızca örnek olarak kullanılmaktadır, projenin farklı bölümlerini istediğiniz gibi isimlendirebilirsiniz.">?</vue-tooltip>
                        </label>
                        <vue3-tags-input 
                            :add-tag-on-keys="[188, 13]" 
                            class="text-3xl" 
                            :tags="newProject.blocks"
                            @on-tags-changed="handleChangeTag">
                        </vue3-tags-input>
                    </div>


                    <label class="single-line-label mb-5" for="images">Şirket Logosu
                        <vue-tooltip
                            explanation="Buradan projenin kendi logosunu veya projeye özel bir logo yoksa şirket logosunu yükleyebilirsiniz.">?</vue-tooltip>
                    </label>

                    <div :class="newProject.companyLogo === '' ? 'items-stretch' : 'items-end'" class="flex justify-start">

                        <acca-dn-d-upload :allow="['.jpeg', '.jpg', '.webp', '.png']" single-file class=" w-1/4"
                            @filesChanged="handleLogoUpload($event)" />


                        <div class="flex flex-col ml-10 items-start w-1/4">
                            <div v-if="newProject.companyLogo === ''"
                                class="bg-gray-200 h-full flex items-center justify-center w-full mb-3 text-2xl text-center">
                                Henüz logo seçilmedi</div>
                            <img v-else class="mb-10 logo self-center" :src="newProject.companyLogo.src"
                                alt="current company logo">
                            <button type="button" @click="isPickingIcon = true"
                                class="bg-gray-100 hover:bg-gray-300 border rounded 400 text-2xl px-4 py-2"> Önceki
                                Logolardan Seç </button>
                        </div>

                    </div>

                    <label class="single-line-label mt-10 mb-5" for="images">Arka Plan Görseli
                        <vue-tooltip
                            explanation="Projenin DTS sayfasında arka plan resmi olarak kullanılacak görsel. Minimum 1000 piksel genişliğinde bir görsel yüklemenizi rica ederiz.">?</vue-tooltip>
                    </label>

                    <acca-dn-d-upload :allow="['.jpeg', '.jpg', '.webp', '.png']" single-file class="mb-8"
                        @filesChanged="handleCoverImageUpload($event)" />


                    <label class="single-line-label" for="link">Link
                        <vue-tooltip
                            explanation="Buraya varsa projenin veya şirketin web sitesini ekleyebilirsiniz. Bu alan zorunlu olmasa da kullanıcıların doğrudan DTS üzerinden kendi sitelerine ulaşmasını sağlayabilir.">?</vue-tooltip>
                    </label>
                    <input type="url" id="link" class="py-4 px-3 text-2xl focus:outline-black has-small-margin-bottom"
                        v-model="newProject.link">

                    <label class="single-line-label b-5" for="images">İş Listesi
                        <vue-tooltip
                            explanation="Proje kapsamında takip edilecek görevleri (İç Ünite Montajı, Dış Ünite Montajı, Otomasyon, Mekanik Süreçler, vb.) buradan ekleyebilirsiniz.">?</vue-tooltip>
                    </label>

                    <p v-if="newProject.hasBlocks" class="text-2xl emphasize-text mb-10">
                        Görev takibinin blok bazında takip edileceği bir proje ekliyorsunuz. Aşağıda eklediğiniz görevler,
                        başlangıçta her bir blok için eklenecektir. Bunları daha sonra proje yönetim ekranından bloklara
                        özel olarak düzenleyebilirsiniz. Bloklara ayrılmış projeler oluştururken, sadece görev başlıklarını
                        ekleyebilirsiniz. Bunların tamamlanma durumunu, proje oluşturulduktan sonra güncellemenizi rica
                        ederiz.
                    </p>

                    <div class="tasks-container">
                        <div class="tasks grid grid-cols-3 gap-8">
                            <div class="mb-10 p-10 border border-gray-300 select-none "
                                v-for="(task, index) in newProject.tasks" :key="task.id">
                                <div class="flex w-full mb-5 items-center justify-end">
                                    <img class="modular-task__status-action-icon mr-5 cursor-pointer"
                                        @click="editTaskName(index)" width="20px"
                                        :src="require(`../../../assets/icons/action-icons/edit.png`)">
                                    <img class="modular-task__status-action-icon cursor-pointer" @click="deleteTask(index)"
                                        width="20px" :src="require(`../../../assets/icons/action-icons/delete.png`)">
                                </div>

                                <div class="flex items-center">
                                    <img class="task-img" width="128px"
                                        :src="require(`../../../assets/icons/bms/${task.icon}.png`)">
                                    <input autofocus @blur="editedTaskIndex = ''"
                                        class="px-4 text-3xl ml-10 text-bold outline-black" type="text" v-model="task.title"
                                        v-if="index === editedTaskIndex">
                                    <span v-else class="ml-10 text-3xl font-semibold">{{ task.title }}</span>
                                </div>
                                <div v-if="!newProject.hasBlocks && !task.isNumeric" class="flex w-full">
                                    <input class="bnw-input-range" type="range" name="taskCompletion"
                                        v-model="task.completionRate" min="0" max="100">
                                    <input class="supporting-numeric-input text-2xl" type="number" name="taskCompletion"
                                        v-model="task.completionRate" min="0" max="100">
                                </div>
                                <div v-if="!newProject.hasBlocks && task.isNumeric"
                                    class="flex w-full items-center text-3xl">
                                    <input class="mr-5 border border-gray-500 pl-5 py-4 w-1/6 text-center" type="number"
                                        name="completedItems" id="completedItems" v-model="task.completedItems" min="0"
                                        :max="task.targetItems">
                                    <span class="text-3xl">/</span>
                                    <input class="ml-5 border border-gray-500 py-4 w-1/6 text-center " type="number"
                                        name="targetedItems" id="targetedItems" v-model="task.targetItems" min="1">
                                </div>
                            </div>

                        </div>
                        <button type="button" @click="isAddingTask = true"
                            class="bg-black text-white text-2xl px-2 py-6 w-1/12">Görev Ekle</button>
                        <button type="button" v-if="newProject.tasks.length < 4" @click="insertDefaultTasks"
                            class="bg-black text-white text-2xl px-2 py-6 w-2/12 ml-10">Standart Görevleri Ekle</button>
                    </div>



                    <button type="submit" class="dashboard-form__submit mr-auto ml-auto w-4/12 mt-10 ">Gönder</button>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import initializeBMS from "../../../mixins/initializeOtherFirebase"
import accaDnDUpload from "../../reusable/accaDnDUpload.vue"
import Vue3TagsInput from 'vue3-tags-input'
import bmsAddTask from "./components/bmsAddTask.vue"
import accaModalMedium from "../../reusable/modal-views/accaModal-medium.vue"
import accaVueNotification from "../../reusable/accaVueNotification.vue"
import bmsLogoPicker from "./components/bmsLogoPicker.vue"
import VueTooltip from '../../reusable/VueTooltip.vue'
import bmsMethods from "../../../mixins/bms/bmsMethods"

// import acLoader from "../../reusable/acLoader.vue"

export default {
    data() {
        return {
            isProjectCreated: false,
            isAddingTask: false,
            isPickingIcon: false,

            editedTaskIndex: "",
            defaultTasks: [],
            newProject: {
                title: "",
                hasBlocks: false,
                link: "",
                blocks: [],
                companyLogo: "",
                coverImage: "",
                tasks: [],
            },

            options: {
                multi: true,
                btnClass: 'multi-select__button'
            },

        }
    },

    methods: {

        handleChangeTag(tags) {
            this.newProject.blocks = tags
        },

        selectExistingLogo(logo) {
            this.newProject.companyLogo = logo
        },

        getDefaultTasks() {
            const tasksReference = this.initializeBMS().firestore().collection("meta").doc("defaultTasks").collection("tasks")
            tasksReference.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.defaultTasks.push({
                        id: doc.id,
                        title: doc.data().title,
                        icon: doc.data().icon,
                        isNumeric: doc.data().isNumeric ? true : false,
                        completionRate: doc.data().completionRate
                    })
                })
            })
        },

        insertDefaultTasks() {
            this.newProject.tasks.push(...this.defaultTasks)
        },

        addNewTask(task) {
            this.newProject.tasks.push(task)
        },

        deleteTask(taskIndex) {
            this.newProject.tasks.splice(taskIndex, 1)
        },

        editTaskName(taskIndex) {
            this.editedTaskIndex = taskIndex
        },

        validate() {
            // Can't add block-based project withouth having at leas one block.
            if (this.newProject.hasBlocks && this.newProject.blocks.length <= 0) {
                alert("Blok bazında takip edilecek bir proje oluşturuyorsunuz ama hiçbir blok eklemediniz. Lütfen en az bir blok ekleyin veya projeyi bloksuz olarak yaratın.")
                return false
            }

            return true
        },

        createProject() {

            const isProjectFormValid = this.validate()
            if (!isProjectFormValid) {
                return false
            }

            const projectsReference = this.initializeBMS().firestore().collection("projects")

            projectsReference.add({
                title: this.newProject.title,
                hasBlocks: this.newProject.hasBlocks,
                blocks: this.newProject.blocks,
                companyLogo: this.newProject.companyLogo,
                coverImage: this.newProject.coverImage,
                link: this.newProject.link,
            }).then((docRef) => {
                console.log("project created")
                const projectID = docRef.id
                const projectReference = projectsReference.doc(projectID)


                if (!this.newProject.hasBlocks) {
                    console.log("Running tasks creation.")
                    this.newProject.tasks.forEach((task) => {
                        projectReference.collection("tasks").add({
                            title: task.title,
                            icon: task.icon,
                            completionRate: task.completionRate,
                            isNumeric: task.isNumeric ? true : false,
                            targetItems: task.targetItems ? task.targetItems : 0,
                            completedItems: task.completedItems ? task.completedItems : 0
                        })
                    })
                } else {
                    this.newProject.blocks.forEach((block) => {
                        projectReference.collection("blocks").add({
                            name: block
                        }).then((docRef) => {
                            this.newProject.tasks.forEach((task) => {
                                projectReference.collection("blocks").doc(docRef.id).collection("tasks").add({
                                    title: task.title,
                                    icon: task.icon,
                                    completionRate: 0,
                                    isNumeric: task.isNumeric,
                                    targetItems: 0,
                                    completedItems: 0
                                }).catch((error) => {
                                    console.error("Error while creating tasks under blocks:", error)
                                })
                            })
                        }).catch((error) => {
                            console.error("Error while creating blocks: ", error)
                        })
                    })
                }

                projectReference.collection("updates").add({
                    title: "DTS Kurulumu Tamamlandı.",
                    text: "Projeniz, Dansuk Mühendislik'in yenilikçi hizmetlerinden Dansuk Takip Sistemi (DTS) kapsamında devreye alındı. Projenizde çalışmalar devam ederken, en son güncellemeleri ve projenizin genel tamamlanma durumunu buradan takip izleyebilirsiniz.",
                    images: [],
                    date: new Date()
                }).then(() => {
                }).catch((error) => {
                    console.error("Error while creating project updates", error)
                })

                projectReference.collection("completion").doc("completionMeta").set({
                    currentUpdate: "DTS'e hoşgeldiniz! Projeniz ilerledikçe, güncellemeleri buradan düzenli olarak sizlerle paylaşacağız.",
                    rate: 5
                }).then(() => {
                }).catch((error) => {
                    console.error("Error while creating completionMeta", error)
                })
            }).then(() => {
                this.isProjectCreated = true
                setTimeout(() => {
                    this.isProjectCreated = false
                    this.$router.push("home")
                }, 1500)
            }).catch((error) => {
                console.error("Error while creating project:", error)
            })





        },


        async handleLogoUpload(event) {
            try {
                const logoDetails = await this.uploadSingleToBMS(event, 'logos', true)
                this.newProject.companyLogo = {
                    "name": logoDetails[0],
                    "src": logoDetails[1]
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleCoverImageUpload(event) {
            try {
                const bgImageDetails = await this.uploadSingleToBMS(event, 'bgImages', true)
                this.newProject.coverImage = {
                    "name": bgImageDetails[0],
                    "src": bgImageDetails[1]
                }
            } catch (error) {
                console.error(error)
            }
        },
    },

    mixins: [initializeBMS, bmsMethods],

    components: {
        accaVueNotification,
        accaDnDUpload,
        Vue3TagsInput,
        accaModalMedium,
        bmsAddTask,
        bmsLogoPicker,
        VueTooltip: VueTooltip,


        // acLoader,
    },

    created() {
        this.getDefaultTasks()
    }

}
</script>

<style scoped lang="scss">.task-img {
    max-width: 5rem;
}

.modal-long-screen {
    top: unset;
    bottom: 15%;
}

.logo {
    width: 10rem;
}</style>

<template>
        <div class="main-container">
        <acca-vue-notification v-if="isDocumentRequestSent" message="Belge talebiniz oluşturuldu"></acca-vue-notification>

            <h2 class="form-title">Doküman Talebi Oluştur</h2>
            <form class="dashboard-form" @submit.prevent="sendDocumentRequest()" >

                <label class="single-line-label" for="title">Doküman Konusu</label>
                    <input
                        required
                        type="text"
                        id="title"
                        class="dashboard-form__input"
                        v-model="documentRequest.title"
                    >

                <label class="single-line-label" for="user">Talebi Oluşturan</label>
                    <input
                        required
                        readonly
                        type="text"
                        id="user"
                        class="dashboard-form__input bg-gray-100"
                        v-model="documentRequest.user"
                    >

                <label class="single-line-label" for="deadline">Teslim Tarihi
                    <vue-tooltip explanation="Bu dokümanı ne zaman kullanmanız gerekiyor? Daha uzun zaman aralıklarında daha kaliteli ve daha çok gözden geçirilmiş dokümanlar oluşturabiliriz.">?</vue-tooltip>
                </label>

                <div class="w-full flex space-between">
                    
                        <input
                            type="date"
                            id="deadline"
                            class="dashboard-form__input w-full"
                            v-model="documentRequest.deadline"
                        >
                </div>

                <label class="text-2xl font-semibold mb-5" for="date">Bu doküman bir şirket için özel olarak mı hazırlanacak, yoksa genel tanıtım amacıyla mı kullanılacak?</label>
                
                <div class="flex mb-5">
                    <div class="flex items-center">
                        <input v-model="documentRequest.documentAudience" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="general" name="documentAudience" value="general">
                        <label class="text-3xl mr-7" for="general">Genel</label>
                    </div>

                    <div class="flex items-center">
                        <input v-model="documentRequest.documentAudience" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="companySpecific" name="documentAudience" value="companySpecific">
                        <label class="text-3xl" for="companySpecific">Şirkete Özel</label>
                    </div>
                </div>
                <div class="flex flex-col mb-5" v-if="documentRequest.documentAudience === 'companySpecific'">
                    <label class="single-line-label" for="title">Şirket Adı</label>
                    <input type="text" id="title" class="dashboard-form__input" v-model="documentRequest.companyName">

                    <label class="text-2xl font-semibold mb-5" for="isEditable">Belgeyi birden fazla şirket için kullanacak mıyız?
                        <vue-tooltip explanation="Belgenin görselleri, başlığı ve ufak bölümleri değiştirilerek başka şirketler için kullanılması mümkün mü? Yoksa sadece tek bir şirket için mi kullanılacak?">?</vue-tooltip>
                    </label>
                        <div class="flex items-center">
                            <input v-model="documentRequest.isEditable" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="true" name="isEditable" value="true">
                            <label class="text-3xl mr-7" for="true">Evet</label>
                            <input v-model="documentRequest.isEditable" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="false" name="isEditable" value="false">
                            <label class="text-3xl mr-7" for="false">Hayır</label>
                        </div>
                </div>

                    <label class="single-line-label" for="body">Doküman Türü
                         <vue-tooltip explanation="Sunum, katalog, tanıtım, vb. gibi seçimleri buradan yapabilirsiniz. Doğrudan sunum yapmak için kullanılan powerpoint dosyaları ile, mail olarak gönderilecek Powerpoint dosyaları farklı şekillerde hazırlanabileceği için bunu özellikle belirtmenizi rica ederiz.">?</vue-tooltip>    
                    </label>
                    <input class="dashboard-form__input" type="text" v-model="documentRequest.documentType" list="documentTypes">
                    <datalist id="documentTypes">
                        <option>Powerpoint - Sunum İçin</option>
                        <option>Powerpoint - Göndermek İçin</option>
                        <option>Katalog</option>
                        <option>Kullanım Kılavuzu</option>
                        <option>Referans Listesi</option>
                    </datalist>


                    <label class="single-line-label" for="body">Açıklama
                         <vue-tooltip explanation="İhtiyaç duyduğunuz belge ile ilgili ek detayları buradan paylaşabilirsiniz: Yaklaşık kaç sayfa / slayt olmalı, nasıl bir içerik vermeliyiz, sonunda referans listesi paylaşılacak mı? Bu ve bunun gibi tüm detayları bu bölümden iletebilirsiniz.">?</vue-tooltip>    
                    </label>
           
                    <textarea
                            id="message"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="documentRequest.body">
                    </textarea>

                    <label class="text-2xl font-semibold mb-5" for="willBePrinted">Belgeyi basarak kullanacak mıyız?</label>
                        <div class="flex items-center mb-5">
                            <input v-model="documentRequest.willBePrinted" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="true" name="willBePrinted" value="true">
                            <label class="text-3xl mr-7" for="true">Evet</label>
                            <input v-model="documentRequest.willBePrinted" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="false" name="willBePrinted" value="false">
                            <label class="text-3xl mr-7" for="false">Hayır</label>
                        </div>

                    <label class="text-2xl font-semibold mb-5" for="images">Görseller
                        <vue-tooltip explanation="Elinizde bu belge için kullanabileceğimiz görseller veya örnek dosyalar varsa, buradan iletebilirsiniz.">?</vue-tooltip>    
                    </label>

                    <acca-dn-d-upload class="mb-8" @filesChanged="uploadFilesToStorage($event, 'documentRequests', documentRequest.images)" />


                <div class="button-row">
                    <button type="submit" @click="postData(documentRequest, 'requests', 'document', 'documentRequests')" class="dashboard-form__submit">Oluştur</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>
            </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import VueTooltip from '../reusable/VueTooltip.vue'
import accaVueNotification from "../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"


export default {
  name: 'DocumentRequests',
  data() {
      return {
          isDocumentRequestSent: false,
          documentRequest: {
              title: "",
              user: "",
              deadline: "",
              documentAudience: "", // Genel, şirkete özel
              companyName: "-",
              isEditable: false, // Sadece şirkete özel olacaksa
              documentType: "", // Sunum, sunulacak sunum, katalog, tanıtım, referans, diğer (açıklama kısmında)
              body: "",
              willBePrinted: false,
              created: new Date(),
              images: [],
          },

      }
  },

  methods: {

      goBack(){
          this.$router.go(-1)
      },

      sendDocumentRequest() {
          this.isDocumentRequestSent = true
          setTimeout(() => {
              this.isDocumentRequestSent = false
              this.goBack()
          }, 3000)
      }
  },
   computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },

    components: {
      VueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
      accaDnDUpload: accaDnDUpload,
  },

  mixins: [postData, firebaseOperations],

   created(){
      this.documentRequest.user = this.userProfile.name
  },

    beforeUpdate(){
        this.documentRequest.user = this.userProfile.name
    }

} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";

.dropzone .dz-message{
    padding: 4em 0;
}



  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<template>
    <div class="main-container items-stretch mt-10">
        <video-card v-for="video in videos" :key="video.id"
        :thumbnail="video.thumbnail"
        :title="video.title"
        :url="video.url"
        class="col-span-3"
        >
        </video-card>
    </div>
  </template>
  
  <script>
  
  import videoCard from "../reusable/videoCard.vue"
  import firebase from "firebase";
  
  export default {
    name: 'UsefulVideos',
    data(){
        return {
            videos: []
        }
    },
    components: {
        videoCard
    },
    methods: {
        getData(){
            let db = firebase.firestore().collection("training").doc("youtubeVideos").collection("videos")
            db
            .get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.videos.push({
                        id: doc.id,
                        title: doc.data().title,
                        url: doc.data().url,
                        thumbnail: doc.data().thumbnail,
                        subject: doc.data().subject
                    })
                })
            });
        },
  },

    created(){
        this.getData()
    },
    }
  </script>
  
  <style scoped lang="scss">
  
  </style>
  
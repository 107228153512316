<template>
    <div class="outer-container relative">
        <div @keyup.esc="updatedProject = ''" class="pt-10 main-container">

        <div class="col-start-1 col-end-13 w-full flex items-center justify-between">
            <list-filter @searchOccured="handleSearch" :searchTerm="searchTerm" />
            <small-icon-button :action="() => isDisplayingInfo = true" icon="info-icon" bgcolor="white">Bilgi</small-icon-button>
        </div>
        <table class="dash-table col-start-1 col-end-13">
        <thead>
            <tr>
                <th> Proje Adı </th>
                <th> Müşteri Adı </th>
                <th> Oluşturulma Tarihi </th>
                <th style="text-align:center;"> Durum </th>
                <th> Son Düzenleme </th>
            </tr>
            <tr v-for="project in filteredProjects" :key="project.id">
                <td> {{ project.project }} </td>
                <td> {{ project.client }} </td>
                <td> {{ project.startDate }} </td>
                <td 
                @click="updatedProject = project"
                class="text-center relative">  
                    <status-chip class="transform transition cursor-pointer w-5/6 hover:scale-105 " :status="project.status" :bg="statusBg[project.status]"></status-chip>
                    <div v-if="updatedProject.id === project.id" class=" small-container w-full block shadow-md z-10 rounded bg-white right-50 border text-2xl p-4 absolute">
                        <div class="flex items-center border-b-2 px-2 my-4">
                            <h4 class="font-bold ">Durumu Güncelle</h4>
                            <button @click.stop="updatedProject = ''" class="ml-auto text-2xl font-bold">x</button>
                        </div>
                            <div class="single-chip-holder w-full px-8 mb-4"
                            v-for="(value, status) in statusBg" :key="status" 
                            @click.stop="updateStatus(status)">
                                <status-chip 
                                class="w-full transition transform hover:scale-105 relative cursor-pointer"
                                :status="status" :bg="value">
                                </status-chip>
                            </div>
                    </div>
                </td>
                <td>  {{ project.lastUpdatedBy ? project.lastUpdatedBy : project.createdBy }} - {{ project.lastUpdateDate }} </td>
            </tr>
        </thead>
        </table>
        </div>
    
    <acca-modal-medium @modalClosed="isDisplayingInfo = false" height="90vh" width="50vw" v-if="isDisplayingInfo" title="DSS Projeler Listesi Hakkında">
            <div class="bg-white px-10 py-10 text-2xl h-5/6 overflow-y-scroll">
                <dss-info-text></dss-info-text>
            </div>
        </acca-modal-medium>
    </div>
    
</template>

<script>
    import firebase from "../../../firebase"
    import statusChip from "../../reusable/statusChip.vue"
    import { mapGetters } from "vuex"
    import ListFilter from "../../reusable/ListFilter.vue"
    import smallIconButton from "../../reusable/buttons/smallIconButton.vue"
    import accaModalMedium from "../../reusable/modal-views/accaModal-medium.vue"
    import dssInfoText from "./dssInfoText.vue"

    export default {
        name: "DSSProjects",
        data(){
            return {
                updatedProject: "",
                isDisplayingInfo: false,
                newStatus: "",
                searchTerm: "",
                projects: [],
                statusBg: {
                    "Kodlar Oluşturuldu": 1,
                    "Etiketler Yapıştırıldı": 2,
                    "Devreye Alındı": 3,
                    "Aktif Kullanılıyor": 4,
                    "İptal": 0
                }
            }
        },
        methods: {

            handleSearch(term){
                this.searchTerm = term
            },

            updateStatus(status){
                if(status){
                    const db = firebase.firestore().collection("digital").doc("dss").collection("projects").doc(this.updatedProject.id)
                    db.update({
                        status: status,
                        lastUpdatedBy: this.userProfile.name,
                        lastUpdateDate: new Date().toISOString().slice(0, 10)

                    }).then(() => {
                        this.updatedProject.status = status
                        this.updatedProject = ""
                    }).catch((error) => {
                        console.log(error)
                    })
                }
        },
            fetchData(){
                    let db = firebase.firestore().collection("digital").doc("dss").collection("projects").orderBy("startDate", "asc")
                    db
                    .get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                    this.projects.push(
                        {
                        id: doc.id,
                        project: doc.data().project,
                        client: doc.data().client,
                        lastUpdatedBy: doc.data().lastUpdatedBy,
                        lastUpdateDate: doc.data().lastUpdateDate ? new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().lastUpdateDate)) : "Oluşturuldu",
                        startDate: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().startDate)),
                        status: doc.data().status,
                        createdBy: doc.data().createdBy,
                            })
                        })
                    });
                },
        },

        components: {
            statusChip,
            ListFilter: ListFilter,
            smallIconButton: smallIconButton,
            accaModalMedium: accaModalMedium,
            dssInfoText,

        },

        computed: {

            // Write a computed property that filters the list of projects based on the searchTerm

    filteredProjects(){
        return this.projects.filter((project) => {
            return project.project.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 || project.client.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        })
    },
    ...mapGetters("user", {
        userProfile: "userProfile",
    })

},

        created(){
            this.fetchData()
        }
    }
</script>

<style lang="scss" scoped>
    table.dash-table{
        background-color: white;
        font-size: 1.5rem;
        border-radius: 2px;
    }

    table.dash-table thead tr th{
        padding: 1rem 0 1rem 1rem;
        color: white;
        background-color: black;
        text-align: left;
    }

    table.dash-table th{

    }

    table.dash-table td{
        padding: 1rem 0 1rem 1rem;
    }


</style>
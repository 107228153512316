import firebase from "../../firebase"
import initializeBMS from "../../mixins/initializeOtherFirebase"

const state = {
    loggedIn: false,
    userProfile: {},
    isAuthenticatedForBMS: false,
}

const getters = {
    userProfile: ({userProfile}) => userProfile,
    loggedIn: ({loggedIn}) => loggedIn,
    isAuthenticatedForBMS: ({isAuthenticatedForBMS}) => isAuthenticatedForBMS
};

const allowedUsers = [
    "berkuralcan@gmail.com",
]

const mutations = {
    USER_DETAILS(state, userProfile) {
        console.log("Running user details")
        
        // Define admin uuids per user

        let admin_uids = [
            "5ssHZrOn0zPZuXgr1wjGRbM7PMp2", // Deniz Dansuk
            "rG7GeyV0pTaMuInmYNwoylisz2l1", // Emine Güven
        ]

        let hr_uids = [
            "4emH4idJBTbF1Bh19zlOxr3pjQf2", // İK 
        ]

        let role = "emp"
        let allowsEdit = false

        // state.loggedIn = true
        // Assigning user roles on login

        if(userProfile.uid === '3gxr38Vxqnc4k0hOUxQUKaZSBp22' || userProfile.uid === 'N1lRaudRFVMaCKTWxpPE5Q0eTBb2') {
            role = 'superAdmin'
            allowsEdit = true
        } else if (admin_uids.includes(userProfile.uid)){
            role = "admin"
            allowsEdit = true
        } else if (hr_uids.includes(userProfile.uid)){
            role = "hr"
            allowsEdit = true
        }

        if(allowedUsers.includes(userProfile.email) || userProfile.email.toLowerCase().split("@")[1] === "dansuk.com.tr" || userProfile.email.toLowerCase().split("@")[1] === "dgbuildingsolution.de"){
            state.loggedIn = true
        } else {
            state.loggedIn = false
        }

        state.userProfile = {
            name: userProfile.displayName,
            picture: userProfile.photoURL,
            email: userProfile.email,
            role: role,
            allowsEdit: allowsEdit,
            department: userProfile.department,
        };


    },

    BMS_LOGGED_IN(state, user){
        if(user){
            state.isAuthenticatedForBMS = true
        }
    },
    BMS_LOG_OUT(state){
        state.isAuthenticatedForBMS = false
    },

    updateUserProfileDepartment(state, department) {
        console.log('Committing department to userProfile:', department);

        state.userProfile.department = department;
    },

    LOGOUT(state){
        state.loggedIn = false;
        state.userProfile = {};
    }

};

const actions = {
    async login(store) {
        if(store.state.loggedIn) return;
        var provider = new firebase.auth.GoogleAuthProvider();
        try {
            console.log("start login");
            await firebase.auth().signInWithRedirect(provider); // For signInWithRedirect, handling would differ
            console.log("success login");
        } catch(error) {
            console.log("fail login", error);
        }
    },
    
    async BMSLogin(store){
        console.log(store.state.isAuthenticatedForBMS)
        if(store.state.isAuthenticatedForBMS) {
            return
        }
        const provider = new firebase.auth.GoogleAuthProvider()

        try{
            await initializeBMS.methods.initializeBMS().auth().signInWithRedirect(provider)
        } catch(error) {
            console.log(error)
        }
    },

    async teamMemberDetails({ commit }, userId){
        console.log("Running team member details with userID:", userId);
        const userRef = firebase.firestore().collection("team").doc(userId);
        const userDoc = await userRef.get();
        if(userDoc.exists){
            commit('updateUserProfileDepartment', userDoc.data().department);
        } else {
            console.log("User does not exist.");
        }
    },


    async logout(){
        try {
            await firebase.auth().signOut();
        } catch(error) {
            console.log(error)
        }
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
<template>
    <article class="w-full bg-gray-100 h-3/4">
        <div class="container h-full overflow-y-scroll shadow-2xl bg-white px-4 py-12 w-2/4 mx-auto">
            <div class="flex items-center mb-8 justify-between">
                <h1 class="text-4xl font-bold">Bu Bölüm Hakkında</h1>
                <button @click="$emit('help-closed')" class="text-black text-xl text-blue-400 hover:text-blue-500">Paylaşıma
                    Dön</button>
            </div>
            <div class="text-body h-[140%]">
                <p class="text-2xl leading-normal mb-4"> Bu bölümden, üzerine tıkladığınız LinkedIn paylaşımı hakkında
                    detaylı bilgilere ve geri bildirim alanlarına ulaşabilirsiniz.</p>

                <p class="text-2xl leading-normal mb-4"> Sol tarafta, eğer paylaşımın metin ve görseli belirlenmiş ise,
                    paylaşımın LinkedIn üzerinde nasıl duracağını gösteren basit bir önizleme bulabilirsiniz.</p>

                <h2 class="text-3xl font-bold my-8"> Geribildirim Alanı </h2>

                <p class="text-2xl leading-normal mb-4"> Geri bildirim alanında, paylaşım ile ilgili görüşlerinizi
                    paylaşabilirsiniz. Alt taraftaki yorum bölümünü kullanarak, paylaşım ile ilgili düşüncelerinizi
                    istediğiniz şekilde iletebilirsiniz.</p>
                <p class="text-2xl leading-normal mb-4"> Özellikle bir bilgi yanlışı, düzeltilmesi gereken bir nokta, proje
                    verileri ile ilgili bir hata vb. tespit ederseniz, buradan yorum yaparak bize iletmenizi rica ederiz.
                </p>

                <h2 class="text-3xl font-bold my-8"> Oylama Özelliği </h2>

                <p class="text-2xl leading-normal mb-4"> Eğer yorum yapmanızı gerektiren özel bir durum yoksa, paylaşım ile
                    ilgili görüşlerinizi üst taraftaki oylama kısmından hızlı bir şekilde iletbilirsiniz. </p>
                <p class="text-2xl leading-normal mb-4"> Oylama bölümünde, "Beğen" ve "Beğenme" seçenekleri olduğunu
                    göreceksiniz. </p>
                <p class="text-2xl leading-normal mb-4"> Yönetici ekibimiz, bu iki butonun yanında "Paylaşımı Onayla" ve
                    "Paylaşımı Reddet" seçeneklerini de kullanabilecek. </p>

                <h2 class="text-3xl font-bold my-8"> Geri Bildirimler Hakkında Önemli Detaylar </h2>


                <p class="text-2xl leading-normal mb-4"> Yönetici ekibimiz tarafından onaylanan paylaşımlar, takvimdeki
                    sıralarına göre LinkedIn'de zamanlanarak paylaşılacak. Reddedilen paylaşımlar ise en kısa sürede
                    kaldıırlıp, yerlerine farklı paylaşımlar gelecek.</p>
            <p class="text-2xl leading-normal mb-4"> Özellikle yöneticiler tarafından reddedilmiş paylaşımlarda ayrıca
                geri bildirim vermenize gerek yok. Bunlar zaten kısa süre içinde takvimden kaldırılacağı için,
                reddedilen paylaşımlarda geri bildirim alanının kapalı olduğunu göreceksiniz. Onaylanan paylaşımlarda da
                aynı şekilde paylaşım takvimdeki sırasına göre yayına alınacak. Bunlar için yine de geri
                bildirimlerinizi paylaşabilirsiniz. </p>
            <p class="text-2xl leading-normal mb-4"> Olumsuz yönde oy kullandığınız paylaşımlarda neden paylaşımı
                olumsuz değerlendirdiğinizi de yorumlarınızı paylaşırsanız seviniriz. Böylece somut geri
                bildirimlerinize göre paylaşımları düzenleyebiliriz. </p>
            <p class="text-2xl leading-normal"> Bununla birlikte, paylaşımların takvime eklenip otomatik olarak
                LinkedIn'e gönderileceğini de hatırlatmak istiyoruz. Bu nedenle paylaşımlarla ilgili yorumlarınızı
                dikkate alabilmemiz için, sol tarafta göreceğiniz yayın tarihinden üç - dört hafta önce yorumlarınızı
                iletmeniz çok daha verimli olacakır.</p>
        </div>

    </div>


</article></template>
<script setup lang="ts">
import { computed, onBeforeMount } from "vue" 
import { modalClosed } from "@/mixins/visualFormatters";
import { blurBackground } from "@/mixins/visualFormatters";
const emit = defineEmits(['modal-closed'])

const props = defineProps({
title: {
            type: String,
            required: false,
        },
        height:{
            type: String,
            required: true,
        },

        width: {
            type: String,
            required: true
        }
})

const emitModalClosed = function(){
    modalClosed();
    emit('modal-closed')
}

const cssVars = computed(() => {
        return {
            '--modal-height': props.height,
            '--modal-width': props.width
        }
})

onBeforeMount(() => {
    blurBackground()
})


</script>



<template>
          <div :style="cssVars" class="ac-modal">
              <div class="flex justify-between items-center mb-5">
                  <h2 class="text-3xl font-bold">{{title}}</h2>
                  <button class="modal-close" @click="emitModalClosed"> &#x2715; </button>
              </div>
              <slot/>
          </div>
</template>


<style scoped>

.ac-modal{

    font-family: var(--gidole);

    position: absolute;
    background-color: white;
    border: 1px solid gray;

    height: var(--modal-height);
    width: var(--modal-width);

    top: calc(((100vh - var(--modal-height)) / 2));
    left: calc(((100vw - var(--modal-width)) / 2));

    border-radius: 10px;
    padding: 1.6rem;

    z-index: 10;

    filter: none !important;
}

.modal-close{
    font-size: 2rem;
    position: relative;
}

@media screen and (max-width: 800px){
    .ac-modal{
        top: 25%;
        left: 15%;
        width: 70vw;
        min-height: 45vh;
    }
}
</style>
<template>

<!-- Depracated. Use teleports/acMediumModal instead. -->


        <transition

            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeIn animate__fast"
            leave-active-class="animate__animated animate__fadeOut animate__fast"
        >
            <div :style="cssVars" class="ac-modal">
                <div class="flex justify-between items-center mb-5">
                    <h2 class="text-3xl font-bold">{{title}}</h2>
                    <button class="modal-close" @click="modalClosed"> &#x2715; </button>
                </div>
                <slot/>
            </div>

        </transition>
</template>

<script>

import modalOperations from "../../../mixins/modalOperations.js"


  export default{
      name: 'accaModalMedium',
      props: {
          title: {
              type: String,
              required: true,
          },
          height:{
              type: String,
              required: true,
          },

          width: {
              type: String,
              required: true
          }
      },

      mixins: [modalOperations],

      computed: {
          cssVars() {
              return {
                  "--height": this.height,
                  "--width": this.width
              }
          }
      },

      methods: {
      },
      mounted(){
          this.blurBackground()
      } 
  }

</script>

<style lang="scss" scoped>
.ac-modal{
    position: absolute;
    background-color: white;
    border: 1px solid gray;

    height: var(--height);
    width: var(--width);


    //TODO: These will be calculated based on the dynamic values, use calc() to approximate. The values below are estimated for 50 w and 50h.
    top: calc(((100vh - var(--height)) / 2));
    left: 25%;

    border-radius: 10px;
    padding: 1.6rem;

    z-index: 99999999999999;

    filter: none !important
}

.modal-close{
    font-size: 2rem;
    position: relative;
}
</style>
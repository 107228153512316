<script setup lang="ts">

const props = defineProps(["currentPage", "totalPages", ])
const emit = defineEmits(["navigateToPage"])

function navigateToPage(pageNumber: number) {
    emit("navigateToPage", pageNumber)
}

</script>


<template>
    <div class="pagination">
        <div class="pagination-info"> Sayfa {{ props.currentPage }} / {{ props.totalPages }}</div>
        <div class="flex gap-16">
            <button @click="navigateToPage(props.currentPage - 1)" :class="{'hidden': props.currentPage === 1}">&lt; Daha Yeni Kayıtlar</button>
            <button @click="navigateToPage(props.currentPage + 1)" :class="{'hidden': props.currentPage === props.totalPages}">Daha Eski Kayıtlar ></button>
        </div>
    </div>
</template>
<template>
    <article class="dss-table-info">
    <h2>
        Bu liste ne işinize yarayabilir?
    </h2>

    <p>
        DSS kapsamına aldığımız projelerin sayısı giderek artıyor. Daha önceden oluşturduğumuz projelerin takibini buradan yapabilirsiniz.
    </p>
    
    <p>
        DSS için kod oluşturulması planlanan bir projenin etiketlerinin daha önceden hazırlanıp hazırlanmadığını veya etiketleme işleminin yapılmadığını yan taraftaki arama kutusunu kullanarak görebilirsiniz.

    </p>

    <p>
        DSS kapsamında hangi müşterilerin aktif kullanım yaptığını buradan takip edebilirsiniz.

    </p>

    <p>
        DSS ile ilgili (veya genel olarak Dansuk Mühendislik ile ilgili) hizmetleri tanıtırken DSS için yaptığımız projelerin bir listesini referans olarak kullanabilirsiniz: "DSS projemiz, X adet projede, Y adet cihaz ile kullanılmaktadır..." gibi

    </p>

    <h2>
        Bu sayfayı nasıl daha kullanışlı hale getirebiliriz?
    </h2>

    <p>
        DSS kodlarını oluştururken bunların kaydını tutmak ve daha sonra buraya aktarmak çok zor değil. Fakat etiketleri oluşturup sizlere gönderdikten sonra benim projeleri takip etme şansım kalmıyor.
    </p>
    
    <p>
        Bu nedenle ben proje durumlarını sadece "Kodlar Oluşturuldu" aşamasına kadar değiştirebiliyorum. Eğer kodların yapıştırıldığını ve sistemin devreye alındığını biliyorsanız, "Durum" alanına tıklayarak bu bilgiyi güncelleyebilirsiniz.
    </p>

    <h2>
        Tekrarlayan Projeler Hakkında
    </h2>
    
    
    <p>
        Listeyi detaylı olarak incelerseniz, bazı projelerin listeye birden fazla eklendiğini görebilirsiniz. Bu ilk bakışta bir hata gibi gözükse de, aslında bir projenin listede birden fazla defa gözükmesinin mantıklı bir açıklaması var.
    </p>

    <p>
        Özellikle büyük projelerde (örneğin Business Istanbul) etiketleme blok bazlı olarak yapılıyor. Bazı müşterilerin, hem A Blok'ta, hem B Blok'ta ofisleri olabiliyor. Bu durumda şirket listede iki kez yer alıyor.
    </p>
    

    <p>
        Yine büyük projelerde, bazen müşteri bazında, bazen ise proje genelinde etiketleme yapıyoruz. Örneğin, İnci Holding için daha önce 24 Mayıs 2021 tarihinde etiketler hazırlanmış. Ancak daha sonra 10.01.2023 tarihinde tüm Business Istanbul için etiket çalışması yapılmış.
    </p>

    <p>
        İşin bu geçmişini daha iyi takip etmek açısından, bu tarz örnekler listede iki kez yer alıyor.
    </p>

    <p>
        Nadir durumlarda, bu iki kategoriye de girmeyen ancak iki kez etiket oluşturulan projeler de olabiliyor. Örneğin, Seba Taşevler projesi için hem 25 Mart 2022, hem de 15 Ocak 2023'te oluşturulmuş etiketler var.
    </p>

    <p>
        Bu da listeye bir hata olarak iki kez eklenmiş bir durum değil. Gerçekten bu proje için bir sebeple iki kez etiket üretilmiş. Bunun kaydını tutabilmek açısından, proje listede iki kez yer alıyor.
    </p>
    </article>
</template>

<style scoped>

h2{
    font-family: 1.8rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
p{
    margin-bottom: 1rem;
    font-size: 1.6rem;
    line-height: 1.5;
}
</style>
<template>
    <div class="bg-white  opacity-90 px-4 py-10 flex flex-col rounded-2xl">
        <div class="h-2/3 flex justify-center">
            <div class="text-5xl font-bold emphasize-text" :style="{ borderColor: borderColor}">
                <span class="text-5xl mr-1" v-if="goal.goalType ==='totalPrice'">$</span>{{completedGoals}} / <span class="text-5xl mr-1" v-if="goal.goalType ==='totalPrice'">$</span>{{goal.target}} 
            </div>
        </div>


        <h3 class="font-bold text-3xl mt-10 mb-2">{{goal.name}}</h3>
        <p class="mb-10 text-xl
        "> <strong>Bitiş Tarihi: </strong> {{ parseDate(goal.expirationDate) }}
             </p>
        <p class="mb-2 text-xl text-gray-800">Son Güncelleme:  </p>

    </div>
</template>

<script>

  export default{
      name: 'SingleGoalTrack',
      data() {
          return {
              chartOptions: {
                  toolbar: {
                      show: false
                  },
                  noData: {
                    text: "Veriler yükleniyor..."
                    }
              },
              series: this.chartData,
          }
      },

      computed: {
          completedGoals(){
              return this.goal.completedGoals
          },

      },

      methods: {
        parseDate(value){
        try{
            return Intl.DateTimeFormat('tr-TR').format(value)
        } catch {
            console.log("Error converting date")
        }
        }
      },

      props:{
          goal: Object,
          borderColor: String
      },

  }
</script>

<style scoped>

    .emphasize-text{
      font-family: 'Encode Sans SC';
      border: 10px solid;
      border-radius: 50%;
      width: 20rem;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }


</style>
<script setup lang="ts">
import { ref, nextTick } from "vue" 

const props = defineProps(["tag", "removable"])
const emits = defineEmits(["deleteTag"])
const isTagClicked = ref(false)
const deleteTag = ref<HTMLElement | null>(null)

const handleTagClick = async () => {
    isTagClicked.value = true
    await nextTick()
    deleteTag.value?.focus()

}

const emitDeleteTag = () => {
    emits("deleteTag", props.tag)
}


</script>

<template>
    <div @click.stop="handleTagClick" :style="{ 'backgroundColor': tag.color }" :title="tag.desc ? tag.desc : ''" class="ac-tag-container relative">
        {{ tag.name }}
        <div @click.stop="emitDeleteTag" ref="deleteTag" tabindex="0" @blur="isTagClicked = false" v-if="removable && isTagClicked" class="remove-tag">
            Kaldır
        </div>
    </div>
</template>

<style scoped>
.ac-tag-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: white;
    padding: .3rem 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    border-radius: 6px;
    cursor: pointer;
    z-index:1;
    user-select: none;
    width: max-content;
}

.remove-tag{
    display: none;
    background-color: inherit;
    filter: brightness(.8);
    position: absolute;
    top: 0;
    padding: .3rem 8px;
    font-size:1.4rem;
    width:100%;
    box-shadow: 0 0 0 1px var(--task-text-main);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:2;
}

.remove-tag:focus{
    outline: none;
}
</style>
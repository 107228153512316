<template>
    <div class="main-container mt-10 relative">

        <acca-modal @modalClosed="isInfoShown = false" class="col-start-4 col-end-9 positioned" v-if="isInfoShown">
          <span v-html="selectedText"></span>
        </acca-modal>

        <external-navigation hasInfo @infoClicked="showAdditionalInformation('theIstanbul')" is-any-desk class="grid-order-1" href="anydesk:878100084" title="The Istanbul" imgURL="the-istanbul.jpg"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('businessIstanbul')" is-any-desk class="grid-order-2" href="anydesk:403060533" title="Business Istanbul" imgURL="business-istanbul.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('antPlato')" is-any-desk class="grid-order-3" href="anydesk:791842473" title="Ant Plato" imgURL="antplato-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('sebaOfficeBoulevard')" is-any-desk class="grid-order-4" href="anydesk:811883499" title="Seba Office Boulevard" imgURL="seba-office-boulevard-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('tff')" is-any-desk class="grid-order-1" href="anydesk:123456" title="TFF" imgURL="tff-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('vodafoneArena')" is-any-desk class="grid-order-2" href="anydesk:123456" title="Vodafone Arena" imgURL="vodafone-arena-logo.png"></external-navigation>
    </div>
</template>

<script>

import ExternalNavigation from "../ExternalNavigation.vue"
import accaModal from "../reusable/accaModal.vue"
import { mapGetters } from 'vuex'
import onlineMonitoringKeys from "./online-monitoring-keys"

export default {
  name: 'OnlineMonitoringHome',
  data(){
    return {
      selectedText: "",
      isInfoShown: false,     
    }
  },
  components: {
      ExternalNavigation: ExternalNavigation,
      accaModal: accaModal
  },

  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },


  methods:{
    showAdditionalInformation(req){
      this.isInfoShown = true
      this.selectedText = onlineMonitoringKeys[req]
    }
  },

  created(){
    console.log(onlineMonitoringKeys)
  }
}
</script>

<style lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

  .has-smaller-icon img{
    width: 3rem;
  }

  .positioned{
    top: unset !important;
  }
</style>

<script setup lang="ts">

    import { onMounted, ref, computed } from "vue";
    import fileUploadFlexible from "../reusable/fileUploadFlexible.vue"
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import bmsMethods from "../../mixins/bms/bmsMethods";
    import { useGetOMProject, checkDataForSelectedPeriod, postExcelFile } from "../../composables/omPlatformFunctions";
    import acLoader from "../reusable/acLoader.vue";

    const route = useRoute();
    const store = useStore();
    const userName = computed(() => store.getters["user/userProfile"].name)
    const projectId = route.query.id as string;

    const existingFiles = ref<object | undefined>(undefined)
    
    const isOverridingExistingACP = ref<boolean>(false)
    const isOverridingExistingSoest = ref<boolean>(false)

    const { project, isLoadingProject } = useGetOMProject(projectId);

    const yearOptions = [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
    ]

    const months = [
    { id: "01", monthName: "Ocak" },
    { id: "02", monthName: "Şubat" },
    { id: "03", monthName: "Mart" },
    { id: "04", monthName: "Nisan" },
    { id: "05", monthName: "Mayıs" },
    { id: "06", monthName: "Haziran" },
    { id: "07", monthName: "Temmuz" },
    { id: "08", monthName: "Ağustos" },
    { id: "09", monthName: "Eylül" },
    { id: "10", monthName: "Ekim" },
    { id: "11", monthName: "Kasım" },
    { id: "12", monthName: "Aralık" }
    ];

   const selectedYear = ref<string>(new Date().getFullYear().toString());
   const selectedMonth = ref<object>(months[new Date().getMonth()]);

    const postOMFile = async (fileCategory: string, file) => {

        const selectedFile = file[0]

         const filePath = await bmsMethods.methods.uploadSingleToBMS(selectedFile, `onlineMonitoring/${projectId}/${selectedYear.value}/${selectedMonth.value.id}`, )

         const OMData = {
            uploadedBy: userName.value,
            path: filePath,
         }
         
         if(fileCategory === 'acp'){
            try{
                await postExcelFile(projectId, selectedYear.value, selectedMonth.value.id,  OMData, 'acpExcel')
            } catch(e){
                alert(`Bir hata oluştu: ${e}. Lütfen tekrar deneyin ve bu hata mesajını yazılım ekibiniz ile paylaşın`)
            }
         } else if (fileCategory === 'soest'){
            try{
                await postExcelFile(projectId, selectedYear.value, selectedMonth.value.id, OMData, 'soestExcel')
            }catch(e){
                alert(`Bir hata oluştu: ${e}. Lütfen tekrar deneyin ve bu hata mesajını yazılım ekibiniz ile paylaşın`)
            }
         }

        onDateChanged()
    }

    const onDateChanged = ( async () => {
        existingFiles.value = undefined
        isLoadingProject.value = true
        existingFiles.value = await checkDataForSelectedPeriod(projectId, selectedYear.value, selectedMonth.value.id)
        isLoadingProject.value = false
    } )

    onMounted( async () => {
        isLoadingProject.value = true
        existingFiles.value = await checkDataForSelectedPeriod(projectId, selectedYear.value, selectedMonth.value.id)
        isLoadingProject.value = false
    })


</script>

<template>
    <div class="main-container !content-start pt-8">
        <div class="actions-row flex items-center justify-start gap-4 col-start-1 col-end-13">
            <select @change="onDateChanged" class="outline-none px-8 py-2 rounded-lg bg-white text-2xl" name="year" id="year" v-model="selectedYear">
                <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
            </select>
            <select @change="onDateChanged" class="outline-none px-8 py-2 rounded-lg bg-white text-2xl" name="month" id="month" v-model="selectedMonth">
                <option v-for="month in months" :key="month.id" :value="month">{{ month.monthName }}</option>
            </select>
      </div>
        <div v-if="isLoadingProject" class="col-start-1 col-end-13 w-full p-4 text-2xl rounded bg-white min-h-[50vh]">
            <ac-loader class="!top-[35%] h-full left-[50%] !absolute"></ac-loader>
        </div>
      <div v-else class="col-start-1 col-end-13 w-full p-4 text-2xl rounded bg-white min-h-[50vh]">
        <h1 class="text-4xl font-semibold mt-4 mb-8">{{ project.name }}</h1>
        <div class="bg-blue-500 rounded flex items-center gap-4 text-white p-4 mb-16">
            <img width="24" class="brightness-0 invert" :src="require('../../assets/icons/info-icon.png')" />
            Şu anda <strong>{{ selectedMonth.monthName }} - {{ selectedYear }}</strong> için verileri güncelliyorsunuz. Lütfen yükleme işleminden önce, üst bölümden doğru ay ve yıl bilgisinin seçili olduğundan emin olun.
        </div>
        <div class="flex gap-16 items-stretch justify-between">
            <div class="acp-container w-full flex flex-col items-start">
                <h2 v-if="!isOverridingExistingACP" class="text-3xl font-semibold mb-8">ACP Excel</h2>
                <div class="upload-container w-full">
                    <div v-if="existingFiles['acpExcel'] && !isOverridingExistingACP" class="upload-container w-full">
                    <div class="bg-yellow-500 rounded flex items-start gap-4 text-white p-4 mb-16">
                        <img width="24" class="" :src="require('../../assets/icons/warning.png')" />
                        <p>{{ selectedMonth.monthName }} - {{ selectedYear }} için ACP Excel dosyası zaten yüklenmiş. Dosyayı görüntülemek için lütfen <a target="_blank" class="underline" :href="existingFiles.acpExcel.path"> buraya tıklayın</a>. Eğer yeniden yüklemek istiyorsanız, lütfen aşağıdaki butona tıklayın.</p>
                    </div>
                    <button @click="isOverridingExistingACP = true" class="ac-primary-button bg-white border border-black text-black">Yeniden Yükle</button>
                </div>
                    <file-upload-flexible v-else @files-changed="postOMFile('acp', $event)" :single-file="true" :allow="['.xls', '.xlsx',]"/>
                </div>
            </div>
            <div class="soest-container flex flex-col items-start w-full">
                <h2 class="text-3xl font-semibold mb-8">Soest Excel</h2>
                <div v-if="existingFiles['soestExcel'] && !isOverridingExistingSoest" class="upload-container w-full">
                    <div class="bg-yellow-500 rounded flex items-start gap-4 text-white p-4 mb-16">
                        <img width="24" class="" :src="require('../../assets/icons/warning.png')" />
                        <p>{{ selectedMonth.monthName }} - {{ selectedYear }} için Soest Excel dosyası zaten yüklenmiş. Dosyayı görüntülemek için lütfen <a target="_blank" class="underline" :href="existingFiles.soestExcel.path"> buraya tıklayın</a>. Eğer yeniden yüklemek istiyorsanız, lütfen aşağıdaki butona tıklayın.</p>
                    </div>
                    <button @click="isOverridingExistingSoest = true" class="ac-primary-button bg-white border border-black  text-black">Yeniden Yükle</button>
                </div>
                <div v-else class="upload-container w-full">
                    <file-upload-flexible @files-changed="postOMFile('soest', $event)" :single-file="true" :allow="['.xls', '.xlsx',]"/>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script setup lang="ts">


import { ref, onBeforeMount,  } from "vue"
import { getMetas, postData, updateData} from "../../../mixins/modularFirebaseOperations"

import type { EventCategory } from "@/types"


const eventCats = ref<EventCategory[]>([])

const emit = defineEmits(["event-added", "event-updated"])
const props = defineProps(["editedEvent"])


const eventName = ref(props.editedEvent?.title || "");
const eventCat = ref(props.editedEvent?.colorScheme || "");
const description = ref(props.editedEvent?.description || "");
const startDate = ref(props.editedEvent?.time.start || "");
const endDate = ref(props.editedEvent?.time.end || "");
const fullDay = ref(props.editedEvent?.fullDay || false);
const fullDayDate = ref(props.editedEvent?.fullDayDate || "");

const isLoadingEventCats = ref(false)

const manageDates = function(){
    if(fullDay.value){
        return {
            start: fullDayDate.value,
            end: fullDayDate.value
        }
    } else {
        return {
            start: startDate.value.replace("T", " "),
            end: endDate.value.replace("T", " ")
        }
    }
}

const addNewEvent = async function(){
    const newEvent = {
        title: eventName.value,
        category: eventCat.value,
        time:  manageDates(),
        description: description.value,
        isEditable: true,
    }
    if(props.editedEvent){
        newEvent.id = props.editedEvent.id
        await updateData("events", props.editedEvent.id, newEvent)
        newEvent.colorScheme = newEvent.category
        emit("event-updated", newEvent)
    } else {
        const docRefId = await postData(newEvent, "events") // Simply replace with post data.
        console.log("Document written with ID: ", docRefId);
        const newEventWithId = {id: docRefId , ...newEvent}
        emit("event-added", newEventWithId)
    }



}


onBeforeMount( async () => {
    await getMetas("metas/calendar/eventCats", eventCats.value, isLoadingEventCats)

} )

</script>   


<template>
    
    <form @submit.prevent class="ac-main-form">

        <div class="ac-form-control">
            <h2 class="subtitle my-4" for="amount">Başlık</h2>
            <div class="flex pl-2 items-center">
                <input v-model="eventName" type="text" required class="w-full title-field p-3">
            </div>
        </div>

        <div class="ac-form-control">
            <h2 class="subtitle my-4">Etkinlik Türü</h2>
            <div class="flex items-center">
                <input v-model="fullDay" id="fullDay" type="checkbox">
                <label for="fullDay">Tüm Gün?</label>
            </div> 
        </div>

        <div class="ac-form-control ">
            <h2 class="subtitle my-4">Etkinlik Zamanı</h2>
            <div class="flex  items-center">
                <input v-if="fullDay" v-model="fullDayDate" type="date" name="eventDate" id="eventDate" class="w-full p-3">
                <div v-else class="date-container flex w-full justify-between gap-4">
                    <div class="w-2/4">
                        <h3 class="third-title pb-2 border-b border-gray-200 mr-36">Başlangıç </h3>
                        <input v-model="startDate" type="datetime-local" name="startDate" id="startDate" class="w-full p-3">
                    </div>
                    <div class="w-2/4">
                        <h3 class="third-title pb-2 border-b border-gray-200 mr-36">Bitiş </h3>
                        <input v-model="endDate" type="datetime-local" name="endDate" id="endDate" class="w-full p-3">
                    </div>
                </div>
                
            </div>
        </div>


        <div class="ac-form-control">
            <h2 class="subtitle my-4" for="amount">Kategori</h2>
            <div class="flex items-center">
                <select v-model="eventCat" required class="w-full py-4 title-field p-3">
                    <option selected disabled :value="undefined">Lütfen Bir Kategori Seçin</option>
                    <option v-for="cat in eventCats" :key="cat.id" :value="cat.name">
                    {{ cat.name  }}
                    </option>
                </select>
            </div>
        </div>

        <div class="ac-form-control">
        <h2 class="subtitle my-4" for="amount">Açıklama</h2>
            <textarea v-model="description" type="text" required class="w-full title-field p-3">
            </textarea>
        </div>        

    <button type="button" @click="addNewEvent" class="ac-primary-button text-2xl mt-8">Etkinlik Oluştur</button>

    </form>
   


</template>
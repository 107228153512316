<template>
    <div class="main-container">
        <table class="table-auto border-collapse col-start-1 w-full col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Başlık </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Açıklama </th>
                    </tr>
                </thead>
                <tr v-for="change in plannedChangeData" :key="change.id">
                        <td class="message-content__data py-4 px-8 text-2xl text-left font-bold">{{change.title}}</td>
                        <td class="message-content__data py-4 px-8 text-left text-2xl">{{change.body}}</td>                
                    </tr>
            </table>
    </div>
</template>

<script>

import firebase from '../../firebase'

export default {
  name: 'PlannedChanges',
    data() {
        return {
            plannedChangeData: [],
        }
    },
      methods: {
        getData(){
            let db = firebase.firestore().collection("planned-changes")
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.plannedChangeData.push(
                {
                id: doc.id,
                body: doc.data().body,
                title: doc.data().title,
                    })
                })
            });
        },

      },
      mounted(){
          this.getData()
      }
}

</script>
<script setup>
    import { defineProps, defineEmits } from 'vue';
    import singleCommentDisplayer from './singleCommentDisplayer.vue';

    defineProps(["comments"])
    const emits = defineEmits(["deleteComment"])

    const emitCommentDelete = (commentIndex) => {
        console.log(commentIndex)
        emits('deleteComment', commentIndex)
    }

</script>

<template>
    <div class="comments-container">
        <single-comment-displayer @delete-comment="emitCommentDelete($event)" v-for="(comment, index) in comments" :key="comment.id" :comment="comment" :comment-index="index" /> 
    </div>
</template>

<style>



</style>
<script setup lang="ts">
import { reactive, computed, ref, onBeforeMount } from 'vue';
import VueTooltip from '../reusable/VueTooltip.vue';
import fileUploadFlexible from '../reusable/fileUploadFlexible.vue';
import multipleObjectInput from '../reusable/multipleObjectInput.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {createUnitNamesFromExcel, createOrEditProject, getOMProject,} from '@/composables/omPlatformFunctions';
import bmsMethods from "../../mixins/bms/bmsMethods";

const existingProjectID = ref<string | undefined>(undefined)

const store = useStore();
const route = useRoute();
const router = useRouter();

const isCalculatingUnitNames = ref(false)
const isChangingLogo = ref(false)
const userName = computed(() => store.getters["user/userProfile"].name)

// const unitDefinition = reactive({})

const project = reactive({
    name: "",
    logoURL: "",
    unitArrayInputType: "automatic",
    units: [],
    editedBy: userName.value,
})

const createOMProject = async () => {
    try {
        await createOrEditProject(project, existingProjectID.value ? existingProjectID.value : undefined)
        router.push('/om-platform/projects');
    } catch (error) {
        alert(`Proje oluşturulurken bir hata oluştu. Lütfen bu mesajı yazılım ekibiniz ile paylaşın: ${error}`)
    }

}

const goBack = () => {
    router.push('/om-platform/omProjects');
}

const uploadLogo = async (file: File[]) => {
    const logoUrl = await bmsMethods.methods.uploadSingleToBMS(file[0], 'logos/',  false)
    project.logoURL = logoUrl
    isChangingLogo.value = false
}

const fetchUnitNamesFromExcel = async (file: File[]) => {
    isCalculatingUnitNames.value = true
    const units = await createUnitNamesFromExcel(file[0], 1)
    project.units = units
    isCalculatingUnitNames.value = false
}

onBeforeMount( async () => {
    // Get the project id from the route, if any
    if(route.query.id){
        const routeProject = await getOMProject(route.query.id as string)
        existingProjectID.value = route.query.id as string
        if(routeProject){
            project.name = routeProject.name
            project.logoURL = routeProject.logoURL
            project.unitArrayInputType = routeProject.unitArrayInputType
            project.units = routeProject.units
        }
    }
})

</script>

<template>
    <div class="main-container mt-10">
        <form class="dashboard-form" @submit.prevent="createOMProject">
            <label class="single-line-label" for="name">Proje Adı
            </label>
            <input required type="text" id="name" class="dashboard-form__input" v-model="project.name">
            <label class="single-line-label mb-5" for="logo">Proje Logosu
                <vue-tooltip
                    explanation="Buradan projenin kendi logosunu veya projeye özel bir logo yoksa şirket logosunu yükleyebilirsiniz. Logo seçmemeniz durumunda varsayılan proje logo görseli kullanılacaktır.">?</vue-tooltip>
            </label>

            <div :class="project.logoURL === '' ? 'items-stretch' : 'items-end'" class="w-full">
                <file-upload-flexible @files-changed="uploadLogo" v-if="project.logoURL === '' || isChangingLogo" :single-file="true" />
                <div class="flex gap-8" v-else>
                    <img :src="project.logoURL" class="w-20 h-20" />
                    <button @click="isChangingLogo = true" type="button" class="ac-secondary-button">Değiştir</button>
                </div>
            </div>

            <h3 class="mt-8 mb-4 text-3xl font-semibold">Üniteler</h3>
            <p class="text-2xl italic">
                Kullanım verilerinin otomatik olarak hesaplanabilmesi için, ünite isimlerinin <strong>birebir Excel
                    dosyalarında gözüktüğü şekilde</strong> girilmesi gerekmektedir. Ünite isimlerini manuel olarak
                girebilir veya hata olmaması için herhangi bir aya ilişkin ACP raporu yükleyebilirsiniz.
            </p>



            <div class="flex my-4">
                <div class="flex items-center">
                    <input @click="project.units = []" v-model="project.unitArrayInputType"
                        class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="automatic"
                        name="automatic" value="automatic">
                    <label @click="project.units = []" class="text-2xl mr-7" for="automatic">Ünite isimleri dosyadan
                        alınsın.</label>
                </div>
                <div class="flex items-center">
                    <input @click="project.units = []" v-model="project.unitArrayInputType" class="flex text-xl mr-3 dashboard-form__input-radio"
                        type="radio" id="manual" name="manual" value="manual">
                    <label @click="project.units = []" class="text-2xl" for="manual">Ünite isimlerini manuel olarak gireceğim.</label>
                </div>
            </div>

            <div v-if="project.units.length > 0 || project.unitArrayInputType === 'manual'" class="flex my-34">
                <multiple-object-input :initial-value="project.units" @update-selected-options="project.units = $event" object-key="Ünite" object-value="Kapasite" />
            </div>
            <div class="w-full flex gap-4" v-else>
                <div v-if="isCalculatingUnitNames">Ünite isimleri alınıyor, lütfen bekleyin...</div>
                <file-upload-flexible v-else-if="!isCalculatingUnitNames && project.units.length < 1" class="w-full flex" @files-changed="fetchUnitNamesFromExcel($event)" :single-file="true"/>
            </div>
            <div class="flex justify-center items-center mt-8">
                <button type="submit" class="ac-primary-button text-2xl mr-8">Proje Oluştur</button>
                <button type="button" @click="goBack" class="ac-secondary-button text-2xl">Vazgeç</button>
            </div>
        </form>
    </div>
</template>
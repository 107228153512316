<template>
    <form class="dashboard-form">
      
    <label class="single-line-label" for="category">Hedef Kategorisi</label>

    <div class="flex w-full mb-10">
      <select required style="margin-bottom: 0px;" id="category" v-model="goalStep2.category" class="w-5/6">  
        <option disabled value="">Lütfen bir kategori seçin</option>
        <option v-for="(cat, index) in goalCategories" :key="index" :value="cat.id">{{cat.name}}</option>
      </select>
      <button type="button" @click="goalCatModalDisplayed = true" class="bg-green-200 ml-4 w-20 text-4xl hover:bg-green-300">+</button>
    </div>

      <label for="type" class="single-line-label">Hedef Ölçütü</label>
        
        <div class="mb-10 w-full flex flex-row">
          <div id="totalPrice" @click="determineGoalType('totalPrice')" :class="{ 'bg-white': 'totalPrice' === goalStep2.goalType }"
          class="text-3xl cursor-pointer text-gray-800 flex flex-col items-center border-gray-500 border-2 px-16 py-10 mr-10 hover:bg-white">
            <img class="mb-5" src="../../../assets/icons/goals-price.png" width="40" alt="icon"/>
            <h5>Gelir</h5>
        </div>
        
        
        <div id="totalNum" @click="determineGoalType('totalNum')" :class="{ 'bg-white': 'totalNum' === goalStep2.goalType }"
        class="text-3xl cursor-pointer text-gray-800 flex flex-col items-center border-gray-500 border-2 px-16 py-10 mr-10 hover:bg-white">
        <img class="mb-5 filter brightness-75" src="../../../assets/icons/goals-num.png" width="40" alt="icon"/>
        <h5>Adet</h5>
      </div>

        <div id="oneAndDone" @click="determineGoalType('oneAndDone')" :class="{ 'bg-white': 'oneAndDone' === goalStep2.goalType }"
        class="text-3xl cursor-pointer text-gray-800 flex flex-col items-center border-gray-500 border-2 px-16 py-10 hover:bg-white">
        <img class="mb-5 filter brightness-75" src="../../../assets/icons/check.png" width="40" alt="icon"/>
        <h5>Tek İş</h5>
      </div>
    </div>

    <transition appear type="animation" mode="out-in" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" class="notice" >
      <div v-if="goalStep2.goalType != '' && goalStep2.goalType != 'oneAndDone'" class="flex-col flex w-full">
        <label v-if="goalStep2.goalType === 'totalPrice'" for="goalValue" class="single-line-label" >Hedeflenen Toplam Tutar</label >
        <label v-else-if="goalStep2.goalType === 'totalNum'" for="goalValue" class="single-line-label" >Hedeflenen Toplam Proje Adedi</label >

        <div class="flex items-center">
              <input type="number" id="target" class="dashboard-form__input" :class="{ ' w-10/12': goalStep2.goalType === 'totalPrice', 'w-full': goalStep2.goalType === 'totalNum' }" v-model="goalStep2.target" />
              <select v-model="goalStep2.currencySymbol" class="w-1/12 ml-4 inline" v-if="goalStep2.goalType === 'totalPrice'" name="currencySymbol" id="currencySymbol">
                <option selected value="₺">₺</option>
                <option value="$">$</option>
                <option value="€">€</option>
              </select>
        </div>



      </div>
    </transition>

    <transition appear type="animation" mode="in-out" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" >
      <div v-if="goalStep2.goalType != '' && goalStep2.target != '' && goalStep2.goalType != 'oneAndDone'">
        <h4 class="single-line-label mb-5">
          Önceden tamamlanan projeleri eklemek istiyor musunuz?
        </h4>

        <input class="mb-10 mr-3 acca-radio" type="radio" id="isAddingManuallyCompletedTrue" value="true" v-model="isAddingManuallyCompleted" />
        <label for="isAddingManuallyCompletedTrue" class="mb-5 text-3xl mr-5" >Evet</label >

        <input class="mb-10 mr-3 acca-radio" type="radio" id="isAddingManuallyCompletedFalse" value="" v-model="isAddingManuallyCompleted" />
        <label for="isAddingManuallyCompletedFalse" class="mb-5 text-3xl" >Hayır</label >
      </div>
    </transition>

    <div v-if="isAddingManuallyCompleted" class="flex-col flex w-full mb-5">
      <label
        v-if="isAddingManuallyCompleted && goalStep2.goalType === 'totalPrice'"
        for="goalValue"
        class="single-line-label"
        >Mevcut Tutar
        <vue-tooltip
          explanation="Eklemekte olduğunuz hedef ile ilgili daha önceden tamamlanmış olan çalışmalar varsa, bunların tutarını buradan belirtebilirsiniz."
          >?</vue-tooltip
        >
      </label>

      <label
        v-else-if="goalStep2.goalType === 'totalNum'"
        for="goalValue"
        class="single-line-label"
        >Mevcut Adet
        <vue-tooltip
          explanation="Eklemekte olduğunuz hedef ile ilgili daha önceden tamamlanmış olan çalışmalar varsa, bunların adedini buradan belirleyebilirsiniz.."
          >?</vue-tooltip
        >
      </label>

      <div class="flex items-center w-full">
        <input
          v-if="goalStep2.goalType != 'oneAndDone'"
          :type="goalStep2.target > 100 ? 'number' : 'range'"
          id="manualCompleted"
          min="0"
          :max="goalStep2.target - 1"
          class="w-10/12 mr-10"
          :class="goalStep2.target > 100 ? 'dashboard-form__input' : 'acca_slider'"
          v-model="goalStep2.manualCompleted"
        />

        <p v-if="goalStep2.goalType != 'oneAndDone'" class="text-3xl font-bold">{{ goalStep2.manualCompleted }}
          <span v-if="goalStep2.currencySymbol != '' && goalStep2.goalType === 'totalPrice' ">{{goalStep2.currencySymbol}}</span>
        </p>
      </div>
    </div>


    <div class="button-row">
        <button type="button" @click="decrementStep" class="dashboard-form__button-secondary button__cancel"> Geri </button>
        <button type="button" @click="saveAndIncrementStep" class="dashboard-form__submit bg-green-400 hover:bg-green-500">İlerle</button>
    </div>


    <acca-modal-medium @modalClosed="goalCatModalDisplayed = false" v-if="goalCatModalDisplayed" title="Yeni Kategori Ekle" width="50vw" height="85vh">
          <goal-category-edit @modalClosed="goalCatModalDisplayed = false" @editingComplete="displayCompletedEdit" :category="newCategory"></goal-category-edit>
    </acca-modal-medium>




    </form>
</template>

<script>

import firebase from "../../../firebase"
import VueTooltip from '../VueTooltip.vue'
import GoalCategoryEdit from "../../goals/goalWidgets/GoalCategoryEdit.vue"
import accaModalMedium from "../modal-views/accaModal-medium.vue"


export default {
  name: "goalFormStep2",
  props: ["goal"],
  data(){
    return {
      goalCatModalDisplayed: false,
      isAddingManuallyCompleted: "",
      newCategory: {
        name: "", 
        icon: "",
        catColor: "",
      },
      goalCategories: [],
      goalStep2: {
        goalType: "",
        target: "",
        currencySymbol: "₺",
        category: "",
        manualCompleted: "",
      }
    }
  },
  components: {
    VueTooltip: VueTooltip,
    GoalCategoryEdit,
    accaModalMedium
  },


  methods: {

    fetchGoalCategories(){
      console.log("Running fetch goals")
      const db = firebase.firestore().collection("goalMetas").doc("goalCategories").collection("categories")

      db.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.goalCategories.push({
            id: doc.id,
            name: doc.data().name,
            icon: doc.data().icon,
            catColor: doc.data().catColor
          }
          )
        })
      })
      },

      fillIfGoalAlreadyExists(){

        let stepFields = ["goalType", "target", "currencySymbol", "category", "manualCompleted"]

        if(this.goal.manualCompleted > 0){
          this.isAddingManuallyCompleted = true
        }
        stepFields.forEach((field) => {
          if(this.goal[field] != undefined){
            this.goalStep2[field] = this.goal[field]
          }
        })

        },

    validateManualCompleted(){
      const remainingGoals = this.goalStep2.target - this.goalStep2.manualCompleted
      console.log(remainingGoals)
      const unnecessaryPercentile = (this.goalStep2.target / 100) * 5
      console.log(unnecessaryPercentile)

      if(remainingGoals <= unnecessaryPercentile){
        const addAlmostCompletedGoal = confirm(`Bu hedef için ölçüt olarak ${this.goalStep2.target} seçtiniz ve halihazırda ulaşılmış olan miktarı ${this.goalStep2.manualCompleted} olarak belirlediniz. Bu hedef neredeyse tamamlanmış gibi gözüküyor. Sayılarda bir yanlışlık olmadığına veya bu hedefi gerçekten sistem üzerinden takip etmek istediğinize emin misiniz? `)
        return addAlmostCompletedGoal
      } else {
        return true
      }

    },

    addNewCategory(){

      if(this.newCategory.length < 2){
        alert("Kategori adı en az iki karakter olmalıdır!")
      } else {
        const docRef = firebase.firestore().collection("goalMetas").doc("goalCategories").collection()
        docRef.update({
          categories: firebase.firestore.FieldValue.arrayUnion(this.newCategory)
        })
        this.goalCategories.push(this.newCategory)
        this.newCategory = ""
        this.goalStep2.category = this.goalCategories[this.goalCategories.length -1]
        this.goalCatModalDisplayed = false
      }
      
    },


    determineGoalType(goalType){
        this.goalStep2.goalType = goalType
      },

    decrementStep(){
      this.$emit("decrementStep")
    },

    saveAndIncrementStep(){
      const manuallyCompletedProblemConfirmation = this.validateManualCompleted()
      if(manuallyCompletedProblemConfirmation){
        this.$emit("step2Complete", this.goalStep2, this.goalCategories)
      }
    }
  },

  created(){
    this.fillIfGoalAlreadyExists();
  },

  mounted(){
    this.fetchGoalCategories();
  }
};
</script>

<style lang="scss" scoped>

  button.modal-button{
    font-size: 1.6rem;
    padding: 5px 10px;
  }

.acca_slider{
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  height: 1rem;
  transition: all .2s;

  &::slider-thumb{
    width: 4rem;
    height: 4rem;
    background-color: rgb(51, 49, 49);
    cursor: pointer;
  }

  &::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgb(51, 49, 49);
    cursor: pointer;
    }

  }
</style>
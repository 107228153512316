<template>
    <div class="modal-container">
        <div class="message-creator">
            <h2 class="message-creator__title">Yeni Mesaj Yayımla</h2>
            <p class="message-creator__exp">Buradan ekibin geri kalanıyla paylaşılması için yeni bir mesaj yayımlayabilirsiniz. Mesajınız herhangi bir websitesine bağlantı içeriyorsa, bunu link olarak ilgili bölümden mesajınıza ekleyebilirsiniz.</p>
            
            <form v-on:submit.prevent="postData(message, 'messages', 'main', 'all'); messageSubmitted()"
            >
                <label class="single-line-label" for="title">Konu</label>
                    <input
                        required
                        type="text"
                        id="title"
                        class="message-creator__input"
                        v-model="message.title"
                    >
                
                <label class="single-line-label" for="message">Mesaj</label><br>
                    
                    <textarea
                            required
                            id="message"
                            rows="5"
                            class="message-creator__message"
                            v-model="message.body">
                    </textarea>

                <label class="single-line-label" for="link">Link</label>
                    <input
                        type="url"
                        id="link"
                        class="message-creator__input has-small-margin-bottom"
                        v-model="message.link"
                    >
                                    
                <label for="is_kept">
                    <input
                            type="checkbox"
                            id="is_kept"
                            value="SendMail"
                            v-model="message.is_kept"> Mesajı Sakla
                            <vue-tooltip explanation="Performans ve depolama optimizasyonu için, sistem en fazla otuz mesajı kaydeder. Uzun vadede silinmemesini istediğiniz mesajlar için, bu kutuyu işaretleyebilirsiniz.">?</vue-tooltip>
                </label>
                    
                <label for="is_emphasized">
                        <input
                                type="checkbox"
                                id="is_emphasized"
                                value="SendMail"
                                v-model="message.is_emphasized"> Mesajı Vurgula
                                <vue-tooltip explanation="En önemli mesajlarınızın, Son Haberler bölümünde diğer mesajlara göre daha vurgulu gözükmesi için bu kutuyu işaretleyebilirsiniz.">?</vue-tooltip>
                    </label>
                <div class="button-row">
                    <button type="submit" class="message-creator__submit">Yayımla</button>
                    <button type="button" @click="closeMessage" class="message-creator__submit button__cancel">İptal</button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>

import VueTooltip from '../reusable/VueTooltip.vue'
import postData from "../../mixins/firebaseOperations"
import { mapGetters } from "vuex"

export default {
  name: 'CreateMessage',
  data(){
      return {
          isMessageSubmitted: false,
          message: {
              title: "",
              body: "",
              link: "",
              user: "",
              date: new Date(),
              is_kept: false,
              is_emphasized: false,
          },
      }
  },
  methods: {
      closeMessage(){
          this.$emit("closeMessage")
      },

      messageSubmitted(){
          this.$emit("messageSubmitted")
      }
  },
    computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },


    mixins: [postData],

    components: {
      VueTooltip: VueTooltip
  },

  mounted(){
      this.message.user = this.userProfile.name
  }
}
</script>

<style scoped lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";

  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}

</style>

<template>

<div class="outer-container mt-10">
    <div class="main-container">

        <acca-vue-notification selectedType="coolWarning" v-if="isEditingCurrentUpdate" message="Düzenlemeniz bitince enter'a basın!"></acca-vue-notification>
        <acca-vue-notification selectedType="success" v-if="postSuccess" message="Güncellemeler başarıyla yapıldı."></acca-vue-notification>
        {{changedTasks}}
        <div class="project-completion-container col-start-1 col-end-13 bg-white">

            <h3>Proje Tamamlanma Durumu</h3>
                <div v-if="isLoadingTasks" class="loader-elasticball">
                    <div class="loader-elasticball__ball"><i></i></div>
                </div>

            <div v-else class="flex project-completion-general">
                <input type="range" name="projectCompletion" id="projectCompletion" v-model="projectCompletion" min="0" max="100">
                <input type="number" name="projectCompletion" id="projectCompletion" v-model="projectCompletion" min="0" max="100">
            </div>


            <h3>Genel Durum Güncellemesi</h3>
            <p @dblclick="isEditingCurrentUpdate = true" class="text-3xl hover:text-gray-500 cursor-pointer" v-if="!isEditingCurrentUpdate">
                {{currentUpdate}}
            </p>
            <input @keyup.enter="isEditingCurrentUpdate = false" autofocus class="w-full py-3 px-3 text-3xl border border-black outline-black focus:outline-black"  v-else type="text" v-model="currentUpdate">
            <button @click="submitCompletion" class="text-2xl mt-6 border border-gray-500 bg-black text-white px-3 py-3 hover:bg-gray-700 hover:text-white active:-scale-90">Güncelle</button>

        </div>

        <div class="tasks-container col-start-1 col-end-13 bg-white">
            <div class="tasks-container-title-row row-start-1 row-end-2 flex justify-between">
                <h3>İş Listesi</h3> 
                <button @click="isEditingTask = true" class="text-2xl border border-gray-500 px-3 py-3 hover:bg-black hover:text-white">Yeni Ekle</button>
            </div>
            
            <bms-block-picker @blockChanged="changeSelectedBlock" class="ml-auto mr-auto col-start-1 col-end-3" v-if="(hasBlocks && blocks.length > 0 )" :selectedBlock="selectedBlock" :blocks="blocks"/>

            <div v-if="isLoadingTasks" class="loader-elasticball">
                <div class="loader-elasticball__ball"><i></i></div>
            </div>

            <div v-else-if="!isLoadingTasks && hasBlocks && blocks.length === 0" class="flex flex-col items-center justify-self-center col-start-1 col-end-13">
                <img width="64" :src="require(`../../../assets/icons/warning.png`)">
                <p class="text-2xl w-2/4">Bu proje blok bazında takip edilecek bir proje olarak oluşturulmuş ancak hiçbir blok eklenmnemiş. Bloklu projelerde görev takibi yapabilmek için önce blokların belirlenmesi gerekiyor. Lütfen önce bir önceki ekrana dönüp "Proje Bilgileri" alanından en az bir blok ekleyin.</p>
            </div>


            <div v-else-if="!isLoadingTasks && hasBlocks" v-for="task in selectedBlock.tasks" :key="task.id" class="modular-task">
                <img :src="require(`../../../assets/icons/bms/${task.icon}.png`)" alt="single task image" class="modular-task__img">
                <div class="modular-task__status">
                    <div class="modular-task__status-action">
                        <img class="modular-task__status-action-icon" @click="completeTask(task)" width="22px" :src="require(`../../../assets/icons/action-icons/complete.png`)">                
                        <img class="modular-task__status-action-icon" @click="editTaskName(task)" width="20px" :src="require(`../../../assets/icons/action-icons/edit.png`)">
                        <img v-if="!task.isNumeric" class="modular-task__status-action-icon" @click="toggleNumeric(task)" width="20px" :src="require(`../../../assets/icons/action-icons/number.png`)">                
                        <img v-else class="modular-task__status-action-icon" @click="toggleNumeric(task)" width="20px" :src="require(`../../../assets/icons/action-icons/percent.png`)">                       
                        <img class="modular-task__status-action-icon" @click="deleteTask(task)" width="20px" :src="require(`../../../assets/icons/action-icons/delete.png`)">                
                    </div>
                    <h3 v-if="task.id != editedTask" class="modular-task-heading">{{task.title}}</h3>
                    <input autofocus @keyup.enter="editedTask = ''" @blur="editedTask= ''" class="px-4 text-bold outline-black" type="text" v-model="task.title" v-else>
                    <div v-if="!task.isNumeric" class="flex w-full percent-completion">
                            <input @change="determineChangedInput(task)" type="range" name="projectCompletion" id="projectCompletion" v-model="task.completionRate" min="0" max="100">
                        <input @change="determineChangedInput(task)" type="number" name="projectCompletion" id="projectCompletion" v-model="task.completionRate" min="0" max="100">
                    </div>
                    <div v-else class="flex mt-2 numeric-completion justify-start">
                        <input @change="determineChangedInput(task)" class="mr-5 border border-gray-500 pl-5 text-center" type="number" name="completedItems" id="completedItems" v-model="task.completedItems" min="0" :max="task.targetItems">
                        <span class="">/</span>
                        <input @change="determineChangedInput(task)" class="ml-5 border border-gray-500 text-center " type="number" name="targetedItems" id="targetedItems" v-model="task.targetItems" min="1">
                    </div>
                </div>
            </div>


            <div v-else v-for="task in tasks" :key="task.id" class="modular-task">
                 <img :src="require(`../../../assets/icons/bms/${task.icon}.png`)" alt="single task image" class="modular-task__img">
              <div class="modular-task__status">
                <div class="modular-task__status-action">
                    <img class="modular-task__status-action-icon" @click="completeTask(task)" width="22px" :src="require(`../../../assets/icons/action-icons/complete.png`)">                
                    <img class="modular-task__status-action-icon" @click="editTaskName(task)" width="20px" :src="require(`../../../assets/icons/action-icons/edit.png`)">                
                    <img v-if="!task.isNumeric" class="modular-task__status-action-icon" @click="toggleNumeric(task)" width="20px" :src="require(`../../../assets/icons/action-icons/number.png`)">                
                    <img v-else class="modular-task__status-action-icon" @click="toggleNumeric(task)" width="20px" :src="require(`../../../assets/icons/action-icons/percent.png`)">                
                    <img class="modular-task__status-action-icon" @click="deleteTask(task)" width="20px" :src="require(`../../../assets/icons/action-icons/delete.png`)">                
                </div>
                    <h3 v-if="task.id != editedTask" class="modular-task-heading">{{task.title}} </h3>
                    <input autofocus @keyup.enter="editedTask = ''" @blur="editedTask= ''" class="px-4 text-bold outline-black" type="text" v-model="task.title" v-else>
                    <div v-if="!task.isNumeric" class="flex w-full percent-completion">
                        <input @change="determineChangedInput(task)" type="range" name="projectCompletion" id="projectCompletion" v-model="task.completionRate" min="0" max="100">
                        <input @change="determineChangedInput(task)" type="number" name="projectCompletion" id="projectCompletion" v-model="task.completionRate" min="0" max="100">
                    </div>
                    <div v-else class="flex mt-2 numeric-completion justify-start">
                        <input @change="determineChangedInput(task)" class="mr-5 border border-gray-500 pl-5 text-center" type="number" name="completedItems" id="completedItems" v-model="task.completedItems" min="0" :max="task.targetItems">
                        <span class="">/</span>
                        <input @change="determineChangedInput(task)" class="ml-5 border border-gray-500 text-center " type="number" name="targetedItems" id="targetedItems" v-model="task.targetItems" min="1">
                    </div>
                </div>
            </div>
            <button @click="submitModularCompletion" class="text-2xl col-start-1 lg:w-1/6 mt-6 border border-gray-500 bg-black text-white px-3 py-3 hover:bg-gray-700 hover:text-white active:-scale-90">Güncelle</button>
        </div>
    </div>
        <acca-modal-medium v-if="isEditingTask" @modalClosed="isEditingTask = false" title="Görev Düzenle" height="60vh" width="50vw"> 
            <bms-add-task @modalClosed="isEditingTask = false" @taskPosted="addNewTask" />
        </acca-modal-medium>
</div>

</template>

<script>
import initializeBMS from "../../../mixins/initializeOtherFirebase"
import bmsOperations from "../../../mixins/bms/bmsMethods"
import accaVueNotification from "../../reusable/accaVueNotification.vue"
import accaModalMedium from "../../reusable/modal-views/accaModal-medium.vue"
import bmsAddTask from "./components/bmsAddTask.vue"
import bmsBlockPicker from "./components/bmsBlockPicker.vue"


export default {
    
    name: 'BMSProjectCompletion',
    data(){
        return {
            projectCompletion: 0,
            currentUpdate: "",
            
            isEditingCurrentUpdate: false,
            postSuccess: false,
            isEditingTask: false,
            editedTask: "",

            hasBlocks: "",
            selectedBlock: {},
            blocks: [],

            isLoadingTasks: false,
            tasks: [],
            changedTasks: []

        }
    },

    mixins: [initializeBMS, bmsOperations],
    components: {
      accaVueNotification: accaVueNotification,
      accaModalMedium,
      bmsAddTask,
      bmsBlockPicker
      
    },

    methods:{

    submitCompletion(){
        const bmsDB = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("completion")

        bmsDB.doc("completionMeta").set({
            rate: this.projectCompletion,
            currentUpdate: this.currentUpdate
        }).then(() => {
         this.postSuccess = true
         setTimeout(() => {
            this.postSuccess = false
         }, 1500)
        }).catch((err) => {
            console.error("Error writing document:", err)
        })

    },

    submitModularCompletion(){
        
        const bmsDBBase = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID)

        const bmsDB = this.hasBlocks ? bmsDBBase.collection("blocks").doc(this.selectedBlock.id).collection("tasks") : bmsDBBase.collection("tasks")
        
        this.changedTasks.forEach((task) => {
            bmsDB.doc(task.id).update({
                completionRate: task.completionRate,
                targetItems: task.targetItems ? task.targetItems : 0,
                completedItems:task.completedItems ? task.completedItems : 0,
                isNumeric:task.isNumeric,
                title: task.title
            }).then(() => {
            this.postSuccess = true
            setTimeout(() => {
                this.postSuccess = false
            }, 1500)
            })
        })

        this.changedTasks = []
    },

    toggleNumeric(task){
        task.isNumeric = !task.isNumeric
        task.targetItems = 0
        task.completedItems = 0
        this.determineChangedInput(task)
    },

    addNewTask(task){
        
        const taskIsValid = this.validateTask(task)
        
        if(taskIsValid){

            if(this.hasBlocks){
                this.addBlockTask(task, this.selectedBlock, this.getProjectID)
            } else {

                this.tasks.push(task)
                const tasksReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("tasks")
                tasksReference.add({
                    title: task.title,
                    icon: task.icon, 
                    completionRate: task.completionRate,
                    isNumeric: task.isNumeric,
                    completedItems: task.completedItems,
                    targetItems: task.targetItems
                }).catch((error) => {
                    console.error(error)
                })
            } 
        } else {
            alert("Task validation failed")
        }
    },

    completeTask(task){

        const editedTask = this.hasBlocks ?  
                           this.selectedBlock.tasks.find(editedTask => editedTask.id === task.id) :
                           this.tasks.find(editedTask => editedTask.id === task.id)
        
        if(editedTask.isNumeric){
            editedTask.completedItems = editedTask.targetItems
        } else {
            editedTask.completionRate = 100
        }
        this.changedTasks.push(task)
    },

    editTaskName(task){
        this.editedTask = task.id
        this.changedTasks.push(task)
    },

    deleteTask(task){
        const isDeleteConfirmed = confirm("Bu işi silmek istediğinizden emin misiniz?")
        if(isDeleteConfirmed){

            if(this.hasBlocks){
                this.deleteBlockTask(task, this.selectedBlock.id)
            } else {

                try{
                    const taskIndex = this.tasks.findIndex(deletedTask => deletedTask.id === task.id)
                    this.tasks.splice(taskIndex, 1)
                }
                
                catch{
                    alert("Bir şeyler ters gitti. Lütfen bu mesajı yazılım ekibinize iletin: There was a problem deleting task locally")
                }

            const taskOnDB = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("tasks").doc(task.id)
            taskOnDB.delete().catch((error) => {
                console.error("Görev silinemedi.", error)
            })

            }
    }
            

    },




    getCompletionRates(){
        this.isLoadingTasks = true
        const projectReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("completion").doc("completionMeta")
        projectReference.get().then((doc) => {
            if(doc.exists){
                this.projectCompletion = doc.data().rate,
                this.currentUpdate = doc.data().currentUpdate
                }
            }
        )
    this.isLoadingTasks = false
    },

    determineChangedInput(task){
        if(this.changedTasks.includes(task)){
            return 
            } else {
            this.changedTasks.push(task)
        }
    },

    changeSelectedBlock(block){
        if(this.changedTasks.length > 0){
            const confirmation = confirm("Bu blok / bölümdeki bazı işler üzerinde değişiklik yaptınız. Bu değişiklikleri kaydetmeden başka bir blok veya bölüme geçmek bu değişikliklerin sıfırlanmasına sebep olacaktır. Eğer değişiklikleri kaybetmek istemiyorsanz, önce bu güncellemeleri kaydetmeniz gerekmektedir. Blok değiştirmek istediğinize emin misiniz?")
            if(confirmation){

                // When working with blocks, Our Firebase operations are all based on the "selectedBlock" property.
                // If the user changes the selectedBlock while adjusting project completion rates, this creates a conflict.
                // We may have changes from Block A in the changedTasks array, and then the user might make changes to the tasks under Block B.

                // When she clicks "Save", we save the changes in the changedTasks array to the tasks under the selectedBlock (.collection("blocks").doc(selectedBlock.id).collection("tasks")). 
                // In this case, the selected Block would be B, so we would try to perform all operations to the tasks collection under Block B.
                // In this case, the Firebase update operation would partially fail, because IDs from Block A's changed tasks would not exist in Block B's tasks.
                // The program would keep functioning but the user would see inconsistent behaviour until she refreshes.
                // If she were to go back to Block A, she would see that the changes she made are still reflected. But they would not be saved on the database, creating confusion.
                // Thus, when the user moves from Block A to Block B, we re fetch the completion rates of Block A to make sure they reflect the latest data on Firebase.

                this.selectedBlock.tasks = this.getBlockTasks(this.getProjectID, this.selectedBlock.id)
                this.selectedBlock = block
                this.changedTasks = []

            } else {
                return
            }
        } else {
            this.selectedBlock = block
            this.changedTasks = []
        }

    },


    getTasks(){
        this.isLoadingTasks = true

        const projectRef = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID)
        projectRef.get().then((doc) => {
            this.hasBlocks = doc.data().hasBlocks
        }).then (() => {
        if(!this.hasBlocks){
            const tasksReference = projectRef.collection("tasks")
            tasksReference.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.tasks.push({
                        id: doc.id,
                        title: doc.data().title,
                        completionRate: doc.data().completionRate,
                        icon: doc.data().icon,
                        isNumeric: doc.data().isNumeric ? true : false,
                        completedItems: doc.data().completedItems,
                        targetItems: doc.data().targetItems
                    })
                })
                this.isLoadingTasks = false
            })
        } else {
            const blocksReference = projectRef.collection("blocks")
            blocksReference.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {        
                    this.blocks.push({
                        id: doc.id,
                        name: doc.data().name,
                        tasks: this.getBlockTasks(this.getProjectID, doc.id)
                    })
                })

                    this.selectedBlock = this.blocks[0]


                })
                this.isLoadingTasks = false
        }
        })
    },

    validateData(){
        if(this.projectCompletion > 100 || this.projectCompletion < 0){
            alert("Proje tamamlanma oranı 0'dan küçük veya 100'den büyük olamaz.")
            return
        }
    },

    validateTask(task){
        if(task.title.length > 3 && task.icon && task.completionRate >=0){
            return true
        } else {
            return false
        }
    }

    },

    created(){
        this.getCompletionRates()
        this.getTasks()
    },

    computed: {
      getProjectID(){
        const params = new URLSearchParams(window.location.search)
        const projectID = params.get("id")
        return projectID
        },
    }
}

</script>

<style lang="scss" scoped>

    .numeric-completion input{
        width: 8rem;
    }

    h3 {
        font-size: 2.1rem;
        margin-bottom: 1rem;
        font-weight: 500;
    }
    
    .project-completion-container{
        padding: 3rem 1rem;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
    }


    .project-completion-general{
        width: 100%;
        font-size: 1.6rem;

        & input[type=range]{
            width: 60%;
            height: 4rem;
            accent-color: black;
            margin-right: 2rem;
        }

        & input[type=number]{
            padding: 0 2rem;

            &:hover{
                background: #f6f6f6;
            }

            &:focus{
                outline: none;
            }
        }

    }
    

.tasks-container{
    padding: 3rem 2rem;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:inherit;

    &-title-row{
        grid-column: 1 / -1;
    }
}



.modular-task{
    width: 100%;
    padding: 2.1rem 2rem;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    background-color: white;
    align-items: center;
    position:relative;


    &__img{
        width: 7%;
        margin-right: 1rem;
    }

    &__status{
        width: 100%;
        font-size: 1.8rem;
    }


    & input[type=range]{
        width: 60%;
        height: 4rem;
        accent-color: black;
        margin-right: 2rem;
        transition: all .2s
    }

    & input[type=number]{
        &:hover{
            background-color: #f6f6f6;
        }
        &:focus{
            outline: none;
        }   
    }

    &-heading{
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1;
    }
}

.modular-task__status-action {

    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 45%;

    &-icon{
        transition: all .2s;
        cursor:pointer;
        margin-right: 1rem;

        &:hover{
            transform: scale(1.2)
        }
    }
}

div.loader-elasticball {
  display: flex;
  justify-content: center;
  position: relative;
  height: 6rem;
  
  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    background-color: black;
    transform: scaleY(0.5) translateY(1rem);
    animation: elasticball-bottom .5s .3s infinite;
  }
  
  &__ball {
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: 50% 100%;
    animation: elasticball .5s infinite cubic-bezier(1,-0.01,0,1);
    
    i {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      background-color: black;
      
      animation: elasticball-color 2s infinite;
    }
  }
}
@keyframes elasticball {
  0% {
    transform: translateY(0) scaleY(1)
  } 50% {
    transform: translateY(4rem) scaleY(.7)
  } 100% {
    transform: translateY(0) scaleY(1)
  }
}
@keyframes elasticball-color {
  0% {
    background-color: black;
  } 30% {
    background-color: black;
  } 50% {
    background-color: #cc4040;
  } 80% {
    background-color: #cc4040;
  }
}
@keyframes elasticball-bottom {
  0% {
    transform: scale(1, 0.5) translateY(1rem);
    opacity: 0.3;
  } 100% {
    transform: scale(2, 0.5) translateY(1rem);
    opacity: 0;
  }
}

@media screen and (max-width: 870px){
    .tasks-container{
        display: flex;
        flex-direction: column;;
    }

    .modular-task{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        &__img{
            width: 15%;
        }
    }

    .modular-task__status{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        & h3 {
            margin: .5rem 0 1rem 0;
        }

    }


    .percent-completion input[type=range]{
        width: 90%;
    }



    .modular-task__status-action{
        position: unset;
        justify-content: center;
        align-self: unset;
        order: 3;
        margin-top: 2rem;
        display:flex;
        flex-direction: row;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

    .modular-task__status-action-icon{
        width: 2.5rem;
        margin-right: 2rem;
    }
}

</style>
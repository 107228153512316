<template>
        <div class="main-container">
        <acca-vue-notification v-if="isMeetingRequestSent" message="Toplantı talebiniz oluşturuldu"></acca-vue-notification>

            <h2 class="form-title">Toplantı Talebi Oluştur</h2>
            <form class="dashboard-form" @submit.prevent="sendMeetingRequest()" >

                <label class="single-line-label" for="user">Talebi Oluşturan </label>
                <input
                    type="text"
                    readonly
                    id="user"
                    class="dashboard-form__input bg-gray-200"
                    v-model="userProfile.name"
                >

                <label class="single-line-label" for="title">Toplantı Konusu</label>
                    <input
                        type="text"
                        id="title"
                        class="dashboard-form__input"
                        v-model="meetingRequest.title"
                    >

                <label class="single-line-label" for="date">Toplantı Zamanı</label>
                <div class="w-full flex space-between">
                    
                        <input
                            type="date"
                            id="date"
                            class="dashboard-form__input w-full"
                            v-model="unformattedDate"
                            @input="calculateDate"
                        >
                        <input
                            type="time"
                            id="time"
                            class="dashboard-form__input w-full"
                            v-model="meetingRequest.time"
                        >


                </div>

                <label class="single-line-label" for="participants">Katılacaklar</label>
                    <vue-multiselect
                        class="w-full"
                        v-model="meetingRequest.participants"
                        search
                        historyButton
                        historyButtonText="Geri Al"
                        searchPlaceholder="Arama"
                        btnClass="multi-select__button"
                        :multiple="true"
                        :options="employees"
                        placeholder="Lütfen en az bir katılımcı seçin."
                        selectLabel="Seçmek için tıklayın"
                        selectedLabel="Seçildi"
                        deselectLabel="Kaldır"
                   />
                
                    <label for="isOnline" class="text-2xl mt-8" >
                        <input
                                class="mb-10"
                                type="checkbox"
                                id="isOnline"
                                value="SendMail"
                                v-model="meetingRequest.isOnline"> Online?
                    </label>

                    <label class="single-line-label" for="body">Açıklama
                         <vue-tooltip explanation="Bu bölümden, istediğiniz toplantının kapsamı ve önemi hakkında detaylı bilgileri paylaşabilirsiniz.">?</vue-tooltip>    
                    </label>
                    
                    <textarea
                            id="message"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="meetingRequest.body">
                    </textarea>

                <div class="button-row">
                    <button type="submit" @click="meetingRequest.user = userProfile.name; postData(meetingRequest, 'requests', 'meeting', 'meetingRequests')" class="dashboard-form__submit">Oluştur</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>

            <div  v-if="existingRequests.length > 0" class="flex flex-col col-start-2 col-end-12">
                <h2 class="form-title text-white mb-5">Mevcut Talepleriniz</h2>
                <meeting-request-status  :requests="existingRequests"></meeting-request-status>
            </div>

            </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import VueTooltip from '../reusable/VueTooltip.vue'
import firebase from "../../firebase"
import VueMultiselect from 'vue-multiselect'
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import accaVueNotification from "../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"
import MeetingRequestStatus from "../reusable/MeetingRequestStatus.vue"

export default {
  name: 'MeetingRequests',
  data() {
      return {
          isMeetingRequestSent: false,
          existingRequests: [],
          unformattedDate: "",
          employees: [],
          btnLabel: (participants) => `Katılacaklar: ${participants}`,
          meetingRequest: {
              title: "",
              participants:[],
              isOnline: false,
              date: "",
              time: "",
              body: "",
              created: new Date(),
              status: "-",
              responseMessage: "",
          }
      }
  },

     computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },


  methods: {
      getEmployees(){
        let db = firebase.firestore().collection("team")
            db
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let name = doc.data().name.first + " " + doc.data().name.last
                    this.employees.push(
                        name
                    )
                })
            })
      },

        checkExistingRequests(){
            let db = firebase.firestore().collection("requests").doc("meeting").collection("meetingRequests").where("user", "==", this.userProfile.name).orderBy("created", 'desc')

            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {

            this.existingRequests.push(
                {
                id: doc.id,
                body: doc.data().body,
                date: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().date)),
                time: doc.data().time,
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                status: doc.data().status,
                responseMessage: doc.data().responseMessage,
                participants: doc.data().participants,
                title: doc.data().title
                    })
                })
            });
      },

      calculateDate(){
          this.meetingRequest.date = new Date(this.unformattedDate).getTime()
        },

      goBack(){
          this.$router.go(-1)
      },

      sendMeetingRequest() {
          this.isMeetingRequestSent = true
          setTimeout(() => {
              this.isMeetingRequestSent = false
              this.goBack()
          }, 3000)
      }
  },

    components: {
      VueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
      VueMultiselect,
      MeetingRequestStatus
  },

  mixins: [postData],

  created() {
      this.getEmployees()
  },

  mounted() {
      this.checkExistingRequests()
  }
} 

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";
  @import "../../styles/vendors/multiselect.scss";
  

.multi-select__button{
    width: 100%;
    padding: 1rem !important;
    margin-bottom: 3rem;
    text-align: left !important;
    font-size: 1.6rem !important;
    border: 1px solid gray !important;

    &:focus{
        outline: 2px solid black;
    }
}


  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>


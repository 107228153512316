<template>
  <div class="main-container">
      <div v-if="!isSurveyActive" class="big-notice font-2xl text-gray-100 font-semibold">
          Şu anda aktif anket bulunmamaktadır.
        </div>
      <div v-else> Eğer bir anket olsaydı burada görüntülenecekti. </div>
  </div>
</template>


<script>
export default {
  name: 'DansukSurveys',
  data(){
      return {
          isSurveyActive: false,
          surveys: [],
      }
  },
  methods: {
      checkSurveys(){
          if(this.surveys.length > 0){
              this.isSurveyActive = true
          }
      }
  },
  created(){
      this.checkSurveys
  }

}
</script>

<style scoped lang="scss">

    .main-container{
        align-content: center;
    }

</style>


<template>
    <div 
    
    :class="`${bgColors[bg]}`"
    class="chip-container whitespace-nowrap">
        <p>{{ status }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data(){
        return {
            bgColors: [
                "has-bg-red",
                "has-bg-yellow",
                "has-bg-blue",
                "has-bg-green",
                "has-bg-green-dark"
                ],
            }
        },
    props: {
        status: String,
        bg: Number,
    }
  }
  </script>
  
  <style lang="scss">
    @import "../../styles/core/variables.scss";
  
    .chip-container{
        border-radius: 1rem;
        color: white;
        font-size: 1.3rem;
        color: white;
        font-weight: 400;
        padding: .4rem 3rem;
        display: inline-block;
        text-align: center;
    }



    .chip-container.has-bg-red{
        background-color: $color-chip-red;
    }
    .chip-container.has-bg-green{
        background-color: $color-chip-green;
    }
    .chip-container.has-bg-yellow{
        background-color: $color-chip-yellow;
    }

    .chip-container.has-bg-blue{
        background-color: $color-chip-blue;
    }
    .chip-container.has-bg-green-dark{
        background-color: $color-chip-green-dark;
    }
    
  </style>
  
<script setup lang="ts">

import { ref } from "vue"

const props = defineProps({
  initialValue: Array,
  objectKey: String,
  objectValue: String // Initial value for selected options, if any
});

const emits = defineEmits(['updateSelectedOptions']);

const selectedOptions = ref(
  props.initialValue.length > 0
  ? props.initialValue.map(item => ({ name: item.name || '', capacity: item.capacity || '' }))
    : [{ name: '', capacity: '' }]
);

const addOption = () => {
  selectedOptions.value.push({name:"", capacity: ""})
  emitSelectedOptions();
};

const removeOption = (index: number) => {
  selectedOptions.value.splice(index, 1);
  emitSelectedOptions();
};

const emitSelectedOptions = () => {
  emits('updateSelectedOptions', selectedOptions.value);
};
</script>

<template>
    <div>
      <div class="grid w-full grid-cols-3 items-center gap-4 mb-4">
        <h2 class="text-2xl col-start-1 col-end-2 font-semibold">
          {{ objectKey }}
        </h2>
        <h2 class="text-2xl col-start-2 col-end-3 font-semibold">
          {{ objectValue }}
        </h2>
        <!-- eslint-disable-next-line -->
        <template v-for="(option, index) in selectedOptions" :key="index">
          <div class="col-start-1 col-end-2 w-full">
            <input placeholder="Ünite adı" class="w-full text-2xl px-2 py-3 outline-none" @input="emitSelectedOptions" type="text" v-model="option.name">
          </div>
          <div class="col-start-2 col-end-3 w-full">
            <input placeholder="Ünite Kapasitesi" class="w-full text-2xl px-2 py-3 outline-none" @input="emitSelectedOptions" type="text" v-model="option.capacity">
          </div>
          <div class="flex gap-4 col-start-3 w-full">
            <button type="button" class="ac-square-button !py-2 !px-4 h-full text-2xl !text-[var(--text-main-color)]" @click="addOption" v-if="index === selectedOptions.length - 1">+</button>
            <button type="button" class="ac-square-button !py-2 !px-4 h-full text-2xl !text-[var(--text-main-color)]" @click="removeOption(index)" v-if="index > 0">-</button>
          </div>
        </template>
      </div>
    </div>
  </template>
  
  
<style lang="scss">
@import "../../styles/global/buttons.scss";


</style>
import firebase from 'firebase/app';
import { store } from "./store"


firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
        console.log("User logged in")
        store.commit("user/USER_DETAILS", user);
        store.dispatch("user/teamMemberDetails", user.uid);

    } else {
        store.commit("user/LOGOUT")
    }
})
<template>
    <main>
        <div class="flex justify-between items-center flex-row">
            <h2 class="text-4xl mb-2">{{goal.name}}</h2>
            <div v-if="goal.isPrimary" class="bg-red-400 text-3xl text-white py-6 px-4">Öncelikli Hedef</div>
        </div>
        <h3 class="text-3xl text-gray-600 mb-5">{{goal.category}} Hedefi</h3>
        <p class="text-2xl mb-10" v-if="goal.description">{{goal.description}}</p>
        <p v-else class="text-2xl text-gray-700 mb-10">Oluşturduğunuz hedef için herhangi bir açıklama girmediniz.</p>
        
        <h2 class="text-4xl mb-4 ">Tarih Aralığı</h2>

        <div class="flex flex-row justify-between items-center mb-10">
            <div class="flex flex-col">
                <p class="text-2xl mb-2 font-bold">Hedef Takip Süreci </p>
                <p class="text-2xl">{{goal.period}}</p>
            </div>
            <div class="flex flex-col">
                <p class="text-2xl mb-2 font-bold">Başlangıç Tarihi </p>
                <p class="text-2xl">{{parseDateFromStr(goal.startDate)}} </p>
            </div>
            <div class="flex flex-col">
                <p class="text-2xl mb-2 font-bold">Bitiş Tarihi</p>
                <p class="text-2xl">{{ parseDate(goal.expirationDate)}}</p>
            </div>
        </div>

        <div v-if="goal.goalType === 'totalNum'" class="flex-col flex" >
            <h2 class="text-4xl mb-2 ">Hedef Ölçüt Türü: Toplam Adet</h2>
            <p v-if="goal.goalType === 'totalNum'" class="text-2xl mb-10"> Hedef ölçütü olarak 'toplam adet' yapısını seçtiniz. Hedefin başarı oranı, "bakım anlaşması sayısı", "proje sayısı", "işe alım sayısı" vb. gibi tamamlanan maddelerin adedine göre belirlenecektir. </p>
            <div class="flex flex-row">
                <p class="font-bold text-3xl">Hedef: </p>
                <p class="text-3xl"> {{goal.target}}</p>
            </div>
        </div>

        <div v-if="goal.goalType === 'totalPrice'" class="flex-col flex" >
            <h2 class="text-4xl mb-2">Hedef Ölçüt Türü: Toplam Gelir</h2>
            <p  class="text-2xl mb-10"> Hedef ölçütü olarak toplam gelir yapısını seçtiniz. Hedefin başarı oranı, belirlediğiniz satış rakamına göre belirlenecektir.</p>
            <div class="flex flex-row">
                <p class="font-bold text-3xl mr-2">Hedef: </p>

                <p class="text-3xl">
                    <span v-if="goal.currencySymbol === '$'">$</span>
                    {{goal.target}}
                     <span v-if="goal.currencySymbol === '₺'">₺</span>
                    <span v-if="goal.currencySymbol === '€'">€</span>
                </p>
            </div>

        </div>

        <div v-if="goal.goalType === 'oneAndDone'" class="flex-col flex" >
            <h2 class="text-4xl mb-2 ">Hedef Ölçüt Türü: Tek İş / Görev</h2>
            <p v-if="goal.goalType === 'oneAndDone'" class="text-2xl mb-10"> Hedef ölçütü olarak "Tek iş" yapısını seçtiniz. Hedef tek bir işin belli bir tarihe kadar tamamlanma durumuna göre, "Evet / Hayır" sorusu şeklinde değerlendirilecektir.</p>
        </div>

        <h2 v-if="goal.manualCompleted" class="text-4xl mb-4 ">İlerleme Süreci</h2>
        <p v-if="goal.manualCompleted" class="text-2xl mb-10">Hedefi, önceden tamamlanmış olan aşamaları dahil ederek oluşturmayı seçtiniz. Önceden tamamlanmış olan {{goal.manualCompleted}} değerinde kısım ulaşılmış olarak işaretlenecektir.</p>

        
     <div class="button-row">
        <button type="button" @click="decrementStep" class="dashboard-form__button-secondary button__cancel"> Geri </button>
        <button type="button" @click="saveAndPostGoal" class="dashboard-form__submit bg-green-400 hover:bg-green-500">Onayla ve Hedef Oluştur</button>
    </div>

    </main>
</template>

<script>

export default {


    props: ["goal", "goalCategories"],


    methods: {
        parseDate(value){
        try{
            return Intl.DateTimeFormat('tr-TR').format(value)
        } catch {
            console.log("Error converting date")
        }
        },

        parseDateFromStr(value){
            const dateSplit = value.split("-")
            const date = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2])
            return Intl.DateTimeFormat('tr-TR').format(date)
        },
        decrementStep(){
            this.$emit("decrementStep")
        },

        fetchCategories(){

        }

    },
}
</script>

<style scoped lang="scss">

</style>
import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router';
import {routes} from "./routes"
import { store } from "./store"
import "./auth"
import { createI18n } from 'vue-i18n';
import './index.css'
import "animate.css"
import './registerServiceWorker'



const app = createApp(App)

const i18n = createI18n({
  locale: 'tr', // set locale
  fallbackLocale: 'tr', // set fallback locale
});



const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAdmin = store.getters['user/userProfile'].role === "admin" || store.getters['user/userProfile'].role === "superAdmin"

  if(store.getters['user/loggedIn'] && to.path != "/sign-in"){ 
    if(to.meta.requiresAuth && !isAdmin){
      console.log("User has requested access to an unauthorized page.")
      console.log("Returning user home.")
      next("/")
    } else {
      next()
    }

  } else if(!store.getters['user/loggedIn'] && to.path === "/sign-in") {
    console.log("User is not logged in.")
    console.log("Requested page is the sign in page, allowing access.")
    next()
  } else if(store.getters['user/loggedIn'] && to.path === "/sign-in") {
    console.log("User is logged in but has requested access to the sign-in page.")
    console.log("Redirecting to homepage.")
    next("/")
  } else
  {
    console.log("User is not logged in.")
    console.log("Redirecting to login page.")
    next('/sign-in')
  }

})

app.use(router)
app.use(store);
app.use(i18n);

app.mount('#app')
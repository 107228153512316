<template>
  <div class="main-container">


      <single-goal-track v-for="(goal, index) in existingGoals" :key="goal.id" :goal="goal" :borderColor="colors[index]" class="col-span-4 h-full"></single-goal-track>



      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="/goals/admin/create" title="Hedef Belirle" imgURL="dansuk-surveys.png" navStyle="1"></inner-navigation>
      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-2" link="/goals/admin/maintenance" title="Örnek Grafikler" imgURL="dansuk-surveys.png" navStyle="1"></inner-navigation>
      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="/goals/all" title="Tüm Hedefler" imgURL="dansuk-surveys.png" navStyle="1"></inner-navigation>
      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="/goals/admin/category-manager" title="Hedef / Objektif Kategrileri" imgURL="dansuk-surveys.png" navStyle="1"></inner-navigation>
  
  
  
  </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import SingleGoalTrack from "../reusable/SingleGoalTrack.vue"
import { mapGetters } from 'vuex'
import firebase from '../../firebase'


export default {
  name: 'GoalsHome',
  data() {
    return {
      existingGoals: [],
      colors: ["#e63946", "#a8dadc", "#457b9d", "#1d3557"]
    }
  },
  components: {
      InnerNavigation: InnerNavigation,
      SingleGoalTrack: SingleGoalTrack
  },

  methods: {
    getGoals(){
      let db = firebase.firestore().collection("goals")
      db.get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.existingGoals.push({
              id: doc.id,
              name: doc.data().name,
              goalType: doc.data().goalType,
              expirationDate: doc.data().expirationDate,
              completedGoals: doc.data().completed.length + doc.data().manualCompleted,
              lastUpdate: doc.data().compleded,
              period: doc.data().period,
              target: doc.data().target,
              type: doc.data().type,
            })
          })
        })


    },

    calculateGoalPercent(goal) {
        let percentArray = []
        percentArray.push( (goal.completedGoals / goal.target) * 100 )
        return percentArray 
    }
  },



    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      }),
  },



  created(){
    this.getGoals()
  }
}
</script>

<style scoped lang="scss">


</style>

<template>
    <div class="messages-container">
    <acca-vue-notification v-if="isEditingOwnMessage" selectedType="coolWarning" message="Düzenlemeniz bittikten sonra 'Gönder'e tıklayın!"></acca-vue-notification>
        <div v-if="!isMessageDisplayed" class="all-messages">
            <h2 class="messages-container__title py-4 px-10">Son Haberler</h2>
                <table class="messages-container__table">
                    <thead>     
                        <tr>       
                        <th>Tarih</th>       
                        <th>Yayımlayan</th>       
                        <th>Konu</th>       
                        <th>Mesaj</th>     
                    </tr>
                    <tr :class="{'message-content__emphasized': message.is_emphasized}" @click="displayMessage(message.id)" class="message-content hover:bg-gray-200" v-for="message in messages" :key="message.id">
                        <td class="message-content__data">{{message.date}}</td>
                        <td class="message-content__data">{{message.user}}</td>
                        <td class="message-content__data">{{message.title}}</td>
                        <td class="message-content__data message-content__data-body">{{message.body}}</td>
                    </tr>
                </thead>   
                <tbody>
                </tbody>
            </table>
        </div>
        <div class="px-10" v-else>
            <div class="message-header-row flex align-center justify-between">
                <h2 class="messages-container__title">{{displayedMessage.title}}</h2>
                <svg class="cursor-pointer" @click="isMessageDisplayed = false; isEditingOwnMessage = false" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="#5b5b5f"><path d="m256 24c127.9 0 232 104.1 232 232s-104.1 232-232 232-231.94-104.1-231.94-232 104.04-232 231.94-232zm-256 232c0 141.16 114.84 256 256 256s256-114.84 256-256-114.84-256-256-256-256 114.84-256 256z"/><path d="m382 172.72-42.71-42.71-83.29 83.28-83.28-83.28-42.71 42.71 83.28 83.28-83.28 83.28 42.71 42.72 83.28-83.29 83.29 83.28 42.71-42.71-83.29-83.28z"/></g></svg>
            </div>
            <div class="font-semibold flex mb-5 mt-2 text-2xl">
                <span class="mr-3"> Yayımlayan: {{displayedMessage.user}} </span>
                <div v-if="userProfile.name === displayedMessage.user && !isEditingOwnMessage || userProfile.role === 'superAdmin' && !isEditingOwnMessage" @click="isEditingOwnMessage = true" class=" text-2xl cursor-pointer text-blue-400">[Düzenle]</div>               
                <div class="flex" v-else-if="isEditingOwnMessage === true">
                    <div  @click="changeMessage(displayedMessage.id)" class=" text-2xl cursor-pointer text-green-400 mr-2"> [Gönder] </div>
                    <div @click="cancelEdit" class=" text-2xl cursor-pointer text-red-400">[İptal]</div>
                </div>
            </div>   
            <div v-if="!isEditingOwnMessage" class="text-2xl mb-10 whitespace-pre-line">{{displayedMessage.body}}</div>
            <textarea rows="10" autofocus class="w-full overflow-y-scroll h-full bg-gray-100 px-3 focus:outline-black opacity-90 text-2xl" v-else name="editedMessage" id="editedMessage" v-model="displayedMessage.body"></textarea>
            <a class="text-2xl text-blue-500 hover:text-blue-600 hover:underline" v-if="displayedMessage.link != ''" :href="displayedMessage.link">{{displayedMessage.link}}</a>
        </div>
    </div>
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/firestore'
import { mapGetters } from 'vuex'
import accaVueNotification from "./reusable/accaVueNotification.vue"


export default {
  name: 'HomeMessages',
  data(){
      return {
          isMessageDisplayed: false,
          isEditingOwnMessage: false,
          displayedMessage: "",
          dailyRespOrder: "",
          dailyResp: [
              "Yağız Berk Demirkaya",
              "Özge Demir",
              "Kaan Çezo",
          ],
          currentDate: new Intl.DateTimeFormat("tr-TR").format(new Date()),
          fsLastDate: "",
          respMessage: {},
          messages: [],

          
      }
  },
  components: {
      accaVueNotification: accaVueNotification
  },
  computed:{
        ...mapGetters( 'user', {
                userProfile: 'userProfile',
                loggedIn: "loggedIn"
    }),

  },
  methods: {
        getData(){
          //  this.createMessage()
          let db = firebase.firestore().collection("messages").doc("main").collection("all").orderBy("date")
          db
            .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.messages.push({
                            id: doc.id,
                            title: doc.data().title,
                            body: doc.data().body,
                            date: new Intl.DateTimeFormat('tr-TR').format(doc.data().date.toDate()),
                            is_emphasized: doc.data().is_emphasized,
                            is_kept: doc.data().is_kept,
                            link: doc.data().link,
                            user: doc.data().user
                        })
                    })
                
                this.messages.push(this.respMessage)
                this.messages.reverse()
                })
        },

        createMessage(){

            // Get dates for comparison with Firestore and to check if it's a workday. 

            const date = new Date()
            const formattedCurrentDate = new Intl.DateTimeFormat('tr-TR').format(date)
            let currentDay = date.getDay()

            // Break out of the function if it's Saturday or Sunday. 

            if(currentDay === 6 || currentDay === 0){
                return
            } else {

                // Run the function if it's a work day. 

                    // Create the new message object.

                let newMessage = {
                    id: "24e70613-2499-4c81-b71e-685ab99b66e0",
                    title: "Ofis Sorumlusu",
                    body: "",
                    date: formattedCurrentDate,
                    is_emphasized: false,
                    is_kept: false,
                    link: null,
                    user: "Dansuk Mühendislik"
                }

                // Get today's responsible index order from Firestore

                let db = firebase.firestore().collection("dailyManagement").doc("dailyResp")
                db.get().then((doc) => {
                        this.dailyRespOrder = doc.data().order
                        this.fsLastDate = new Intl.DateTimeFormat('tr-TR').format(doc.data().lastDate.toDate())

                        // Pick the person from the array of employees:

                        let responsible = this.dailyResp[this.dailyRespOrder]
                        newMessage.body = `Herkese merhaba,
                        
                        Bugün için ofis düzeni sorumlusu ${responsible} olacaktır. 
                        
                        İyi çalışmalar,
                        
                        Dansuk Dashboard
                        
                        Not: Bu mesaj otomatik olarak oluşturulmaktadır. Bir sorun olduğunu düşünüyorsanız lütfen Öneriler bölümü altından hata bildirimi oluşturun! 🤖  
                        
                        `
                        

                    this.respMessage = newMessage

                    // Check whether the date on Firestore is equal to the date on the Vue instance:

                    if(formattedCurrentDate === this.fsLastDate){
                        console.log("Same day, not updating.")
                        return
                    } else {
                        let newOrder = this.incrementEmployees(this.dailyRespOrder)
                        db.set({
                            lastDate: date,
                            order: newOrder
                        }).then(() => {
                            console.log("Daily message succesfully updated")
                        }).catch(() => {
                            console.log("Error writing document")
                        })
                    }
                    })
            }



                // Check the day of the week: 


        },

        incrementEmployees(currentOrder){


        // Change this when there's a change in employee status:


            let newOrder

            if(currentOrder >= 3){
                newOrder = 0
            } else {
                newOrder = currentOrder + 1
            }

            return newOrder
        },

        changeMessage(id){
            let message = firebase.firestore().collection('messages').doc("main").collection("all").doc(id)
            message.update({
                body: this.displayedMessage.body
            }).then(() => {
                console.log("Document updated.")
                this.isEditingOwnMessage = false
            }).catch((error) => {
                console.log(error)
            })
        },

        cancelEdit(){
            console.log("Cancel edit ran.")
            this.isEditingOwnMessage = false
        },

        displayMessage(id){
            this.isMessageDisplayed = true
            this.displayedMessage = this.messages.find(message => message.id === id)
        }
    },


  created(){
    
    this.getData()
  }

}
</script>

<style scoped lang="scss">
  @import "../styles/core/variables.scss";
  @import "../styles/global/tables.scss";
   

</style>

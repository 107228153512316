<template>
    <div class="main-container content-center">
        <acca-vue-notification v-if="isBugReportSent" message="Hata bildiriminiz oluşturuldu, teşekkür ederiz!"></acca-vue-notification>
        <h2 class="form-title">Panel - Hata Bildir</h2>

        <form class="dashboard-form" @submit.prevent="sendBugReport()" >

            <label class="single-line-label" for="user">Oluşturan</label>
            <input required type="text" readonly id="user" class="dashboard-form__input bg-gray-200" v-model="bugReport.user">

            <label class="single-line-label" for="title">Hata Konusu</label>
            <input required type="text" id="title" class="dashboard-form__input" v-model="bugReport.title">

            <label class="single-line-label" for="body">Hata Detayları</label>
            <textarea required id="body" rows="5" class="medium-message mb-10" v-model="bugReport.body"> </textarea>

            <label class="single-line-label b-5" for="images">Ekran Görüntüsü
                <vue-tooltip explanation="Yaşadığınız hata ile ilgili bir ekran görüntüsü eklemeniz, sorunun tanımlanmasını ve çözülmesini hızlandırabilir.">?</vue-tooltip>    
            </label>


            <acca-dn-d-upload class="mb-8" :uploads="bugReport.images" @filesChanged="uploadFilesToStorage($event, 'bugReports', bugReport.images)" />




            <div class="button-row">
                <button type="submit" @click="postData(bugReport, 'suggestions', 'bugReport', 'bugReports')" class="dashboard-form__submit">Oluştur</button>
                <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
            </div>

        </form>

    </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"
import VueTooltip from '../reusable/VueTooltip.vue'
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"



export default {
  name: 'BugReports',
  data() {
      return {
          isBugReportSent: false,
          bugReport: {
              user: "",
              title: "",
              body: "",
              images: [],
              created: new Date()
          },
      }
  },

  methods: {
      goBack(){
          this.$router.push("/suggestions")
      },

      sendBugReport() {
          this.isBugReportSent = true
          setTimeout(() => {
              this.isBugReportSent = false
              this.goBack()
          }, 3000)
      }
  },

   computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },
    components: {
      VueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
      accaDnDUpload: accaDnDUpload,
  },


  mixins: [postData, firebaseOperations],

    created(){
      this.bugReport.user = this.userProfile.name
  },

    beforeUpdate(){
        this.bugReport.user = this.userProfile.name
    }
} 

</script>

<style lang="scss">

</style>

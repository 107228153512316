<template>
    <form id="editCategory">
        <div class="flex flex-col w-full ">
            <label  for="name" class="single-line-label">Kategori Adı</label>
            <input
            style="margin-bottom: 0"
            type="text"
            required
            id="name"
            class="dashboard-form__input"
            v-model="editedCategory.name"
            />
      </div>

        <div class="flex flex-col w-full mt-10">
            <h3 class="text-3xl font-semibold">İkon</h3>
            <ac-icon-picker @iconSelected="determineIcon" height="300px"/>
      </div>

        <div class="flex flex-col w-full mt-10">
            <label  for="color" class="single-line-label">Renk</label>
            <input
            style="margin-bottom: 0"
            type="color"
            id="color"
            class="w-1/6 h-15 cursor-pointer"
            value="editedCategory.catColor"
            v-model="editedCategory.catColor"
            />
      </div>

    
    <div class="button-row pt-5">
      <button @click="postOrEditData" type="button" class="dashboard-form__submit bg-green-300 hover:bg-green-400">Onayla</button>
      <button @click="cancel" type="button" class="button__cancel text-3xl px-16 py-4 hover:bg-gray-200">İptal</button>
    </div>

          <p v-if="editedCategory.id != undefined" @click="deleteCategory(editedCategory.id)" class="text-2xl text-red-500 hover:text-red-600 cursor-pointer hover:underline mb-10">Bu kategoriyi sil</p>


    </form>
</template>

<script>

import acIconPicker from "../../reusable/acIconPicker.vue"
import firebase from "../../../firebase";
import modalOperations from "../../../mixins/modalOperations.js"


export default {
    name: "GoalCategoryEdit",
    data(){
        return{
            selectedIcon: null,
            editedCategory: {
                id: this.category.id,
                name: this.category.name,
                catColor: this.category.catColor,
                icon: this.category.icon,

            }
        }
    },

    methods:{
        cancel(){
            this.$emit("modalClosed")
            this.$destroy();
            this.modalClosed()
             // remove the element from the DOM
            this.$el.parentNode.removeChild(this.$el);
        },

        determineIcon(icon){
            this.selectedIcon = icon
        },

         postOrEditData(){
            if(this.selectedIcon != null){
                this.editedCategory.icon = this.selectedIcon
            }

            const db = firebase.firestore().collection("goalMetas").doc("goalCategories").collection("categories")


            if(this.editedCategory.id === undefined){
                console.log("Running post")
                db.doc().set({
                    name: this.editedCategory.name,
                    catColor: this.editedCategory.catColor,
                    icon: this.editedCategory.icon
                }).then(this.editingComplete())
            } else {
                db.doc(this.editedCategory.id).set({
                    name: this.editedCategory.name,
                    catColor: this.editedCategory.catColor ? this.editedCategory.catColor : "#e3e3e3",
                    icon: this.editedCategory.icon ? this.editedCategory.icon : "golf-course",
                }).then(this.editingComplete())
            }
        },

        deleteCategory(id){
            const confirmation = confirm("Bu kategoriyi silmek istediğinizden emin misiniz?")
            if(confirmation){
                const db = firebase.firestore().collection("goalMetas").doc("goalCategories").collection("categories")
                db.doc(id).delete().then((this.deletionComplete))
            }
        },

        deletionComplete(){
            this.$emit("deletionComplete", this.editedCategory)
            this.modalClosed()
        },
        editingComplete(){
            this.$emit("editingComplete", this.editedCategory)
            this.modalClosed()
        }
    },
    mixins: [modalOperations],

    components: {
        acIconPicker,
    },
    props: {
        category: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">

.button-row{
    position: relative;
    bottom: 0;
    margin-bottom: 0;
}
</style>
<template>
    <div class="main-container">

      <acca-vue-notification v-if="isEditingOwnMessage" selectedType="coolWarning" message="Düzenlemeniz bittikten sonra 'Gönder'e tıklayın!"></acca-vue-notification>


      <div class="messages-container col-start-1 col-end-13">
        <div v-if="!isMessageDisplayed" class="all-messages">
            <h2 class="messages-container__title py-4 px-10">Duyurular</h2>
                <table class="messages-container__table">
                    <thead>     
                        <tr>       
                        <th>Tarih</th>       
                        <th>Yayımlayan</th>       
                        <th>Konu</th>       
                        <th>Mesaj</th>     
                    </tr>
                    <tr @click="displayMessage(message.id)" class="message-content hover:bg-gray-200" v-for="message in messages" :key="message.id">
                        <td class="message-content__data">{{message.created}}</td>
                        <td class="message-content__data">{{message.user}}</td>
                        <td class="message-content__data">{{message.title}}</td>
                        <td class="message-content__data message-content__data-body">{{message.body}}</td>
                    </tr>
                </thead>   
                <tbody>
                </tbody>
            </table>
        </div>

      <div class="px-10" v-else>
        <div class="message-header-row flex align-center justify-between">
          <h2 class="messages-container__title">{{displayedMessage.title}}</h2>
          <svg class="cursor-pointer" @click="isMessageDisplayed = false; isEditingOwnMessage = false" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="#5b5b5f"><path d="m256 24c127.9 0 232 104.1 232 232s-104.1 232-232 232-231.94-104.1-231.94-232 104.04-232 231.94-232zm-256 232c0 141.16 114.84 256 256 256s256-114.84 256-256-114.84-256-256-256-256 114.84-256 256z"/><path d="m382 172.72-42.71-42.71-83.29 83.28-83.28-83.28-42.71 42.71 83.28 83.28-83.28 83.28 42.71 42.72 83.28-83.29 83.29 83.28 42.71-42.71-83.29-83.28z"/></g></svg>
        </div>
      
      <div class="font-semibold flex mb-5 mt-2 text-2xl">
        <span class="mr-3"> Yayımlayan: {{displayedMessage.user}} </span>
          <div 
            v-if="userProfile.name === displayedMessage.user && !isEditingOwnMessage"
            @click="isEditingOwnMessage = true" 
            class=" text-2xl cursor-pointer text-blue-400">
            
            [Düzenle]
            
          </div>

          <div class="flex" v-else-if="isEditingOwnMessage === true">
          <div  @click="changeMessage(displayedMessage.id)" class=" text-2xl cursor-pointer text-green-400 mr-2"> [Gönder] </div>
          <div @click="cancelEdit" class=" text-2xl cursor-pointer text-red-400">[İptal]</div>
          </div>

      </div>

      <div v-if="!isEditingOwnMessage" class="text-2xl mb-10 whitespace-pre-line">{{displayedMessage.body}}</div>
      <textarea rows="10" autofocus class="w-full overflow-y-scroll h-full bg-gray-100 px-3 focus:outline-black opacity-90 text-2xl" v-else name="editedMessage" id="editedMessage" v-model="displayedMessage.body"></textarea>

      <a class="text-2xl text-blue-400 hover:text-blue-500 hover:underline" v-if="displayedMessage.link != ''" :href="displayedMessage.link">{{displayedMessage.link}}</a>
      </div>
      </div>







        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-1" link="/human-resources/documents/add" title="Yeni İK Belgesi Ekle" imgURL="document-request-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-2" link="/human-resources/messages/add" title="Duyuru Yayımla" imgURL="create-hr-message.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-1" link="/human-resources/documents/" title="İK Belgeleri" imgURL="main-documents.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="requests" title="Talepler" imgURL="main-requests.png" navStyle=1></inner-navigation>
      
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'
import firebase from 'firebase/app';
import 'firebase/firestore'
import accaVueNotification from "../reusable/accaVueNotification.vue"

export default {
  name: 'HumanResourcesHome',
  data(){
    return{
      messages: [],
      isMessageDisplayed: false,
      isEditingOwnMessage: false,
    }
  },
  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },

  methods: {
    getData(){
      let db = firebase.firestore().collection("messages").doc("main").collection("hr").orderBy("created")
      db
        .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.messages.push({
                        id: doc.id,
                        title: doc.data().title,
                        body: doc.data().body,
                        created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                        link: doc.data().link,
                        user: doc.data().user
                    })
                })
            
            this.messages.reverse()
            })
    },

    displayMessage(id){
      this.isMessageDisplayed = true
      this.displayedMessage = this.messages.find(message => message.id === id)
    },

    changeMessage(id){
        let message = firebase.firestore().collection('messages').doc("main").collection("hr").doc(id)
        message.update({
            body: this.displayedMessage.body
        }).then(() => {
            console.log("Document updated.")
            this.isEditingOwnMessage = false
        }).catch((error) => {
            console.log(error)
        })
    },

        cancelEdit(){
        console.log("Cancel edit ran.")
        this.isEditingOwnMessage = false
    },

  },

  components: {
      InnerNavigation: InnerNavigation,
      accaVueNotification: accaVueNotification

      
  },

  created(){
    this.getData()
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";
  @import "../../styles/global/tables.scss";

</style>

<template>
  <div class="global-container">
    <create-message @closeMessage="isCreatingMessage = false" @messageSubmitted="showMessageSuccess" v-if="isCreatingMessage"></create-message>
    <dashboard-header @createMessageInitiated="isCreatingMessage = true"></dashboard-header>
      <router-view> </router-view>
          <transition 
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeInDown animate__fast"
            leave-active-class="animate__animated animate__fadeOutUp animate__fast"
            class="notice"
        >
            <div v-if="isMessageSubmitted" class="success-notice">
                Mesajınız kaydedildi!
            </div>
        </transition>
  </div>
</template>

<script>

import Header from './components/Header.vue'
import CreateMessage from "./components/admin/CreateMessage.vue"

export default {
  name: 'App',
  data() {
    return {
      isCreatingMessage: false,
      isMessageSubmitted: false,
    }
  },
  methods: {
    showMessageSuccess(){
      this.isCreatingMessage = false,
      this.isMessageSubmitted = true,
      setTimeout(() => { 
        this.isMessageSubmitted = false
       }, 2000);
    },

    globalGoBack(){
      const inputFields = document.getElementsByTagName("input")

      if(inputFields.length > 0){
        return
      }

        window.addEventListener('keydown', e => {
          if(this.$route.path === "/" && e.key === "ArrowLeft"){
            return
          }
          else if(e.key === "ArrowLeft") {
            window.history.go(-1)
          } else if (e.key === "ArrowRight") {
            window.history.go(1)
          }
        })
    },

/*     async checkMessaging() {
        const token = await getToken(this.messaging, {vapidKey: "BKUWGGpgrxpQeho2IunTs-_UIj8dnYk0fOT_K0nVUgNAoRSTjCt-Q6xFR6YPx_eWoI-ew7AFLdJYlcxWMRZbykQ"})
        if(token){
          console.log("Token receieved successfuly.", token)
        } else {
          console.log('No registration token available. Request permission to generate one.');
         }
    } */
  },
  components: {
      dashboardHeader: Header,
      CreateMessage: CreateMessage 
    },


    created(){
      // this.checkMessaging()
    },

    mounted(){
      this.globalGoBack()
    }

}
</script>

<style lang="scss">

</style>
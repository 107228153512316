<template>

    <div class="main-container">
        <acca-vue-notification message="Link kopyalandı!" v-if="isCodeCopied" >

        </acca-vue-notification>

        <div class="document-actions-container w-full col-start-1 col-end-13 mt-10 flex justify-between">
            <docuement-filter-widget @searchOccured="searchList" :searchTerm="searchTerm"></docuement-filter-widget>
                  <div class="ml-10  preset-filters-container justify-start flex">
                </div>

                <div class="flex items-center gap-4">
                <button class="bg-white whitespace-nowrap hover:bg-gray-100 border px-4 py-3 rounded-xl text-2xl flex items-center"
                @click="isAddingNewDoc = true">
                <img class="mr-4" width="20" :src="require('../../assets/icons/add.png')" alt="">
                Yeni Ekle
            </button>
        </div>
    </div>
            
            <table class="alt-table col-start-1 col-end-13 mt-10">
                <thead>
                    <tr>
                        <th class="w-fit whitespace-nowrap"> Belge</th>
                        <th> Yıl </th>
                        <th> Açıklama </th>
                        <th> İşlemler </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="document in filteredDocuments" :key="document.id">
                        <td @click="showCopyNotification" class="hover:text-blue-500 cursor-pointer w-fit whitespace-nowrap"> {{ document.title }} </td>
                        <td> {{ document.year }} </td>
                        <td> {{ document.desc }} </td>
                        <td class="inline-flex gap-4 items-center">  
                            <a :href="document.url" target="_blank" class="hover:text-blue-500 table-button cursor-pointer">  Görüntüle </a>
                            <button @click="editedDoc = document" class="hover:text-blue-500 table-button cursor-pointer"> Düzenle </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
        >
        </transition>

        <teleport to="body">
            <ac-medium-modal class="absolute top-10" width="50vw" height="90vh" title="Yeni Belge Ekle" @modal-closed="isAddingNewDoc = false; editedDoc = undefined" v-if="isAddingNewDoc || editedDoc">
                <form @submit.prevent="addOrUpdateDoc" class="ac-main-fort">
                    <div class="ac-form-control w-full">
                        <label class="single-line-label" for="title">Belge Adı</label>
                        <input type="text" id="title" class="dashboard-form__input" v-model="newDoc.title">
                    </div>
                    <div class="ac-form-control w-full">
                        <label class="single-line-label" for="year">Yıl</label>
                        <input type="number" id="year" class="dashboard-form__input" v-model="newDoc.year">
                    </div>
                    <div class="ac-form-control">
                        <label class="single-line-label" for="desc">Açıklama</label>
                        <textarea id="desc" rows="5" class="medium-message mb-10 w-full" v-model="newDoc.desc">
                        </textarea>
                    </div>
                    <div class="ac-form-control w-full">
                        <label class="single-line-label" for="url">Belge Bağlantısı</label>
                        <input type="url" id="url" class="dashboard-form__input" v-model="newDoc.url">
                    </div>
                    <div class="button- w-full justify-center gap-4 flex">
                        <button type="submit" class="ac-primary-button text-2xl">
                            Ekle
                        </button>
                        <button @click="closeModal" type="button" class="ac-secondary-button text-2xl button__cancel">İptal</button>
                    </div>
                </form>
            </ac-medium-modal>
        </teleport>
            
    </div>
</template>

<script>
import { modalClosed } from "../../mixins/visualFormatters.ts";
import firebase from "../../firebase"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import DocuementFilterWidget from "../reusable/DocumentFilter.vue"
import AcMediumModal from "../reusable/teleports/acMediumModal.vue";
import { postData, updateData } from "@/mixins/modularFirebaseOperations.ts";
export default {


  name: 'CorporateIdentity',
  data(){
      return {
        isCodeCopied: false,
        isAddingNewDoc: false,
        editedDoc: undefined,
        documents: [],
        searchTerm: "",
        isListFiltered: false,
        filterTerm: "",
        newDoc: {
            title: this.editedDoc ? this.editedDoc.title : "",
            year: this.editedDoc ? this.editedDoc.year : "",
            desc: this.editedDoc ? this.editedDoc.desc : "",
            url: this.editedDoc ? this.editedDoc.url : "",
        },
        }
  },
  watch: {
    editedDoc: {
        handler(newVal) {
            this.newDoc = {
                title: newVal ? newVal.title : "",
                year: newVal ? newVal.year : "",
                desc: newVal ? newVal.desc : "",
                url: newVal ? newVal.url : ""
            };
        },
        immediate: true // trigger the handler immediately after the watcher is created
    }
    },

  computed: {

    filteredDocuments() {
      if (this.searchTerm === "" && this.filterTerm != "") {
        return this.documents.filter(
          (document) => document.fileType.toLowerCase() === this.filterTerm
        );
      } else if (this.searchTerm !== "" && this.filterTerm === "") {
        return this.documents.filter(
          (document) =>
            document.title.toLowerCase().indexOf(this.searchTerm) > -1
        );
      } else if (this.searchTerm !== "" && this.filterTerm !== "") {
        const filteredList = this.documents.filter(
          (document) => document.fileType.toLowerCase() === this.filterTerm
        );
        return filteredList.filter(
          (document) =>
            document.title.toLowerCase().indexOf(this.searchTerm) > -1
        );
      } else {
        return this.documents;
      }
    },
  },
  methods: {

    resetNewDoc(){
        this.newDoc = {
            title: "",
            year: "",
            desc: "",
            url: ""
        }
    },
    closeModal(){
        this.isAddingNewDoc = false; 
        this.editedDoc = undefined; 
        this.resetNewDoc()
        modalClosed()
    },
    async addOrUpdateDoc(){
        if(!this.editedDoc){
            try{
                await postData(this.newDoc, "docs/dansuk/corporateIdentity")
                this.isAddingNewDoc = false
                this.editedDoc = undefined
                this.documents.unshift(this.newDoc)
                modalClosed()
            } catch(e){
                alert(`Belge yüklenirken bir hata oluştu. Lütfen bu hata mesajını yazılım ekibiniz ile paylaşın: ${e}`)
            }
        }
        else{
            try {
                await updateData("docs/dansuk/corporateIdentity", this.editedDoc.id, this.newDoc)
                const index = this.documents.findIndex((doc) => doc.id === this.editedDoc.id)
                this.documents[index] = this.newDoc
            } catch(e){
                alert(`Belge güncellenirken bir hata oluştu. Lütfen bu hata mesajını yazılım ekibiniz ile paylaşın: ${e}`)

            } finally {
                this.isAddingNewDoc = false
                this.editedDoc = undefined
                this.resetNewDoc()
                modalClosed()
            }

        }
    },

    filterList(filterTerm, index) {
        // No filters applied, user clicks a filterItem

        if(!this.isListFiltered){
            this.filterTerm = filterTerm.toLowerCase()
            this.isListFiltered = true
            this.presetFilters.forEach((filterItem, i) => {
                if(i === index) {
                    filterItem["active"] = true;
                }
            })
        } else {
            // If the list is already filtered on users click.

                // Get the index of the active element

            const activeElementIndex = this.presetFilters.findIndex((item) => item.active === true)

            // If the clicked link is the same as the one already selected
            
            if(activeElementIndex === index){
                this.filterTerm = ""
                this.isListFiltered = false
                this.presetFilters.forEach((filterItem) => {
                    filterItem["active"] = false
                })
            } else {
                this.filterTerm = filterTerm.toLowerCase()
                this.isListFiltered = true
                this.presetFilters.forEach((filterItem, i) => {
                    if(i === index) {
                        filterItem["active"] = true;
                    } else {
                        filterItem["active"] = false
                    }
                })
            }
        }
    },

      getData(){
          let db = firebase.firestore().collection("docs").doc("dansuk").collection("corporateIdentity").orderBy('year')
          db
            .get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.documents.push({
                        id: doc.id,
                        title: doc.data().title,
                        desc: doc.data().desc,
                        url: doc.data().url,
                        year: (doc.data().year) ? doc.data().year : "-"
                        }
                    )
                }
                )
                this.documents.reverse()
            });

      },

      searchList(searchTerm){
          this.searchTerm = searchTerm.toLowerCase()
      }
  },

  components: {
    accaVueNotification: accaVueNotification,
    DocuementFilterWidget: DocuementFilterWidget,
    AcMediumModal
},

  mounted(){
      this.getData()
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/tables.scss";


</style>

<template>
<div class="outer-container mt-10">
  <div class="main-container">
      <acca-vue-notification selectedType="success" v-if="isUpdatePosted" message="Güncelleme başarıyla yayımlandı."></acca-vue-notification>

    <section id="add-update" class="col-start-1 col-end-13">
        <form class="dashboard-form" @submit.prevent="postUpdate" >

            <label class="single-line-label" for="title">Güncelleme Başlığı</label>
            <input required type="text" id="title" class="dashboard-form__input" v-model="newUpdate.title">

            <label class="single-line-label" for="body">Güncelleme Metni</label>
            <textarea required id="body" rows="5" class="medium-message mb-10" v-model="newUpdate.text"> </textarea>

            <label class="single-line-label b-5" for="images">Görseller</label>

            <acca-dn-d-upload ref="uploadArea" class="mb-8" :allow="['.jpeg', '.jpg', '.png', '.heic', '.tiff', '.webp']"  @filesChanged="uploadFilesToBMS($event, `projects/${getProjectID}`, newUpdate.images)" />

              
              <button type="submit" class="dashboard-form__submit mr-auto ml-auto w-4/12">Gönder</button>
        </form>
    </section>
    
        <section class="updates-container col-start-1 col-end-13 bg-white px-8 py-10">
      <h3 class="updates-container__heading font-bold text-4xl mb-8">Mevcut Güncellemeler</h3>
      <acLoader v-if="isLoadingExistingUpdates === true"/>
      <ul v-else class="updates-container__updates">
        <li v-for="update in sortedUpdates" :key="update.id" class="updates-container__update flex border-b pb-4 mt-4">
          <div class="updates-container__update-meta flex flex-col mr-10">

            <p class="text-2xl">{{update.date}} </p>
            <div id="updateActions" class="flex mt-5">
                <img class="modular-task__status-action-icon mr-3 cursor-pointer" @click="editUpdate(update)" width="20px" :src="require(`../../../assets/icons/action-icons/edit.png`)">                
                <img class="modular-task__status-action-icon cursor-pointer" @click="deleteUpdate(update)" width="20px" :src="require(`../../../assets/icons/action-icons/delete.png`)">
            </div>
            <transition-group          
                appear
                type="animation"
                enter-active-class="animate__animated animate__fadeInDown animate__fast"
                leave-active-class="animate__animated animate__fadeOutUp"
                v-if="editedUpdate.id === update.id"                
                >
            <button key="1" @click="saveEditedUpdate(editedUpdate)" class="w-full border border-gray-500 text-2xl mt-5 bg-black text-white" >Kaydet</button>
            <button key="2" @click="editedUpdate = ''" class="w-full border border-gray-500 text-2xl mt-5">İptal</button>
            </transition-group>
          </div>
          <div class="updates-container__update-content">
              <h4 v-if="editedUpdate.id != update.id" class="font-semibold text-3xl">{{update.title}} </h4>
              <input class="px-4 mb-5 text-bold text-3xl outline-black w-4/12" type="text" v-model="editedUpdate.title" v-else>
              <div v-if="update.images.length > 0" class="updates-container__update-images">
                <div v-for="(img, index) in update.images" :key="img" class="image-area">
                  <img :src="img"  alt="update image" >
                   <img class="delete-image" @click="deleteImage(update, 'images', index)" v-if="editedUpdate.id === update.id" width="20px" :src="require(`../../../assets/icons/action-icons/close.png`)">                
                </div>
              </div>
              <textarea v-if="editedUpdate.id === update.id" name="editUpdateText" v-model="editedUpdate.text" id="editedUpdateText" rows=4 class="outline-black p-2 text-2xl w-full"></textarea>
              <p v-else class="text-2xl">
                {{update.text}}
              </p>
          </div>
        </li>
      </ul>
    </section>



  </div>
</div>

</template>

<script>

import initializeBMS from "../../../mixins/initializeOtherFirebase"
import accaDnDUpload from "../../reusable/accaDnDUpload.vue"
import acLoader from "../../reusable/acLoader.vue"
import deleteImage from "../../../mixins/firebaseOperations.js"
import accaVueNotification from "../../reusable/accaVueNotification.vue"
import bmsMethods from "../../../mixins/bms/bmsMethods.js"

export default {
    
    name: 'BMSProjectUpdates',
    data(){
        return {
          newUpdate: {
            title: "",
            text: "",
            images: [],
            date: new Date(),
          },

          editedUpdate: "",
          
          isUpdatePosted: false,

          isLoadingExistingUpdates: true,
          existingUpdates: []

        }
    },

    mixins: [initializeBMS, bmsMethods, deleteImage],

    methods: {
      postUpdate(){
        const updatesReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("updates")
        updatesReference.add({
          title: this.newUpdate.title,
          text: this.newUpdate.text,
          date: this.newUpdate.date,
          images: this.newUpdate.images
        }).then((docRef) => {
        this.isUpdatePosted = true
                setTimeout(() => {
                    this.isUpdatePosted = false
                }, 1500)
          const writtenID = docRef.id
          this.newUpdate.id = writtenID
          this.existingUpdates.push(this.newUpdate)
          this.newUpdate = {
            title: "",
            text: "",
            images: [],
            date: new Date(),
          }
          this.$refs.uploadArea.clearFiles()
        }).catch((error) => {
          console.error(error)
        })
      },

      editUpdate(update){
        this.editedUpdate = this.existingUpdates.find(editedUpdate => editedUpdate.id === update.id)
      },

      saveEditedUpdate(editedUpdate){
        const updateReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("updates").doc(editedUpdate.id)
        updateReference.set({
          title: this.editedUpdate.title,
          text: this.editedUpdate.text,
          images: this.editedUpdate.images,
          date: new Date(),
        }).then(() => {
          const localUpdateIndex = this.existingUpdates.findIndex(localUpdate => localUpdate.id === editedUpdate.id)
          const changedUpdate = this.existingUpdates[localUpdateIndex]
          changedUpdate.title = this.editedUpdate.title
          changedUpdate.text = this.editedUpdate.text
          changedUpdate.images = this.editedUpdate.images
          this.editedUpdate = ""
        }).catch((error) => {
          console.error("Could not write document changes to the server", error)
        })
      },

      getExistingUpdates(){
        this.isLoadingExistingUpdates = true
        const updatesReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("updates")
        updatesReference.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.existingUpdates.push({
                id: doc.id,
                title: doc.data().title,
                date: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().date.toDate())),
                text: doc.data().text,
                images: doc.data().images
            })
          })
        })
        this.isLoadingExistingUpdates = false
        },

      deleteUpdate(update){
        const isDeleteConfirmed = confirm("Bu güncellemeyi silmek istediğinizden emin misiniz?")
        if(isDeleteConfirmed){
          
          try{
              const updateIndex = this.existingUpdates.findIndex(deletedUpdate => deletedUpdate.id === update.id)
              this.existingUpdates.splice(updateIndex, 1)
          }
          
          catch{
              alert("Bir şeyler ters gitti. Lütfen bu mesajı yazılım ekibinize iletin: There was a problem deleting update locally")
          }

        const updateOnDB = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("updates").doc(update.id)
        updateOnDB.delete().then(() => {
          alert("Güncelleme başarıyla silindi.")
        }).catch((error) => {
          console.error("Güncelleme silinemedi.", error)
        })

        }
      },
    },

    computed: {
      getProjectID(){
        const params = new URLSearchParams(window.location.search)
        const projectID = params.get("id")
        return projectID
    },
    sortedUpdates(){
      return [...this.existingUpdates].reverse()
    }
  },

  components: {
      accaDnDUpload: accaDnDUpload,
      acLoader,
      accaVueNotification
  },

  created(){
      this.getExistingUpdates()
    }
}

</script>

<style lang="scss" scoped>
  @import "../../../styles/core/variables.scss";



  .updates-container__update-meta{
    flex-basis: 10%;
  }

  .updates-container__update-content{
    flex-basis: 90%;
  }
  .updates-container__update-images{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  
    & img{
      width: 20rem;
      height: 15rem;
      object-fit: fill;
      margin: 2rem 2rem 2rem 0;
    }
  }

  .image-area{
    position: relative;
  }

  .delete-image{
    filter: $red-filter;
    position: absolute;
    max-width: 1.3rem;
    max-height: 1.3rem;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


</style>

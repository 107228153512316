<script setup lang=ts>
import { ref } from "vue"
import { useGetAllMetas } from "../../mixins/metaManagement"
import fileUploadFlexible from "../reusable/fileUploadFlexible.vue"
import Multiselect from 'vue-multiselect'

import type { EMailTag } from "@/types"
import VueTooltip from "../reusable/VueTooltip.vue";

const emits = defineEmits(["cancelled", "initiateImport"])
const uploadedFile = ref<File | null>(null)

const { metaList, isLoadingMeta } = useGetAllMetas("crm/EMailTags")

const tags = ref<EMailTag[]>([])

const updateFiles = (files: File[]) => {
    uploadedFile.value = files[0]
}

const initiateImport = () => {
    emits("initiateImport", {file: uploadedFile.value, tags: tags.value})
}

const cancel = () => {
    emits("cancelled")
}

</script>

<template>
    <aside>
        <p class="text-2xl text-white bg-blue-400 p-4 border-blue- border rounded">
        Lütfen içe aktarım yapmadan önce, Excel veya CSV dosyasının doğru formatta olduğuna emin olun. Detaylı bilgileri, eğitimler bölümündeki videodan da alabilirsiniz.
    </p>
    <file-upload-flexible @files-changed="updateFiles" :single-file="true" :allow="['.xls', '.xlsx', '.csv']" class="my-8"/>
    <div v-if="!isLoadingMeta" class="ac-form-control">
                <label class="single-line-label text-3xl my-4" for="email">Etiketler
                    <vue-tooltip explanation="Eğer maillerin tamamına eklemek istediğiniz bir etiket varsa, buradan ekleyebilirsiniz.">
                        <span class="bottom-2 left-2 w-full h-full absolute">?</span>
                    </vue-tooltip>
                </label>
                <multiselect class="w-5/6 email-multiselect h-full border flex flex-col items-start justify-center relative !text-black mr-4"
                    v-model="tags" multiple :options="metaList" placeholder="Eklemek istediğiniz etiketleri seçin"
                    track-by="id" select-label="Seçmek için tıklayın" deselect-label="Seçimi kaldırmak için tıklayın"
                    selected-label="Seçildi" label="name">
                    <template #tag="{option, remove}">
                        <span class="custom-option" :style="{'backgroundColor': option.color}">
                            {{ option.name }}
                            <span class="remove-option" @click="remove(option)">×</span>
                        </span>
                    </template>
                </multiselect>
            </div>

    <div class="button-row justify-center flex gap-4 mt-4">
        <button @click="initiateImport" class="ac-primary-button">İçe Aktar</button>
        <button @click="cancel" class="ac-secondary-button">İptal</button>
    </div>
    </aside>

</template>

<style>
input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}

.custom-option {
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 1rem;
  border-radius: 5px;
  color: white !important;
  display: inline-block;
}

.remove-option {
  margin-left: 10px;
  cursor: pointer;
}

.email-multiselect .multiselect__option--highlight::after{
    background-color: #c4c4c4;
}
.email-multiselect .multiselect__option--highlight.multiselect__option{
    background-color: #c4c4c4;
}

</style>

<template>
    <div class="main-container">
        <inner-navigation class="grid-order-1" link="/documents/lg/all" title="Tüm Dokümanlar" imgURL="all-documents.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/documents/lg/price-lists" title="Fiyat Listeleri" imgURL="price-lists.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"


export default {
  name: 'DocumentsLGHome',
  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

<template>
    <div class="main-container">
        <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="digital/reference-admin" title="Referansları Görüntüle" imgURL="display-references.png" navStyle=1></inner-navigation>
        <inner-navigation v-if="userProfile.role === 'superAdmin'" class="grid-order-2" link="digital/linkedin-posts" title="LinkedIn Paylaşımı Oluştur" imgURL="linkedin-posts.png" navStyle=1></inner-navigation>
        <inner-navigation v-if="userProfile.role === 'superAdmin'" class="grid-order-3" link="digital/qr-admin" title="QR Taleplerini Görüntüle" imgURL="qr-code.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-1" link="/digital/dss/" title="DSS" imgURL="dss-icon.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/digital/bms/home" title="DTS" imgURL="building.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-3" link="/digital/reference-submission" title="Referans Gönder" imgURL="reference-submit.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-4" link="/digital/qr-request" title="DSS - QR Kod Talebi Oluştur" imgURL="qr-code.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-1" link="digital/linkedin-schedule" title="LinkedIn Paylaşımları" imgURL="linkedin-posts-2.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/digital/linkedin-suggestions" title="LinkedIn Paylaşım Önerileri" imgURL="linkedin-suggestions.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'


export default {
  name: 'DansukDigitalHome',
  components: {
      InnerNavigation: InnerNavigation
  },

    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      })
  },
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

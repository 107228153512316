<template>
    <div :style="{'height': loaderHeight + 'px'}" class="loader-elasticball">
        <div  class="loader-elasticball__ball"><i></i></div>
    </div>
</template>

<script>
export default {
    name: "acLoader",
    props: ["loaderHeight"]
}
</script>

<style lang="scss" scoped>

div.loader-elasticball {
  display: flex;
  justify-content: center;
  position: relative;
  height: 6rem;
  
  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    background-color: black;
    transform: scaleY(0.5) translateY(1rem);
    animation: elasticball-bottom .5s .3s infinite;
  }
  
  &__ball {
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: 50% 100%;
    animation: elasticball .5s infinite cubic-bezier(1,-0.01,0,1);
    
    i {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      background-color: black;
      
      animation: elasticball-color 2s infinite;
    }
  }
}
@keyframes elasticball {
  0% {
    transform: translateY(0) scaleY(1)
  } 50% {
    transform: translateY(4rem) scaleY(.7)
  } 100% {
    transform: translateY(0) scaleY(1)
  }
}
@keyframes elasticball-color {
  0% {
    background-color: black;
  } 30% {
    background-color: black;
  } 50% {
    background-color: #cc4040;
  } 80% {
    background-color: #cc4040;
  }
}
@keyframes elasticball-bottom {
  0% {
    transform: scale(1, 0.5) translateY(1rem);
    opacity: 0.3;
  } 100% {
    transform: scale(2, 0.5) translateY(1rem);
    opacity: 0;
  }
}
</style>
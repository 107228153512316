<script setup lang="ts">

import type { ByEvent } from "@/types";
import type { EventCategory } from "@/types";

import { ref, reactive, onBeforeMount, computed } from "vue"
import { mapEventCatsToColorSchemes, getAllEvents, useGetEmployeeBirthdays } from "../../mixins/calendarOperations"
import { useRoute, useRouter } from "vue-router"
import { Qalendar } from "qalendar"

import acLoader from "../reusable/acLoader.vue";


const isLoadingEvents = ref(false)
const events = ref<ByEvent[]>([])
const eventCats = ref<EventCategory[]>([])

const { birthdays, isLoadingBirthdays } = useGetEmployeeBirthdays()

const route = useRoute()
const router = useRouter()

const selectedDate = route.query.day ? new Date(route.query.day) : new Date()

const navigateToCalendar = function(clickedDate: string) {
    router.push({path: "/calendar", query: { day: clickedDate}})
}

const displayedEvents = computed(() => {
    return [...events.value, ...birthdays.value]
})


const calendarConfig = reactive({
    week: {
        startsOn: "monday",
        scrollToHour: 6,
    },
    locale: "tr-TR",
    style: {
        fontFamily: "sans-serif",
        colorSchemes: {},
    },
    month: {
        // Hide leading and trailing dates in the month view (defaults to true when not set)
        showTrailingAndLeadingDates: false,
    },
    defaultMode: "month",
    showCurrentTime: true,
    disableModes: ["day", "week"],
    isSilent: true,
})



onBeforeMount( async () => {
    isLoadingEvents.value = true
    await mapEventCatsToColorSchemes(eventCats, calendarConfig, isLoadingEvents)
    await getAllEvents(events.value, isLoadingEvents)

})

</script>

<template>
    <div class="w-full h-full relative">

            <Qalendar class="summary-calendar" 
            v-if="!isLoadingEvents && !isLoadingBirthdays" 
            :selectedDate="selectedDate" 
            :events="displayedEvents" 
            :config="calendarConfig" 
            @day-was-clicked="navigateToCalendar($event)"
            />
            <div v-else class="w-full h-full relative has-st-bg">
                <ac-loader class="!top-[50%] h-full left-[50%] !absolute"></ac-loader>
            </div>

    </div>
</template>

<style lang="css">
@import "../../../node_modules/qalendar/dist/style.css";

.has-st-bg{
    background-color: rgba(255, 255, 255, 0.394);
    box-shadow: 0 1px 2px rgba(41, 35, 35, 0.4);
    border-radius: 7px;

}

.summary-calendar.calendar-root-wrapper{
    min-height: unset;
    background-color: rgba(255, 255, 255, 0.7066666667);
    box-shadow: 0 1px 2px rgba(41, 35, 35, 0.4);
    border-radius: 7px;
}

.calendar-header__period-name {
    font-size: 1.4rem !important;
}

.week-timeline__day-name {
    font-size: 1rem !important;
}


.summary-calendar .calendar-month__weekday{
    cursor: pointer;
    transition: all .2s;
}
.summary-calendar .calendar-month__weekday:hover{
    background-color: white !important;
}

.summary-calendar .calendar-header__mode-picker{
    display: none !important;
}

.week-timeline__event {
    height: min-content !important;
    font-size: 1.2rem !important;

}

.week-timeline__date {
    font-size: 1.2rem !important;
}

.calendar-header__mode-picker,
.date-picker__value-display-text {
    font-size: 1.2rem !important;
}


/* Flyout sizes */

.event-flyout__row.is-title {
    font-size: 1.2rem !important;
}

.event-flyout__row.is-time {
    font-size: 1.2rem !important;
}

.event-flyout__row {
    font-size: 1.2rem !important;
}
.summary-calendar .calendar-month__event{
    color: #4c4c4c !important;

    font-size: 1.2rem !important;
}

/* Events on the calendar */
.calendar-week__event-row.is-title {
    font-size: 1.2rem !important;
}

.calendar-week__event-row.is-time {
    font-size: 1.2rem !important;
    align-items: center !important;
}

.calendar-week__event-row.is-with {
    font-size: 1.2rem !important;
    align-items: center !important;
    margin-top: 1rem !important;

}

.calendar-week__event-row.is-description {
    font-size: 1.2rem !important;
    margin-top: 1rem !important;
}
</style>
<template>
      <transition 
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeInDown animate__fast"
            leave-active-class="animate__animated animate__fadeOutUp animate__fast"
            class="notice"
        >
            <div :style="{backgroundColor: types[selectedType].backgroundColor, borderBottom: types[selectedType].borderBottom}" class="success-notice">
               {{message}}
            </div>
        </transition>
</template>

<script>
  export default{
      name: 'accaVueNotification',
      data(){
          return{
              types: {
                  success: {
                          backgroundColor: 'rgb(87, 221, 132)',
                          borderBottom: '1px solid rgb(87, 221, 132)'
                  },
                  coolWarning: {
                          backgroundColor: 'rgb(116, 156, 216)',
                          borderBottom: 'rgb(96, 142, 210)'
                  },
                    error: {
                            backgroundColor: 'rgb(255, 99, 132)',
                            borderBottom: '1px solid rgb(255, 99, 132)'
                    }
              }
          }
      },
      props: {
          message: String,
          selectedType: {
              type: String,
              default: 'success'
          }
      }
  }
</script>

<script setup lang="ts">
import { ref } from "vue" 

const searchTerm = ref<string>("")

const searchTermChange = () => {
    console.log(searchTerm.value)
    emits("searchTermChanged", searchTerm.value)
}

// const props = defineProps(["searchTerm"])
const emits = defineEmits(["searchTermChanged"])

</script>
<template>
    <div class="flex border pl-2 items-center bg-white rounded-2xl">
        <span class="text-3xl mr-2">
            <img width="16"  v-if="searchTerm === ''" :src="require('../../assets/icons/search.png')" alt="arama">
            <img  width="16" v-else :src="require('../../assets/icons/search-active.png')" alt="arama">
        </span>
        <input type="text" @input="searchTermChange" v-model="searchTerm" class="w-full outline-none text-2xl rounded-xl p-3">
    </div>
</template>

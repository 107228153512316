<template>

<div class="outer-container">
    <div class="main-container mt-10">

    <acca-vue-notification selectedType="coolWarning" v-if="isAddingNewBlock || isEditingBlock" message="Blok oluşturmak için Enter'a, iptal etmek için ESC tuşuna basın."></acca-vue-notification>
    <acca-vue-notification selectedType="success" v-if="updateSuccess" message="Düzenlemeler başarıyla yapıldı!"></acca-vue-notification>

    <ac-loader class="col-start-3 col-end-10" v-if="isLoadingProject"></ac-loader>
    <form v-else class="dashboard-form" @submit.prevent="updateProject" @keydown.enter.prevent>

        <label class="single-line-label" for="title">Proje Adı
            <vue-tooltip explanation="Kullanıcılar tarafından görüntüleneceği için, buraya projenin resmi adını girmenizi, kısaltma veya Dansuk Mühendislik içinde kullanılan proje adlarını kullanmamanızı rica ederiz. Örnek: 'BJK Stadyum' yerine 'Vodafone Park' gibi.">?</vue-tooltip>     
        </label>
        
        <input required type="text" id="title" class="dashboard-form__input" v-model="project.title">

        <label class="single-line-label mb-5" for="images">Şirket Logosu
                <vue-tooltip explanation="Buradan projenin kendi logosunu veya projeye özel bir logo yoksa şirket logosunu yükleyebilirsiniz.">?</vue-tooltip>    
        </label>

        <div class="flex items-stretch my-4">
            <div class="flex flex-col items-center justify-center w-1/6 mr-10">
                <img class="mb-4" :src="project.companyLogo.src" alt="current company logo">
                <button type="button" @click="isPickingIcon = true" class="bg-gray-100 hover:bg-gray-300 border rounded 400 h-ful text-2xl py-2"> Önceki Logolardan Seç </button>
            </div>
            <acca-dn-d-upload :allow="['.jpeg', '.jpg', '.webp', '.png']" single-file class="w-full"
            @filesChanged="handleLogoUpload($event)" />
        </div>



        <label class="single-line-label mt-5 mb-5" for="images">Arka Plan Görseli
                <vue-tooltip explanation="Projenin DTS sayfasında arka plan resmi olarak kullanılacak görsel. Minimum 1000 piksel genişliğinde bir görsel yüklemenizi rica ederiz.">?</vue-tooltip>    
        </label>
        
        <img :src="project.coverImage.src" alt="project cover image" class="ml-auto mr-auto mb-10">
        <acca-dn-d-upload :allow="['.jpeg', '.jpg', '.webp', '.png']" single-file class="mb-8"
                        @filesChanged="handleCoverImageUpload($event)" />


        <label class="single-line-label" for="link">Proje Websitesi
            <vue-tooltip explanation="Buraya varsa projenin veya şirketin web sitesini ekleyebilirsiniz. Bu alan zorunlu olmasa da kullanıcıların doğrudan DTS üzerinden kendi sitelerine ulaşmasını sağlayabilir.">?</vue-tooltip>    
        </label>
        <input
            type="url"
            id="link"
            class="py-4 px-3 text-2xl focus:outline-black has-small-margin-bottom"
            v-model="project.link"
        >

        <section v-if="project.hasBlocks" class="blocks-manager">
            <label class="single-line-label b-5" for="images"> Proje Bölümleri
                <vue-tooltip explanation="Buradan proje kapsamındaki blokları silebilir, düzenleyebilir veya yeni bloklar ekleyebilirsiniz. Bloklar altında yer alan görevleri düzenlemek için, lütfen 'Proje Tamamlanma Durumu' bölümünü kullanın.">?</vue-tooltip>    
            </label>
            <div class="blocks-container flex-wrap gap-y-10 justify-start flex mt-5">
                <div @dblclick="editBlock(block)" v-for="(block, index) in blocks" :key="block.id" class="flex-col select-none w-1/6 relative items-center flex text-3xl font-semibold px-10 py-10 bg-gray-100 opacity-70 rounded-3xl mr-10">
                    <img width="40px" class="mb-5" :src="require(`../../../assets/icons/building.png`)">
                    <input v-model="newBlock.name" ref="addBlock" autofocus @keyup.enter.prevent="postNewBlock" @blur="cancelNewBlockPost" @keydown.esc="cancelNewBlockPost" class="w-2/4 ml-auto mr-auto outline-black focus:outline-grey-400 px-3" v-if="isAddingNewBlock && index === (blocks.length - 1)">
                    <input v-model="editedBlock.name" autofocus @keyup.enter.prevent="postBlockNameEdit" @blur="cancelNameEdit" @keydown.esc="cancelNameEdit" class="w-3/4 ml-auto mr-auto outline-black focus:outline-grey-400 px-3" v-else-if="isEditingBlock && editedBlock === block">
                    <p v-else>{{block.name}}</p>
                    <img v-if="index != blocks.length - 1 || !isAddingNewBlock" @click="bmsDeleteData(getProjectID, 'blocks', block.id, blocks, index)" width="15px" class="absolute top-2 right-2 cursor-pointer hover:scale-1.1 red-filter" :src="require(`../../../assets/icons/action-icons/delete.png`)">

                </div>
                <div @click="addNewBlock" id="newBlock" class="flex-col relative items-center flex text-3xl font-semibold px-10 py-10 bg-green-100 opacity-70 rounded-3xl mr-10 cursor-pointer" :class="{'cursor-not-allowed': isAddingNewBlock}">
                    <img width="40px" class="mb-5" :src="require(`../../../assets/icons/action-icons/add.png`)">
                    Yeni Ekle
                </div>
            </div>
        </section>
            <button type="submit" class="dashboard-form__submit ml-auto mr-auto mt-10 ">Gönder</button>
        </form>    
    </div>

        <acca-modal-medium class="overflow-scroll" v-if="isPickingIcon" @modalClosed="isPickingIcon = false" title="Logo Seç" height="50vh" width="55vw"> 
            <bms-logo-picker @modalClosed="isPickingIcon = false" @logoSelected="selectExistingLogo" />
        </acca-modal-medium>
    
</div>

</template>

<script>
import initializeBMS from "../../../mixins/initializeOtherFirebase"
import accaVueNotification from "../../reusable/accaVueNotification.vue"
import accaDnDUpload from "../../reusable/accaDnDUpload.vue"

import acLoader from "../../reusable/acLoader.vue"
import bmsMethods from '../../../mixins/bms/bmsMethods.js'
import accaModalMedium from "../../reusable/modal-views/accaModal-medium.vue"
import bmsLogoPicker from "./components/bmsLogoPicker.vue"
import VueTooltip from '../../reusable/VueTooltip.vue'


export default {
    
    name: 'BMSProjectMetaEdit',
    data(){
        return {
            isPickingIcon: false,
            isLoadingProject: false,
            isAddingNewBlock: false,
            isEditingBlock: false,
            updateSuccess: false, 
            
            editedBlock: {
                id: '',
                name: ''
            },
            project: {
                title: "",
                coverImage: {},
                companyLogo: {},
                hasBlocks: "",
                link: "",
            },

            newBlock: {
                name: "",
            },
            
            blocks: [],

            tasks: [],

        }
    },


    mixins: [initializeBMS, bmsMethods],
    components: {
      accaVueNotification: accaVueNotification,
      accaDnDUpload,
      acLoader,
      bmsLogoPicker,
      accaModalMedium,
      VueTooltip: VueTooltip,
    },

    methods:{

     selectExistingLogo(logo){
        this.project.companyLogo = logo
     },

     getProject(){
        this.isLoadingProject = true

        const projectReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID)
        projectReference.get().then((doc) => {
            this.project.title = doc.data().title,
            this.project.coverImage = doc.data().coverImage,
            this.project.companyLogo = doc.data().companyLogo,
            this.project.hasBlocks = doc.data().hasBlocks
            this.project.link = doc.data().link
        }).then(() => {
            if(this.project.hasBlocks){
                const blocksRef = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("blocks")
                blocksRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.blocks.push({
                            id: doc.id,
                            name: doc.data().name,
                        })
                    })
                }).then(() => {
                        this.getTasks()
                    })
            }
        }).catch((error) => {
            console.error("Error while fetching project. Errors:", error)
        })
            this.isLoadingProject = false
        },

    async getTasks(){
        const firstBlock = this.blocks[0].id
        const blocksReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("blocks").doc(firstBlock).collection("tasks")
        blocksReference.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.tasks.push({
                    icon: doc.data().icon,
                    title: doc.data().title,
                    completionRate: 0,
                })
            })
        })
    },

    updateProject(){
        const projectReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID)
        projectReference.update({
            title: this.project.title,
            coverImage: this.project.coverImage,
            companyLogo: this.project.companyLogo,
            link: this.project.link
        }).then(() => {
            this.updateSuccess = true
            setTimeout(() => {
                this.updateSuccess = false
                this.$router.go(-1)
            }, 1300)
           
        }).catch((err) => {
            console.log("Error,", err)
        })

    },

    addNewBlock(){
        if(!this.isAddingNewBlock){            
            this.blocks.push(this.newBlock)
            this.isAddingNewBlock = true
        }
    },

    editBlock(block){
        this.isEditingBlock = true
        this.editedBlock = block
    },

    postBlockNameEdit(){
        const blockRef =this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("blocks").doc(this.editedBlock.id)
        blockRef.update({
            name: this.editedBlock.name
        }).then(() => {
            const blockInLocalList = this.blocks.find(block => block.id === this.editedBlock.id)
            blockInLocalList.name = this.editedBlock.name
            this.cancelNameEdit()
        }).catch((error) => {
            console.error(error)
        })

    },

    cancelNameEdit(){
        this.isEditingBlock = false
        this.editedBlock = ''
    },

    cancelNewBlockPost(){
        this.isAddingNewBlock = false,
        this.blocks.splice((this.blocks.length - 1), 1)
        this.newBlock = {
            name: ""
        }
    },

    postNewBlock(){
        if(this.newBlock.name === undefined || this.newBlock.name === '') {
            return
        }
        const blocksRef =this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("blocks")
        blocksRef.add({
            name: this.newBlock.name
        }).then((docRef) => {
            this.blocks.push(this.newBlock)
            const tasksRef = blocksRef.doc(docRef.id).collection("tasks")
            this.tasks.forEach((task) => {
                tasksRef.add({
                    title: task.title,
                    icon: task.icon,
                    completionRate: 0,
                })
            })
            this.newBlock = {
                name: "",
            }
            this.isAddingNewBlock = false
        }).catch((err) => {
            console.error("Error while posting new block:", err)
        })
    },

    async handleLogoUpload(event) {
            try {
                const logoDetails = await this.uploadSingleToBMS(event, 'logos', true)
                this.project.companyLogo = {
                    "name": logoDetails[0],
                    "src": logoDetails[1]
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleCoverImageUpload(event) {
            try {
                const bgImageDetails = await this.uploadSingleToBMS(event, 'bgImages', true)
                this.project.coverImage = {
                    "name": bgImageDetails[0],
                    "src": bgImageDetails[1]
                }
            } catch (error) {
                console.error(error)
            }
        },

    },


    created(){
        this.getProject()
    },

    computed: {
      getProjectID(){
        const params = new URLSearchParams(window.location.search)
        const projectID = params.get("id")
        return projectID
        },
    }
}

</script>

<style lang="scss" scoped>

@import "../../../styles/core/variables.scss";


.red-filter{
    filter: $red-filter-dark
}

</style>
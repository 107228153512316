<template>
        <div class="main-container">
        <acca-vue-notification v-if="isLeaveRequestSent" message="İzin talebiniz oluşturuldu"></acca-vue-notification>

            <h2 class="form-title">İzin Talebi Oluştur</h2>
            <form class="dashboard-form" @submit.prevent="sendLeaveRequest()" >


            <label class="single-line-label" for="user">Talebi Oluşturan </label>
                <input
                    type="text"
                    readonly
                    id="user"
                    class="dashboard-form__input bg-gray-200"
                    v-model="userProfile.name"
                >
        
            <label class="single-line-label" for="startDate">İzin Başlangıç Tarihi
                    <vue-tooltip explanation="Lütfen ofiste olmayacağınız veya çalışmayacağınız ilk günü işaretleyin">?</vue-tooltip>    
                </label>
                        <input
                            type="date"
                            id="startDate"
                            class="dashboard-form__input w-full"
                            @input="calculateStartDate"
                            v-model="unformattedStartDate"
                        >

            <label class="single-line-label" for="startDate">İzin Bitiş Tarihi
                    <vue-tooltip explanation="Lütfen izin bitiminde ofiste olacağınız ilk günü işaretleyin">?</vue-tooltip>    
                </label>
                        <input
                            type="date"
                            id="endDate"
                            class="dashboard-form__input w-full"
                            @input="calculateEndDate"
                            v-model="unformattedEndDate"
                        >

                    <label class="single-line-label" for="body">Açıklama
                         <vue-tooltip explanation="Oluşturduğunuz izin talebi ile ilgili iletmek istediğiniz bir mesaj varsa, aşağıdaki bölümü kullanabilirsiniz.">?</vue-tooltip>    
                    </label>
                    
                    <textarea
                            id="body"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="leaveRequest.body">
                    </textarea>

                <div class="button-row">
                    <button type="submit" @click="leaveRequest.user = userProfile.name; postData(leaveRequest, 'requests', 'leave', 'leaveRequests')" class="dashboard-form__submit">Oluştur</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>
            <div  v-if="existingRequests.length > 0" class="flex flex-col col-start-3 col-end-11">
                <h2 class="form-title text-white mb-5">Mevcut Talepleriniz</h2>
                <leave-request-status  :requests="existingRequests"></leave-request-status>
            </div>
            </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import VueTooltip from '../reusable/VueTooltip.vue'
import accaVueNotification from "../reusable/accaVueNotification.vue"
import LeaveRequestStatus from "../reusable/LeaveRequestStatus.vue"
import { mapGetters } from "vuex"
import firebase from '../../firebase'

export default {
  name: 'LeaveRequests',
  data() {
      return {
          unformattedStartDate: "",
          unformattedEndDate: "",
          isLeaveRequestSent: false,
          existingRequests: [],
          leaveRequest: {
              user: "",
              startDate: "",
              endDate: "",
              body: "",
              created: new Date(),
              status: "-",
              responseMessage: "",
          }
      }
  },

  methods: {
      goBack(){
          this.$router.go(-1)
      },

      calculateStartDate(){
          this.leaveRequest.startDate = new Date(this.unformattedStartDate).getTime()
        },
      calculateEndDate(){
          this.leaveRequest.endDate = new Date(this.unformattedEndDate).getTime()
        },


    
        checkExistingRequests(){
            console.log("checkExsitingRequests ran")
            let db = firebase.firestore().collection("requests").doc("leave").collection("leaveRequests").where("user", "==", this.userProfile.name).orderBy("created", "desc")

            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.existingRequests.push(
                {
                id: doc.id,
                body: doc.data().body,
                startDate: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().startDate)),
                endDate: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().endDate)),
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                status: doc.data().status,
                responseMessage: doc.data().responseMessage
                    })
                })
            });
      },

      sendLeaveRequest() {
          this.isLeaveRequestSent = true
          setTimeout(() => {
              this.isLeaveRequestSent = false
              this.goBack()
          }, 3000)
      }
  },

   computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },

    components: {
      VueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
      LeaveRequestStatus: LeaveRequestStatus
  },

  mixins: [postData],

    created(){
      this.leaveRequest.user = this.userProfile.name

  },

    mounted(){
        this.checkExistingRequests()
    },

    beforeUpdate(){
        this.leaveRequest.user = this.userProfile.name
    }
} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<template>
        <div class="main-container">
            <table class="table-auto border-collapse col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Gönderen </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Proje </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Tamamlanma Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Sistemler </th>
                        <th colspan="3" class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Proje Detayları </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Bilgiler </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Bahsedilmeyecek </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Görseller </th>
                    </tr>
                </thead>

                <tr v-for="post in submittedReferences" :key="post.id">
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{post.submittedBy}}</td>
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{post.title}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{post.date}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{post.systemType}}</td>
                        <td class="message-content__data whitespace-nowrap py-4 px-8 text-2xl">İç Ünite: <strong>{{post.indoorUnits}}</strong> </td>
                        <td class="message-content__data whitespace-nowrap py-4 px-8 text-2xl">Dış Ünite: <strong>{{post.outdoorUnits}}</strong></td>
                        <td class="message-content__data whitespace-nowrap py-4 px-8 text-2xl">Kapasite: <strong>{{post.capacity}}</strong></td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{post.mention}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{post.doNotMention}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">
                            <a class="text-2xl text-blue-300 list-item list-none" v-for="imageLink in post.images" :key="imageLink" :href="imageLink.src">İndir</a>
                        </td>
                </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'


export default {
    
    name: 'ReferenceAdmin',
    data() {
        return {
            submittedReferences: [],
        }
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("digital").doc("references").collection("referenceSubmissions").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data())
            this.submittedReferences.push(
                {
                id: doc.id,
                images: doc.data().images,
                title: doc.data().title,
                mention: doc.data().mention,
                doNotMention: doc.data().doNotMention,
                capacity: doc.data().capacity,
                indoorUnits: doc.data().indoorUnits,
                outdoorUnits: doc.data().outdoorUnits,
                systemType: doc.data().systemType,
                date: doc.data().date,
                submittedBy: doc.data().submittedBy
                    })

                    this.submittedReferences.reverse()
                })
            });
        },

        logData(){
            console.log(this.submittedReferences[0].image.src)
        }
      },

      mounted(){
          this.getData()
      }
  }


</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


</style>

import initializeBMS from "../initializeOtherFirebase"
import { store } from "../../store"
import { v4 as uuidv4 } from "uuid";

export default {
    mixins: [initializeBMS],
    methods: {

        // File Uploads 


        async uploadSingleToBMS(file, uploadTarget, returnsFileName = false) {

            // The drag and drop uploader returns a file array. So if the type of the "file" argument is an array, we need to access the first element.

            let singleFile
            if(Array.isArray(file)){
                singleFile = file[0]
            } else {
                singleFile = file
            }

            const storageRef = initializeBMS.methods.initializeBMS().storage().ref()
            const folderRef = storageRef.child(`${uploadTarget}/`)
            const originalExtension = singleFile.name.split('.').pop();
            console.log(originalExtension)
            const imageName = `${uuidv4()}.${originalExtension}`;
            console.log(imageName)
            const fileRef = folderRef.child(imageName)
            try {
                await fileRef.put(singleFile)
                const downloadUrl = await fileRef.getDownloadURL()
                if(returnsFileName){
                    return [imageName, downloadUrl]
                } else {
                    return downloadUrl
                }
            } catch (error) {
                console.error(error)
            }
        },
    // Upload files to firebase storage

    async uploadFilesToBMS(files, uploadTarget, databaseField) {

        const storageRef = initializeBMS.methods.initializeBMS().storage().ref()
        const timeStamp = Date.now().toString()
        const folderRef = storageRef.child(`${uploadTarget}/${timeStamp}/`)

        try {
            for (const file of files) {
                const originalExtension = file.name.split('.').pop();
                const imageName = `${uuidv4()}.${originalExtension}`;
                const fileRef = folderRef.child(imageName)
                await fileRef.put(file)
                const downloadUrl = await fileRef.getDownloadURL()
                databaseField.push(
                    downloadUrl
                )
              }
        } catch (error) {
            console.error(error)
        }
    },

        checkBMSAuth(){
            const bmsDB = initializeBMS.methods.initializeBMS().auth()
            bmsDB.onAuthStateChanged((user) => {
                if(user){
                    console.log("Display name is:", user.displayName)
                    store.commit("user/BMS_LOGGED_IN", user)
                } else {
                    store.commit("user/BMS_LOG_OUT")
                }
            })
        },

        bmsDeleteData(projectID, projectSection, itemToBeDeleted, localList, indexInLocalList){
            const confirmation = confirm("Bu veriyi silmek istediğinize emin misiniz? Bu işlem geri alınamaz ve bu veriye bağlı diğer verilerin de silinmesine neden olabilir.")
            if(confirmation){
                const docRef = this.initializeBMS().firestore().collection("projects").doc(projectID).collection(projectSection).doc(itemToBeDeleted)
                docRef.delete().then(() => {
                    if(localList != undefined && indexInLocalList != undefined){
                        localList.splice(indexInLocalList, 1)
                    }
                }).catch((error) => {
                    console.error("Error while deleting item", error)
                })
            }
        },
        // Operations for Block Based Projects

        getBlockTasks(projectID, blockID){
        
            let tasks = []
            const blockRef = this.initializeBMS().firestore().collection("projects").doc(projectID).collection("blocks").doc(blockID).collection("tasks")
    
            blockRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    tasks.push({
                        id: doc.id,
                        title: doc.data().title,
                        icon: doc.data().icon,
                        completionRate: doc.data().completionRate,
                        isNumeric: doc.data().isNumeric ? true : false,
                        targetItems: doc.data().targetItems,
                        completedItems: doc.data().completedItems,
                    })
                })
            })
            
            return tasks
        },

        addBlockTask(task, block, projectID){
            
            block.tasks.push(task)

            const bmsDB = this.initializeBMS().firestore().collection("projects").doc(projectID).collection("blocks").doc(block.id).collection("tasks")
            bmsDB.add({
                title: task.title,
                icon: task.icon,
                completionRate: task.completionRate,
                isNumeric: task.isNumeric,
                targetItems: task.targetItems,
                completedItems: task.completedItems
            })
        },

        deleteBlockTask(task, blockID){
            try {
                const blockTaskIndex = this.selectedBlock.tasks.findIndex(deletedTask => deletedTask.id === task.id)
                this.selectedBlock.tasks.splice(blockTaskIndex, 1)
            }
    
            catch{
                alert("Bir şeyler ters gitti. Lütfen bu mesajı yazılım ekibinize iletin: There was a problem deleting task locally")
            }
    
            const blockTaskOnDB = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID).collection("blocks").doc(blockID).collection("tasks").doc(task.id)
            blockTaskOnDB.delete().then(() => {
                console.log("Task deleted.")
            }).catch((error) => {
                console.error("Problem deleting block task: ", error)
            })
        },
    
  },
}
<script setup>

import { defineProps, ref, computed, reactive } from 'vue';
import { formatDate } from '../../../mixins/visualFormatters';
import inlineTextEditor from '../../reusable/inlineTextEditor.vue';
import { useStore } from 'vuex';
import firebase from '../../../firebase'
import commentListDisplayer from '../../reusable/commentListDisplayer.vue';
import linkedInActions from './linkedInActions.vue';
import singlePostViewExplanation from './singlePostViewExplanation.vue';
const store = useStore()

const props = defineProps({
    post: {
        required: true
    }
})

defineEmits(["post-closed"])

const userProfile = computed(() => store.getters['user/userProfile']);
const isDisplayingHelp = ref(false)
const currentVote = ref("")
const isAddingComment = ref(false)
const newComment = reactive({
    text: '',
    user: {
        name: userProfile.value.name,
        picture: userProfile.value.picture
    },
    time: new Date()
})
const postComments = ref(props.post.comments ? [...props.post.comments] : [])
const postVotes = ref(props.post.votes ? [...props.post.votes]: [])

const handleCancelEdit = (event) => {

    newComment.text = event
    isAddingComment.value = false
}

const handleCompleteEdit = async (event) => {
    if(event === ""){
        isAddingComment.value = false
    }
    newComment.text = event

}

const postCommentToLinkedInPost = async () => {

    const db = firebase.firestore();
    const docRef = db.collection("digital/linkedin/linkedInPosts").doc(props.post.id);
    if(docRef){
        try{
        const addedPost = {...newComment, date: new Date()};

        await docRef.set({
        comments: firebase.firestore.FieldValue.arrayUnion(newComment)
        }, 
        { merge: true });
        postComments.value.push(addedPost)
        newComment.text = ""
        newComment.date = new Date()
        isAddingComment.value = false
        } catch(error){
            alert(`Yorumunuz eklenirken bir hata oluştu: ${error}`)
        }}
    }


const handleCommentDelete = (commentIndex) => {
    const db = firebase.firestore();
    const docRef = db.collection("digital/linkedin/linkedInPosts").doc(props.post.id);
    const deletedComment = postComments.value[commentIndex]

    if(docRef){
        try{
        docRef.update({
        comments: firebase.firestore.FieldValue.arrayRemove(deletedComment)
        })
        postComments.value.splice(commentIndex, 1)
        } catch(error){
            alert(`Yorumunuz silinirken bir hata oluştu: ${error}`)
        }}
}

const currentStatus = computed(() => {
    if(currentVote.value === 'approved'){
        return 'approved'
    } else if(currentVote.value === 'rejected'){
        return 'rejected'
    } else {
        return props.post.status
    }
})

const approvePost = async () => {
    const db = firebase.firestore();
    const docRef = db.collection("digital/linkedin/linkedInPosts").doc(props.post.id);
    if(docRef){
        try{
        docRef.set({
            status: 'approved'
        },{merge: true})
        currentVote.value = 'approved'

        } catch(error){
            alert(`Paylaşım onaylanırken bir hata oluştu: ${error}`)
        }}
} 

const rejectPost = async () => {
    const db = firebase.firestore();
    const docRef = db.collection("digital/linkedin/linkedInPosts").doc(props.post.id);
    if(docRef){
        try{
        docRef.set({
            status: 'rejected'
        },{merge: true})
        currentVote.value = 'rejected'
        } catch(error){
            alert(`Paylaşım onaylanırken bir hata oluştu: ${error}`)
        }}
}

const currentUserVote = computed(() => {
    if(postVotes.value.length === 0) return 'none'
    else {
        if(currentVote.value){
            return currentVote.value
        } else {
            const userVote = postVotes.value.find(vote => vote.user === userProfile.value.name)
                if(userVote){
                    return userVote.direction
                } else {
                    return undefined
                }
        }

    }
})

const voteCount = computed(() => {
    const upVotes = [];
    const downVotes = [];

    if (postVotes.value === undefined) {
        return {
            upVotes: [],
            downVotes: []
        };
    } else {
        postVotes.value.forEach(vote => {
            if (vote.direction === 'up') {
                upVotes.push(vote);
            } else if (vote.direction === 'down') {
                downVotes.push(vote);
            }
        });
    }

    return {
        upVotes: upVotes,
        downVotes: downVotes
    };
});

const votePost = async (direction) => {

    const vote = {
        user: userProfile.value.name,
        direction: direction
    };

    // Find the current vote of the user in our copied array to disallow multiple votes in the same direction.

    let currentUserVoteInDB = undefined;
    
    if (postVotes.value) {
        currentUserVoteInDB = postVotes.value.find(v => v.user === userProfile.value.name);

        // Remove the existing vote from our copied array, and add the new vote.
        
        postVotes.value.splice(postVotes.value.indexOf(currentUserVoteInDB), 1)
    }



    currentVote.value = vote
    postVotes.value.push(vote)

    const db = firebase.firestore();
    const docRef = db.collection("digital/linkedin/linkedInPosts").doc(props.post.id);



    // Check if the user has already voted in the opposite direction
    
    if (currentUserVoteInDB && currentUserVoteInDB.direction !== direction) {
        try {
            // Remove the existing vote
            await docRef.set({
                votes: firebase.firestore.FieldValue.arrayRemove(currentUserVoteInDB)
            }, {merge: true}).then(() => {
                // Add the new vote
                docRef.set({
                    votes: firebase.firestore.FieldValue.arrayUnion(vote)
                }, {merge: true});
            });
        } catch (error) {
            alert(`Error updating the vote: ${error}`);
        }
    } else if (!currentUserVoteInDB) {


        // User hasn't voted yet, add new vote
        try {
            await docRef.set({
                votes: firebase.firestore.FieldValue.arrayUnion(vote)
            }, {merge: true});
        } catch (error) {
            alert(`Error adding the vote: ${error}`);
        }
    } 
    // Handle the case where a user tries to vote in the same direction again
    // (You mentioned this is already handled, so not included here)
};




</script>

<template>
    <div class="post-modal-container md:grid grid-cols-6 relative">
        <div @click="() => {$emit('post-closed')}" class="absolute z-20 cursor-pointer rounded-full p-8 text-3xl right-4 md:right-[13%] flex items-center justify-center opacity-70 hover:opacity-100 font-thin text-gray-400 line-0 w-4 h-4 bg-white">
            <div class="pb-2 pl-1">x</div>
        </div>

        <single-post-view-explanation @help-closed="isDisplayingHelp = false" class=" col-start-1 col-end-6 shadow rounded" v-if="isDisplayingHelp"></single-post-view-explanation>
        <div v-else class="post-and-feedback-container w-full min-h-[80vh] overflow-y-scroll content-start flex flex-col md:grid grid-cols-6 col-start-1 col-end-7 relative">
            <div class="post-container col-start-1 col-end-4">
            <div class="post-body">
                <div class="post-header bg-white flex items-center pb-4 pt-8">
                    <div class="img-container pl-4">
                        <img class="w-20" :src="require('../../../assets/images/dansuk-logo.png')" alt="Dansuk Mühendislik">
                    </div>
                    <div class="ml-4">
                        <h4 class="font-bold"> Dansuk Mühendislik </h4>
                        <p class="text-gray-400 text-lg"> 1.234 followers </p>
                        <p class="text-gray-400 text-lg"> {{ formatDate(post.due) }} </p>
                    </div>
                </div>
                <div class="post-data">
                    <div class="post-content whitespace-pre-line">
                        <p>{{ post.desc }}</p>
                    </div>
                </div>
            </div>
            <img class="mb-12 w-full post-image" :src="post.cover_image" alt="">
        </div>
        <div class="feedback-container col-start-4 col-end-6">
            <div class="feedback-header flex items-center justify-between mb-4">
                <h2>
                    Geri Bildirim
                </h2>
                <div @click="isDisplayingHelp = true" class="rounded-full w-6 h-6 4 bg-gray-200 hover:bg-gray-300 flex items-center justify-center cursor-pointer">
                    <span class="text-gray-700 text-sm">?</span>
                </div>

            </div>
            <hr class="w-full mb-8">
            
            <div v-if="post.isPublished" class="bg-green-300 border border-green-500 rounded p-4 text-xl mb-8">
                <p>Bu mesaj zaten yayımlanmış. Geri bildirim bırakmanıza gerek yok..</p>
            </div> 
            <div v-else-if="post.status === 'rejected' && !['superAdmin', 'admin'].includes(userProfile.role)" class="bg-red-200 border border-red-500 rounded p-4 text-xl mb-8">
                <p>Bu mesaj yönetici kadromuz tarafından reddedilmiş. Yakın zamanda kaldırılıp yerine yeni bir paylaşım geleceği için geri bildirim bırakmanıza gerek yok.</p>
            </div>
            <div v-else class="feedback-body-container">
                <div class="feedback-header mb-8">
                Paylaşımı Değerlendirin
            </div>
            <div v-if="post.status === 'approved'" class="bg-green-200 border border-green-500 rounded p-4 text-xl mb-8">
                <p>Bu mesaj yönetici kadromuz tarafından onaylanmış. Geri bildirim bırakabilirsiniz, ancak özel bir durum olmadığı takdirde mesaj normal takvime göre yayımlanacaktır.</p>
            </div>
            <linked-in-actions
            :current-user-vote="currentUserVote"
            :status="currentStatus"
            :positive-votes="voteCount.upVotes"
            :negative-votes="voteCount.downVotes"
            @post-approved="approvePost"
            @post-rejected="rejectPost"
            @post-voted="votePost($event)"
            class="mb-12" />
            <div class="comment-area relative">
                <transition
                type="animation"
                enter-active-class="animate__animated animate__fadeIn animate__fast"
                >
                    <div v-if="!isAddingComment" class="comment-area__inactive" @click="isAddingComment = true">
                    <img class="comment-profile-image" referrerpolicy="no-referrer" :src="userProfile.picture">
                    <span>Yorumunuzu ekleyin...</span>
                </div>
                <div v-else class="comment-area__active" @blur="isAddingComment = false">
                    <div class="comment-editor-area relative">
                        <img class="comment-active-profile-image" referrerpolicy="no-referrer" :src="userProfile.picture">
                        <div class="comment w-full">
                            <inline-text-editor 
                            :existing-text="newComment.text"
                            @completeEdit="handleCompleteEdit($event)"
                            @cancelEdit="handleCancelEdit($event)"
                            :is-editing="isAddingComment"
                            v-model="newComment.text" class="comment-editor" placeholder="Yorumunuzu ekleyin..."></inline-text-editor>
                        </div>
                    </div>
                    <div class="actions-row relative">
                        <button class="minimal-button" type="button" @click="postCommentToLinkedInPost">Yayımla</button>
                    </div>
                </div>
                </transition>

            </div>
            </div>
           
            <comment-list-displayer @delete-comment="handleCommentDelete($event)" v-if="postComments.length > 0" :comments="postComments"></comment-list-displayer>
            <div v-else class="comments-placeholder text-center">
                <p class="text-2xl text-gray-500 mb-4">Henüz yorum yok.</p>
                <p v-if="post.isPublished" class="text-gray-400 text-xl">Yayımlanmadan önce bu paylaşım için hiç yorum yapılmamış.</p>
                <p v-else-if="!post.isPublished && post.status !== 'rejected'" class="text-gray-400 text-xl">İlk yorumu yaparak geri bildirim verebilirsiniz!</p>
                <p v-else class="text-gray-400 text-xl">Reddedilmeden önce bu paylaşım için hiç yorum yapılmamış.</p>
            </div> 
        </div>
        </div>
       

    </div>
</template>

<style scoped>

.feedback-container{
    background-color: white;
    padding: 1.2rem;
    overflow-y: scroll;
}
.feedback-header{
    color: #5e6166;
    font-weight: 500;
    font-size: 1.6rem;
}

.comment-area{
    border-radius: 8px;
    margin-bottom: 4rem;
    cursor: text;
    transition: height .2s ease-in-out;
    border: 1px solid #d9d9d9;
    background-color: #f5f6f7;
}

.comment-area:has(.comment-area__active){
    background-color: white;
}
.comment-area__inactive{
    display: flex;
    align-items: center;

}

.comment-active-profile-image,
.comment-profile-image{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin-right: .8rem;
}

.comment-area__inactive span{
    padding: 1rem 1.2rem;
    font-size: 1.4rem;
    color: #9fa4ab;
    
}

.comment-area__active{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.4rem;
    background-color: white;
    box-shadow: none;
    border-radius: 8px;
}

.comment-area:focus-within{
    border: 1px solid #1677ff;
}

.comment-editor-area{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.2rem;
    margin-top: .7rem;
}

.comment-active-profile-image{

}

.comment{
    display:flex;
    flex-direction: column;

}
.comment-editor:focus{
    outline: none;
}

.actions-row{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: .5rem;
}
button.minimal-button{
    box-shadow: none;
    font-size: 14px;
    padding: 5px 7px;
    border-radius: 4px;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    color: white;
}

button.minimal-button:hover{
    background-color: #2b80f7;

}
button.minimal-button:disabled{
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    cursor: not-allowed;
}

.post-modal-container {
    overflow-y: scroll;

}
.post-container {
    line-height: 1.5384616;
    color: #333;
    font-size: 1.4rem;
    border: 1px solid #d9d9d9;
    overflow-y: scroll;
    background-color: rgb(238, 241, 243);
    padding: 3rem 8rem;
}
.post-header,
.post-data{
    background-color: white;
    padding: 1.2rem 1.6rem;

}


.post-body{
    overflow-y: scroll;
}

@media screen and (max-width: 750px){

    .post-and-feedback-container{
        padding: 1.6rem 2.4rem;
    }

    .post-container{
        padding: 1.6rem;
    }



}

@media screen and (min-width: 1600px){
    .post-body,
    .post-image{
        max-width: 60rem;
        margin: 0 auto;
    }
}
</style>
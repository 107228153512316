<template>
    <div class="main-container py-10 h-full">
        <div height="100vh" class="col-start-1 col-end-4 border bg-white rounded-xl w-full h-full py-10">
            <h3 class="text-3xl font-bold px-4 mt mb-5">Tüm Anketler</h3>
            <div v-for="survey in availableSurveys" :key="survey.id" @click="fetchResponses(survey.id)"
                :class="{ 'font-bold': survey.id === selectedSurvey }"
                class="text-2xl border-b border-gray-300 px-4 py-6 hover:bg-gray-300 cursor-pointer">
                {{ survey.name }}
            </div>

        </div>

        <div id="survey-responses" class="col-start-4 col-end-13 bg-gray-100 w-full h-full">

            <ac-loader v-if="isLoading" />
            <div v-else id="survey-controls" class="h-20 flex items-center justify-between px-10 bg-black w-full">
                <div v-if="responses[displayedResponse - 1].created !== ''" id="created" class="text-white flex">
                    <p class="text-2xl font-bold mx-5">Cevap Tarihi:</p>
                    <p class="text-2xl"> {{ responses[displayedResponse - 1].created }}</p>
                </div>
                <div id="survey-selector" class="flex items-center">
                    <input type="number" min="0" :max="responses.length" @blur="checkInput"
                        class="w-24 text-center text-2xl py-5 focus:outline-none" v-model="displayedResponse">
                    <span class="text-3xl px-4 text-white">/</span>
                    <p class="text-3xl text-white">{{ responses.length }}</p>
                </div>
            </div>

            <div v-if="responses.length !== 1" class="flex no-resize">
                <div @click="decrementSelectedSurvey"
                    class="w-1/12 cursor-pointer hover:bg-blue-200 text-gray-400 text-4xl flex flex-col justify-center items-center">
                    &#10094; </div>

                <transition type="animation" enter-active-class="animate__animated animate__fadeInRight animate__fast"
                    leave-active-class="animate__animated animate__fadeOutLeft animate__faster" mode="out-in">
                    <img v-if="responses.length > 0" :key="responses[displayedResponse - 1].file"
                        :src="responses[displayedResponse - 1].file" height="90vh" alt="Anket Görseli" class="w-10/12">
                </transition>

                <div @click="incrementSelectedSurvey"
                    class="w-1/12 cursor-pointer hover:bg-blue-200 text-gray-400 text-4xl flex flex-col justify-center items-center">
                    &#10095; </div>
            </div>

            <div v-else class="text-center m-auto mt-auto text-2xl pt-20">
                Bu anket için herhangi bir sonuç bulunamadı.
            </div>


        </div>
    </div>
</template>

<script>

import firebase from '../../firebase'
import 'firebase/storage';
import removeBackground from "../../mixins/removeBackground"
import resetBackground from "../../mixins/resetBackground"
import acLoader from "../reusable/acLoader.vue"
export default {

    name: "SurveyResponses",
    data() {
        return {
            isLoading: false,
            responses: [],
            availableSurveys: [],
            selectedSurvey: "genel-müşteri-anketi",
            displayedResponse: 1,
        }
    },

    mixins: [removeBackground, resetBackground],

    components: {
        acLoader,
    },

    methods: {

        decrementSelectedSurvey() {
            if (this.displayedResponse === 1) {
                return
            } else {
                console.log("Running decrement")
                this.displayedResponse--
            }
        },

        incrementSelectedSurvey() {
            if (this.displayedResponse < this.responses.length) {
                this.displayedResponse++
                console.log("Running increment")
            } else {
                this.displayedResponse = 1
            }
        },

        checkInput() {
            if (this.displayedResponse > this.responses.length) {
                this.displayedResponse = this.responses.length
            }
        },

        async fetchAvailableSurveys() {
            try {
                let db = firebase.firestore().collection("surveys")
                db
                    .get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            if (doc.id !== "responses") {
                                this.availableSurveys.push({
                                    id: doc.data().id,
                                    name: doc.data().name,
                                    created: doc.data().created,
                                })
                            }
                        })
                    })
            } catch (error) {
                alert(error)
            }

        },

        fetchResponses(survey) {
            this.responses = [];
            this.isLoading = true;
            this.selectedSurvey = survey;
            let db = firebase.firestore().collection("surveys").doc("responses").collection(survey).orderBy("created", 'desc');

            db.get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        console.log(doc.data())
                        this.responses.push({
                            file: doc.data().file,
                            created: doc.data().created !== 1 ? new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().created.toDate())) : "",
                        });
                    });
                })
                .catch((error) => {
                    console.error(error);
                    // Optionally handle the error, e.g., show an error message to the user
                })
                .finally(() => {
                    this.isLoading = false; // This will run whether the promise resolved or rejected
                });
        }

    },

    created() {
        this.fetchAvailableSurveys()
        this.fetchResponses("genel-müşteri-anketi")
    },

    mounted() {
        this.removeBackground()
    },

    beforeDestroy() {
        this.resetBackground()
    }


}



</script>

<style lang="scss" scoped>
input[type="number"] {
    text-align: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
    margin-right: 5px;
}

.no-resize {
    min-height: 95vh;
}
</style>>
<script setup lang="ts">
import { reactive, computed } from "vue"
import { useStore } from 'vuex';
import Multiselect from 'vue-multiselect'
import VueTooltip from "../reusable/VueTooltip.vue"

const store = useStore()
const userProfile = computed(() => store.getters['user/userProfile']);


const props = defineProps(['existingEMail', 'tags'])
const emit = defineEmits(['submitted', 'cancel'])

const emailSubmission = reactive({
    firstName: props.existingEMail?.firstName || "",
    lastName: props.existingEMail?.lastName || "",
    email: props.existingEMail?.email || "",
    phone: props.existingEMail?.phone || "",
    company: props.existingEMail?.company || "",
    companyUrl: props.existingEMail?.companyUrl || "",
    industry: props.existingEMail?.industry || "",
    desc: props.existingEMail?.desc || "",
    tags: props.existingEMail?.tags || [],
    created: props.existingEMail?.created || new Date(),
    sentBy:  props.existingEMail?.sentBy || userProfile.value.name
});
 
const emitSubmission = () => {
    emit('submitted', emailSubmission)
}

const emitCancel = () => {
    emit('cancel')
}

</script>

<template>
    <div class="form-container content-center">
        <form class="ac-main-form" @submit.prevent="emitSubmission">
            <div class="ac-form-control">
                <label class="single-line-label" for="email">E Posta <span class="text-gray-400">(Gerekli)</span>
                </label>
                <input required type="email" id="email" class="" v-model="emailSubmission.email">
            </div>

            <div class="flex items-center gap-4">
                <div class="ac-form-control w-full">
                    <label class="single-line-label" for="name">Adı
                    </label>
                    <input type="text" id="name" class="dashboard-form__input" v-model="emailSubmission.firstName">
                </div>
                <div class="ac-form-control w-full">
                    <label class="single-line-label" for="name">Soyadı
                    </label>
                    <input type="text" id="name" class="dashboard-form__input" v-model="emailSubmission.lastName">
                </div>
            </div>

            <div class="ac-form-control">
                <label class="single-line-label" for="email">Etiketler
                </label>
                <multiselect class="w-full border email-multiselect h-full flex flex-col items-start justify-center relative !text-black mr-4"
                    v-model="emailSubmission.tags" multiple :options="tags" placeholder="Eklemek istediğiniz etiketleri seçin"
                    track-by="id" select-label="Seçmek için tıklayın" deselect-label="Seçimi kaldırmak için tıklayın"
                    selected-label="Seçildi" label="name">
                    <template #tag="{option, remove}">
                        <span class="custom-option" :style="{'backgroundColor': option.color}">
                            {{ option.name }}
                            <span class="remove-option" @click="remove(option)">×</span>
                        </span>
                    </template>
                </multiselect>
            </div>


            <div class="ac-form-control">
                <label class="single-line-label" for="email">Telefon Numarası
                </label>
                <input type="phone" id="phone" class="dashboard-form__input" v-model="emailSubmission.phone">
            </div>


            <div class="flex items-center gap-4">

                <div class="ac-form-control w-full">
                    <label class="single-line-label" for="company">Şirket </label>
                    <input type="text" id="company" class="dashboard-form__input" v-model="emailSubmission.company">
                </div>


                <div class="ac-form-control w-full">
                    <label class="single-line-label" for="company">Şirket Websitesi </label>
                    <input type="url" id="companyURL" class="dashboard-form__input" v-model="emailSubmission.companyUrl">
                </div>
            </div>

            <div class="ac-form-control w-full">
                <label class="single-line-label" for="category">Sektör
                </label>
                <input type="text" id="category" class="dashboard-form__input" v-model="emailSubmission.industry">
            </div>

            <div class="ac-form-control">
                <label class="single-line-label" for="body">Açıklama
                    <vue-tooltip
                        explanation="Eklediğiniz bağlantı ile ilgili önemli detaylar varsa, bunları bu alandan iletebilirsiniz.">?</vue-tooltip>
                </label>
                <textarea id="body" rows="5" class="medium-message mb-10 w-full" v-model="emailSubmission.desc">
                </textarea>
            </div>

            <div class="button- w-full justify-center gap-4 flex">
                <button type="submit" 
                    class="ac-primary-button">
                    <span v-if="!existingEMail">
                        Oluştur
                    </span>
                    <span v-else>
                        Güncelle
                    </span>
                </button>
                <button type="button" @click="emitCancel" class="ac-secondary-button button__cancel">İptal</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
@import "../../styles/global/forms.scss";

input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}

.custom-option {
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 1rem;
  border-radius: 5px;
  color: white !important;
  display: inline-block;
}

.remove-option {
  margin-left: 10px;
  cursor: pointer;
}

.multiselect__tags{
    border: none;
}

.email-multiselect .multiselect__option--highlight::after{
    background-color: #c4c4c4;
}
.email-multiselect .multiselect__option--highlight.multiselect__option{
    background-color: #c4c4c4;
}

</style>

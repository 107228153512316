<template>
  <div class="main-container">
      <table class="bg-gray-300 col-start-1 col-end-13 w-full">
          <tr>
              <th class="text-2xl font-bold text-center py-2">Hedef</th>
              <th class="text-2xl font-bold text-center py-2">Hedef Türü</th>
              <th class="text-2xl font-bold text-center py-2">Hedeflenen Miktar</th>
              <th class="text-2xl font-bold text-center py-2">Hedef Bitiş Tarihi</th>
              <th class="text-2xl font-bold text-center py-2"></th>
          </tr>
          <tr class="bg-white cursor-pointer hover:bg-gray-100 border" v-for="goal in existingGoals" :key="goal.id"> 
              <td class="text-2xl text-center py-6 px-2"> {{goal.name}} </td>
              <td class="text-2xl text-center py-6 px-2"> {{goal.goalType}} </td>
              <td class="text-2xl text-center py-6 px-2"> {{goal.target}} </td>
              <td class="text-2xl text-center py-6 px-2"> {{ parseDate(goal.expirationDate) }} </td>
              <td class=" h-full items-center text-center bg-red-400">
                Actions cell
              </td>
          </tr>
      </table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import firebase from '../../firebase'


export default {
  name: 'AllGoals',
  data() {
    return {
      existingGoals: [],
      colors: ["#e63946", "#a8dadc", "#457b9d", "#1d3557"]
    }
  },
  components: {
  },


  methods: {
    parseDate(value){
    try{
        return Intl.DateTimeFormat('tr-TR').format(value)
    } catch {
        console.log("Error converting date")
        }
    },

    displayGoalTarget(value) {
        if(value === "totalNum"){
            return "Proje Sayısı"
        } else {
            return "Proje Adedi"
        }
    },
    getGoals(){
      let db = firebase.firestore().collection("goals")
      db.get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.existingGoals.push({
              id: doc.id,
              name: doc.data().name,
              goalType: doc.data().goalType,
              expirationDate: doc.data().expirationDate,
              completedGoals: doc.data().completed.length + doc.data().manualCompleted,
              lastUpdate: doc.data().compleded,
              period: doc.data().period,
              target: doc.data().target,
              type: doc.data().type,
            })
          })
        })
    },

    calculateGoalPercent(goal) {
        let percentArray = []
        percentArray.push( (goal.completedGoals / goal.target) * 100 )
        return percentArray 
    }
  },



    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      }),
  },



  created(){
    this.getGoals()
  }
}
</script>

<style scoped lang="scss">

.actions-row{

}

</style>

<template>
<main class="w-full">
  <div class="table-actions mt-10 mb-10 w-full flex">
    <document-filter v-if="filterActive" class=" col-start-1 col" @searchOccured="searchList" :searchTerm="searchTerm" />
  </div>
  <table class="table-auto w-full border-2 border-gray-200 rounded-3xl shadow-sm bg-white">
        <thead>
            <tr class="bg-black">
                <th @click="sortTable('ID')" class="dash-table-header"> 
                  Servis No
                    <span class="ml-4" v-if="sortColumn === 'ID' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'ID'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg :class="{'active-sort': sortColumn === 'ID'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                    </span>
                </th>
                <th @click="sortTable('service_date')"  class="dash-table-header" > 
                  Tarih 
                  <vue-tooltip class="bottom-2 left-2 relative" explanation="Talep veya kaydın oluşturulduğu saati görmek için, mouse'ınızı kısa bir süre tarih bilgisi üzerinde bekletebilirsiniz.">i</vue-tooltip>
                  <span class="ml-4" v-if="sortColumn === 'service_date' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'service_date'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg :class="{'active-sort': sortColumn === 'service_date'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                    </span>
                </th>


                <th @click="sortTable('project_name')" class="dash-table-header"> 
                  Proje 
                  <span class="ml-4" v-if="sortColumn === 'project_name' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'project_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg :class="{'active-sort': sortColumn === 'project_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                    </span>
                </th>

                <th @click="sortTable('customer_name')"  class="dash-table-header"> 
                  Müşteri
                  
                  <span class="ml-4" v-if="sortColumn === 'customer_name' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'customer_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg  :class="{'active-sort': sortColumn === 'customer_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                    </span>
                </th>


                <th class="dash-table-header"> Cihaz</th>
                
                <th @click="sortTable('service_type')" class="dash-table-header" > Servis Türü
                  <span class="ml-4" v-if="sortColumn === 'service_type' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'service_type'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg  :class="{'active-sort': sortColumn === 'service_type'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>

                    </span>
                </th>


                <th class="dash-table-header"> Bildirilen Sorun</th>
                <th @click="sortTable('contact_name')"  class="dash-table-header">                
                  Bildiren
                                  <span class="ml-4" v-if="sortColumn === 'contact_name' && isSortReversed">
                      <svg :class="{'active-sort': sortColumn === 'contact_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </span>
                    <span class="ml-4" v-else>
                      <svg :class="{'active-sort': sortColumn === 'contact_name'}" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                    </span>
                </th>
                <th class="dash-table-header"> İletişim Numarası</th>
            </tr>
        </thead>

        <tr class="cursor-pointer hover:bg-gray-100" v-for="doc in sortedData" :key="doc.id">
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.ID}}</td>
                <td :title="parseTimeForTitle(doc.service_date)" class="message-content__data whitespace-nowrap py-4 px-8 text-2xl text-center">{{ parseDate(doc.service_date) }}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.project_name}}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center"> {{doc.customer_name }} </td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.unit_ip}}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.service_type }}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.reported_problem}}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">{{doc.contact_name}}</td>
                <td class="message-content__data py-4 px-8 text-2xl text-center">
                    <a class="underline" :href="`tel:${doc.contact_phone}`">{{doc.contact_phone}}</a>
                </td>
        </tr>
    </table>
</main>

</template>

<script>
import DocumentFilter from '../../reusable/DocumentFilter.vue';
import VueTooltip from '../../reusable/VueTooltip.vue';

export default {
  name: 'DSSTable',
  data(){
    return{
      sortColumn: "ID",
      isSortReversed: false,
      searchTerm: "",

  }},
  components: {
    DocumentFilter,
    "vue-tooltip": VueTooltip

  },
  props: ["tableData", "filterActive"],
  computed: {
    filteredData(){
      
      if(this.searchTerm === ""){
        return this.tableData
      } else {
        console.log("Filtering")
        
        const filteredTable = this.tableData.filter(data => Object.values(data).some(objValue => String(objValue).toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1))

        return filteredTable
      }
    },

    sortedData(){

      let sortedTable;

      if(this.sortColumn === "ID" || this.sortColumn === "service_date"){
        sortedTable = this.filteredData.filter(() => true).sort((a, b) => a[this.sortColumn] > b[this.sortColumn] ? -1 : 1); 
      } else {
        sortedTable = this.filteredData.filter(() => true).sort((a, b) => a[this.sortColumn] > b[this.sortColumn] ? 1 : -1);
      }
      if(this.isSortReversed){
        return sortedTable.reverse()
      } else {
        return sortedTable
      }
      }
  },
  methods: {
    parseDate(value){
        const date = new Date(value)

      try{
        return Intl.DateTimeFormat('tr-TR', {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }).format(date)
      } catch {
        console.log("Error converting date")
      }
    },  
  
    parseTimeForTitle(value){

      const date = new Date(value)

      try{
        const parsedTime = Intl.DateTimeFormat('tr-TR', {hour: "2-digit", minute:"2-digit"}).format(date)
        return `Oluşturulma Zamanı: ${parsedTime}`
      } catch {
        console.log("Error converting date")
      }
    },
    searchList(searchTerm){
      this.searchTerm = searchTerm
    },
    sortTable(sortBy){
      if(sortBy != this.sortColumn){
        this.sortColumn = sortBy
        this.isSortReversed = false
      } else {
        this.isSortReversed = !this.isSortReversed
      }

    }
  },

  }
</script>

<style lang="scss" scoped>

.active-sort{
  color: #ccca40;
}

.dash-table-header{
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 1rem 2rem;
  white-space: nowrap;
  text-align: center;
  user-select: none;

  & span svg{
    display: inline-block;
  }
}

</style>

<script setup>

import { defineProps, defineEmits, onBeforeMount, ref, nextTick } from "vue";

const props = defineProps(["possibleViews", "initialView"])
const emits = defineEmits(["viewChanged"])
const selectedView = ref(props.initialView)
const isSwitchingView = ref(false)
const switcher = ref(null)

const emitViewChange = (view) => {
    emits("viewChanged", view)
    selectedView.value = view
    isSwitchingView.value = false
}

const toggleSwitcher = () => {
    isSwitchingView.value = !isSwitchingView.value
    nextTick(() =>{
            switcher.value.focus()
        })
}

onBeforeMount(() => {
    console.log(props.possibleViews)
})

</script>

<template>
    <div tabindex="-1" class="view-switch-container z-20 select-none relative bg-white border border-gray-200 py-2 px-4 text-xl rounded-2xl cursor-pointer">
        <div @click="toggleSwitcher" class="selected-view flex items-center gap-4 hover:bg-gray-100">
            <img width="24px" class="opacity-80" :src="require(`../../assets/icons/${selectedView.icon}`)"/>
            <span>{{selectedView.displayName}}</span>
        </div>
        <div ref="switcher" v-if="isSwitchingView" tabIndex="0" @blur="isSwitchingView = false" class="view-switch-popup rounded-xl absolute z-40 py-4 shadow-lg top-[120%] w-[120%] left-[-10%] bg-white">
            <div @click="emitViewChange(view)" v-for="view in possibleViews" :key="view.name" class="view-switch-row flex items-center gap-4 mb-4 cursor-pointer hover:bg-gray-100 border-b-1 border-gray-400 justify-start px-8 " :class="{'font-bold': view.name === selectedView.name}">
                <img width="24px" class="opacity-40" :class="{'!opacity-100': view.name === selectedView.name}" :src="require(`../../assets/icons/${view.icon}`)"/>
                <span>{{ view.displayName }}</span>
            </div>
        </div>
    </div>
</template>

<style>
.view-switch-popup::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust as needed */
    left: 55%; /* Center the triangle. Adjust as needed */
    transform: translateX(-50%); /* Ensure it's centered under the modal */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white; /* Size and color of the triangle */
}
</style>


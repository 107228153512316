import firebase from '../firebase'
import initializeOtherFirebase from '@/mixins/initializeOtherFirebase'
import { ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs';


export const useGetElectricityPrices = (selectedYear: string) => {

    const prices = ref({})

    const getPrices = async (year) => {
        console.log("Running getPrices")
        const bmsDB = initializeOtherFirebase.methods.initializeBMS()
        const pricesCollection = bmsDB.firestore().collection("meta").doc("electricityPrices").collection(year).doc("prices")
        const pricesData = await pricesCollection.get()
        if(pricesData.exists) {
            prices.value = pricesData.data().list
        } else {
            prices.value = {}
        }
    }

    getPrices(selectedYear)

    return { prices, getPrices }

}

export const updateElectricityPrice = async (year, month, price) => {
    console.log(price)
    console.log(typeof(price))
    const bmsDB = initializeOtherFirebase.methods.initializeBMS()
    const pricesCollection = bmsDB.firestore().collection("meta").doc("electricityPrices").collection(year).doc("prices")
     const existingData = await pricesCollection.get()
    if(existingData.exists) {
    pricesCollection.update({
        [`list.${month}`]: price
    });
    } else {
    pricesCollection.set({
        list: {
            [month]: price
        }
    });
    }

}

export const useGetAllOMProjects = () => {
    const projects = ref([])
    const isLoadingProjects = ref(false)

    const getProjects = async () => {
        isLoadingProjects.value = true
        const omDB = initializeOtherFirebase.methods.initializeBMS()
        const projectsCollection = omDB.firestore().collection("onlineMonitoringDemo")
        const projectsData = await projectsCollection.get()
        projects.value = projectsData.docs.map(project => {
            return { id: project.id, ...project.data() }
        })
        isLoadingProjects.value = false
    }

    getProjects()

    return { projects, isLoadingProjects }

}

export const useGetOMProject = (projectId) => {
    const project = ref({})
    const isLoadingProject = ref(false)

    const getProject = async () => {
        isLoadingProject.value = true
        const omDB = initializeOtherFirebase.methods.initializeBMS()
        const projectCollection = omDB.firestore().collection("onlineMonitoringDemo").doc(projectId)
        const projectData = await projectCollection.get()
        project.value = { id: projectData.id, ...projectData.data() }
        console.log(project.value)
        isLoadingProject.value = false
    }

    getProject()

    return { project, isLoadingProject }

}

export const getOMProject = async (projectId) => {
    const omDB = initializeOtherFirebase.methods.initializeBMS()
    const projectCollection = omDB.firestore().collection("onlineMonitoringDemo").doc(projectId)
    const projectData = await projectCollection.get()
    return { id: projectData.id, ...projectData.data() }
}

export const checkDataForSelectedPeriod = async (projectId: string, year: string, month: string) => {
    const existingData = {}
    const bmsDB = initializeOtherFirebase.methods.initializeBMS()
    const acpExcelRef = bmsDB.firestore().collection("onlineMonitoringDemo").doc(projectId).collection("data").doc(year).collection(month).doc("acpExcel")
    const soestExcelRef = bmsDB.firestore().collection("onlineMonitoringDemo").doc(projectId).collection("data").doc(year).collection(month).doc("soestExcel")

    const excelData = await acpExcelRef.get()
    const soestData = await soestExcelRef.get()    
    
    if(excelData.exists) {
        console.log(excelData.data())
        existingData["acpExcel"] = excelData.data()
    } 

    if(soestData.exists) {
        console.log(excelData.data())
        existingData["soestExcel"] = soestData.data()
    }

    return existingData

}

export const postExcelFile = async (projectId: string, year: string, month: string, omData: any, fileType: string) => {
    const bmsDB = initializeOtherFirebase.methods.initializeBMS()
    const docRef = bmsDB.firestore().collection("onlineMonitoringDemo").doc(projectId).collection("data").doc(year).collection(month).doc(`${fileType}`)
    await docRef.set({
        path: omData.path,
        uploadedOn: new Date(),
        uploadedBy: omData.uploadedBy
    })

}


/* Project form functions */

/* 

This function fetches the unit names as an array from an ACP report excel file.

*/

export const createUnitNamesFromExcel = (file, usedSheet) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
            const fileData = event.target.result;
            const workbook = XLSX.read(fileData, { type: 'binary' });
            const sheetName = workbook.SheetNames[usedSheet];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Remove the first two rows of the excel file
            jsonData.splice(0, 2);

            // For each row, get the second column value and push it to the unitNames array
            const units = [];

            jsonData.forEach(row => {
                units.push({name: row[1], capacity: ""});
            });


            resolve(units);
        };

        reader.onerror = function(error) {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
};

export const createOrEditProject = async (projectData, projectId) => {
    const bmsDB = initializeOtherFirebase.methods.initializeBMS()
    const projectsCollection = bmsDB.firestore().collection("onlineMonitoringDemo")
    if(projectId) {
        console.log("updating")
        await projectsCollection.doc(projectId).update(projectData)
    } else {
        console.log("creating new")
        await projectsCollection.add(projectData)
    }
}
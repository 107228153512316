<template>
    <div class="main-container">
        <inner-navigation class="grid-order-1" link="/crm/email-list" title="Kontakt Listesi" imgURL="email-list.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/crm/email-tags" title="Kontakt Etiketleri" imgURL="new-email.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'


export default {
  name: 'CRMHome',
  components: {
      InnerNavigation: InnerNavigation
  },
    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      })
  },
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

<template>
  <div class="main-container relative">

    <acca-modal @modalClosed="isInfoShown = false" class="col-start-4 col-end-9" v-if="isInfoShown">
        <span v-html="selectedText"></span>
      </acca-modal>

      <inner-navigation class="grid-order-1" link="/training/dansuk" title="Dansuk Mühendislik - Eğitimler" imgURL="dansuk-logo-en.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-2" link="/training/standard-trainings" title="Eğitim Programı" imgURL="standard-trainings.png"></inner-navigation>
      <external-navigation class="grid-order-3" link="https://www.kocakademi.com" title="Koç Akademi" imgURL="kocakademi.png"></external-navigation>
      <external-navigation class="grid-order-4" link="https://www.udemy.com" title="Udemy" imgURL="udemy.png"></external-navigation>
      <inner-navigation class="grid-order-1" link="/training/useful-videos" title="Faydalı Videolar" imgURL="youtube-icon.png"></inner-navigation>
      <external-navigation class="grid-order-2" link="https://www.youtube.com/c/eLogoDestek" title="Logo Eğitimleri" imgURL="logo-logo.png"></external-navigation>
      <external-navigation hasInfo @infoClicked="showAdditionalInformation('playlist')" class="grid-order-3" link="https://youtube.com/playlist?list=PLjyUXBe6oKS7PEpByjeH1onxhpXav7pmg" title="Nisan 2023 - Ofis Logo Eğitimleri" imgURL="logo-logo.png"></external-navigation>
      <external-navigation hasInfo @infoClicked="showAdditionalInformation('playlist')" class="grid-order-4" link="https://www.youtube.com/playlist?list=PLjyUXBe6oKS5Uv4fdChtJzQwn8HOiMWFA" title="Mayıs 2023 - Ofis Logo Eğitimleri" imgURL="logo-logo.png"></external-navigation>
  </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import ExternalNavigation from "../ExternalNavigation.vue"
import accaModal from "../reusable/accaModal.vue"


export default {
  name: 'TrainingHome',
  data(){
    return {
      isInfoShown: false,
      additionalInformation: {
        playlist: "<strong>Oynatma Listesi </strong> <br> Bu bağlantıyı takip ettiğinizde YouTube üzerinde bir oynatma listesine erişeceksiniz. Tüm videoları bu listede bulabilirsiniz.",
      },

    }
  },
  methods: {
    showAdditionalInformation(req){
      this.isInfoShown = true
      this.selectedText = this.additionalInformation[req]
    }
  },
  components: {
      InnerNavigation: InnerNavigation,
      ExternalNavigation: ExternalNavigation,
      accaModal: accaModal

  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

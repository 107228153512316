<template>

    <div class="main-container">
        <acca-vue-notification message="Link kopyalandı!" v-if="isCodeCopied" >

        </acca-vue-notification>

        <div class="document-actions-container col-start-1 col-end-13 mt-10">
            <docuement-filter-widget @searchOccured="filterList" :searchTerm="searchTerm"></docuement-filter-widget>
            <document-view-switcher :selectedView="selectedComponent" @viewSet="displaySelectedComponent" class="col-start-12 col-end-12" ></document-view-switcher>
        </div>

        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
        >
            <component @codeCopied="showCopyNotification" class="col-span-full w-full" :is="selectedComponent" :documentData="filteredDocuments" coverType="vertical"/>
        </transition>
        
    </div>
</template>

<script>
import firebase from "../../firebase"
import DocumentTableView from "../reusable/DocumentTableView.vue"
import DocumentGridView from "../reusable/DocumentGridView.vue"
import DocumentViewSwitcher from "../reusable/DocumentViewSwitcher.vue"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import DocuementFilterWidget from "../reusable/DocumentFilter.vue"


export default {


  name: 'DocumentsDansukGeneral',
  data(){
      return {
          selectedComponent: "DocumentTableView",
          isCodeCopied: false,
          documents: [],
          searchTerm: ""

      }
  },

  computed: {
      filteredDocuments(){
          if(this.searchTerm === "") {
              return this.documents
          } else {
              return this.documents.filter(document => 
              document.title.toLowerCase().indexOf(this.searchTerm) > -1) 
              }
              }
  },

  methods: {
      filterList(searchTerm){
          this.searchTerm = searchTerm.toLowerCase()
      },

      displaySelectedComponent(view){
          this.selectedComponent = view
      },


      showCopyNotification: function() {
        this.isCodeCopied = true
        setTimeout(() => { 
            this.isCodeCopied = false
       }, 2000);
      },
      getData(){
          let db = firebase.firestore().collection("docs").doc("dansuk").collection("general").orderBy('year')
          db
            .get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.documents.push({
                        id: doc.id,
                        image: doc.data().image,
                        title: doc.data().title,
                        lang: doc.data().lang,
                        fileType: doc.data().fileType,
                        url: doc.data().url,
                        year: (doc.data().year) ? doc.data().year : "-"
                        }
                    )
                }
                )
                this.documents.reverse()
            });

      }
  },

  components: {
      DocumentTableView: DocumentTableView,
      DocumentGridView: DocumentGridView,
      DocumentViewSwitcher: DocumentViewSwitcher,
      accaVueNotification: accaVueNotification,
      DocuementFilterWidget: DocuementFilterWidget,


    
  },

  mounted(){
      this.getData()
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/tables.scss";

</style>

<template>
    <div class="main-container">
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-1" link="requests/admin/document" title="Doküman Taleplerini Görüntüle" imgURL="document-request-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-2" link="requests/admin/meeting" title="Toplantı Taleplerini Görüntüle" imgURL="meeting-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-3" link="requests/admin/leave" title="İzin Taleplerini Görüntüle" imgURL="leave-requests-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-4" link="requests/admin/other" title="Diğer Talepleri Görüntüle" imgURL="other-requests-admin.png" navStyle=1></inner-navigation>
        
        <inner-navigation class="grid-order-1" link="requests/document" title="Doküman Talebi" imgURL="document-request.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="requests/meeting" title="Toplantı Talebi" imgURL="meeting-request.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-3" link="requests/leave" title="İzin Talebi" imgURL="leave-request-alt.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-4" link="requests/other" title="Diğer Talepler" imgURL="other-requests.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'


export default {
  name: 'RequestsHome',
  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },

  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

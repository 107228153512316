<template>
    <div @click="componentClicked" id="weatherToday" class="module-container flex flex-row items-center justify-around">
        <div v-for="forecast in weatherForecastData" :key="forecast.dt">
            <p class="text-xl font-bold">{{formatDate(forecast.dt)}}</p>
            <img width="55" :src="require(`../../assets/icons/weather-icons/${forecast.weather[0].icon}.png`)" alt="">
            <p id="weatherDegrees" class="text-2xl font-bold">{{ cleanWeatherData(forecast.temp.day) }}&#176;</p>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WeatherToday',
  data(){ 
      return {

       weatherForecastData:{},
       apiOptions: {
         baseURL: "https://api.openweathermap.org/data/2.5/onecall",
         appid: "ab08cd45ed2d559d56fbb3971780a415",
         units: "metric",
         exclude: "current,minutely,hourly,alerts",
         lat: "41.01",
         lon: "28.97" // Lat and lon for Istanbul
       }
      }
    },
    
      methods: {
        componentClicked(){
          this.$emit("componentClicked")
      },

      formatDate(rawDate){
        const processedDate = new Date(rawDate * 1000)
        const formattedDate = new Intl.DateTimeFormat('tr-TR').format(processedDate)
        return formattedDate.slice(0,5)
      },

      cleanWeatherData(weather){
        return weather.toFixed()
    },



      fetchData(){
        const finalURL = `${this.apiOptions.baseURL}?lat=${this.apiOptions.lat}&lon=${this.apiOptions.lon}&exclude=${this.apiOptions.exclude}&appid=${this.apiOptions.appid}&units=${this.apiOptions.units}`
        axios.get(finalURL)
          .then(response => {
            const responseSliced = response.data.daily.slice(0, 5)
            this.weatherForecastData = responseSliced
          })
      }
  },


  created(){
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
    #weatherDegrees{
        font-family: 'Encode Sans SC';
        font-weight: 600
    }
</style>
<template>
    <button @click="action" 
    class="add-goal flex items-center px-10 whitespace-nowrap rounded-3xl py-4 col-end-13 text-2xl bg-white cursor-pointer hover:bg-gray-100">
        {{text}}
    </button>
</template>

<script>
export default {
    name: "accaAddButton",
    props: ["text", "action"]
}
</script>

<style>
button.add-goal::before{
    content: "+";
    margin-right: 7px;
    font-size: 15px;
    color: #7e7e7e;
    font-weight: bold;
    border: 1px solid #7e7e7e;
    width: 25px;
    height: 25px;
    border-radius: 100%;

}
</style>
<template>
    <div class="main-container items-center content-center">
        <acca-vue-notification v-if="isWorking" message="Mail imza oluşturuluyor, lütfen bekleyin..."></acca-vue-notification>
        <h2 class="form-title col-start-1 col-end-6 justify-self-center">Mail İmza Oluştur</h2>
        <form class="dashboard-form col-start-1 col-end-6" @submit.prevent="sendMailSignatureRequest()" >

            <label class="single-line-label" for="name">İsim</label>
            <input required type="text" id="name" class="dashboard-form__input" v-model="signature.name">

            <label class="single-line-label" for="tr_title">Türkçe Ünvan</label>
            <input required type="text" id="tr_title" class="dashboard-form__input" v-model="signature.tr_title">

            <label class="single-line-label" for="en_title">İngilizce Ünvan
                <vue-tooltip explanation="Sistem sizin için otomatik olarak İngilizce ve Türkçe iki mail imza oluşturacak. Lütfen kullanılmayacak olsa bile İngilizce ünvan alanını doldurunuz.">?</vue-tooltip>
            </label>
            <input required type="text" id="en_title" class="dashboard-form__input" v-model="signature.en_title">

            <label class="single-line-label" for="email">E-Posta Adresi</label>
            <input required type="email" id="email" class="dashboard-form__input" v-model="signature.email">

            <label class="single-line-label" for="mobile">Cep Telefonu
                <vue-tooltip explanation="Cep telefonunu mail imzada gözükeceği şekilde, başında ülke kodu olacak şekilde ve boşluklarla yazabilirsiniz.">?</vue-tooltip>
            </label>
            <div class="flex w-full h-full items-center">
                <p class="text-4xl pl-3 mr-6 text-center leading-none h-full ">+</p>
                <input placeholder="90 539 123 45 54" type="text" id="mobile" class="dashboard-form__input w-full h-full mb-0" v-model="signature.mobile">
            </div>
            <div class="button-row mt-10">
                <button type="submit" class="dashboard-form__submit">Oluştur</button>
                <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
            </div>
        </form>
        <div class="col-start-6 col-end-13">
            <div class="flex flex-col items-center justify-center mb-10">
                <img 
                :class="{'enlarged': signature.mobile === ''}"
                class="mail-signature-sample mb-10" id="m-3" src="https://firebasestorage.googleapis.com/v0/b/dansuk-engineering-dashboard.appspot.com/o/assets%2Fimages%2Fsample-mail-signature-3.png?alt=media&token=0d11a2e1-4b61-4b16-b7d2-4b8ff30f05d0" alt="Üçlü mail imza">
                <p 
                :class="{'opacity-70': signature.mobile === '', 'opacity-0': signature.mobile != ''}"
                class="text-2xl bg-gray-100  p-2 rounded-xl transform-opacity" >Eğer kişiye özel bir cep telefonu olmayacaksa, imzayı üç alanlı  şablonumuza göre oluşturacağız.</p>
            </div>
            <div class="flex flex-col items-center justify-center">
                <img 
                :class="{'enlarged': signature.mobile != ''}"
                class="mail-signature-sample mb-10" id="m-19" src="https://firebasestorage.googleapis.com/v0/b/dansuk-engineering-dashboard.appspot.com/o/assets%2Fimages%2Fsample-mail-signature-4.png?alt=media&token=93e01de7-c36e-49ee-9551-8a5deba956d5" alt="Dörtlü mail imza">
                <p 
                :class="{'opacity-0': signature.mobile === '', 'opacity-70': signature.mobile != ''}"
                class="text-2xl bg-gray-100  p-2 rounded-2xl transform-opacity">Cep telefonu numarası girdiğiniz için, dört alanlı şablonumuzu kullanacağız.</p>
            </div>
        </div>
    </div>
</template>

<script>
import accaVueNotification from "../reusable/accaVueNotification.vue"
import axios from "axios"
import VueTooltip from "../reusable/VueTooltip.vue"

export default {
  name: 'MailSignatureGenerator',
  data() {
      return {
          
          isWorking: false,
          signature: {
            name: "",
            en_title: "",
            tr_title: "",
            mobile: "",
            email: "",
          }
      }
  },



  methods: {

      async sendMailSignatureRequest(){
        this.isWorking = true
        const response = await axios.get("https://dansuk-dashboard-api.herokuapp.com/mail_signature", {
          responseType: "blob",
          params: {
            name: this.signature.name,
            en_title: this.signature.en_title,
            tr_title: this.signature.tr_title,
            mobile: this.signature.mobile ? `+${this.signature.mobile}`  : null,
            email: this.signature.email
          }
        })
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'mail_signature.zip');
        link.click();
        this.isWorking = false
      },

      goBack(){
          this.$router.push("/tools")
      },

  },

    components: {
      vueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
  },
} 

</script>

<style lang="scss">

.mail-signature-sample{
    filter: grayscale(100%);
    transform: scale(.8);
    transition: all 0.5s ease;
}

.enlarged{
    transform: scale(1.2);
    filter: none;
}

</style>

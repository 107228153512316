<script setup>

import { defineProps, computed, onMounted } from "vue";

const props = defineProps({
    posts: {
        type: Object,
        required: true
    },
    displayEmpty: {
        type: Boolean,
        required: false,
        default: false
    }
})

const displayedCards = computed(() => {
    if(props.displayEmpty) {
        return props.posts
    } else {
        const filteredData = {}
        for (const month in props.posts) {
            filteredData[month] = props.posts[month].filter(item => item.desc !== '')
        }
        return filteredData
    }
})

const emits = defineEmits(['postDisplayed'])

const emitPostDisplayed = (post) => {
    console.log("emitting")
    console.log(post)
    emits('postDisplayed', post)
}

const formatDate = (string) => {
    const date = new Date(string);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('tr-TR', options);
}

const jumpToCurrentMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const year = currentYear.toString();
    const currentMonthString = `${year}-${month}`;
    const element = document.getElementById(currentMonthString);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

}

const monthNames = {
    "01": "Ocak",
    "02": "Şubat",
    "03": "Mart",
    "04": "Nisan",
    "05": "Mayıs",
    "06": "Haziran",
    "07": "Temmuz",
    "08": "Ağustos",
    "09": "Eylül",
    "10": "Ekim",
    "11": "Kasım",
    "12": "Aralık"
}


onMounted(() => {
    jumpToCurrentMonth()
})

</script>

<template>
    <div class="">
    <div class="monthly-posts" v-for="(cards, month) in displayedCards" :key="month">
        <h2 :id="month" class="ac-large-title mb-8">{{ monthNames[month.split('-')[1]] }}</h2>
        <ul  class="posts-container relative">
            <li @click="emitPostDisplayed(card)" class="post-card relative" v-for="card in cards" :key="card.id" :class="{'!border-red-700 !border !border-4' : card.status === 'rejected'}">
                <img class="post-image" v-if="card.cover_image" :src="card.cover_image" :alt="card.name + 'görseli'">
                <div v-else class="no-image w-full relative">
                    <img class="post-image" :src="require('../../../assets/images/no-image.jpg')" alt="placeholder">
                    <div class="text-md w-full italic absolute bottom-10">
                        <p class="inline-block text-center whitespace-nowrap mx-auto">Bu paylaşımın görseli henüz belirlenmemiş.</p>
                    </div>
                </div>
                <h3> {{ card.name}} </h3>
                <p class="card-date"> {{ formatDate(card.due) }}</p>
                <p v-if="card.desc && card.status !== 'rejected'"> {{ card.desc}} </p>
                <p class="italic !text-red-700" v-else-if="card.status === 'rejected'"> Bu paylaşım yönetici kadromuz tarafından reddedilmiş ve yakında kaldırılacak. </p>
                <p class="no-desc" v-else>Bu paylaşım için henüz metin yazılmamış, yazıldığında buradan inceleyebilirsiniz.</p>
                <button v-if="card.desc"> Devamını oku > </button>
                <img title="Bu mesaj LinkedIn üzerinde paylaşıldı." v-if="card.isPublished" :src="require('../../../assets/icons/checked.png')"  class="absolute right-2 bottom-4" width="20px" alt="Paylaşıldı.">
                <img title="Bu mesaj yönetici kadromuz tarafından onaylanmış." v-if="!card.isPublished && card.status === 'approved'" :src="require('../../../assets/icons/checked.png')" width="20px" class="opacity-50 absolute right-2 bottom-4" alt="Onaylandı.">
            </li>
        </ul>
        </div>
</div>
</template>

<style scoped lang="scss">

@import "../../../styles/core/typography.scss";

.posts-container{
    display: flex;
    max-width: 100%;
    gap: 2.4rem;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 2rem;
    align-items: flex-start;
    height: 55vh;
    /* Hide the scrollbar */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.posts-container::-webkit-scrollbar {
    display: none;
}


.post-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    border: 1px solid #e0e0e0;
    flex: 0 0 25vw;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding-bottom: 1rem;
    aspect-ratio: 3 / 2;
    max-width: 25vw;

    & img.post-image{
        object-fit: fill;
        margin-bottom: 2rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        aspect-ratio: 3 / 2; 
        height: 25rem;
        max-width:100%;
        width:100%;

    }

}

.no-image img.post-image{
    width: 100%;
    max-width: unset;
    object-fit: fill;
}

.post-card:hover{
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    background-color: #dfdfdf;
    cursor:pointer
}

.post-card:hover img{
    filter: brightness(0.8);

}

.post-card h3{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: .5rem;
    padding: 0 1.2rem;
    -webkit-line-clamp: 1;          /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
}

.post-card p{
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 1.5;
    color: #4a4a4a;
    padding: 0 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;          /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post-card button{
    font-weight: 700;
    font-size: 1.25em;
    padding-left: 1.2rem;

}
.post-card p.card-date{
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.post-card p.no-desc{
    font-style: italic;
    color: #acacac;
}

@media screen and (max-width: 850px){

    .posts-container{
        align-items: unset;
    }
    .post-card{
       width: 85vw !important;
       height: 65vh;
       aspect-ratio: 4 / 3;
       max-width: unset;
    }
}


</style>
<template>
    <button :style='`background-color: ${bgcolor}`' class="large-icon-button" @click="action">
        <img :src="require(`../../../assets/icons/${icon}.png`)"  alt="Button Action Image">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "largeIconButton",
    props: ["action", "bgcolor", "icon"]
}
</script>

<style lang="scss" scoped>
.large-icon-button{
    aspect-ratio: 1 / .8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
    font-size: 1.8rem;
    color: white;
    font-weight: 600;

    &:hover{
        filter: brightness(.9);
    }

    & img {
        margin-bottom: 2rem;
        max-width: 7rem;
    }
}
</style>


<script setup>
    import { defineProps, defineEmits, computed } from 'vue';
    import { formatDate } from '../../mixins/visualFormatters';
    import { useStore } from 'vuex';

    const store = useStore()
    const userProfile = computed(() => store.getters['user/userProfile']);

    const postDateFormatted = computed(() => {
        // If the comment.time is a date object, format it
        if (props.comment.time instanceof Date) {
            return formatDate(props.comment.time)
        } else {
            return formatDate(props.comment.time.toDate())
        }
    })

    const emits = defineEmits(['deleteComment'])

    const props = defineProps(["comment", "commentIndex"])

    const emitCommentDelete = () => {
        const deleteConfirmation = confirm("Bu yorumu silmek istediğinize emin misiniz?")
        const commentIndex = props.commentIndex
        if (deleteConfirmation) {
            emits('deleteComment', commentIndex)
        }
    }

</script>

<template>
    <div :data-comment-index="commentIndex" class="comment-area">
        <div class="comment-header">
            <div class="comment-user">
                <img class="comment-profile-image" :src="comment.user.picture" alt="user picture">
                <span>{{ comment.user.name }}</span>
            </div>
            <div class="comment-date">
                {{ postDateFormatted }}
            </div>

            <div @click="emitCommentDelete()" v-if="userProfile.name === comment.user.name" class="comment-deleter ml-auto opacity-40 hover:scale-120 cursor-pointer " >
                <img width="14" :src="require('../../assets/icons/delete-grayscale.png')" alt="">
            </div>


        </div>
        <div class="comment-body">
            {{ comment.text }}
        </div>
    </div>
</template>

<style lang="scss">

@import "../../styles/global/helpers.scss";

.comment-area{
    border: 1px solid rgba(0,0,0,.1);
    padding: 1.2rem 1.6rem;
    border-radius: 8px;
    margin-bottom: 1.6rem;
}

.comment-header{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.comment-user{
    display: flex;
    align-items: center;
    gap: .4rem;
    font-size: 1.4rem;
    font-weight: 600;;
    margin-right: 1.6rem;
}

.comment-date{
    color: #8a8a8a;
    font-size: 1.4rem;
}

.comment-body{
    white-space: pre-wrap;
    font-size:1.4rem;
    line-height: 145%;
}

</style>
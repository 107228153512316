import firebase from "../firebase";

const bmsConfig = {
    apiKey: "AIzaSyBfGGUK7jZLpNji684qK1a0nrX8uO5rxEk",
    authDomain: "dansuk-mesa-ms.firebaseapp.com",
    projectId: "dansuk-mesa-ms",
    storageBucket: "dansuk-mesa-ms.appspot.com",
    messagingSenderId: "512672738261",
    appId: "1:512672738261:web:4d79d92a561d2b418aeb5c"
  };
  

export default {
    methods: {

        initializeBMS(){        
            try{
                const bmsDB = firebase.initializeApp(bmsConfig, "bmsFirebase")
                return bmsDB

            }
            catch{
                const bmsDB = firebase.app("bmsFirebase")
                return bmsDB
            }
        }
    }
}
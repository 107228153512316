<template>
    <div class="main-container">
    <acca-vue-notification v-if="isqrRequestsent" message="Talebiniz oluşturuldu, teşekkür ederiz!"></acca-vue-notification>

    <h2 class="form-title">DSS - QR Talebi Oluştur</h2>
    <form class="dashboard-form" @submit.prevent="sendqrRequest()" >

    <label class="single-line-label" for="title">Proje / Bina Adı
        <vue-tooltip explanation="QR kodu oluşturulacak ana projenin adı - örneğin Seba Office Boulevard, Business Istanbul, vb.">?</vue-tooltip>    
    </label>
                            

    <input
        required
        type="text"
        id="title"
        class="dashboard-form__input"
        v-model="qrRequest.project"
    >

    <label class="single-line-label" for="title">Müşteri Adı
        <vue-tooltip explanation="Bu QR Kodları hangi müşteri için oluşturuyoruz? Eğer müşteri bir ofis kompleksi içinde yer almıyorsa, Proje / Bina Adıyla aynı olabilir.">?</vue-tooltip>    
    </label>
                            

    <input
        required
        type="text"
        id="title"
        class="dashboard-form__input"
        v-model="qrRequest.client"
    >
        

            <label class="text-3xl font-semibold mb-5" for="images">Oluşturulacak Kodların Listesi
                <vue-tooltip explanation="Oluşturulmasını istediğiniz QR kodların listesini buradan yükleyebilirsiniz. Word, Excel, PDF ve metin (txt) dosyaları veya görsel dosyalar yükleyebilirsiniz.">?</vue-tooltip>    
            </label>

            <acca-dn-d-upload class="mb-8" @filesChanged="uploadFilesToStorage($event, 'digital/qrRequests', qrRequest.files)" />

            <label class="single-line-label" for="body">Notlarınız
                <vue-tooltip explanation="Oluşturulacak QR Kodlar ile ilgili eklemek istediğiniz herhangi bir şey varsa buradan iletebilirsiniz.">?</vue-tooltip>    
            </label>
    
            <textarea
                    id="message"
                    rows="5"
                    class="medium-message mb-10"
                    v-model="qrRequest.notes">
            </textarea>

        <div class="button-row">
            <button type="submit" @click="postData(qrRequest, 'digital', 'dss', 'qrRequests')" class="dashboard-form__submit">Gönder</button>
            <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
        </div>
    </form>
    </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import VueTooltip from '../reusable/VueTooltip.vue'
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"
import { mapGetters } from "vuex"



export default {
  name: 'QRRequest',
  data() {
      return {
          isqrRequestsent: false,
          qrRequest: {
              project: "",
              client: "",
              notes: "",
              created: new Date(),
              files: [],
              user: ""
          },
      }
  },

    computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },

  methods: {

      goBack(){
          this.$router.go(-1)
      },

      sendqrRequest() {
          this.isqrRequestsent = true
          setTimeout(() => {
              this.isqrRequestsent = false
              this.goBack()
          }, 1500)
      }
  },
    components: {
      accaVueNotification: accaVueNotification,
      accaDnDUpload: accaDnDUpload,
      VueTooltip: VueTooltip,

  },

  mixins: [postData, firebaseOperations],

  created(){
      this.qrRequest.user = this.userProfile.name
  }
} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";

.dropzone .dz-message{
    padding: 4em 0;
}



  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<template>
    <div class="main-container items-start mt-10 py-10 charts-container">      
         <ac-loader class="col-start-1 col-end-5 bg-white w-full ac-loader" v-if="isLoadingData"></ac-loader>
          <apexchart
            v-else
            class="col-start-1 col-end-5 row-start-1 bg-white rounded-2xl apexchart"
            :options="chartOptionsTotal"
            :series="seriesTotal"
            height="300"
            type="bar"
        >
          </apexchart>

        <div class="col-start-1 col-end-5 row-start-2 bg-white py-8 rounded-2xl flex w-full flex-col items-center ">
            <h2 class="text-2xl mb-2 font-bold">Tüm Faturalar - Genel Bakış</h2>
            <p :class="{'text-green-400': seriesTotal[0].data[0] > seriesTotal[0].data[1], 'text-red-400': seriesTotal[0].data[0] < seriesTotal[0].data[1]}"
             class="text-4xl text-center">{{ localizer((seriesTotal[0].data[0] - seriesTotal[0].data[1])) }}₺ </p>
        </div>

         <ac-loader class="col-start-1 col-end-5 bg-white w-full ac-loader" v-if="isLoadingData"></ac-loader>
          <apexchart
          v-else
            class="col-start-1 col-end-5 row-start-3 bg-white rounded-2xl apexchart rounded-2xl"
            ref="TRYChart"
            type="bar"
            :options="chartOptionsTRY"
            :series="seriesTRY"
            height="300"
        >
          </apexchart>

        <div class="col-start-1 col-end-5 row-start-4 bg-white py-8 rounded-2xl flex w-full flex-col items-center ">
            <h2 class="text-2xl mb-2 font-bold">Genel Bakış TRY</h2>
            <p :class="{'text-green-400': seriesTRY[0].data[0] > seriesTRY[0].data[1], 'text-red-400': seriesTRY[0].data[0] < seriesTRY[0].data[1]}"
             class="text-4xl text-center">{{ localizer((seriesTRY[0].data[0] - seriesTRY[0].data[1])) }}₺ </p>
        </div>

         <ac-loader height="300" class="col-start-5 col-end-9 bg-white w-full ac-loader rounded-2xl" v-if="isLoadingData"></ac-loader>
          <apexchart
            v-else
            class="col-start-5 col-end-9 row-start-3 bg-white rounded-2xl apexchart"
            ref="USDChart"
            type="bar"
            :options="chartOptionsUSD"
            :series="seriesUSD"
            height="300"

        >  
          </apexchart>



        <div class="col-start-5 col-end-9 w-full row-start-4  bg-white py-8 rounded-2xl flex flex-col items-center">
            <h2 class="text-2xl mb-2 font-bold">Genel Bakış USD</h2>
            <p :class="{'text-green-400': seriesUSD[0].data[0] > seriesUSD[0].data[1], 'text-red-400': seriesUSD[0].data[0] < seriesUSD[0].data[1]}"
             class="text-4xl text-center"> ${{( localizer(seriesUSD[0].data[0] - seriesUSD[0].data[1])) }} </p>
        </div>


         <ac-loader height="300" class="col-start-9 col-end-13 bg-white ac-loader w-full rounded-2xl" v-if="isLoadingData"></ac-loader>
          <apexchart
            v-else
            class="col-start-9 col-end-13 row-start-3 bg-white rounded-2xl apexchart"
            ref="EURChart"
            type="bar"
            :options="chartOptionsEUR"
            :series="seriesEUR"
            height="300"

        >  
          </apexchart>

            <div class="col-start-9 col-end-13 w-full row-start-4 bg-white py-8 rounded-2xl flex flex-col items-center">
              <h2 class="text-2xl mb-2 font-bold">Genel Bakış EUR</h2>
              <p :class="{'text-green-400': seriesEUR[0].data[0] > seriesEUR[0].data[1], 'text-red-400': seriesEUR[0].data[0] < seriesEUR[0].data[1]}"
              class="text-4xl text-center">{{ localizer(seriesEUR[0].data[0] - seriesEUR[0].data[1]) }}€ </p>
            </div>  

            <csdbs-stats :usdCurrencyRate="usdRate" class="col-start-1 col-end-13 row-start-5"></csdbs-stats>
    </div>
</template>

<script>
import googleSheetOperations from "../../mixins/googleSheetOperations";
import VueApexCharts from 'vue3-apexcharts'
import acLoader from "../reusable/acLoader.vue"
import axios from 'axios'
import csdbsStats from "./stats-components/csdbsStats.vue";


export default {

    
  name: 'FinancialStats',
  data(){
    return {
      isLoadingData: false,
      usdRate: "",
      eurRate: "",
      chartOptionsTotal: {
         title: {
            
            text: "Tüm Faturalar",
            align: "center",
            offsetY: 5,
            style: {
                fontSize: "16px",
                
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          plotOptions: {
            bar: {
                distributed: true,
                backgroundBarColors: ["#40CC86", "#CC4040"],
            dataLabels: {
                position: "top",
            },
            }
          },
          dataLabels: {
            formatter: function(val){
                return val.toLocaleString() + " ₺"
            },
            enabled: true,
            offsetY: -23,
            style: {
                fontSize: "14px",
                colors: ["#000"]
            }
          },
        colors: ["#40CC86", "#CC4040"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
          yaxis: {            
            labels: {
            formatter: (val) => val.toLocaleString(),
            },
            seriesName: "TRY",
          },
          xaxis: {
              categories: ["Satış Faturaları", "Alış Faturaları"],
              labels: {
                style:{
                  fontSize: "1px",
                },
                offsetX: -2,
              },
          },
      },
      chartOptionsTRY: {
         title: {
            text: "TRY Satış Faturaları - Alış Faturaları",
            align: "center",
            offsetY: 5,
            style: {
                fontSize: "16px",
                
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          plotOptions: {
            bar: {
                distributed: true,
            dataLabels: {
                position: "top",
            },
            }
          },
          dataLabels: {
            formatter: function(val){
                return val.toLocaleString() + " ₺"
            },
            enabled: true,
            offsetY: -23,
            style: {
                fontSize: "14px",
                colors: ["#000"]
            }
          },
        colors: ["#40CC86", "#CC4040"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
          yaxis: {            
            labels: {
            formatter: (val) => val.toLocaleString(),
            },
            seriesName: "TRY",
          },
          xaxis: {
              categories: ["Satış Faturaları", "Alış Faturaları"],
              labels: {
                style:{
                  fontSize: "1px",
                },
                offsetX: -2,
              },
          },
      },
      chartOptionsUSD: {
         title: {
            text: "USD Satış Faturaları - Alış Faturaları",
            align: "center",
            offsetY: 5,
            style: {
                fontSize: "16px",
                
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          plotOptions: {
            bar: {
                distributed: true,
            dataLabels: {
                position: "top",
            },
            }
          },
          dataLabels: {
            formatter: function(val){
                return "$" + val.toLocaleString()
            },
            enabled: true,
            offsetY: -23,
            style: {
                fontSize: "14px",
                colors: ["#000"]
            }
          },
        colors: ["#40CC86", "#CC4040"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
          yaxis: {
            labels: {
            formatter: (val) => val.toLocaleString(),
            },
            seriesName: "TRY",
          },
          xaxis: {
              categories: ["Satış Faturaları", "Alış Faturaları"],
              labels: {
                style:{
                  fontSize: "1px",
                },
                offsetX: -2,
              },
          },
      },
      chartOptionsEUR: {
         title: {
            text: "EUR Satış Faturaları - Alış Faturaları",
            align: "center",
            offsetY: 5,
            style: {
                fontSize: "16px",
                
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          plotOptions: {
            bar: {
                distributed: true,
            dataLabels: {
                position: "top",
            },
            }
          },
          dataLabels: {
            formatter: function(val){
                return val.toLocaleString() + "€"
            },
            enabled: true,
            offsetY: -23,
            style: {
                fontSize: "14px",
                colors: ["#000"]
            }
          },
        colors: ["#40CC86", "#CC4040"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
          yaxis: {
            labels: {
            formatter: (val) => val.toLocaleString(),
            },
            seriesName: "TRY",
          },
          xaxis: {
              categories: ["Satış Faturaları", "Alış Faturaları"],
              labels: {
                style:{
                  fontSize: "1px",
                },
                offsetX: -2,
              },
          },
      },



        seriesTotal: [{
                name: "Total",
                data: []
            },
        ],
        seriesTRY: [{
                name: "TRY",
                data: []
            },
        ],
        seriesUSD: [{
                name: "USD",
                data: []
            },
        ],
        seriesEUR: [{
                name: "EUR",
                data: []
            },
        ]


    }
  },

    components:{
      'apexchart': VueApexCharts,
      acLoader,
      csdbsStats,
  },

  methods: {

    localizer(value){
        return value.toLocaleString()
    },

    async populateData(){
      this.isLoadingData = true
      const response = await this.getDataFromGS("1ib97WO0FvT2Lnk2kQo-vmXZXoNa2hgdA0nh1POSiPXc", "Sheet1", "A20:F20")
      const tryIncome = parseFloat(response[0][5])
      const tryExpense = parseFloat(response[0][2])


      const usdIncome = parseFloat(response[0][3])
      const usdExpense = parseFloat(response[0][0])


      const eurIncome = parseFloat(response[0][4])
      const eurExpense = parseFloat(response[0][1])

      const allIncome = {
        "tryIncome": tryIncome,
        "usdIncome": usdIncome,
        "eurIncome": eurIncome
      }

    const allExpense = {
        "tryExpense": tryExpense,
        "usdExpense": usdExpense,
        "eurExpense": eurExpense
      }


      // Currency (income, expense)
      
      this.seriesTRY[0].data.push(
        tryIncome, tryExpense
      )

        this.seriesUSD[0].data.push(
        usdIncome, usdExpense
      )

      this.seriesEUR[0].data.push(
        eurIncome, eurExpense
      )

    await this.calculateTotals(allIncome, allExpense)
    console.log(this.seriesTotal)
    this.isLoadingData = false

    },



  async fetchCurrencyDetails() {
    try {
      const response = await axios.get("https://hasanadiguzel.com.tr/api/kurgetir");
      const currenciesArray = response.data["TCMB_AnlikKurBilgileri"];
      const usdData = currenciesArray.find(currency => currency.CurrencyName === "US DOLLAR");
      const eurData = currenciesArray.find(currency => currency.CurrencyName === "EURO");
      this.usdRate = usdData["ForexSelling"];
      this.eurRate = eurData["ForexSelling"];
    } catch (error) {
      console.error("Error fetching currency details:", error);
    }
},

  async calculateTotals(income, expense){
    this.isLoadingData = true
    let totalIncome = 0
    let totalExpense = 0

    // Convert USD to TRY

    totalIncome = income["usdIncome"] * this.usdRate
    totalExpense = expense["usdExpense"] * this.usdRate



    //Convert EUR to TRY
    
    totalIncome += income["eurIncome"] * this.eurRate
    totalExpense += expense["eurExpense"] * this.eurRate


    // Add Try

    totalIncome += income["tryIncome"]
    totalExpense += expense["tryExpense"]

    //Send to Graph

      this.seriesTotal[0].data.push(
        totalIncome, totalExpense
      )

      this.isLoadingData = false 

    }

  },

  mixins: [googleSheetOperations],

async created(){
  this.isLoadingData = true
  try {
    await this.fetchCurrencyDetails();
    await this.populateData();
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    this.isLoadingData = false;
  }
}
}

</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

.ac-loader{
    height: 30rem !important;
    padding-top: 25%;

}

@media screen and (max-width: 950px){
  .charts-container{
    display: flex;
    flex-direction: column;
    width:100%;
  }

  .charts-container .apexchart{
    width: 100%;

  }

}

</style>

// Export emails as a csv file

import firebase from '../firebase'
import { PotentialEMail, EMailTag } from "@/types";
import { ref, computed } from "vue";
import { useStore } from 'vuex';


import * as XLSX from 'xlsx/xlsx.mjs';


// Composable to multi-select emails on the table

const selectedEMails = ref<string[]>([])

export const useMultiSelectEMails = () => {
    return { selectedEMails }
}

export const resetSelectedEMails = () => {
    selectedEMails.value = []
}

// Add tags to multiple emails

export const addTagToMultipleEMails = async (tag: EMailTag, localEmailArray: PotentialEMail[]) => {
    const emailsRef = firebase.firestore().collection("crm/sales/EMailList");

    for (const emailId of selectedEMails.value) {
        try {
            const docRef = emailsRef.doc(emailId)
            docRef.update({
                tags: firebase.firestore.FieldValue.arrayUnion(tag),
            });

            const email = localEmailArray.find((email) => email.id === emailId);
            if (email) {
                if (!email.tags?.includes(tag)) {
                    email.tags?.push(tag);
                }
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }

    }
}

export const useGetAllEmails = () => {
    const emailList = ref<PotentialEMail[]>([]);
    const isLoadingEmails = ref<boolean>(false);

    const getData = async () => {
        isLoadingEmails.value = true;
        const emailsRef = firebase.firestore().collection("crm/sales/EMailList");
        try {
            emailsRef.orderBy("created", "desc").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    emailList.value.push({
                        id: doc.id,
                        desc: doc.data().desc,
                        firstName: doc.data().firstName,
                        lastName: doc.data().lastName,
                        industry: doc.data().industry,
                        email: doc.data().email,
                        phone: doc.data().phone,
                        company: doc.data().company,
                        companyUrl: doc.data().companyUrl,
                        tags: doc.data().tags,
                        sentBy: doc.data().sentBy,
                    })
                });
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        isLoadingEmails.value = false;
    }

    // Call getData function when the component is mounted

    getData();
    return { emailList, isLoadingEmails };
};


export const exportEmailsToCSV = async (emails: PotentialEMail[]) => {


    const exportedEmails = emails.map(email => {
        return {
            email: email.email,
            firstName: email.firstName,
            lastName: email.lastName,
            phone: email.phone,
            company: email.company?.replace(/,/g, ''),
            industry: email.industry,
        }
    })

    const exportEmailsToCSV = exportedEmails.map(email => {
        return `${email.email},${email.firstName},${email.lastName},${email.phone},${email.company},${email.industry}`
    })

    const headers = "email,firstName,lastName,phone,company,industry, \n"

    const csvContent = "data:text/csv;charset=utf-8," + headers + exportEmailsToCSV.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "emails.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
}

export const exportEmailsToExcel = async (emails: PotentialEMail[]) => {
    // Prepare the data for the worksheet
    const data = emails.map(email => ({
        "E Posta Adresi": email.email,
        "Adı": email.firstName,
        "Soyadı": email.lastName,
        "Telefon Numarası": email.phone,
        "Şirketi": email.company?.replace(/,/g, ''),
        "Şirket Web Sitesi": email.companyUrl,
        "Sektör": email.industry,
        "Açıklama": email.desc,
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Emails");

    // Generate buffer
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and link to download
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'emails.xlsx';

    // Append to the DOM and trigger download

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const massImportEmails = async (file: File, tags: EMailTag[] | [], existingEmails: PotentialEMail[], userName: string) => {
    const user = userName
    const addedTags = tags ? tags : []
    const created = new Date()

    // Return and throw error if file is not a csv, xls, or xlsx
    if (!file.name.endsWith(".csv") && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        return alert("Please upload a csv, xls, or xlsx file");
    } else if (file.name.endsWith(".csv")) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const csv = reader.result;
            const allLines = csv?.toString().split("\n") || [];

            // Remove empty lines
            const lines = allLines.filter(line => line.trim() !== "");

            // Map each line to a new object, removing the first line (headers)
            const emails = lines.slice(1).map(line => {
                const data = line.split(",");
                if (data.length < 1 || data[0] === '') return null; // Adjust as needed
                return {
                    email: data[0],
                    firstName: data[1] || "",
                    lastName: data[2] || "",
                    phone: data[3] || "",
                    company: data[4] || "",
                    companyUrl: data[5] || "",
                    industry: data[6] || "",
                    tags: addedTags,
                    sentBy: user,
                    created: created,
                    desc: "",
                };
            }).filter(email => email !== null);


            const addedEmails = excludeExistingEmails(emails, existingEmails)

            const emailsRef = firebase.firestore().collection("crm/sales/EMailList");

            // Create a new batch

            let batch = firebase.firestore().batch();
            let batchCount = 0;


            for (const email of addedEmails) {
                if (!email.email) {
                    continue;
                } else {
                    try{
                        const docRef = (emailsRef).doc(); // Create a new document reference
                        batch.set(docRef, email); // Add to batch
                        batchCount++;
                    } catch(error){
                        console.error(error)
                    }

                }

                // Commit and start a new batch if limit is reached
                
                if (batchCount === 500) {
                    await batch.commit();
                    batch = firebase.firestore().batch();
                    batchCount = 0;
                }
            }

            // Commit any remaining operations in the final batch
            if (batchCount > 0) {
                await batch.commit();
            } 
        };




    } else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {

        const excelData: any = await convertExcelFileToJSON(file);
        const isExcelDataValid = validateExcelData(excelData);

        if (!isExcelDataValid) {
            return alert("Yüklediğiniz excel dosyası beklenen formatta değil. Lütfen excel dosyanızı kontrol edin ve tekrar deneyin.");
        }

        const mappedEmails = excelData.map((email) => {
            return {
                email: email["E-Posta Adresi"],
                firstName: email["Adı"] ?? "",
                lastName: email["Soyadı"] ?? "",
                phone: email["Telefon Numarası"] ? email["Telefon Numarası"].toString() : "",
                company: email["Şirketi"] ?? "",
                companyUrl: email["Şirket Web Sitesi"] ?? "",
                industry: email["Sektör"] ?? "",
                tags: addedTags,
                sentBy: user,
                created: new Date(),
                desc: email["Açıklama"] ?? "",
            };
        })

        // Exclude emails that are already in the database

        const addedEmails = excludeExistingEmails(mappedEmails, existingEmails)


        const emailsRef = firebase.firestore().collection("crm/sales/EMailList");
        let batch = firebase.firestore().batch();
        let batchCount = 0;

        addedEmails.forEach(async (email) => {
            const docRef = emailsRef.doc(); // Create a new document reference
            batch.set(docRef, email);
            batchCount++;

            // Commit and start a new batch if limit is reached

            if (batchCount === 500) {
                batch.commit();
                batch = firebase.firestore().batch();
                batchCount = 0;
            }

        })

        if (batchCount > 0) {
            await batch.commit();
        }
    }

    // If there are no errors, let the user know the import was successful and refresh the page.

}

function convertExcelFileToJSON(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result as ArrayBufferLike);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                resolve(json); // Resolve the promise with the JSON data
            } catch (error) {
                reject(error); // Reject the promise if there's an error
            }
        };

        reader.onerror = (error) => {
            reject(error); // Also reject the promise if FileReader encounters an error
        };

        reader.readAsArrayBuffer(file);
    });
}

// Check the JSON keys to ensure they match the expected format.
function validateExcelData(data: any[]) {
    const expectedKeys = ['E-Posta Adresi', 'Adı', 'Soyadı', 'Telefon Numarası', 'Şirketi', 'Şirket Web Sitesi', 'Sektör', 'Açıklama'];
    const keys = Object.keys(data[0]);

    return keys.every((key) => expectedKeys.includes(key));
}

// Exclude emails that are already in the database: 

function excludeExistingEmails(addedEmails: any[], existingEmails: PotentialEMail[]) {
    const existingEmailsArray = existingEmails.map(email => email.email)
    const emailsNotOnLocal =  addedEmails.filter(email => !existingEmailsArray.includes(email.email))

    // Remove duplicates

    const uniqueEmails = Array.from(new Set(emailsNotOnLocal.map(email => email.email)))
        .map(email => {
            return emailsNotOnLocal.find(e => e.email === email)
        })

    return uniqueEmails
}
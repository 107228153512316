<template>
    <div class="main-container">
        <large-icon-button bgcolor="#cc4040" icon="action-icons/clean-up" :action="deleteOldDBRows" class="col-start-1 col-end-3">
            Eski DB Kayıtlarını Sil
        </large-icon-button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import largeIconButton from "../reusable/buttons/largeIconButton.vue"

export default {

    name: "SuperAdminHome",
    data(){
        return {

        }
    },

    computed: {
        ...mapGetters(
        'user', {
            userProfile: 'userProfile'
            }
        )
    },

    components:{
        largeIconButton,
    },

    methods: {
        deleteOldDBRows(){
            const confirmation = confirm("Bu işlem veri tabanındaki eski satırları kalıcı olarak silecektir. Devam etmek istediğinize emin misiniz?")

            if (confirmation) {
                alert("Operation completed")
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isSuperAdmin = vm.userProfile.role === "superAdmin"
            if(!isSuperAdmin){
                return false
            }
        })
    
  },
}

</script>

<style lang="scss" scoped>
.super-admin-action-button{

}


</style>
<template>
    <div class="main-container">
        <inner-navigation class="grid-order-1" link="/requests/document" title="Yeni Doküman Talebi Oluştur" imgURL="document-request.png" navStyle=1></inner-navigation>
        <external-navigation is-drive class="grid-order-2" href="https://drive.google.com/drive/folders/1Gq_tdO_2bN66znqvQVm8y4gGHrVQiZ12?usp=drive_link" title="Antetli Kağıtlar" imgURL="dansuk-documents-general.png"></external-navigation>
        <inner-navigation class="grid-order-1" link="/documents/dansuk/catalogs" title="Kataloglar" imgURL="catalog.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="/documents/dansuk/presentations" title="Sunumlar" imgURL="presentation.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-3" link="/documents/dansuk/ar" title="Faaliyet Raporları" imgURL="annual-report.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-4" link="/documents/dansuk/general" title="Belgeler" imgURL="dansuk-documents-general.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import ExternalNavigation from "../ExternalNavigation.vue"

export default {
  name: 'DocumentsDansukHome',
  components: {
      ExternalNavigation,
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firebase-messaging'


const firebaseConfig = {
    apiKey: "AIzaSyB-FCQ09bluZK9csnN_Wn9vpGj4FrZFybE",
    authDomain: "dash.dansuk.com.tr",
    databaseURL: "https://dansuk-engineering-dashboard-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dansuk-engineering-dashboard",
    storageBucket: "dansuk-engineering-dashboard.appspot.com",
    messagingSenderId: "125219650343",
    appId: "1:125219650343:web:3ebabc297bce9ee3e80702",
    measurementId: "G-9Y9PPBZ7DH"
  };

  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase


<template>

<div @click="componentClicked" id="copperPrice" >
<div class="module-container flex items-center justify-evenly flex-row">      
      <div class="flex flex-col items-center">
        <img width="50" :src="require(`../../assets/images/indoor-unit.png`)" alt="">
        <h5 class="text-2xl"> İç Ünite </h5>
          <p class="text-gray-800 currency-value font-bold text-4xl"> {{indoorUnits}}
          <svg class="inline" v-if="hasNumberChanged" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /></svg>

          </p>
      </div>
      <div class="flex flex-col items-center">
        <img width="50" :src="require(`../../assets/images/outdoor-unit.png`)" alt="">
        <h5 class="text-2xl "> Dış Ünite </h5>
          <p class="text-gray-800 currency-value font-bold text-4xl"> {{outdoorUnits}}
            <svg class="inline" v-if="hasNumberChanged" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /></svg>
          </p>
      </div>
      <div class="flex flex-col items-center">
        <img width="50" :src="require(`../../assets/images/project.png`)" alt="">
        <h5 class="text-2xl"> Proje </h5>
          <p class="text-gray-800 currency-value font-bold text-4xl">  {{totalProjects}}
          <svg class="inline" v-if="hasNumberChanged" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 6.5l8.9 8.9c-.8.8-2 .8-2.8 0L12 9.3l-6.1 6c-.8.8-2 .8-2.8 0L12 6.5z" fill="#40CC40" /></svg>
          </p>
      </div>
    </div> 

</div>


</template>

<script>
import googleSheetOperations from "../../mixins/googleSheetOperations";

// import googleServiceAccount from "../../gsec/dansuk-engineering-dashboard-56868a68e490.json"

export default {
  name: 'UnitsCount',
  data(){ 
      return {
        outdoorUnits: "",
        indoorUnits: "",
        totalProjects: "",
      hasNumberChanged: false,
      }
  },

  mixins: [googleSheetOperations],

  watch: {
      outdoorUnits(newValue,  oldValue){
          if(oldValue != "" && newValue != oldValue){
              this.valueChanged();
        }
      },
      indoorUnits(newValue,  oldValue){
          if(oldValue != "" && newValue != oldValue){
              this.valueChanged();
        }
      },
      totalProjects(newValue,  oldValue){
          if(oldValue != "" && newValue != oldValue){
              this.valueChanged()
        }
      }
  },

  methods: {
    componentClicked(){
      this.$emit("componentClicked")
      },

    valueChanged(){
            this.hasNumberChanged = true
            setTimeout(() => {
                this.hasNumberChanged =false
            }, 1000)
    },





    async populateData(){
      const response = await this.getDataFromGS("1ib97WO0FvT2Lnk2kQo-vmXZXoNa2hgdA0nh1POSiPXc", "Sheet1", "J19:N19")
      this.totalProjects = response[0][0]
      this.indoorUnits = response[0][3]
      this.outdoorUnits = response[0][4]
    },

    },
 
 
    created(){
        this.populateData()
    }
}

</script>

<style lang="scss" scoped>
h5{
    font-family: 'Encode Sans SC';
}

.currency-value {
      font-family: 'Encode Sans SC';
}
</style>


<template>
    <main class="px-2 flex flex-col">
      <div class="table-actions w-full flex items-center justify-between">
        <document-filter v-if="filterActive" class="col-start-1" @searchOccured="searchList" :searchTerm="searchTerm" />

        <select class="text-2xl bg-transparent" @change="isSortReversed = false" name="sort" v-model="sortColumn" id="sort">
            <option value="ID">Servis Kayıt No</option>
            <option value="service_date">Tarih</option>
            <option value="customer_name">Müşteri</option>
            <option value="project_name">Proje</option>
            <option value="service_type">Servis Türü</option>
            <option value="reported_problem">Sorun</option>
        </select>

        <div>
            <svg v-if="!isSortReversed" @click="isSortReversed = true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                <path d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"/>
            </svg>
            <svg v-else @click="isSortReversed = false" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                <path d="M3.5 13.5a.5.5 0 0 1-1 0V4.707L1.354 5.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 4.707V13.5zm4-9.5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"/>
            </svg>
        </div>
      </div>
      <details v-for="record in sortedData" :key="record.id" class="bg-white mt-8 w-full p-4 flex mb-4 shadow shadow-lg">
            <summary class="text-2xl">
                <div class="flex justify-between items-start">
                    <div class="">
                        <h4 class="text-2xl">{{record.customer_name}}</h4>
                        <p class="text-xl text-gray-500">{{record.project_name}}</p>
                    </div>
                    <div class="date">{{parseDate(record.service_date) }}</div>
                </div>
            </summary>
            <div class="border-t mt-6 border-gray-200">
                <div class="mt-6">
                    <strong class="text-2xl">Kayıt No:</strong>
                    <span class="text-2xl">{{record.ID}}</span>
                </div>
                <div class="mt-4">
                    <strong class="text-2xl">Ünite: </strong>
                    <span class="text-2xl">{{record.unit_ip}}</span>
                </div>
                <div class="mt-4">
                    <strong class="text-2xl">Bildirilen Sorun: </strong>
                    <span class="text-2xl">{{record.reported_problem}}</span>
                </div>
                <div class="mt-4">
                    <strong class="text-2xl">Servis Tipi: </strong>
                    <span class="text-2xl">{{record.service_type}}</span>
                </div>
                <div class="mt-6 border-t border-gray-200 flex items-center justify-between">
                    <div class="contact flex items-center mt-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>
                        <span class="text-2xl ml-2">{{record.contact_name}}</span>
                    </div>
                    <a class="underline text-blue-500 text-2xl self-end" :href="`tel:${record.contact_phone}`">{{record.contact_phone}}</a>
                </div>
                <div class="mt-2">
                    <span class="text-2xl text-gray-400">{{parseDateTime(record.service_date)}}</span>
                </div>
            </div>
      </details>

    </main>
    
    </template>
    
    <script>
    import DocumentFilter from '../../reusable/DocumentFilter.vue';
    
    export default {
      name: 'DSSMobile',
      data(){
        return{
          sortColumn: "ID",
          isSortReversed: false,
          searchTerm: "",
    
      }},
      components: {
        DocumentFilter
      },
      props: ["tableData", "filterActive"],
      computed: {
        filteredData(){
          
          if(this.searchTerm === ""){
            return this.tableData
          } else {
            console.log("Filtering")
            
            const filteredTable = this.tableData.filter(data => Object.values(data).some(objValue => String(objValue).toLowerCase().indexOf(this.searchTerm) > -1))
    
            return filteredTable
          }
        },
    
        sortedData(){
    
          let sortedTable;
    
          if(this.sortColumn === "ID" || this.sortColumn === "service_date"){
            sortedTable = this.filteredData.filter(() => true).sort((a, b) => a[this.sortColumn] > b[this.sortColumn] ? -1 : 1); 
          } else {
            sortedTable = this.filteredData.filter(() => true).sort((a, b) => a[this.sortColumn] > b[this.sortColumn] ? 1 : -1);
          }
          if(this.isSortReversed){
            return sortedTable.reverse()
          } else {
            return sortedTable
          }
          }
      },
      methods: {
        parseDate(value){
            const date = new Date(value)
    
          try{
            return Intl.DateTimeFormat('tr-TR', {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }).format(date)
          } catch {
            console.log("Error converting date")
          }
        },  

        parseDateTime(value){
            const date = new Date(value)
    
          try{
            return Intl.DateTimeFormat('tr-TR', {
              day: "2-digit",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            }).format(date)
          } catch {
            console.log("Error converting date")
          }
        },  
      
        parseTimeForTitle(value){
    
          const date = new Date(value)
    
          try{
            const parsedTime = Intl.DateTimeFormat('tr-TR', {hour: "2-digit", minute:"2-digit"}).format(date)
            return `Oluşturulma Zamanı: ${parsedTime}`
          } catch {
            console.log("Error converting date")
          }
        },
        searchList(searchTerm){
          this.searchTerm = searchTerm
        },
        sortTable(sortBy){
        console.log(sortBy)

          if(sortBy != this.sortColumn){
            this.sortColumn = sortBy
            this.isSortReversed = false
          } else {
            this.isSortReversed = !this.isSortReversed
          }
    
        }
      },

    
      }
    </script>
    
    <style lang="scss" scoped>

    </style>
    
<template>
    <div class="main-container">
          <button v-if="!isAuthenticatedForBMS" @click="BMSLogin" class="dts-link col-start-1 col-end-7">
            <img :src="require(`../../../assets/icons/info-icon.png`)">
            <span>Lütfen Düzenleme Yapmadan Önce Buraya Tıklayarak Hesabınızı Yetkilendirin</span>
          </button>
        <div class="actions-row col-start-1 col-end-13 flex justify-between">
          <a href="https://dts.dansuk.com.tr" class="dts-link col-start-1">
            <img :src="require(`../../../assets/icons/link.png`)">
            <span>DTS'e Git</span>
          </a>
          <acca-add-button text="Yeni Proje Ekle" :action="addNewProject" class="col-end-13"></acca-add-button>
        </div>

        <inner-navigation v-for="project in projectsList" :key="project.id" class="col-span-3" :link="`project?id=${project.id}`" :title="project.title" :remoteImage="project.companyLogo" navStyle=2></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../../InnerNavigation.vue"
import { mapGetters } from 'vuex'
import initializeBMS from "../../../mixins/initializeOtherFirebase"
import accaAddButton from "../../reusable/buttons/accaAddButton.vue"
import bmsMethods from '../../../mixins/bms/bmsMethods'
// import firebase from '../../../firebase'
// import 'firebase/firestore'


export default {
  name: 'DansukBMSHome',
  data(){
    return {
        projectsList: [],
        isAddingNewProject:false 
    }
  },
  mixins: [initializeBMS, bmsMethods],
  components: {
      InnerNavigation: InnerNavigation,
      accaAddButton: accaAddButton
  },

  methods: {

    BMSLogin(){
      this.$store.dispatch("user/BMSLogin")
    },

    getBMSProjects(){
        const bmsDB = this.initializeBMS().firestore().collection("projects")
        bmsDB.get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.projectsList.push({
                        id: doc.id,
                        title: doc.data().title,
                        companyLogo: doc.data().companyLogo.src
                    })
                })
        })
    },
    

    addNewProject(){
      this.$router.push("add-project")
    }
  },

    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
          isAuthenticatedForBMS: "isAuthenticatedForBMS"
      })
  },

  created(){
    this.getBMSProjects()
    this.checkBMSAuth()
  }
}
</script>

<style scoped lang="scss">
  .dts-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border-radius: 10px;
    padding: 1rem 2rem 1rem 1rem;
    font-size: 1.6rem;
    white-space: nowrap;

    &:hover{
      background-color: #932828;
      color: white;

      & img{
        filter: invert(1)
      }
    }

    & img {
      width: 2rem;
      height: 2rem;
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 700px){
      .dts-link{
        white-space: pre-wrap;
      }

}

</style>

<script setup lang="ts">

    import { useGetAllOMProjects } from '@/composables/omPlatformFunctions';
    import {useRouter} from 'vue-router';
    const {projects, isLoadingProjects} = useGetAllOMProjects();
    const router = useRouter();

    const navigateToProject = (projectId: string) => {
        router.push(`/om-platform/project?id=${projectId}`)
    }

    const editExistingProject = (projectId: string) => {
        router.push(`/om-platform/project-form?id=${projectId}`)
    }

</script>

<template>
  
    <div class="main-container mt-8 !items-start !content-start ">
      <div class="actions-row flex justify-end col-start-1 col-end-13">
        <button @click="router.push('/om-platform/project-form')" class="ac-add-button">
          <img :src="require('../../assets/icons/add.png')"/>
          Yeni Proje Ekle
        </button>
      </div>
      <table v-if="!isLoadingProjects" class="alt-table mobile-preserved fixed-column-1 border-collapse col-start-1 col-end-13 max-w-full">
        <thead>
          <tr>
            <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left "> Proje Adı </th>
            <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> İşlemler </th>
          </tr>
        </thead>
        <tbody>
            <tr @click="navigateToProject(project.id)" v-for="project in projects" :key="project.id">
                <td class="py-4 px-8 whitespace-nowrap hover:!bg-gray-100  text-left cursor-pointer w-[95%] ">{{ project.name }}</td>
                <td class="py-4 px-8 whitespace-nowrap text-left">
                    <button @click.stop="editExistingProject(project.id)" class="ac-table-button hover:bg-black hover:text-white border p-4">Düzenle</button>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </template>
<script setup lang="ts">

import { ref, onMounted, nextTick } from "vue"

const props = defineProps({
    text: {
        type: String,
        required: true
    },
    editedField: {
        type: String,
        required: true
    }
})

const emits = defineEmits(["completeEdit"])
const editableArea = ref<HTMLElement | null>(null)
const isEditing = ref<boolean>(false)


const startEdit = async () => {
    isEditing.value = true
    await nextTick();  // Wait for the next DOM update
    if(editableArea.value){
        editableArea.value.focus()
    }
}

// We need a helper function here. If the user presses enter, the editable area loses focus.
// That means if we assign the same function to the blur event and the keyup.enter, it will be called twice.
// This helper function will be called by the keyup.enter event and it will blur the field.

const blurField = () => {
    editableArea.value?.blur()
}

const completeEdit = () => {
    isEditing.value = false

    let newString = editableArea.value?.innerText.trim() || "";
    console.log(newString)

    if(newString === props.text){
        console.log("No change")
        return
    } else if (newString === "") {
        newString = props.text
        emits("completeEdit", newString)
    } else {
        newString = editableArea.value?.innerText.trim()
        emits("completeEdit", newString)
    }

}

const cancelEdit = () => {
    isEditing.value = false
}

onMounted(() => {

})

</script>

<template>
    <div class="text-toggler-container" @dblclick="startEdit">
        <p class="text-toggler-text"  v-if="!isEditing">
        {{ props.text }}
    </p>
        <div 
            v-else
            ref="editableArea"
            contenteditable="true"
            tabIndex="0"
            @blur.once="completeEdit"
            @keyup.enter.prevent="blurField" 
            @keydown.esc.prevent="cancelEdit" 
            class="edit-area"
        >
        {{ props.text }}
        </div>
            
    </div>

</template>

<style>

.text-toggler-container{
    cursor: pointer;
}

.text-toggler-text{
    min-width: 25%;
    display: inline-block;
}

.edit-area{
    cursor: text;
    padding: .4rem .3rem;
}

.edit-area:focus{
    outline: 1px solid black;
}

</style>
<template>
    <div class="grid grid-flow-row gap-10" 
            :class="{'grid-cols-3': coverType === 'horizontal',
                'grid-cols-4': coverType === 'vertical' }" >
        <div v-for="doc in documentData" :key="doc.id" @click="copyURL(doc.url)" class="flex-col w-full mb-5 cursor-pointer transition duration-200 transform hover:scale-105">
            
            <img oading="lazy" 
                :class="{'acca-h-cover-image': coverType === 'horizontal',
                          'acca-v-cover-image': coverType === 'vertical' }" 
                          
            class="mb-5" :src="doc.image" width="400" alt="document-cover">

            <div class="flex items-center justify-start">
                <img class="mr-5" width="30" :src="require(`../../assets/decors/flag-${doc.lang}.png`)" alt="flag">
                <h5 class="text-2xl text-black">{{doc.title}} ({{doc.fileType}})</h5>
            </div>
        </div>
    </div>
</template>

<script>

import removeBackground from "../../mixins/removeBackground"
import resetBackground from "../../mixins/resetBackground"

export default {
    
    name: 'DocumentGridView',

    props:{
        documentData: Array,
        coverType: String
    },

    methods: {
        copyURL : function(link) {
            console.log("Running copy link")
            navigator.clipboard.writeText(link)
            this.$emit("codeCopied")
        }
    },

    mixins: [removeBackground, resetBackground],

    created(){
        this.removeBackground()
    },

    beforeDestroy(){
        this.resetBackground()
    }
}

</script>

<style scoped>

img.acca-h-cover-image {
    width: 100%;
    height: 23rem;
}

img.acca-v-cover-image {
    height: calc(37rem * 1.3142);
    width: 37rem;
}

</style>
<template>
  <a :href="link" :is-drive="isDrive ? '' : null" :is-any-desk="isAnyDesk ? '' : null " target="_blank" class="external-navigation-container">
    <img @click.prevent="infoClicked" width="25" v-if="hasInfo" :src="require(`../assets/icons/info-icon.png`)" alt="" class="external-navigation-container__info-image">
    <img :src="require(`../assets/images/${imgURL}`)" alt="" class="external-navigation-container__image">
    <h3 class="external-navigation-container__title"> {{title}} </h3>
  </a>
</template>

<script>
export default {
  name: 'ExternalNavigation',
  props: {
    title: String,
    imgURL: String,
    link: String,
    isDrive: Boolean,
    hasInfo: Boolean,
    isAnyDesk: Boolean,
  },

  methods: {
    infoClicked(){
      this.$emit('infoClicked')
    }
  }
}
</script>

<style lang="scss">
  @import "../styles/core/variables.scss";
  @import "../styles/global/components" 

  
</style>

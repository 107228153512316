<template>
        <div class="main-container p-relative z-0">

            <div v-if="isRejectModalOpen"

                class="absolute left-1/4 top-20 z-10 w-1/2 py-10 px-10 border-gray-400 border-2 bg-white rounded-3xl">

                <h3 class="text-4xl font-bold mb-5">
                    İzin Talebini Reddet
                </h3>

                <p class="text-2xl mb-5">
                    Oluşturulan izin talebini reddediyorsunuz. Reddetme işlemini tamamlamak için aşağıdaki 'Reddet' butonunu tıklayın. Açıklama eklemek isterseniz, aşağıdaki bölümden kullanıcıya gösterilecek bir mesaj yazabilirsiniz.
                </p>

                <textarea class="border-gray-400 border w-full mb-5 px-4 py-4 text-2xl focus:outline-black" name="rejectMessage" v-model="rejectMessage" cols="30" rows="10"></textarea>

                <div class="flex flex-start w-1/2 m-auto">
                    <button class="bg-red-400 rounded-l text-white px-10 mr-10 py-4 hover:bg-red-500 text-2xl" @click="rejectRequest(rejectionId)">İzin Talebini Reddet</button>
                    <button class="border border-gray-600 rounded-l px-10 py-4 text-2xl" @click="isRejectModalOpen = false">İptal</button>
                </div>
            </div>


            <table class="table-auto border-collapse w-full col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Talep Eden </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Oluşturulma Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> İzin Başlangıç Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> İşe Dönüş Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Açıklama</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Onay</th>
                    </tr>
                </thead>

                <tr v-for="request in requestData" :key="request.id">
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{request.user}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.created}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.startDate}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.endDate}}</td>
                        <td class="message-content__data py-4 px-8 text-left text-2xl">{{request.body}}</td>
                        <td v-if="request.status === undefined || request.status === '-'" class="py-4 px-8 text-left text-2xl">
                            <div class="flex">
                                <button @click="approveRequest(request.id)" class="bg-green-400 text-white px-10 py-3 rounded-2xl hover:bg-green-500 mr-5">Onayla</button>
                                <button @click="openRejectModal(request.id)" class="bg-red-400 text-white px-10 py-3 rounded-2xl hover:bg-red-500">Reddet</button>
                            </div>
                        </td>
                        <td v-else-if="request.status === 'Approved'" class="py-4 px-8 text-center bg-green-200 text-2xl text-green-500 w">
                            Onaylandı.
                        </td>
                        <td v-else-if="request.status === 'Rejected'" class="py-4 px-8 relative flex bg-red-200 text-center justify-center h-full text-2xl text-red-500">
                            Reddedildi
                            <div @mouseenter="displayRejectionMessage(request.id)" @mouseleave="isDisplayingRejectMessage = false" class="cursor-pointer text-white bg-red-500 w-8 h-8 rounded-full ml-5">?</div>
                            <div class="text-2xl px-6 py-6 text-gray-600 border border-black absolute -top-20 -left-20 bg-gray-100" v-if="isDisplayingRejectMessage  && displayedRejectionMessageId === request.id">{{request.responseMessage}}</div>
                        </td>
                </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'


export default {
    
    name: 'LeaveRequestsAdmin',
    data() {
        return {
            requestData: [],
            isRejectModalOpen: false,
            rejectionId: "",
            rejectMessage: "",
            isDisplayingRejectMessage: false,
            displayedRejectionMessageId: "",
        }
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("requests").doc("leave").collection("leaveRequests").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.requestData.push(
                {
                id: doc.id,
                body: doc.data().body,
                startDate: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().startDate)),
                endDate: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().endDate)),
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                status: doc.data().status,
                responseMessage: doc.data().responseMessage
                    })
                })
            });
        },

        approveRequest(id){
            let db = firebase.firestore().collection("requests").doc("leave").collection("leaveRequests")
                db.doc(id).update({
                    status: "Approved"
                })
                .then(() => {
                    let approvedRequest = this.requestData.find(request => request.id === id)
                    approvedRequest.status = "Approved"
                })
                .catch((error) => {
                    console.error("Error writingocument: ", error);
                });
        },

        openRejectModal(id){
            console.log(id)
            this.isRejectModalOpen = true
            this.rejectionId = id
        },

        displayRejectionMessage(id){
            this.isDisplayingRejectMessage = true
            this.displayedRejectionMessageId = id
        },

        rejectRequest(id){
            let db = firebase.firestore().collection("requests").doc("leave").collection("leaveRequests")
                db.doc(id).update({
                    status: "Rejected",
                    responseMessage: this.rejectMessage
                })
                .then(() => {
                    console.log("Document successfully written!");
                    let approvedRequest = this.requestData.find(request => request.id === id)
                    approvedRequest.status = "Rejected"
                    approvedRequest.responseMessage = this.rejectMessage
                    this.isRejectModalOpen = false
                    this.rejectionId = ""
                })
                .catch((error) => {
                    console.error("Error writingocument: ", error);
                });
        },

      },

      mounted(){
          this.getData()
      }
  }

</script>

<template>
        <div class="main-container">
            <table class="table-auto border-collapse col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Gönderen </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Talep Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Proje </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Müşteri </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Notlar </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Dosyalar </th>
                    </tr>
                </thead>

                <tr v-for="req in qrRequests" :key="req.id">
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{req.submittedBy}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{req.created}}</td>
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{req.project}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">{{req.client}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{req.notes}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">
                            <a class="text-2xl text-blue-600 list-item list-none" v-for="link in req.files" :key="link.src" :href="link.src">İndir</a>
                        </td>
                </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'


export default {
    
    name: 'QRRequestAdmin',
    data() {
        return {
            qrRequests: [],
        }
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("digital").doc("dss").collection("qrRequests").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.qrRequests.push(
                {
                id: doc.id,
                files: doc.data().files,
                project: doc.data().project,
                client: doc.data().client,
                notes: doc.data().notes,
                created: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().created.toDate())),
                submittedBy: doc.data().user
                    })

                    this.qrRequests.reverse()
                })
            });
        },

        logData(){
            console.log(this.qrRequests[0].image.src)
        }
      },

      mounted(){
          this.getData()
      }
  }


</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


</style>

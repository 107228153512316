<template>
  <router-link :to="link" class="inner-navigation-container">
    <img v-if="remoteImage" :src="remoteImage" alt="" class="inner-navigation-container__remote-img" :exact="isExact" :class="[navStyle == 2 ? 'inner-navigation-container__icon-large' : 'inner-navigation-container__icon']">
    <img v-else :src="require(`../assets/icons/${imgURL}`)" alt="" :exact="isExact" :class="[navStyle == 2 ? 'inner-navigation-container__icon-large' : 'inner-navigation-container__icon']">
    <h3 class="inner-navigation-container__title"> {{title}} </h3>
  </router-link>
</template>

<script>
export default {
  name: 'InnerNavigation',
  props: {
    title: String,
    imgURL: String,
    link: String,
    navStyle: String,
    isExact: Boolean,
    remoteImage: String
  },
}
</script>

<style lang="scss">
  @import "../styles/core/variables.scss";
  @import "../styles/global/components" 
</style>

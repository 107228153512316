<template>
  <div class="main-container">
        <transition 
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeInDown animate__fast"
            leave-active-class="animate__animated animate__fadeOutUp animate__fast"
            class="notice"
        >
            <acca-vue-notification message="Link kopyalandı!" v-if="isCodeCopied" >     
            </acca-vue-notification>
        </transition>

      <inner-navigation class="grid-order-1" link="/surveys/outbound/responses" title="Anket Sonuçlarını Görüntüle" imgURL="responses.png" navStyle="1"></inner-navigation>
      <external-navigation class="grid-order-2 grayscale" link="https://docs.google.com/forms/d/1e3fUHQ2NT2IzWgqiyEKd3Bo30XUqjdcqTUfqMe8sD-4/edit?usp=sharing" title="Google Forms - Müşteri Memnuniyeti Anket Sonuçları" imgURL="google-forms.png" navStyle="1"></external-navigation>
      <module-container @codeCopied="showCopyNotification" class="grid-order-1" title="Genel Müşteri Anketi (Google Forms)" url="https://docs.google.com/forms/d/e/1FAIpQLSfx-5qqJ3Tp1xtlGcoqzYytCY_FvB71PGbXmaNzdOkRzc9mvg/viewform?usp=sf_link" imgURL="customer-satisfaction.png"></module-container>
      <module-container @codeCopied="showCopyNotification" class="grid-order-2" title="Genel Müşteri Anketi" url="https://tr.surveymonkey.com/r/6K8WKQN" imgURL="customer-satisfaction.png"></module-container>
      <module-container @codeCopied="showCopyNotification" class="grid-order-3" title="Genel Müşteri Anketi - EN" url="https://www.surveymonkey.com/r/FQDGMSR" imgURL="customer-satisfaction.png"></module-container>
      <module-container @codeCopied="showCopyNotification" class="grid-order-4" title="Devreye Alım Anketi" url="https://www.surveymonkey.com/r/MG8X5JR" imgURL="customer-satisfaction.png"></module-container>
      <module-container @codeCopied="showCopyNotification" class="grid-order-1" title="Business Istanbul Anketi" url="https://tr.surveymonkey.com/r/JDRY62N" imgURL="building.png"></module-container>
      <module-container @codeCopied="showCopyNotification" class="grid-order-2" title="Seba Office Boulevard Anketi" url="https://tr.surveymonkey.com/r/PZCHDJM" imgURL="building.png"></module-container>
  </div>
</template>

<script>

import ModuleContainer from "../ModuleContainer.vue"
import InnerNavigation from "../InnerNavigation.vue"
import ExternalNavigation from "../ExternalNavigation.vue"
import accaVueNotification from "../reusable/accaVueNotification.vue"

export default {
    
  name: 'outboundSurveys',


  data(){
      return {
          isHovered: false,
          isCodeCopied: false,
      }
  },
  components: {
      ModuleContainer: ModuleContainer,
      accaVueNotification: accaVueNotification,
      InnerNavigation: InnerNavigation,
      ExternalNavigation,
  },

  methods: {
      showCopyNotification: function() {
        this.isCodeCopied = true
        setTimeout(() => { 
            this.isCodeCopied = false
       }, 2000);
      }
  }

}
</script>

<style scoped lang="scss">

</style>

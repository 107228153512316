<template>
  <div class="main-container">
      <inner-navigation class="grid-order-1" link="/training/dansuk/trello" title="Trello Eğitimleri" imgURL="trello-logo.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-2" link="/training/dansuk/google-drive" title="Google Drive Eğitimleri" imgURL="googledrive-logo.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-3" link="/training/dansuk/dts" title="DTS Eğitimleri" imgURL="dansuk-logo-en.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-4" link="/training/dansuk/dash" title="Dashboard Eğitimleri" imgURL="dansuk-logo-en.png" navStyle="2"></inner-navigation>
   
  </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"


export default {
  name: 'DansukTraining',
  components: {
      InnerNavigation: InnerNavigation,
  }
}
</script>

<style scoped lang="scss">

</style>

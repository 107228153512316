<template>
        <div class="main-container">
            <h2 class="form-title">Yeni Doküman Ekle</h2>
            <form class="dashboard-form">
                <label for="company" class="single-line-label">Şirket</label>
                <select v-model="company" name="company" id="company">
                    <option value="dansuk">Dansuk</option>
                    <option value="arcelik">Arçelik</option>
                    <option value="lg">LG</option>
                    <option value="otherCompanies">Diğer</option>
                </select>
                <label v-if="company == 'dansuk'" for="docType" class="single-line-label">Doküman Türü</label>
                <select v-if="company == 'dansuk'" v-model="document.docType" name="docType" id="docType">
                    <option value="catalog">Katalog</option>
                    <option value="presentation">Sunum</option>
                    <option value="annualReport">Faaliyet Raporu</option>
                    <option value="general">Diğer</option>
                </select>

                <label for="lang" class="single-line-label">Dil</label>
                    <select v-model="document.lang" name="lang" id="lang">
                        <option value="tr">Türkçe</option>
                        <option value="en">English</option>
                        <option value="de">Deutsch</option>
                    </select>

                <label class="single-line-label" for="title">Doküman Adı</label>
                    <input
                        type="text"
                        id="title"
                        class="dashboard-form__input"
                        v-model="document.title"
                    >
                <label class="single-line-label" for="title">Doküman Yılı</label>
                    <input
                        type="number"
                        id="year"
                        class="dashboard-form__input"
                        v-model="document.year"
                    >
                <label class="single-line-label" for="image">Kapak Görseli</label>
                    <input
                        type="text"
                        id="image"
                        class="dashboard-form__input"
                        v-model="document.image"
                    >
                <label class="single-line-label" for="url">İndirme / Paylaşma Linki</label>
                    <input
                        type="text"
                        id="url"
                        class="dashboard-form__input"
                        v-model="document.url"
                    >
                <label class="single-line-label" for="fileType">Dosya Türü</label>
                    <input
                        type="text"
                        id="fileType"
                        class="dashboard-form__input has-margin-bottom"
                        v-model="document.fileType"
                    >
                <div class="button-row">
                    <button type="button" @click="postData(document, 'docs', company, document.docType)" class="dashboard-form__submit">Yayımla</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>
            </div>
</template>

<script>
import firebase from "../../firebase"
import postData from "../../mixins/firebaseOperations"

export default {
  name: 'addDocument',
  data() {
      return {
          company: "",
          document: {
              title: "",
              docType: "catalog",
              image: "",
              url: "",
              lang: "",
              fileType: "",
              year: Number,
          }
      }
  },

  methods: {
      sendDocument(document) {
          const db = firebase.firestore().collection("docs")
          let dbLocation = db.doc(this.company).collection(this.document.docType)
          dbLocation
          .add(document)
          .then(() => {
              this.$router.push("/documents/add")
          })
          .catch((error) => {
              console.error("Error writing document: ", error)
          })
      },

      goBack(){
          this.$router.go(-1)
      }
  },

  mixins: [postData]
} 

</script>

<style scoped lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


</style>

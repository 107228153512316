<template>
    <div class="main-container">
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-1" link="stats/yearly" title="Yıllara Göre Veriler" imgURL="finance-stats.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-1" link="stats/finance" title="Finans" imgURL="finance-stats.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-2" link="stats/finance-2023" title="2023 Finans Verileri" imgURL="finance-stats.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-3" link="stats/finance-2022" title="2022 Finans Verileri" imgURL="finance-stats.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-4" link="stats/projects" title="Proje Verileri" imgURL="project-stats.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'StatsHome',
    computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },
  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

<template>
    <div class="main-container content-center">
        <acca-vue-notification v-if="isGeneralSuggestionSent" message="Öneriniz bize ulaştı, teşekkür ederiz!"></acca-vue-notification>
        <h2 class="form-title">Genel - Öneri Gönder</h2>

        <form class="dashboard-form" @submit.prevent="sendgeneralSuggestion()" >

            <label class="single-line-label" for="user">Oluşturan</label>
            <input required type="text" readonly id="user" class="dashboard-form__input bg-gray-200" v-model="generalSuggestion.user">

            <label class="single-line-label" for="title">Öneri Başlığı</label>
            <input required type="text" id="title" class="dashboard-form__input" v-model="generalSuggestion.title">

            <label class="single-line-label" for="body">Öneriniz</label>
            <textarea required id="body" rows="5" class="medium-message mb-10" v-model="generalSuggestion.body"> </textarea>

            <div class="button-row">
                <button type="submit" @click="postData(generalSuggestion, 'suggestions', 'general', 'generalSuggestions')" class="dashboard-form__submit">Oluştur</button>
                <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
            </div>

        </form>

        <p class="col-start-3 col-end-11 mb-5 text-3xl text-gray-100 leading-snug">Bu bölümden, genel olarak Dansuk Mühendislik ile ilgili aklınıza gelen her türlü öneriyi iletebilirsiniz. Spesifik olarak bu panel ile ilgili önerilerinizi, 
            <router-link class="text-blue-400 hover:text-blue-500" to="panel">Panel Hakkında Öneriler</router-link> sayfasından iletmenizi rica ederiz.
        </p>

    </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"

export default {
  name: 'GeneralSuggestions',
  data() {
      return {
          isGeneralSuggestionSent: false,
          generalSuggestion: {
              user: "",
              title: "",
              body: "",
              created: new Date()
          }
      }
  },

  methods: {
      goBack(){
          this.$router.push("/suggestions")
      },

      sendgeneralSuggestion() {
          this.isGeneralSuggestionSent = true
          setTimeout(() => {
              this.isGeneralSuggestionSent = false
              this.goBack()
          }, 3000)
      }
  },

   computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },

    components: {
      accaVueNotification: accaVueNotification,
  },

  mixins: [postData],

    created(){
      this.generalSuggestion.user = this.userProfile.name
  },

    beforeUpdate(){
        this.generalSuggestion.user = this.userProfile.name
    }
} 

</script>

<style lang="scss">

</style>

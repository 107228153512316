<template>
  <div class="main-container">
      <external-navigation class="grid-order-1" link="https://www.udemy.com/course/liderlik-online-egitim/" title="Liderlik Becerileri ile Sürdürülebilir Yüksek Performans" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-2" link="https://www.udemy.com/course/liderlik-ve-ceo-egitimi/" title="Kuşaklar Arası Liderlik ve CEO'luk Eğitimi" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-3" link="https://www.udemy.com/course/exceldashboard/" title="İleri Düzey Excel | Dashboard | Excel İp Uçları" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-4" link="https://www.udemy.com/course/liderlik-online-egitim/" title="İş Yaşamında Proje Yönetimi" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-1" link="https://www.udemy.com/course/zihinlerfora-zihin-haritalari-ile-ogrenmeyi-ogrenelim/" title="Zihin Haritası (Mind Map) ile Öğrenmeyi Öğrenelim" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-2" link="https://www.udemy.com/course/how-to-lead-effective-meetings/" title="Leading Effective Meetings" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-3" link="https://www.udemy.com/course/nlp-teknikleri-ileri-seviye-egitimi/" title="NLP Teknikleri İleri Seviye Eğitimi" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-4" link="https://www.udemy.com/course/yonetici/" title="Yönetici'nin El Kitabı" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-1" link="https://www.udemy.com/course/satis-koclugu/" title="Satış Koçluğu Eğitimi" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-2" link="https://www.udemy.com/course/herkes-icin-agile/" title="Agile (Çevik) 101 - Herkes için Agile!" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-3" link="https://www.udemy.com/course/leadership-leading-not-in-charge/" title="Leadership: Leading When You Are Not In Charge!" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-4" link="https://www.udemy.com/course/satis-eglenceli-bir-istir/" title="Satış Teknikleri ve İkna Eğitimi: Satış Gücünü İkiye Katla" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-1" link="https://www.udemy.com/course/satis-koclugu/" title="Satış Koçluğu Eğitimi" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-2" link="https://www.udemy.com/course/ppcelegitimi/" title="Potansiyeli Performansa Çeviren Ekip Lideri" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-3" link="https://www.udemy.com/course/stresle-basetme-teknikleri/" title="Stresle Baş Etme Teknikleri Kursu" imgURL="udemy.png"></external-navigation>
      <external-navigation class="grid-order-4" link="https://www.iienstitu.com/online-egitim/konsantrasyon-egitimi" title="Konsantrasyon (Odaklanma) Eğitimi" imgURL="iienst-logo.png"></external-navigation>
  </div>
</template>

<script>

import ExternalNavigation from "../ExternalNavigation.vue"


export default {
  name: 'StandardTrainings',
  components: {
      ExternalNavigation: ExternalNavigation

  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

<template>
    <div class="main-container">
        <dss-table v-if="largeScreen" class="col-start-1 col-end-13" :tableData="dssData" filterActive="true"></dss-table>
        <dss-mobile v-else :tableData="dssData" filterActive="true"></dss-mobile>
    </div>
</template>

<script>

import axios from 'axios';
import DSSTable from "./DSSTable.vue"
import DSSMobile from "./DSSMobile.vue"

export default {
  name: 'DSSAllRecords',
  data(){
    return {
        searchTerm: "",
        dssData: [],
        largeScreen: ""
    }
  },
  components: {
    "dss-table": DSSTable,
    "dss-mobile": DSSMobile
  },
  methods: {
    getScreenSize(){
    console.log("get screen size ran")
    window.innerWidth > 800 ? this.largeScreen = true : this.largeScreen = false
  },
  

    fetchData(){
        console.log("Running fetch data.")
        const url = "https://dss.dansuk.com.tr/api/v1/all"

        axios.get(url).then((response) => {
            console.log(response.data)
            this.dssData = response.data
        }).catch((error) => {
          console.log(error.message)
          console.log(error)
        })
    }
  },
  created(){
  this.fetchData()
  this.getScreenSize()
  window.addEventListener("resize", this.getScreenSize)
},

beforeDestroy(){
  window.removeEventListener("resize", this.getScreenSize)
}
}
</script>


<template>
    <div class="main-container">
      <h2 class="col-start-1 col-end-9 text-4xl font-semibold text-white">{{projectTitle}}</h2>
        <div @click="bmsNavigate('edit-project')" class="module-container inner-navigation-container grid-order-1" link="completion-rates">
          <img :src="require(`../../../assets/icons/action-icons/edit.png`)">
          <h3 class="text-2xl">Proje Bilgileri</h3>
        </div>
        <div @click="bmsNavigate('completion')" class="module-container inner-navigation-container grid-order-2" link="completion-rates">
          <img class="filter backdrop-opacity-75" :src="require(`../../../assets/icons/check.png`)">
          <h3 class="text-2xl">Proje Tamamlanma Durumu</h3>
        </div>
        <div @click="bmsNavigate('updates')" class="module-container inner-navigation-container grid-order-3" link="project-updates" title="Proje Güncellemeleri">
            <img :src="require(`../../../assets/icons/add.png`)">
            <h3 class="text-2xl">Proje Güncellemeleri</h3>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import initializeBMS from "../../../mixins/initializeOtherFirebase"


export default {
  name: 'BMSProjectOverview',
  data(){
    return {
      projectTitle: "",
      projectId: "",
      project: {}
    }
  },
  mixins: [initializeBMS],

  methods: {

    bmsNavigate(destination){
      const link = `project/${destination}`


      this.$router.push({
        path: link,
        query: {
          id: this.getProjectID
        }
      })

    },
    getBMSProjects(){
        const projectReference = this.initializeBMS().firestore().collection("projects").doc(this.getProjectID)
        projectReference.get().then((doc) => {
          if(doc.exists){
            this.projectTitle = doc.data().title
          }
        })
    }
  },

    computed: {
      getProjectID(){
        const params = new URLSearchParams(window.location.search)
        const projectID = params.get("id")
        return projectID
    },
      ...mapGetters('user', {
          userProfile: 'userProfile',
      })
  },

  created(){
    this.getBMSProjects()
  }
}
</script>

<style scoped lang="scss">
  .module-container img{
    max-width: 5rem;
    filter: grayscale(1)
  }
</style>

<template>
    <div :style="cssVars" class="ac-icon-container">
        <input @input="inputtedSearchTerm" v-model="searchTerm" class="acca-search text-2xl my-5 w-full" type="text">
        <div class="icons-container">
            <span @click="selectIcon(icon)" class="material-icons icons-container__icon" :class="{'bg-gray-200': icon === selectedIcon}" v-for="icon in icons" :key="icon">
                {{icon}}
            </span>
        </div>
    </div>
</template>

<script>
import material_icons from "./collections/material-icons-list"


export default {
    name: "acIconPicker",
    data(){
        return{
            selectedIcon: null,
            searchTerm: "",
        }
    },

    props: {
        height: {
            type: String,
            required: true,
        },
    },

    computed: {
        icons(){
            if(this.searchTerm != ""){
                return material_icons.filter( (icon) => icon.indexOf(this.searchTerm) > -1)
            }

            return material_icons
        },

        cssVars(){
            return {
                "--icon-container-height": this.height
            }
        }
    },

    methods: {

        inputtedSearchTerm(){
            this.searchTerm = this.searchTerm.toLowerCase()

        },

        selectIcon(icon){
            if(icon === this.selectedIcon){
                this.selectedIcon = null
            } else{
                this.selectedIcon = icon;
                this.$emit("iconSelected", this.selectedIcon)
            }
        }
    }
}
</script>

<style scoped lang="scss">

@import "../../styles/vendors/material-icons.css";


.ac-icon-container{
    height: var(--icon-container-height);
    overflow-y: hidden;
}

.icons-container{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    border: 1px solid #e3e3e3;
    padding: 6px;
    overflow-y: scroll;
    height: var(--icon-container-height);

    &__icon{
        font-size: 5rem;
        padding: .3rem;
        font-weight: 300;
        cursor: pointer;
        margin: 0 auto;
        color: rgba(0, 0, 0, .54);
        user-select: none;

    }
}

</style>
<template>
    <div class="flex justify-evenly bg-white border-2 py-5 rounded-3xl">
        <div @click="setSelectedView('DocumentTableView')" class="flex border-r-2 cursor-pointer border-gray-200 pr-5 items-center justify-center">
            <img :class="{'is-selected': selectedView === 'DocumentTableView'}" width="18" src="../../assets/icons/list-view.png" alt="list">
        </div>
        <div  @click="setSelectedView('DocumentGridView')"  class="flex items-center cursor-pointer justify-center">
            <img :class="{'is-selected': selectedView === 'DocumentGridView'}"  width="18" src="../../assets/icons/grid-view.png" alt="grid">
        </div>
    </div>
</template>

<script>
  export default{
      name: 'DocumentViewSwitcher',
      props:{
          selectedView: String
      },
      methods:{
          setSelectedView: function(view) {
              this.$emit('viewSet', view)
          }
      }
  }


</script>

<style scoped>

.is-selected{
    filter:brightness(0)
}

</style>

<script setup lang="ts">
import firebase from "../../firebase"
import { computed, ref, onBeforeMount, onBeforeUnmount } from "vue";
import { modalClosed } from "../../mixins/visualFormatters";
import { useMultiSelectEMails,  exportEmailsToCSV, exportEmailsToExcel, addTagToMultipleEMails, resetSelectedEMails, massImportEmails, useGetAllEmails } from "../../composables/crmBasedFunctions"
import { useGetAllMetas, addOrUpdateMeta } from "../../mixins/metaManagement";
import { PotentialEMail, EMailTag } from "@/types/crm";
import { useLocalPagination,  } from "../../mixins/visualFormatters";
import { updateSingleField, addSingleArrayItem, removeSingleArrayItem } from "../../mixins/modularFirebaseOperations";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import resetBackground from "@/mixins/resetBackground";
import removeBackground from "@/mixins/removeBackground";
import mailImporter from "../crm-components/mailImporter.vue"
import EMailRow from "../crm-components/EMailRow.vue"
import addOrUpdateEmail from "../crm-components/addOrUpdateEmail.vue";
import listSearch from "../reusable/listSearch.vue";
import acPagination from "../reusable/acPagination.vue";
import filterPopup from "../reusable/filterPopup.vue";
import tagViewer from "../reusable/tagViewer.vue";
import acContextMenu from "../reusable/acContextMenu.vue";
import acMediumModal from "../reusable/teleports/acMediumModal.vue";
import statusChanger from "../reusable/statusChanger.vue";

const { emailList, isLoadingEmails } = useGetAllEmails()

/* eslint-disable no-unused-vars */
const { metaList, isLoadingMetas } = useGetAllMetas("crm/EMailTags")

const isAddingEmail = ref<boolean>(false);
const isDisplayingMenu = ref<boolean>(false);
const editedEmail = ref<PotentialEMail | undefined>(undefined);
const currentTagToggledEmail = ref<string | undefined>(undefined)

const isImporting = ref<boolean>(false);
const isMassAddingTags = ref<boolean>(false);
const router = useRouter()
const store = useStore()
const userName = computed(() => store.getters["user/userProfile"].name)
// Searching and filtering:

const searchTerm = ref<string>("")
const isFilteringByIndustry = ref<boolean>(false)
const isFilteringByTag = ref(false)
const industriesFilter = ref<string[]>([])
const tagsFilter = ref<EMailTag[]>([])
const isTagFilterReversed = ref(false)

const industryList = computed(() => {
  const industries = emailList.value.map((email: PotentialEMail) => email.industry)
  return [...new Set(industries)]
})

const { selectedEMails } = useMultiSelectEMails()

const isMassSelected = ref(false)

const handleMassSelect = () => {
        if(isMassSelected.value){
            selectedEMails.value = emailList.value.map(mail => mail.id)
        }
        else{
            selectedEMails.value = []
        }
    }

const filteredEMails = computed(() => {

    let filteredResult = [...emailList.value]
    // Filter by the search term according to the name, industry, description, firstName, lastName

    if (searchTerm.value) {
        filteredResult = filteredResult.filter((EMail: PotentialEMail) => {
            return (
                EMail.email?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                EMail.industry?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                EMail.company?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                EMail.desc?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                EMail.firstName?.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                EMail.lastName?.toLowerCase().includes(searchTerm.value.toLowerCase())
            );
        });
    }

    if(industriesFilter.value.length > 0){
        filteredResult = filteredResult.filter(filterByIndustry)
    }

    if(tagsFilter.value.length > 0){
        filteredResult = filteredResult.filter(filterByTags)
    }

  // If userFiltered.value is false or undefined, return the original array
    return filteredResult;
});

// Pagination:

const { currentPage, totalPages, paginatedItems, setPage, nextPage, previousPage } = useLocalPagination(filteredEMails, 30)


function filterByIndustry(EMail: PotentialEMail){
    if(industriesFilter.value){
        return industriesFilter.value.includes(EMail.industry)
    }
}

function filterByTags(EMail: PotentialEMail) {
    if (!tagsFilter.value || tagsFilter.value.length === 0) {
        return true;
    }

    else if (isTagFilterReversed.value) {
        return !tagsFilter.value.some((tag: EMailTag) => {
            return EMail.tags?.some((EMailTag: EMailTag) => EMailTag.id === tag.id) ?? false;
        });
    }

    return tagsFilter.value.some((tag: EMailTag) => {
        return EMail.tags?.some((EMailTag: EMailTag) => EMailTag.id === tag.id) ?? false;
    });
}

const cancelSubmission = () => {
  isAddingEmail.value = false;
  editedEmail.value = undefined;
  modalClosed()
};

const handleSubmission = async (emailInformation: PotentialEMail) => {
  try {
    if (editedEmail.value) {
      await addOrUpdateMeta("crm/sales/EMailList", emailInformation, editedEmail.value.id)
      const index = emailList.value.findIndex((email: PotentialEMail) => email.id === editedEmail.value?.id)
      emailList.value[index] = {
        ...emailInformation,
        id: editedEmail.value.id
      }
    } else {
      // Check if the email already exists in the database
      const query = firebase.firestore().collection("crm/sales/EMailList").where("email", "==", emailInformation.email)
      const queryResult = query.get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          throw new Error("Bu e-posta adresi zaten kayıtlı.");
        }
      })

      const newEmailId = await addOrUpdateMeta("crm/sales/EMailList", emailInformation)
        emailList.value.unshift({
          ...emailInformation,
          id: newEmailId
        })
    }
  } catch (error) {
    alert(`E-Mail bilgileri kaydedilirken bir hata oluştu: ${error}`)
  }
  isAddingEmail.value = false;
  editedEmail.value = undefined;
  modalClosed()

}

// Handle the update of a single field on the table

const handleSingleFieldUpdate = async (event: {field: string, value: string}, id: string) => {
  const field = event["field"],
  newValue = event["value"]
  try {
    await updateSingleField(`crm/sales/EMailList/${id}`, field, newValue)
    const index = emailList.value.findIndex((email: PotentialEMail) => email.id === id)
    emailList.value[index][field] = newValue
  } catch (error) {
    alert(`E-Mail bilgileri kaydedilirken bir hata oluştu: ${error}`) 
  } 

}

// Add tags from the email directly on the table

const handleAddTag = async (tag: EMailTag, emailId: string) => {
  try {
    await addSingleArrayItem(`crm/sales/EMailList/${emailId}`, "tags", tag);
    const index = emailList.value.findIndex((email: PotentialEMail) => email.id === emailId);
    
    if (index === -1) {
      throw new Error("Edited email not found in the list.");
    }

    emailList.value[index].tags?.push(tag);
  } catch (error) {
    alert(`E-Mail bilgileri kaydedilirken bir hata oluştu: ${error.message || error}`); 
  }
}

// Remove tags from the email directly on the table

const handleDeleteTagFromEmail = async (tag: EMailTag, emailId: string) => {
  try {
    await removeSingleArrayItem(`crm/sales/EMailList/${emailId}`, "tags", tag);
    const index = emailList.value.findIndex((email: PotentialEMail) => email.id === emailId);
    
    if (index === -1) {
      throw new Error("Edited email not found in the list.");
    }

    const tagIndex = emailList.value[index]?.tags?.findIndex((localTag: EMailTag) => localTag.id === tag.id);
    
    if (tagIndex === -1) {
      throw new Error("Tag not found in the email.");
    }

    emailList.value[index].tags?.splice(tagIndex, 1);
  } catch (error) {
    alert(`E-Mail bilgileri kaydedilirken bir hata oluştu: ${error.message || error}`); 
  }
}
// Filter by industry and tags

const addToFilterArray = (targetArray, item: string) => {
    if(targetArray.includes(item)){
        targetArray.splice(targetArray.indexOf(item), 1)
    } else {
        targetArray.push(item)
    }
    isFilteringByIndustry.value = false
    isFilteringByTag.value = false
}

// Handle mass import of emails

const handleImport = async (fileAndTags) => {
  const file = fileAndTags.file
  const tags = fileAndTags.tags
  await massImportEmails(file, tags, emailList.value, userName.value)

  // Without this timeout, the Firebase operation fails. We need to check this for larger data.

  setTimeout(() => {
    isImporting.value = false
    alert("İçe aktarım başarıyla tamamlandı.")
    window.location.reload()
  }, 1000)
}

// Handle mass adding of tags

const handleMassAddTag = async (tag: EMailTag) => {
  await addTagToMultipleEMails(tag, emailList.value); 
  resetSelectedEMails()
}

// Select the emails that are currently added to the selection by their ids.

const getSelectedEmails = () => {
  return emailList.value.filter((email: PotentialEMail) => selectedEMails.value.includes(email.id))
}

// Create the context menu for importing and exporting

const emailsContextMenu = computed(() => {
  const staticItems = [
    {name: "Etiketleri Düzenle", action: () => {
      router.push("/crm/email-tags")
    }},
    {name: "İçe Aktarım Excel'ini İndir", action: () => {window.open("https://docs.google.com/spreadsheets/d/1Oyj5fbXSGXbfsjKoMUYiWgsOuZdA-zBL/edit?usp=sharing&ouid=103776557649670381330&rtpof=true&sd=true", "_blank")}},
    {name: "İçe Aktar", action: () => {isImporting.value = true}},
    ]

    const massExportItems = [
    {name: "CSV Olarak Dışa Aktar", action: () => exportEmailsToCSV(filteredEMails.value)},
    {name: "Excel Olarak Dışa Aktar", action: () => {exportEmailsToExcel(filteredEMails.value)}},
    ]

    const selectedExportItems = [
    {name: "Seçimi CSV Olarak Dışa Aktar", action: () => exportEmailsToCSV(getSelectedEmails())},
    {name: "Seçimi Excel Olarak Dışa Aktar", action: () => {exportEmailsToExcel(getSelectedEmails())}},
    ]

    if(selectedEMails.value.length > 0){
        return [...staticItems, ...selectedExportItems]
    } else {
        return [...staticItems, ...massExportItems]
    }
  
})

onBeforeMount(() => {
  removeBackground.methods.removeBackground()

})

onBeforeUnmount(() => {
  resetBackground.methods.resetBackground()
})

</script>
<template>
  
  <div class="main-container mt-8 !items-start !content-start ">
    <div class="actions-row flex items-center justify-between col-start-1 col-end-13">
      <div class="flex items-center gap-4">
        <list-search @search-term-changed="searchTerm = $event" />
        <div class="relative hidden md:block">
                <div @click.stop="isFilteringByIndustry = true" class="ac-add-button">
                    <img :class="{'filter-white': isFilteringByIndustry}" :src="require('../../assets/icons/filter.png')" alt="Filtrele">
                    <span>Sektöre Göre Filtrele</span>
                </div>
                <filter-popup 
                    @filterClosed="isFilteringByIndustry = false"
                    @filter-reset="industriesFilter = []"
                    @item-selected="addToFilterArray(industriesFilter, $event)" 
                    :select-multiple="true"
                    :selected-items="industriesFilter"
                    class="absolute w-full" 
                    v-if="isFilteringByIndustry" 
                    :itemList="industryList" 
                    displayed-attribute="name" />
            </div>
        <div class="relative hidden md:block">
                <div @click.stop="isFilteringByTag = true" class="ac-add-button">
                    <img :class="{'filter-white': isFilteringByTag}" :src="require('../../assets/icons/filter.png')" alt="Filtrele">
                    <span>Etikete Göre Filtrele</span>
                </div>
                <status-changer 
                  v-if="isFilteringByTag"
                  :status-list="metaList" 
                  :displays-triangle="true"
                  @updated="addToFilterArray(tagsFilter, $event)" 
                  @cancelled="isFilteringByTag = false"
                  title="Etikete Göre Filtrele" 
                  class="status-changer absolute w-[145%] top[95%] left-[0] !z-50"  />
            </div>
            <div v-if="selectedEMails.length > 0" class="relative hidden md:block">
                <div @click.stop="isMassAddingTags = true" class="ac-add-button">
                    <img :class="{'filter-white': isMassAddingTags}" :src="require('../../assets/icons/filter.png')" alt="Filtrele">
                    <span>Toplu Etiket Ekle</span>
                </div>
                <status-changer 
                  v-if="isMassAddingTags"
                  :status-list="metaList" 
                  :displays-triangle="true"

                  @updated="handleMassAddTag($event)" 
                  @cancelled="isMassAddingTags = false"
                  title="Toplu Etiket Ekle" 
                  class="status-changer absolute !w-[20vw] top-[85%] left-[-25%] !z-50"  />
            </div>
      </div>
      <div class="flex items-center gap-4">
        <button class="bg-white whitespace-nowrap border px-4 py-3 rounded-xl text-2xl flex items-center"
        @click="editedEmail = undefined; isAddingEmail = true">
        <img class="mr-4" width="20" :src="require('../../assets/icons/add.png')" alt="">
        Yeni Ekle
      </button>
      <div @click="isDisplayingMenu = !isDisplayingMenu" class="bg-white whitespace-nowrap border px-4 py-3 rounded-xl text-2xl flex items-center relative">İşlemler</div>
      <transition enter-active-class="animate__animated animate__fadeIn animate__fast"
          leave-active-class="animate__animated animate__fadeOut animate__fast">
          <ac-context-menu class="absolute bg-white !top-8 !outline-none !right-0 mt-6" v-if="isDisplayingMenu" :items="emailsContextMenu" @menu-done="isDisplayingMenu = false"/>
      </transition>
      </div>
   
    </div>
    <div class="flex col-start-1 col-end-13 w-full text-2xl text-gray-500">
      Toplam {{ filteredEMails.length }} adet kontakt bulundu.
    </div>
    <div v-if="industriesFilter.length > 0" class="filtered-industry-row mt-4 items-center flex flex-wrap gap-1 w-full col-start-1 col-end-13 gap-4">
        <h4 class="font-bold text-2xl">Filtrelenen Sektörler: </h4>
        <p class="text-2xl" v-for="industry in industriesFilter" :key="industry">{{ industry }}</p>
    </div>
    <div v-if="tagsFilter.length > 0" class="filtered-tags-row mt-4 flex items-center flex-wrap gap-1 w-full col-start-1 col-end-13 gap-4">
        <h4 class="font-bold text-2xl">Filtrelenen Etiketler </h4>
        <tag-viewer :removable="true" @delete-tag="addToFilterArray(tagsFilter, $event)" v-for="tag in tagsFilter" :key="tag.id" :tag="tag" />
        <button @click="isTagFilterReversed = !isTagFilterReversed" class="ac-secondary-button text-xl ml-auto">
          <span v-if="isTagFilterReversed">Seçili Etiketleri Kullan</span>
          <span v-else>Seçili Etiketleri Hariç Tut</span>
        </button>
    </div>
    <table v-if="!isLoadingEmails" class="alt-table mobile-preserved fixed-column-1 border-collapse col-start-1 col-end-13 max-w-full">
      <thead>
        <tr>
          <th class="w-[1rem] ">
              <input v-model="isMassSelected" @change="handleMassSelect" type="checkbox" class="table-checkbox  !w-4" />
          </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> E-Mail Adresi </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Adı</th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Soyadı</th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Sektör </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left scrolling-table-field"> Etiketler </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Şirket </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Telefon </th>
          <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <e-mail-row 
          v-for="email in paginatedItems" 
          :key="email.id" 
          :email="email"
          :current-toggled-e-mail="currentTagToggledEmail"
          :tagList="metaList" 
          @tag-added="handleAddTag($event, email.id)"
          @tag-deleted="handleDeleteTagFromEmail($event, email.id)"
          @edit="editedEmail = $event"
          @single-field-update="handleSingleFieldUpdate($event, email.id)"/>
      </tbody>
      

    </table>

    <ac-pagination class="w-full mx-auto col-start-1 col-end-13 flex flex-col items-center mb-8" :current-page="currentPage" :total-pages="totalPages" @navigate-to-page="setPage" />

    <teleport to="#app">
      <transition appear type="animation" enter-active-class="animate__animated animate__fadeIn animate__fast"
        leave-active-class="animate__animated animate__fadeOut animate__faster">
        <ac-medium-modal class="lg:!top-[4%]" @modal-closed="isAddingEmail = false; editedEmail = undefined" v-if="isAddingEmail || editedEmail"
          height="auto" width="50vw" title="E-Posta Düzenle">
          <add-or-update-email @cancel="cancelSubmission" @submitted="handleSubmission($event)" :tags="metaList"
            :existingEMail="editedEmail" @form-submitted="handleSubmission" />
        </ac-medium-modal>
      </transition>
    </teleport>

    <teleport to="#app">
      <transition appear type="animation" enter-active-class="animate__animated animate__fadeIn animate__fast"
        leave-active-class="animate__animated animate__fadeOut animate__faster">
        <ac-medium-modal class="lg:!top-44" @modal-closed="isImporting = false" v-if="isImporting"
          height="auto" width="50vw" title="İçe Aktar">
         <mail-importer @initiate-import="handleImport($event)" @cancelled="isImporting = false; modalClosed()"/>
        </ac-medium-modal>
      </transition>
    </teleport>

  </div>
</template>
  
<style lang="scss">

@import "../../styles/global/helpers.scss";
@import "../../styles/global/buttons.scss";
@import "../../styles/global/tables.scss";


</style>
<template>
    <div @click="componentClicked" id="copperGraph" class="module-container px-0 grid-cols-6 grid w-full content-center flex-row">

          <apexchart
            class="col-span-full"
            ref="copperPriceChart"
            type="area"
            :options="chartOptions"
            :series="series"
            height="110%"
        >  
          </apexchart>

    </div> 
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue3-apexcharts'


export default {
  name: 'MetalGraph',
  data(){ 
      return {
      copperPrices: [],
      dates: [],
      prices: [],
      chartOptions: {
          chart: {
              id: 'copper-chart',
              toolbar: {
                show: false
              }
          },

          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },

          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              formatter: function(value){
                return '$' + value
              }
            }
          },
          xaxis: {
              categories: this.dates,
              labels: {
                style:{
                  fontSize: "11px",
                },
                offsetX: -2,
              },
          },
      },
        series: [{
              name: 'Bakır Fiyatı',
              data: []
          }]
      }
      },

  components:{
      'apexchart': VueApexCharts
  },


  methods: {
    calculateReverse(value){
      return (1 / value).toFixed(2)
    },
    getCopperPrices(){
      const baseURL = "https://dansuk-dashboard-api.herokuapp.com/metals?"
      const limit = "5"
      const finalURL = `${baseURL}limit=${limit}`
      axios.get(finalURL)
        .then((response) => {
            const data = response.data
            const values = []
            for (let value of data){
              const price = value
              values.push(price)
            }
            this.copperPrices = values
            this.populateDates()
            this.populatePrices()
            this.$refs.copperPriceChart.updateSeries([{
              data: this.prices.reverse()
            }])
            this.$refs.copperPriceChart.updateOptions({
              xaxis: {
                categories: this.dates.reverse()
              }
            })
        })    
        .catch((error) => {
          console.error("Something went wrong with the copper price request", error)}
        )
    },


    componentClicked(){
      this.$emit("componentClicked")
      },

      populateDates(){

        for (const price of this.copperPrices){
          let date = price.date
          let dateSliced = date.slice(5, 10)
          let formattedDate = dateSliced.split("-").reverse()
          this.dates.push(formattedDate.join("."))
        }
      },

      populatePrices(){
        for (const price of this.copperPrices){
          let daysRate = price.price
          this.prices.push(daysRate)
        }
      },



    
  },

  created(){
    this.getCopperPrices()

  }
}

</script>

<style lang="scss" scoped>
h5{
    font-family: 'Encode Sans SC';
}

.currency-value {
      font-family: 'Encode Sans SC';
}
</style>


<template>
    <div class="main-container">
        <acca-vue-notification v-if="isMessageSent" message="Mesajınız başarıyla oluşturuldu."></acca-vue-notification>

                <h2 class="form-title">İK Duyurusu Yayımla</h2>
                <form class="dashboard-form" @submit.prevent="sendMessage" >

                <label class="single-line-label" for="title">Konu</label>
                <input
                    required
                    type="text"
                    id="title"
                    class="dashboard-form__input"
                    v-model="message.title"
                >

        <label class="single-line-label" for="body">Mesaj</label>

        <textarea
            required
            id="message"
            rows="5"
            class="medium-message mb-10"
            v-model="message.body">
        </textarea>

        <label class="single-line-label" for="link">Link

            <vue-tooltip explanation="Duyurunuz ile ilgili paylaşmak istediğiniz bir bağlantı (web sitesi, belge, vb.) varsa buradan paylaşabilirsiniz.">?</vue-tooltip>    


        </label>


            <input
                type="url"
                id="link"
                class="hr-message__link has-small-margin-bottom"
                v-model="message.link"
            >



            <div class="button-row">
                <button type="submit" class="dashboard-form__submit">Gönder</button>
                <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
            </div>
                </form>
    </div>
</template>

<script>

import accaVueNotification from "../reusable/accaVueNotification.vue"
import postData from "../../mixins/firebaseOperations"
import { mapGetters } from "vuex"
import VueTooltip from '../reusable/VueTooltip.vue'



export default {
  name: 'HRAddDocument',
  data() {
      return {
          isMessageSent: false,
          message: {
              title: "",
              user: "",
              link: "",
              body: "",
              created: new Date(),
          },
          currentHrDocs: [],
          options: {
            multi: false,
            btnClass: 'multi-select__button'
        },

      }
  },

  methods:{

    goBack(){
          this.$router.go(-1)
      },

      sendMessage() {
          this.postData(this.message, 'messages', 'main', 'hr')
          this.isMessageSent = true
          setTimeout(() => {
              this.isMessageSent = false
              this.goBack()
          }, 2000)
      }
  },

    computed: {
    ...mapGetters("user", {
        userProfile: "userProfile",
    })
},

  components: {
       accaVueNotification: accaVueNotification,      
       VueTooltip: VueTooltip,

  },

  mixins: [postData],

  created(){
      this.message.user = this.userProfile.name
  }
}
</script>

<style scoped lang="scss">
  @import "../../styles/global/forms.scss";
  @import "../../styles/core/variables.scss";

    .hr-message__link{

    margin-bottom: 1.3rem;
    font-size: 1.6rem;
    padding: 1rem;
    border: 1px solid gray;

    &:focus {
        outline: 2px solid black;
        border: none;
        }
    }


</style>

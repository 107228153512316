<script setup>

import axios from "axios"
import { onBeforeMount, onBeforeUnmount, watchEffect } from "vue";
import { ref, computed } from "vue";
import { toggleScrollOnModalOpen, forceRemoveModalStyles } from "../../mixins/visualFormatters";

import linkedInCalendar from "./components/linkedInCalendar.vue"
import linkedInGroupedPosts from "./components/linkedInGroupedPosts.vue"
import linkedInClassic from "./components/linkedInClassic.vue"
import linkedInSinglePostViever from "./components/linkedInSinglePostViever.vue";
import acLoader from "../reusable/acLoader.vue"
import firebase from "../../firebase";
import reusableViewSwitcher from "../reusable/reusableViewSwitcher.vue";
import resetBackground from "../../mixins/resetBackground";
import removeBackground from "../..//mixins/removeBackground";

const selectedView = ref("standard")
const linkedInPostCards = ref([])
const isLoading = ref(false)
const displayedPost = ref(undefined)
const postMetas = ref([])
const isDisplayingEmpty = ref(false)

const combinedPosts = computed(() => {
    const mergedMap = new Map();

    // Helper function to merge two objects
    const mergeObjects = (array1, array2) => {
        return { ...array1, ...array2 };
    };

    // Process each array
    [linkedInPostCards.value, postMetas.value].forEach(array => {
        array.forEach(item => {
            if (mergedMap.has(item.id)) {
                mergedMap.set(item.id, mergeObjects(mergedMap.get(item.id), item));
            } else {
                mergedMap.set(item.id, item);
            }
        });
    });

    // Convert the Map values back to an array
    return Array.from(mergedMap.values());
})

// Get the actual LinkedIn Posts for the dashboard

const fetchDataFromTrello = async () => {
    try {
        const baseUrl = "https://dansuk-dashboard-api.herokuapp.com/trello-posts"
        const response = await axios.get(baseUrl)
        linkedInPostCards.value = response.data
    } catch (error) {
        console.log(error)
    }
}

// Get additional metas saved on the app database for the posts

const fetchLinkedInPostMetas = async () => {
    const postMetasRef = firebase.firestore().collection("digital/linkedin/linkedInPosts")
    try {
        await postMetasRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                postMetas.value.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
        })
    }
    catch (error) {
        console.log(error)
    }
}

const itemsByMonth = computed(() => {
    const grouped = {};
    combinedPosts.value.forEach(item => {
        // Check if item.due is not null
        if (item.due) {
            const month = item.due.slice(0, 7); // "2024-02"
            if (!grouped[month]) {
                grouped[month] = [];
            }
            grouped[month].push(item);
        }
    });
    return grouped;
});

watchEffect(() => {
    if (displayedPost.value) {
        toggleScrollOnModalOpen(displayedPost.value);
    } else {
        toggleScrollOnModalOpen(displayedPost.value);
    }
});



const syncPosts = async () => {
    isLoading.value = true
    await axios.get("https://dansuk-dashboard-api.herokuapp.com/hard-sync-trello-posts")
    isLoading.value = false
}


const possibleViews = [
    {
        name: "standard",
        displayName: "Standart",
        icon: "grid.png"
    },
    {
        name: "calendar",
        displayName: "Takvim",
        icon: "calendar.png"
    },
    {
        name: "classic",
        displayName: "Klasik",
        icon: "list.png"
    }
]

const changeView = (view) => {
    selectedView.value = view.name
}

onBeforeMount(async () => {
    removeBackground.methods.removeBackground()
    isLoading.value = true
    await fetchDataFromTrello()
    await fetchLinkedInPostMetas()
    isLoading.value = false
})

const initialView = possibleViews[0]

onBeforeUnmount(() => {
    resetBackground.methods.resetBackground()
    forceRemoveModalStyles();
})

</script>

<template>
    <div class="main-container pt-4 relative">
        <div class="md:flex items-center justify-between col-start-1 col-end-13 w-full">
            <h1 class="form-title whitespace-nowrap">LinkedIn Paylaşımları</h1>
            <div class="actions-container flex items-stretch mt-5">
                <div class="actions-row no-scrollbars flex items-stretch "> 
                <button @click="isDisplayingEmpty = !isDisplayingEmpty" class="bg-white min-w-[28rem] md:min-w-[unset]  border flex items-center gap-4 border-gray-200 py-2 px-4 text-2xl rounded-2xl cursor-pointer mr-4 hover:bg-gray-100" type="button">
                    <img width="18px" class="opacity-80" :src="require(`../../assets/icons/empty-2.png`)"/>
                    <span class="!whitespace-nowrap" v-if="isDisplayingEmpty">Hazırlanmamış Paylaşımları Gizle</span>
                    <span class="!whitespace-nowrap" v-else>Hazırlanmamış Paylaşımları Göster</span>
                </button>
                <button @click="syncPosts" class="bg-white border min-w-[14rem] md:min-w-[unset] flex items-center gap-4 border-gray-200 py-2 px-4 text-2xl rounded-2xl cursor-pointer mr-4 hover:gray-100 hover:bg-gray-100" type="button">
                    <img width="18px" class="opacity-80" :src="require(`../../assets/icons/sync.png`)"/>
                    <span class="!whitespace-nowrap">Senkronize Et</span>
                </button> 
            </div>
            <reusable-view-switcher class="min-w-[10rem] md:min-w-[unset] !z-99 ml-2"  @view-changed="changeView($event)" :initial-view="initialView" :possible-views="possibleViews"/>    
            </div>

        </div>
        <div v-if="isLoading" class="col-start-1 col-end-13 w-full self-stretch min-h-[90vh] relative bg-white opacity-60">
            <ac-loader class="absolute top-[30%] left-24 opacity-100" />
        </div>
        <linked-in-calendar v-else-if="!isLoading && selectedView === 'calendar'"
            @post-displayed="displayedPost = $event"
            :posts="itemsByMonth"></linked-in-calendar>
        <linked-in-grouped-posts class="col-start-1 col-end-13 w-full" v-else-if="!isLoading && selectedView === 'standard'"
            @post-displayed="displayedPost = $event"
            :display-empty="isDisplayingEmpty"
            :posts="itemsByMonth"></linked-in-grouped-posts>
        <linked-in-classic class="col-start-1 col-end-13 w-full" v-else-if="!isLoading && selectedView === 'classic'"
            @post-displayed="displayedPost = $event"
            :display-empty="isDisplayingEmpty"
            :posts="combinedPosts"></linked-in-classic>
        <teleport to="#app">
            <div v-if="displayedPost"
                class="single-post-container md:bg-[rgba(0,0,0,.3)] w-[102%] h-[85vh] lg:h-[100vh] 2xl:h-[95vh] absolute top-[15%] md:top-0 left-[-2%] shadow-xl">
                <linked-in-single-post-viever class="lg:top-[5%] md:left-[15%] h-full overflow-y-scroll md:w-5/6 absolute" :post="displayedPost"
                    @post-closed="displayedPost = undefined"></linked-in-single-post-viever>
            </div>
        </teleport>

    </div>
</template>

<style lang="scss">@import "../../styles/global/helpers.scss";

@media screen and (max-width: 750px){
    .actions-row{
        overflow-x: scroll;
    }
}

</style>
<template>
    <div class="main-container">
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-1" link="suggestions/admin/general" title="Genel Önerileri Görüntüle" imgURL="general-suggestions-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-2" link="suggestions/admin/panel" title="Panel Önerilerini Görüntüle" imgURL="dashboard-suggestions-admin.png" navStyle=1></inner-navigation>
        <inner-navigation v-if='userProfile.allowsEdit' class="grid-order-3" link="suggestions/admin/bugs" title="Hata Bildirimlerini Görüntüle" imgURL="bug-reports-admin.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-1" link="suggestions/general" title="Genel Öneriler" imgURL="general-suggestions.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="suggestions/panel" title="Panel Hakkında Öneriler" imgURL="panel-suggestions.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-3" link="suggestions/bug-report" title="Hata Bildir" imgURL="bug-report.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-4" link="suggestions/planned-changes" title="Planlanan Değişiklikler" imgURL="planned-changes-and-updates.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'SuggestionsHome',
    computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },
  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

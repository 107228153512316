<script setup lang="ts">
import acMediumModal from "../reusable/teleports/acMediumModal.vue"
import addMeta from "../reusable/teleports/addMeta"
import { ref, onBeforeMount } from "vue"
import firebaseOperations from "../../mixins/firebaseOperations"
import { modalClosed } from "../../mixins/visualFormatters"
import { updateMeta, getMetas, deleteMeta } from "../../mixins/modularFirebaseOperations"
import { useRouter } from "vue-router"
import type { EventCategory } from "@/types/events"

const router = useRouter()
const addedMeta = ref<string>("")
const existingMeta = ref<EventCategory | undefined>(undefined)

const eventCats = ref<EventCategory[]>([])

const isLoadingEventCats = ref(false)

const handleAddedData = function(meta) {
    firebaseOperations.methods.postData(meta, "/metas/calendar/eventCats")
    eventCats.value.push(meta)
    addedMeta.value = ""
    modalClosed()
}

const goBackToCalendar = function(){
    router.push({path: "/calendar"})
}

const handleUpdatedData =  async function(newdata){
    await updateMeta("metas/calendar/eventCats", existingMeta.value.id, newdata)

    // find the updated meta in the array and update it
    const index = eventCats.value.findIndex((meta) => meta.id === existingMeta.value.id)
    eventCats.value[index].name = newdata.name
    eventCats.value[index].color = newdata.color
    existingMeta.value = undefined
    addedMeta.value = ""
    modalClosed()

}

onBeforeMount(() => {
    getMetas("metas/calendar/eventCats", eventCats.value, isLoadingEventCats)
})


</script>

<template>
    <div class="main-container content-start items-start mt-8">
        <div class="flex col-start-1 col-end-13 justify-between !items-center w-full">
            <h2 class="ac-subtitle !text-3xl my-0">Etkinlik Kategorileri</h2>
            <div class="action-row col-start-1 col-end-13 flex items-center justify-end">
                <button type="button" @click="goBackToCalendar" class="ac-add-button mr-4">
                    <img class="mr-4" width="20" src="../../assets/icons/calendar.png" alt="">
                    Takvime Dön
                </button>        
                <button type="button" @click="addedMeta = 'eventCats'" class="ac-add-button">
                    <img class="mr-4" width="20" src="../../assets/icons/add.png" alt="">
                    Yeni Etkinlik Kategorisi Ekle
                </button>        
            </div>
        </div>
        <table class="alt-table col-start-1 col-end-13 w-full mt-5">
            <thead>
                <tr>
                    <th>Başlık</th>
                    <th>İşlemler</th>
                </tr>
            </thead>
            <tr class="hover:bg-gray-100 relative cursor-pointer" v-for="cat in eventCats" :key="cat.id">
                <td  class="text-overflow w-24"> 
                    <div class="w-6 h-6 rounded-full" :style="{backgroundColor: cat.color}"></div>
                </td>
                <td  class="text-overflow"> {{ cat.name }}</td>
                <td class="flex">
                    <button type="button" class="table-button"  @click="deleteMeta('metas/calendar/eventCats', cat.id, eventCats)">
                        Sil
                    </button>
                    <button type="button" class="table-button"  @click="addedMeta='eventCats'; existingMeta=cat">
                        Düzenle
                    </button>
                </td>
            </tr>
        </table>

    <teleport to="#app">
        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeIn animate__fast"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
            <ac-medium-modal class="lg:!top-44 overflow-y-scroll" @modal-closed="addedMeta = ''" v-if="addedMeta" height="85vh" width="70vw" title="Yeni Ekle">
                <add-meta @add-new-meta="handleAddedData($event)" @updated-meta="handleUpdatedData($event)" :existing-meta="existingMeta" :meta="addedMeta" />
            </ac-medium-modal>
       </transition>
  </teleport>
        </div>



</template>
<template>
      <transition 
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeInDown animate__fast"
            leave-active-class="animate__animated animate__fadeOutUp animate__fast"
            class="notice"
        >
            <div :height="height" :width="width" class="ac-modal">

                <h3 class="text-3xl font-bold mb-5">Bilgiler</h3>
               <slot></slot>
                
                <button class="ac-modal__button mt-5 m-auto" @click="modalClosed"> Kapat </button>

            </div>


        </transition>
</template>

<script>
  export default{
      name: 'accaModal',
      props: {
          height: Number,
          width: Number
      },

      methods: {
          modalClosed() {
              this.$emit('modalClosed')
          }
      }
  }
</script>

<style lang="scss" scoped>
    .ac-modal{

        display: block;

        max-height: 90vh;
        overflow-y: scroll;

        border: 1px solid rgb(70, 70, 70);
        border-radius: 2px;
        font-size: 1.6rem;
        padding: 2rem 2rem;
        width: 100%;

        position: absolute;
        top: 40%;
        left: 20%;
        background-color: white;
        z-index: 100;
    }

    .ac-modal__button{
        background-color: rgb(90, 90, 90);
        color: white;
        font-size: 106x;
        border: 1px solid rgb(90, 90, 90);
        padding: 10px 10px;
        display: block;
        cursor: pointer;
        transition: all 2s;

        &:hover{
            background-color: black;;
        }
    }

    @media screen and (max-width: 700px){
        .ac-modal{
            max-width:85%;
            align-self: center;
        }
}

</style>
<template>
    <div class="main-container content-center">
    <acca-vue-notification v-if="isDSSProjectSent" message="Proje başarıyla oluşturuldu"></acca-vue-notification>
        <h2 class="form-title">DSS - Aktif Proje Ekle</h2>
        <form class="dashboard-form" @submit.prevent="sendDSSProject()" >

            <label class="single-line-label" for="title">Müşteri Adı
                <vue-tooltip explanation="Proje hangi müşteriye ait?">?</vue-tooltip>
            </label>
                <input
                    required
                    type="text"
                    id="title"
                    class="dashboard-form__input"
                    v-model="dssProject.client"
                >

            <label class="single-line-label" for="title">Proje Adı
                <vue-tooltip explanation="Eğer bu DSS projesi, Business Istanbul veya Seba Office Boulevard gibi birden fazla müşteri içeren bir projede yer alıyorsa, buradan belirtebilirsiniz. Aksi takdirde tekrar Müşteri Adını girebilirsiniz.">?</vue-tooltip>
            </label>
                <input
                    required
                    type="text"
                    id="title"
                    class="dashboard-form__input"
                    v-model="dssProject.project"
                >

            <label class="single-line-label" for="deadline">DSS Eklenme Tarihi
            </label>

            <div class="w-full flex space-between">
                
                    <input
                        type="date"
                        id="deadline"
                        class="dashboard-form__input w-full"
                        v-model="dssProject.startDate"
                    >
            </div>

            <label class="single-line-label" for="status">DSS Durumu</label>
            <select
                required
                id="status"
                class="dashboard-form__input"
                v-model="dssProject.status">
                <option v-for="status in statuses" :key="status" :value="status">
                {{ status }}
                </option>
            </select>

            <div class="button-row">
                <button type="submit" @click="postData(dssProject, 'digital', 'dss', 'projects')" class="dashboard-form__submit">Oluştur</button>
                <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
            </div>
        </form>
        </div>
</template>

<script>
import postData from "../../../mixins/firebaseOperations"
import VueTooltip from '../../reusable/VueTooltip.vue'
import accaVueNotification from "../../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"




export default {
data() {
  return {
      isDSSProjectSent: false,
      statuses: [
        "Kodlar Oluşturuldu",
        "Etiketler Yapıştırıldı",
        "Devreye Alındı",
        "Aktif",
        "İptal"
      ],
      dssProject: {
          client: "",
          project: "",
          status: "Kodlar Oluşturuldu",
          startDate: "",
          createdBy: "",
          lastUpdateDate: "",
          lastUpdatedBy: "",
      },
}},

methods: {
  goBack(){
      this.$router.go(-1)
  },



  sendDSSProject() {
      this.isDSSProjectSent = true
      setTimeout(() => {
          this.isDSSProjectSent = false
          this.goBack()
      }, 1000)
  }
},
computed: {
    ...mapGetters("user", {
        userProfile: "userProfile",
    })
},

components: {
  VueTooltip: VueTooltip,
  accaVueNotification: accaVueNotification,
},



mixins: [postData],

mounted(){
    this.dssProject.createdBy = this.userProfile.name
    this.dssProject.createdBy = this.userProfile.name
}   

} 

</script>

<style lang="scss">
@import "../../../styles/core/variables.scss";
@import "../../../styles/global/forms.scss";


input[type=checkbox] {
transform: scale(1.5);
margin-right: .5rem;
margin-left: .5rem;
}


</style>

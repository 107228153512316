<template>
  <div class="main-container">
      <inner-navigation v-if="userProfile.allowsEdit" class="grid-order-1" link="/documents/add" title="Doküman Ekle" imgURL="add.png" navStyle="1"></inner-navigation>
      <inner-navigation class="grid-order-1" link="/documents/technical" title="Teknik Belgeler" imgURL="technical-documents.png" navStyle="1"></inner-navigation>
      <inner-navigation class="grid-order-2" link="/documents/corporate" title="Kurumsal Kimlik" imgURL="branding.png" navStyle="1"></inner-navigation>
      <inner-navigation class="grid-order-4 grayscale" link="/documents/other-companies" title="Diğer" imgURL="acme-logo.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-1" link="/documents/dansuk" title="Dansuk Mühendislik" imgURL="dansuk-logo-en.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-2" link="/documents/arcelik" title="Arçelik" imgURL="arcelik-logo.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-3" link="/documents/lg" title="LG" imgURL="lg-logo.png" navStyle="2"></inner-navigation>
      <inner-navigation class="grid-order-4" link="/documents/hitachi" title="Hitachi" imgURL="hitachi-logo.png" navStyle="2"></inner-navigation>
  </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'


export default {

  name: 'DocumentsHome',
  computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
      })
  },

  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

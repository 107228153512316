<template>
  <div class="training-container">
    <div class="go-back-container">
          <router-link class="text-2xl" to="/training">&#8592; Tüm Eğitimler </router-link>
      </div>
      <iframe id="video" :src="selectedVideo" class="w-full h-full md:w-3/5 lg:w-4/5" frameborder="0"></iframe>
      <aside class="training-list-container w-full lg:w-1/5 bg-white h-full border-t-2 lg:overflow-y-scroll">
        <div class="flex w-full justify-around items-center">
            <h3 class="font-sans text-3xl py-5 px-1 cursor-pointer font-semibold border-b-2 border-black">
                Google Drive Eğitimleri
            </h3>
        </div>
        <div class="px-3 flex flex-col mt-8 space-y-5 lg:overflow-y-scroll">
            <div class="cursor-pointer" v-for="training, index in googleDriveTrainings" :key="training.id" @click="changeSRC(index)">
                <h4 class="font-semibold mb-23 text-3xl whitespace-nowrap">
                    {{training.title}}
                </h4>
                <p class="text-2xl py-4 text-gray-500 border-b-2">
                    {{training.desc}}
                </p>
            </div>
        </div>
      </aside>
  </div>
</template>

<script>



export default {
  name: 'GoogleDriveTraining',
  data(){
      return {
          selectedVideo: 0,
          googleDriveTrainings: [
            {id: 1, 
            title: "Google Drive Temeller",
            desc: "Google Drive nasıl kullanılır? Klasörler ve dosyalar nasıl yaratılır, nasıl düzenlenir? Dosyalar ekip içinde ve ekip dışında nasıl paylaşılır? Google Drive içindeki temel kullanıcı rolleri nelerdir?",
            videoURL: "https://www.youtube.com/embed/3K0cO4ZFbvE"},
            {id: 2, 
            title: "Google Drive - Dosya Bulma 1",
            desc: "Google Drive'da bir dosya paylaştığınızda, paylaşılan kişilerin bu dosyaları rahatça bulmasını nasıl sağlayabilirsiniz? Yüklediğiniz dosyaların karışık klasörlerde kaybolmamasını ve gözden kaçmamasını nasıl sağlayabilirsiniz?",
            videoURL: "https://www.youtube.com/embed/1wbzPH3XMAY"},
            {id: 3, 
            title: "Google Drive - Dosya Bulma 2",
            desc: "Google Drive'a yüklendiğini bildiğiniz, ancak klasörler içinde bulmakta zorlandığınız bir dosya olduğunda, son yüklenen dosyaları nasıl kontrol edebilirsiniz?",
            videoURL: "https://www.youtube.com/embed/y54geDOPcRU"},
          ]
      }
  },
          
  methods: {
      changeSRC: function(index){
          this.selectedVideo = this.googleDriveTrainings[index].videoURL
      }
  },
  mounted() {
    this.selectedVideo = this.googleDriveTrainings[0].videoURL
  }
}
</script>

<style scoped lang="scss">
  @import "../../styles/modules/training.scss";

</style>

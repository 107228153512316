<template>
    <div class="chart-container bg-white">

        <!-- Don't forget to add a year selector here.-->

        <ac-loader :loaderHeight="700" class="col-start-1 col-end-5 bg-white w-full ac-loader" v-if="isLoadingData"></ac-loader>

        <main v-else>
            <h2 class="text-center text-3xl mt-4 mb-2 mr-auto ml-auto font-bold">Çek - Senet DBS - Kredi Geri Ödeme Verileri</h2>
            <div id="chartPrefs" class="flex mt-10 ml-10 text-2xl">
            <div class="border p-3 year-selection">
                <label for="yearSelection">Yıl:</label>
                <select v-model="selectedYear" class="outline-0" name="yearSelection" @change="populateData()" id="year">
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                </select>
            </div>
            <div class="border p-3 type-selection">
                <label for="typeSelection">Grafik Türü:</label>
                <select v-model="selectedChartType" class="outline-0" name="typeSelection" @input="changeChartType(selectedChartType)" id="type">
                    <option value="bar">Sütun</option>
                    <option value="line">Çizgi</option>
                </select>
            </div>
            <div class="border p-3 type-selection">
                <label for="dataSelection">Veri Tipi:</label>
                <select v-model="selectedChartContent" class="outline-0" name="dataSelection" id="data" @change="changeChartColors()">
                    <option value="total">Toplam</option>
                    <option value="detailed">Detaylı</option>
                </select>
            </div>
        </div>
        <apexchart
            ref="csdbsChart"
            :type="selectedChartType"
            :options="chartOptions"
            :series="activeSeries"
            height="700"
        >
        </apexchart>
        </main>
     
    </div>
</template>


<script>
import googleSheetOperations from "../../../mixins/googleSheetOperations";
import VueApexCharts from 'vue3-apexcharts'
import acLoader from "../../reusable/acLoader.vue"
// import axios from 'axios'

export default {

    
  name: 'CSDBSStats',
  data(){
    return {
        selectedChartContent: "total",
        selectedChartType: "bar",
        selectedYear: "2024",
        isLoadingData: false,
        chartOptions: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            responsive: [{
                breakpoint: 650,
                options: {
                    yaxis: {
                        show:false,
                    }
                }
            }],

         yaxis: {            
            labels: {
            formatter: (val) => val.toLocaleString() + " ₺",
            },
          },
        colors: ["#40CC86", "#CC4040"],
          xaxis: {
              categories: ["Ocak","Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
              labels: {
                style:{
                  fontSize: "14px",
                },
                offsetX: -2,
              },
          },
          plotOptions: {
            bar: {
            dataLabels: {
                position: "top",
                orientation: "vertical"
            }
            }
          },
          dataLabels: {
            formatter: function(val){
                return val.toLocaleString() + " ₺"
            },

            enabled: true,
            offsetY: 10,
            style: {
                fontSize: "14px",
                colors: ["#838383"]
            }
          },

      },

      series: [
        {
            name: "Gelir (USD + Çek Toplam)",
            data: []
        },
        {
            name: "Gider (DBS + Kredi Geri Ödeme)",
            data: []
        },
      ],

       rawSeries: [
        {
            name: "TRY Çek Toplamı",
            data: []
        },
        {
            name: "USD Çek Toplamı",
            data: []
        },
        {
            name: "DBS Toplamı",
            data: []
        },
        {
            name: "Kredi Geri Ödeme Toplamı",
            data: []
        },
     ]
    }
  },

  methods: {

    changeChartColors(){
        if(this.selectedChartContent === "total"){
            this.chartOptions = {
                ...this.chartOptions,
                colors: ["#40CC86", "#CC4040"] 
            }
        } else {
            this.populateData(this.selectedYear)
            this.chartOptions = {
                ...this.chartOptions,
                colors: ["#3bd163", "#357a47", "#d9720b", "#c4130a"]
            }      
        }
    },
    changeChartType(type){
        if(type === 'line'){
            this.chartOptions.dataLabels.enabled = true
        } else {
            this.chartOptions.dataLabels.enabled = false 
        }
    },

    convertCurrency(arr){
        const newArray = arr.map((data) => data * this.usdCurrencyRate)
        return newArray
    },

    combineArrays(arr1, arr2){
        const totalArray = []
        arr1.forEach((item, index) => {
            totalArray.push(parseFloat(item) + parseFloat(arr2[index]))
        })
        return totalArray
    },

    async populateData(){
        let yearDataRange;
        if(this.selectedYear === "2022"){
            yearDataRange = "A4:L7"
        } else if (this.selectedYear === "2023") {
            yearDataRange = "A9:L12"
        } else if (this.selectedYear === "2024") {
            yearDataRange = "A14:L17"
        } else {
            yearDataRange = 2022
        }

        this.isLoadingData =true
        const response = await this.getDataFromGS("1ib97WO0FvT2Lnk2kQo-vmXZXoNa2hgdA0nh1POSiPXc", "Sheet1", yearDataRange)
        console.log(response)
        // Setting raw values: 

        this.rawSeries[0].data = response[0]
        this.rawSeries[1].data = response[1].map((data) => data * this.usdCurrencyRate)
        this.rawSeries[2].data = response[2]
        this.rawSeries[3].data = response[3]

        // Setting Combined Values

        this.series[0].data = this.combineArrays(response[0], this.convertCurrency(response[1]))
        this.series[1].data = this.combineArrays(response[2], response[3])
        this.isLoadingData = false
    }
  },

  props: ["usdCurrencyRate"],

  mixins: [googleSheetOperations],

  computed: {

    activeSeries(){
        if(this.selectedChartContent === "total"){
            return this.series
        } else {
            return this.rawSeries
        }
    },

    totalSeries(){

        const series = 
        [
            {
                    name: "Gelir",
                    data: []
                },
                {
                    name: "Gider",
                    data: []
                },
            ]
        return series
           

    }
  },
   components:{
      'apexchart': VueApexCharts,
      acLoader,
  },

  created(){

    this.populateData(2022)
  }
}

</script>

<style scoped>

    .chart-container{
        width:100%;
    }
    option, select {
        outline: none;
    }

    @media screen and (max-width: 650px){
        #chartPrefs {
            width: 40vw;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
</style>
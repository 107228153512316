const onlineMonitoringKeys = {

    theIstanbul: 
    
        `<strong>AC Manager Admin: </strong> dansuk 
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> dansuk0000
        <br> <strong>Windows ve Program Kullanıcı Adı: </strong> dansuk_user 
        <br> <strong>Şifre: </strong> dansuk2023 `,

    businessIstanbul: 
    
        `<strong>AC Manager Admin: </strong> dansuk
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> dansuk0000
        <br> <strong>Windows ve Program Kullanıcı Adı: </strong> dansuk_user veya dansuk
        <br> <strong>Şifre: </strong> dansuk2023 `,

    antPlato: 
    
        `
        
        <strong>Bağlantı Kurmak İçin </strong> <br><br>

        Bu bağlantıya tıkladıktan sonra, bilgisayarınız AnyDesk programı vasıtasıyla doğrudan bağlantı sağlayacaktır. <br><br>

        Eğer otomatik olarak bağlanmazsanız, AnyDesk üzerinde Enter'a basarak bağlantı sürecini manuel olarak başlatabilirsiniz.<br><br>

        AnyDesk şifre sorduğunda, aşağıdaki şifreyi girin. Her seferinde tekrar şifre sormaması için, şifre alanı altındaki kutucuğu işaretleyebilirsiniz.<br>
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> antplato2017 <br><br>

        Bağlantı sağladığınızda, eğer Windows giriş ekranını görürseniz, aşağıdaki bilgilerle giriş sağlayabilirsiniz.<br>

        <br> <strong>Windows Kullanıcı Adı : </strong> admin
        <br> <strong>Şifre: </strong> admin2023<br><br>

        Eğer AC Manager programını çalıştırdığınızda kullanıcı adı ve şifre bilgisi sorulursa, aşağıdaki bilgileri kullanabilirsiniz.<br><br>

        <strong>AC Manager Admin: </strong> ACP 
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> antplato17 <br><br>

        Ant Plato sistemlerini incelerken, farklı alanlarda yer alan ACP'lere erişim sağlayabilirsiniz. Bunun için, AC Smart Connect programında üst menüden "Favorites"ı seçip, görüntülemek istediğiniz alana tıklayabilirsiniz.<br><br>

        Bu alana ulaşmak için, tekrar şifre girmeniz gerekecektir. Burada açılan ekrana, aşağıdaki bilgileri girebilirsiniz: <br><br>

        <strong>Kullanıcı Adı </strong> system_admin
        <br> <strong>Şifre: </strong> antplato17<br><br>

        `,

    change: "the following",

    sebaOfficeBoulevard: 

        `

        <strong>Bağlantı Kurmak İçin </strong> <br><br>

        Bu bağlantıya tıkladıktan sonra, bilgisayarınız AnyDesk programı vasıtasıyla doğrudan bağlantı sağlayacaktır. <br><br>

        Eğer otomatik olarak bağlanmazsanız, AnyDesk üzerinde Enter'a basarak bağlantı sürecini manuel olarak başlatabilirsiniz.<br><br>

        AnyDesk şifre sorduğunda, aşağıdaki şifreyi girin. Her seferinde tekrar şifre sormaması için, şifre alanı altındaki kutucuğu işaretleyebilirsiniz.<br>
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> seba2023.. <br><br>

        Bağlantı sağladığınızda, eğer Windows giriş ekranını görürseniz, aşağıdaki bilgilerle giriş sağlayabilirsiniz.<br><br>

        <br> <strong>Windows Kullanıcı Adı: </strong> dansuk
        <br> <strong>Şifre: </strong> dansuk0000<br><br>

        Eğer AC Manager programını çalıştırdığınızda kullanıcı adı ve şifre bilgisi sorulursa, aşağıdaki bilgileri kullanabilirsiniz.<br><br>

        <strong>AC Manager Admin: </strong> dansuk
        <br> <strong>Şifre: </strong> dansuk2023<br><br>

        `,


    tff: 
    
        `<strong>AC Manager Admin: </strong> dansuk
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> dansuk0000
        <br> <strong>Windows ve Program Kullanıcı Adı: </strong> dansuk
        <br> <strong>Şifre: </strong> dansuk2023 `,

    vodafoneArena: 
    
        `<strong>AC Manager Admin: </strong> dansuk
        <br> <strong>AnyDesk Bağlantı Şifresi: </strong> dansuk0000
        <br> <strong>Windows ve Program Kullanıcı Adı: </strong> dansuk
        <br> <strong>Şifre: </strong> dansuk2023 `,
  }

  export default onlineMonitoringKeys;
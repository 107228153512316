<template>
  <form id="step1" key="step1" class="dashboard-form">

    <div class="flex items-end mb-5">
      <div class="flex flex-col w-3/4">
        <label  for="name" class="single-line-label">Hedef Başlığı</label>
        <input
          style="margin-bottom: 0"
          type="text"
          id="name"
          class="dashboard-form__input"
          v-model="goalStep1.name"
        />
      </div>

      <div @click="goalStep1.isPrimary = !goalStep1.isPrimary" class="w-1/4 py-5 ml-10 text-2xl flex justify-center border border-black cursor-pointer"
      :class="{ 'text-white': goalStep1.isPrimary, 'bg-red-400': goalStep1.isPrimary, 'border-red-400': goalStep1.isPrimary}"

      >
        Öncelikli Hedef
      </div>
    </div>

      <label for="startDate" class="single-line-label"
        >Hedef Başlangıç Tarihi
        <vue-tooltip
          explanation="Bugünün tarihi otomatik olarak eklenmiştir. Hedefi farklı bir tarihte başlatmak için aşağıdan seçim yapabilirsiniz."
          >?</vue-tooltip
        >
      </label>

    <input
      type="date"
      id="date"
      class="dashboard-form__input"
      v-model="goalStep1.startDate"
      @input="setExpirationDate"
    />

    <label for="period" class="single-line-label">Dönem</label>
    <div class="border-1-white w-full flex flex-row justify-between">
      <div
        v-for="period in periods"
        @click="determinePeriod(period);"
        :key="period"
        :class="{ 'bg-white': period === goalStep1.period }"
        class="text-4xl cursor-pointer mb-5 text-gray-800 border-gray-500 border-2 px-20 py-8 hover:bg-white " >
        {{ period }}
      </div>
    </div>

    <div v-if="expirationDate != null" class="text-2xl mt-5 mb-5">
      Hedefin bitiş tarihi: <strong>{{ parseDate(expirationDate) }}</strong>
    </div>

    <label for="description" class="single-line-label">Açıklama
      <vue-tooltip explanation="Hedef konusunda yanlış / eksik anlaşılmalar olmaması için, buraya kısa bir tanım girebilirsiniz. Açıklama girmek istemiyorsanız, bu alanı boş bırakabilirsiniz.">?</vue-tooltip>
    </label>

    <textarea id="description" rows="5" class="medium-message mb-10" v-model="goalStep1.description"></textarea>

    <div class="button-row">
        <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel"> İptal </button>
        <button type="button" @click="saveAndIncrementStep" class="dashboard-form__submit bg-green-400 hover:bg-green-500">İlerle</button>
    </div>

  </form>
</template>

<script>
import VueTooltip from '../VueTooltip.vue'

export default {
  name: "goalFormStep1",

  data(){
      return {
          periods: ['3 Ay', '6 Ay', '9 Ay', "12 Ay"],

          goalStep1: {          
            name: "",
            isPrimary: false,
            startDate: new Date().toISOString().substr(0,10),
            formattedStartDate: "",
            period: "",
            expirationDate: "",
            description: ""

          }

      }
  },

  props: ["goal"],


  computed: {
    expirationDate(){
      if(this.goalStep1.period === ""){
            return null
      } else {

        console.log("Calculating expiration date")
        
        // Period is a string, so we parse it to an integer

        const period = this.goalStep1.period
        const periodParsed = period.split(" ")[0]
        const periodNumber = parseInt(periodParsed, 10)


        // Get current date (hours don't matter in this case)

        const goalStartDate = this.goalStep1.startDate
        const startDateSplit = goalStartDate.split("-")


        // Format of startDateSplit is going to be Y - M - D

        const startDate = new Date(startDateSplit[0], startDateSplit[1] - 1, startDateSplit[2])

        // Add specified months to the date:

        const goalExpiration = new Date(startDate.setMonth(startDate.getMonth() + periodNumber))
        console.log(goalExpiration)

        return goalExpiration
        }
    }
  },

  components: {
    VueTooltip: VueTooltip,
  },


  methods: {

    parseDate(value){
      try{
        return Intl.DateTimeFormat('tr-TR').format(value)
      } catch {
        console.log("Error converting date")
      }
    },
    fillIfGoalAlreadyExists(){

      let stepFields = ["name", "period", "description", "startDate"]

      stepFields.forEach((field) => {
        if(this.goal[field] != undefined){
          this.goalStep1[field] = this.goal[field]
        }
      })

      if(this.goal.isPrimary === true){
        this.goalStep1.isPrimary = true
      }
    },


    determinePeriod(period){
      console.log("Running component determinePeriod")
      this.goalStep1.period = period
      this.setExpirationDate()
    },




      goBack(){
          this.$router.go(-1)
      },

    setExpirationDate(){
      if(this.goalStep1.period != ""){
        this.goalStep1.expirationDate = this.expirationDate
      }
      else {
        return
      }
    },

      saveAndIncrementStep(){
          console.log("incrementing step to 2")
      this.$emit("step1Complete", this.goalStep1)
  }

  },

created(){
  this.fillIfGoalAlreadyExists()
}

};
</script>
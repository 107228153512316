<template>

<!-- Adding the outer comntainer because the modal will be displayed here. -->
<div class="outer-container">
    <acca-vue-notification v-if="isEditingComplete" message="Kategori düzenlendi!"></acca-vue-notification>

  <div class="main-container">
      <button @click="addCategory" class="add-goal flex items-center px-10 whitespace-nowrap rounded-3xl py-4 col-end-13 text-2xl bg-white cursor-pointer row-start-1 row-end-1 hover:bg-gray-100">Yeni Kategori Ekle</button>
      <div class="goals-container grid col-start-1 col-end-13">
        <div @click="editCategory(cat)" v-for="cat in goalCategories" :key="cat.catColor" class="goal-category" :style="{backgroundColor: cat.catColor}">
            <span class="material-icons icons-container__icon text-9xl text-white mb-10">
                {{cat.icon}}
            </span>
                <h3 class="text-3xl text-white">{{cat.name}}</h3>
        </div>
      </div>
  </div>
          <acca-modal-medium @modalClosed="this.endEditing" v-if="isEditingCat" :title="title" width="50vw" height="85vh">
            <goal-category-edit @modalClosed="this.endEditing" @editingComplete="displayCompletedEdit" @deletionComplete="deleteCategory" :category="editedCat"></goal-category-edit>
      </acca-modal-medium>
</div>
 

</template>

<script>
import accaModalMedium from "../reusable/modal-views/accaModal-medium.vue"
import GoalCategoryEdit from "./goalWidgets/GoalCategoryEdit.vue"
import firebase from '../../firebase'
import accaVueNotification from "../reusable/accaVueNotification.vue"


export default {
  name: 'GoalsHome',
  data(){
      return {
          goalCategories: [],
          isEditingComplete: false,
          isEditingCat: false,
          editedCat: null,
          title: "",
      }
  },




  methods: {

    determineModalTitle(){
        if(this.isAddingCat) {
            this.title = "Hedef Ekle"
        } else if(this.isEditingCat){
            this.title = `Kategori Düzenleniyor: ${this.editedCat.name}`
        } else {
            return
        }
    },

    addCategory(){
        console.log("Adding category")
        this.isEditingCat = true
        this.editedCat = {
            name: "",
            icon: "",
            catColor: "",
        }
    },

    editCategory(cat){
        this.isEditingCat = true
        this.editedCat = cat
        this.determineModalTitle()
    },

    displayCompletedEdit(category){
        // find edited category in the list

        let editedCategoryIndex = this.goalCategories.findIndex((cat) => cat.id === category.id)
        console.log(editedCategoryIndex)

        if(editedCategoryIndex < 0){
            console.log("Running for undefined")
            this.goalCategories.push(category)
        } else {
            this.goalCategories[editedCategoryIndex] = category
        }

        // Replace it


        // Display notification
          this.isEditingComplete = true
          setTimeout(() => {
              this.isEditingComplete = false
          }, 1500)
    },

    deleteCategory(category){
        let deletedCategoryIndex = this.goalCategories.findIndex((cat) => cat.id === category.id)
        this.goalCategories.splice(deletedCategoryIndex, 1)
    },


    // Just to call a simple function when we're done either adding or editing.
    // We can call this with or without saving the changes, so it allows a minor code duplication. 

    endEditing(){
        this.isEditingCat = false
        this.isAddingCat = false
        this.editedCat = null
    },
    getGoalCategories(){
        let db = firebase.firestore().collection("goalMetas").doc("goalCategories").collection("categories")
        db.get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.goalCategories.push({
                    id: doc.id,
                    name: doc.data().name,
                    icon: doc.data().icon,
                    catColor: doc.data().catColor
                    })
                })
        }).catch((error) => console.log(error))
  },

  },

  components: {
    accaModalMedium, 
    GoalCategoryEdit,
    accaVueNotification
  },

  created(){
      this.getGoalCategories()
  }
  
}
</script>

<style scoped lang="scss">

button.add-goal::before{
    content: "+";
    margin-right: 7px;
    font-size: 15px;
    color: #7e7e7e;
    font-weight: bold;
    border: 1px solid #7e7e7e;
    width: 25px;
    height: 25px;
    border-radius: 100%;

}

.goals-container{
    grid-template-columns: repeat(5, 1fr);
    gap: inherit;
    
}

.goal-category{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 0;
    cursor: pointer;
}

</style>

<script setup lang="ts">
    import { ref, onBeforeMount, nextTick } from "vue" 
    import acMenuItem from "./acMenuItem.vue";
    const acMenu = ref(null)
    defineProps({
        items: Array,
        displaysTriangle: Boolean
    })
    const emit = defineEmits(["menuDone"])

    const closeMenu = () => {
        emit("menuDone")
    }

    onBeforeMount(() =>  {
        nextTick(() =>{
            acMenu.value?.focus()
        })
    })

</script>

<template>
    <ul 
    :class="{'displays-triangle': displaysTriangle}"
    class="task-menu py-2 px-4 max-w-[25rem]"
    ref="acMenu"
    tabindex="0"
    @blur="closeMenu"
    >
        <ac-menu-item
            @action-performed="closeMenu"
            v-for="item in items" 
            :key="item.name" 
            :name="item.name" 
            :icon="item.icon"
            :action="item.action"
        />
    </ul>
</template>
<style lang="scss">
  @import "../../styles/global/helpers.scss";


</style>
<script setup lang="ts">

import {ref, onBeforeMount, nextTick, computed} from "vue"
import tagViewer from "./tagViewer.vue";


const statusChanger = ref<HTMLElement | null>(null)
const props = defineProps(["statusList", "title", "existingStatuses", "displaysTriangle"])

const availableSelections = computed(() => {
    if(!props.existingStatuses || props.existingStatuses.length === 0) return props.statusList

    // Filter out the existing statuses from the status list, using the id property.

    return props.statusList.filter((status: any) => {
        return !props.existingStatuses.some((existingStatus: any) => existingStatus.id === status.id)
    })

})

const emits = defineEmits(["cancelled", "updated"])

const cancel = () => {
    emits("cancelled")
}

const updateStatus = (status: any) => {
    emits("updated", status)
}

onBeforeMount(() => {
    nextTick(() => {
        statusChanger.value.focus()
    })
})

</script>

<template>
    <div :class="{'display-triangle': displaysTriangle}" ref="statusChanger" tabindex="0" @blur="cancel"
        class=" shadow-md outline-none z-30 rounded bg-[var(--box-color)] border text-2xl p-4">
        <div class="flex items-center border-b-2 px-2 my-4 !w-full">
            <h4 class="font-bold ">{{ title }}</h4>
            <button @click.stop="cancel" class="ml-auto text-2xl font-bold">x</button>
        </div>
        <div class="chip-list !w-full">
        <div class="chip-container mx-auto w-full max-h-[20rem] overflow-y-scroll">
            <div class="single-chip-holder !w-full mb-4" v-for="(v, k) in availableSelections" :key="k">
                <tag-viewer @click="updateStatus(v)" class="!w-full transition transform hover:scale-105 relative cursor-pointer"
                    :tag="v">
                </tag-viewer>
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>

.display-triangle::after{
    content: "";
    position: absolute;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 1.5rem solid var(--box-color);
}

</style>
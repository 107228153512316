import { createStore } from 'vuex';
import user from "./user";
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    key: "user-vuex",
    storage: window.localStorage,
    reducer: state => ({
        user: {
            loggedIn: state.user.loggedIn,
            userProfile: state.user.userProfile,
            isAuthenticatedForBMS: state.user.isAuthenticatedForBMS
        }
    })
});

export const store = createStore({
    modules: {
        user,
    },
    plugins: [vuexLocal.plugin]
});

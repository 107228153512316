<script setup>
const props = defineProps(["name", "action", "icon"]);

const emits = defineEmits(["actionPerformed"]);

const performAction = () => {
    props.action();
    emits("actionPerformed");
};
</script>

<template>
    <div class="text-2xl flex items-center my-6 hover:bg-gray-100 py-4 px-4 cursor-pointer" @click="performAction">
        <img v-if="props.icon" :src="`~/assets/icons/${icon}`" alt="menu icon">
        <span>
            {{ name }}
        </span>
    </div>
</template>
  
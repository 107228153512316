<template>
        <div class="main-container content-center">
        <acca-vue-notification v-if="isOtherRequestSent" message="Talebiniz oluşturuldu"></acca-vue-notification>

            <h2 class="form-title">Talep Oluştur</h2>

            <form class="dashboard-form" @submit.prevent="sendOtherRequest()" >


            <label class="single-line-label" for="user">Talebi Oluşturan </label>
                <input
                    required
                    type="text"
                    readonly
                    id="user"
                    class="dashboard-form__input bg-gray-200"
                    v-model="userProfile.name"
                >

            <label class="single-line-label" for="title">Talep
                <vue-tooltip explanation="Lütfen talebinizin konusu ile ilgili kısa bir başlık girin.">?</vue-tooltip>    
            </label>
                <input
                    required
                    type="text"
                    id="title"
                    class="dashboard-form__input"
                    v-model="otherRequest.title"
                >
        

                    <label class="single-line-label" for="body">Açıklama
                         <vue-tooltip explanation="Talebiniz ile ilgili detayları buradan iletebilirsiniz.">?</vue-tooltip>    
                    </label>
                    
                    <textarea
                            required
                            id="body"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="otherRequest.body">
                    </textarea>

                <div class="button-row">
                    <button type="submit" @click="otherRequest.user = userProfile.name; postData(otherRequest, 'requests', 'other', 'otherRequests')" class="dashboard-form__submit">Oluştur</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>
            </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import VueTooltip from '../reusable/VueTooltip.vue'
import accaVueNotification from "../reusable/accaVueNotification.vue"
import { mapGetters } from "vuex"

export default {
  name: 'OtherRequests',
  data() {
      return {
          isOtherRequestSent: false,
          otherRequest: {
              user: "",
              title: "",
              body: "",
              created: new Date()
          }
      }
  },

  methods: {
      goBack(){
          this.$router.go(-1)
      },

      sendOtherRequest() {
          this.isOtherRequestSent = true
          setTimeout(() => {
              this.isOtherRequestSent = false
              this.goBack()
          }, 3000)
      }
  },

   computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        })
    },

    components: {
      VueTooltip: VueTooltip,
      accaVueNotification: accaVueNotification,
  },

  mixins: [postData],

    created(){
      this.otherRequest.user = this.userProfile.name
  },

    beforeUpdate(){
        this.otherRequest.user = this.userProfile.name
    }
} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

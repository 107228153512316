
export default {
    methods: {
    resetBackground() {
        console.log('Reset Background ran.')
        const elements = document.getElementsByClassName('global-container')
        const background = elements[0]
        background.style.backgroundImage = ""
        }
}
}
<template>    
            <div class="posts-container">
-                <table class="table-auto border-collapse col-start-1 col-end-13 bg-white">
                    <thead>
                        <tr class="bg-gray-700">
                            <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Paylaşım Konusu </th>
                            <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Görsel </th>
                            <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Metin </th>
                            <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Yayın Tarihi </th>
                        </tr>
                    </thead>
                    <tr @click="emitPostDisplayed(post)" class="border-b-2 table-row-group cursor-pointer hover:bg-gray-100" :class="{'!border-red-700 !border !border-4' : post.status === 'rejected'}"  v-for="post in displayedPosts" :key="post.id">
                            <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">
                                <div class="flex flex-col justify-center items-center">
                                    <img title="Bu mesaj LinkedIn üzerinde paylaşıldı." v-if="post.isPublished" :src="require('../../../assets/icons/checked.png')"  class="" width="20px" alt="Paylaşıldı.">
                                    <img title="Bu mesaj yönetici kadromuz tarafından onaylanmış." v-else-if="!post.isPublished && post.status === 'approved'" :src="require('../../../assets/icons/checked.png')" width="20px" class="opacity-50" alt="Onaylandı.">
                                    <p>
                                        {{post.name}}
                                    </p>
                                </div>
                            </td>
                            <td class="message-content__data-image py-4 px-8 has-min-width">
                                <img v-if="!post.cover_image" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/dansuk-engineering-dashboard.appspot.com/o/assets%2Fimages%2Fdefault-img.jpg?alt=media&token=3da82787-0b68-4e7d-b6a0-661d3c876867" alt="default image">
                                <img v-else loading="lazy" :src="post.cover_image" alt="linkedin image post">
                            </td>
                            <td class="message-content__data py-4 px-8 text-2xl">
                                <div v-if="post.desc" class="message-content__data-desc">{{post.desc}}</div>
                                <div v-else-if="post.status === 'rejected'" class="message-content__data-desc italic">Bu paylaşım yönetici kadromuz tarafından reddedilmiş ve yakında kaldırılacak.</div>
                                <span v-else class="message-content__data desc py-4 italic text-gray-400 px-8 text-2xl">Bu paylaşım için henüz metin yazılmamış.</span>
                            </td>
                            <td class="message-content__data py-4 px-8 text-center text-2xl">{{formatDate(post.due)}}</td>
                    </tr>
                </table>
            </div>
    </template>
    
    <script>
    
    import { formatDate } from '../../../mixins/visualFormatters';

    export default {
        
        name: 'LinkedInPostView',    
        computed: {
            displayedPosts: function() {
                if(this.displayEmpty) {
                    return this.posts
                } else {
                    const filteredPosts = this.posts.filter(post => post.desc !== "")
                    console.log(filteredPosts)
                    return filteredPosts
                }
        }
        },
        methods: {

            emitPostDisplayed(post) {
                this.$emit('post-displayed', post)
            },
        
            formatDate,

        },
          props: {
              posts: {
                  type: Object,
                  required: true
              },
                displayEmpty: {
                    type: Boolean,
                    default: false
                }
          },
      }
    
    
    </script>
    
    <style lang="scss">
      @import "../../../styles/core/variables.scss";
      @import "../../../styles/global/forms.scss";
      .message-content__data-image img{
        aspect-ratio: 16/9;
        object-fit: cover;
      }
      .message-content__data-desc{
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        word-break: break-word;
    
      }
      @media screen and (min-width: 1280px){
          .has-min-width{
            min-width: 40rem;
          }
      }
    </style>
    
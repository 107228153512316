<script setup lang="ts">
    import { ref, onMounted, nextTick } from "vue" 


    defineProps(["itemList", "displayedAttribute", "selectMultiple", "selectedItems",])
    const emits = defineEmits(["itemSelected", "filterClosed", "filterReset"])
    const reusableFilterPopup = ref<HTMLDivElement | null>(null)

    function selectItem(item: string | undefined){
        if(item === undefined){
            emits("itemSelected", undefined)
            emits("filterReset")
            emits("filterClosed")
            return
        }
        emits("itemSelected", item)

    }

    const closeFilter = () => {
        emits("filterClosed")
    }

    onMounted(() =>  {
        nextTick(() => {
            reusableFilterPopup.value?.focus()!
        })
    })
</script>

<template>
    <div 
        tabindex="0" 
        @blur="closeFilter()"
        ref="reusableFilterPopup"
        class="filter-popup container top-[4.8rem] z-20 border text-2xl shadow-xl top-16 py-4 w-[150%] rounded-xl border-gray-300">
        <ul v-if="typeof(itemList[0]) === 'string'" class="list-container">
            <li :class="{'filter-item-selected': selectedItems.includes(item)}" class="mb-4 cursor-pointer p-4 px-8 select-none" v-for="item in itemList" :key="item" @click="selectItem(item)">{{ item }}</li>
            <li class="mb-4 cursor-pointer p-4 px-8 select-none text-gray-400" @click="selectItem(undefined)">
                Sıfırla
            </li>
        </ul>
        <ul v-else class="list-container">
            <li :class="{'filter-item-selected': selectedItems.includes(item)}" class="mb-4 cursor-pointer p-4 px-8 select-none" v-for="item in itemList" :key="item.id" @click="selectItem(item)">
                {{ item[displayedAttribute] }}
            </li>            
            <li class="mb-4 cursor-pointer p-4 px-8 select-none text-gray-400" @click="selectItem(undefined)">
                Sıfırla
            </li>
        </ul>
    </div>
</template>

<style scoped>

.filter-popup{
    max-height: 50vh;
    overflow-y: scroll;
    background-color: var(--box-color);
    outline: none !important;

}

.filter-popup li:hover{
    background-color: var(--accent-gray-dark);

}

.filter-popup::focus{
    outline: none !important;
}
.filter-popup::before{
    /* Put a small triangle */

    content: "";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 45%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}

.filter-item-selected{
    position: relative;
}

.filter-item-selected::after{
    content: "\2713";
    position: absolute;
    right: .7rem;
    color: var(--accent-gray);
    top: 30%;    
    width: 1rem;
    height: 1rem;
}

</style>
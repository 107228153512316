<script setup lang="ts">

import { ref } from "vue"
import { useGetAllMetas, addOrUpdateMeta, deleteMetaField } from "../../mixins/metaManagement"
import { modalClosed } from "../../mixins/visualFormatters"
import { EMailTag } from "../../types"

import addMeta from "../reusable/teleports/addMeta.vue"
import tagViewer from "../reusable/tagViewer.vue"
import acMediumModal from "../reusable/teleports/acMediumModal.vue"

const isAddingMeta = ref<boolean>(false)
const existingMeta = ref<EMailTag | undefined>(undefined)

const { metaList, isLoadingMeta } = useGetAllMetas("crm/EMailTags")

const handleAddTag = async (tag: EMailTag) => {
    try {
        if (!existingMeta.value) {
            const newId = await addOrUpdateMeta("metas/crm/EMailTags", tag);
            metaList.value.push({ ...tag, id: newId });
        } else {
            await addOrUpdateMeta("metas/crm/EMailTags", tag, existingMeta.value.id);
            const index = metaList.value.findIndex((item) => item.id === existingMeta.value.id);
            if (index !== -1) {
                metaList.value[index] = { ...tag, id: existingMeta.value.id };
            }
        }
        modalClosed();
    } catch (error) {
        console.error("Error in handleAddTag:", error);
    } finally {
        existingMeta.value = undefined;
        isAddingMeta.value = false;

    }
}

const handleDeleteMeta = async (id: string) => {
    const index = metaList.value.findIndex((item) => item.id === id)
    await deleteMetaField("metas/crm/EMailTags", id)
    metaList.value.splice(index, 1)
}

</script>

<template>

    <div class="main-container mt-8 content-start items-start">
        <div class="flex col-start-1 col-end-13 justify-between items-center w-full">
            <h2 class="ac-large-title">E-Posta Etiketleri</h2>
            <div class="action-row col-start-1 col-end-13 flex items-center justify-end">
                <button type="button" @click="isAddingMeta = true" class="ac-add-button">
                    <img class="mr-4" width="20" :src="require('../../assets/icons/add.png')" alt="">
                    Yeni Kategori Ekle
                </button>        
            </div>
        </div>
        <table v-if="!isLoadingMeta" class="alt-table col-start-1 col-end-13 w-full mt-5">
            <thead>
                <tr>
                    <th>Etiket </th>
                    <th>Açıklama</th>
                    <th>İşlemler</th>
                </tr>
            </thead>
            <tr class="hover:bg-gray-100 relative cursor-pointer" v-for="tag in metaList" :key="tag.id">
                <td  class="text-overflow w-24"> 
                    <tag-viewer class="max-w-full" :tag="tag" />
                </td>
                <td  class="text-overflow"> {{ tag.desc }}</td>
                <td class="flex">
                    <button type="button" class="table-button"  @click="handleDeleteMeta(tag.id)">
                        Sil
                    </button>
                    <button type="button" class="table-button"  @click="existingMeta = tag">
                        Düzenle
                    </button>
                </td>
            </tr>
        </table>

    <teleport to="#app">
        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__fadeIn animate__fast"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
            <ac-medium-modal class="lg:!top-44 overflow-y-scroll" @modal-closed="isAddingMeta = false; existingMeta = undefined; modalClosed()" v-if="isAddingMeta || existingMeta" height="85vh" width="70vw" title="Yeni Ekle">
                <add-meta 
                @add-new-meta="handleAddTag($event)" 
                @updated-meta="handleAddTag($event)" 
                :existing-meta="existingMeta" 
                :has-color="true"
                :has-desc="true"
                 />
            </ac-medium-modal>
       </transition>
  </teleport>
        </div>



</template>

<style lang="scss">
@import "../../styles/global/buttons.scss";
@import "../../styles/global/tables.scss";
@import "../../styles/core/typography.scss";
</style>
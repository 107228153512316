
export default {
    methods: {
      removeBackground() {
        console.log('Running')
        const elements = document.getElementsByClassName('global-container')
        const background = elements[0]
        background.style.backgroundImage = "None"
        },
}
}
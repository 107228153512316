import axios from "axios"


export default {
    methods: {
      async getDataFromGS(spreadsheetID, sheetName, cellRange){
        const baseURL = "https://sheets.googleapis.com/v4/spreadsheets"
        const apiKey = "AIzaSyDNi3zi8FU-6Dxt7ONwPCr9oLhDN1BHH4o"

        const finalURL = `${baseURL}/${spreadsheetID}/values/${sheetName}!${cellRange}?key=${apiKey}`
    
        const response = await axios.get(finalURL)
        return response.data.values
        
    
    }
  },
}
<template>
  <div class="main-container">
    <acca-vue-notification message="Link kopyalandı!" v-if="isCodeCopied">
    </acca-vue-notification>

    <div class="document-actions-container col-start-1 col-end-13 mt-10">
      <docuement-filter-widget
        @searchOccured="searchList"
        :searchTerm="searchTerm"
      ></docuement-filter-widget>

      <div class="ml-10 preset-filters-container justify-evenly flex">
        <prepared-filter
          v-for="(filter, index) in presetFilters"
          :key="filter.title"
          @filterActivated="filterList"
          :text="filter.text"
          :searchTerm="filter.searchTerm"
          :active="filter.active"
          :index="index"
        ></prepared-filter>
      </div>

      <document-view-switcher
        :selectedView="selectedComponent"
        @viewSet="displaySelectedComponent"
        class="col-start-12 col-end-12"
      ></document-view-switcher>
    </div>

    <transition
      appear
      type="animation"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      mode="out-in"
    >
      <component
        @codeCopied="showCopyNotification"
        class="col-span-full"
        :is="selectedComponent"
        :documentData="filteredDocuments"
        coverType="vertical"
      />
    </transition>
  </div>
</template>

<script>
import firebase from "../../firebase";
import DocumentTableView from "../reusable/DocumentTableView.vue";
import DocumentGridView from "../reusable/DocumentGridView.vue";
import DocumentViewSwitcher from "../reusable/DocumentViewSwitcher.vue";
import accaVueNotification from "../reusable/accaVueNotification.vue";
import DocuementFilterWidget from "../reusable/DocumentFilter.vue";
import PreparedFilter from "../reusable/PreparedFilter.vue";

export default {
  name: "DocumentsLGAll",
  data() {
    return {
      selectedComponent: "DocumentTableView",
      isCodeCopied: false,
      documents: [],
      searchTerm: "",
      isListFiltered: false,
      filterTerm: "",
      presetFilters: [
        {
          text: "Fiyat Listesi",
          searchTerm: "PDF - Fiyat Listesi",
          active: false,
        },
        {
          text: "Bayi Alım",
          searchTerm: "PDF - Bayi Alım Koşulları",
          active: false,
        },
        { text: "Datasheet", searchTerm: "PDF - Datasheet", active: false },
        { text: "Databook", searchTerm: "PDF - Databook", active: false },
      ],
    };
  },

  computed: {
    filteredDocuments() {
      if (this.searchTerm === "" && this.filterTerm != "") {
        return this.documents.filter(
          (document) => document.fileType.toLowerCase() === this.filterTerm
        );
      } else if (this.searchTerm !== "" && this.filterTerm === "") {
        return this.documents.filter(
          (document) =>
            document.title.toLowerCase().indexOf(this.searchTerm) > -1
        );
      } else if (this.searchTerm !== "" && this.filterTerm !== "") {
        const filteredList = this.documents.filter(
          (document) => document.fileType.toLowerCase() === this.filterTerm
        );
        return filteredList.filter(
          (document) =>
            document.title.toLowerCase().indexOf(this.searchTerm) > -1
        );
      } else {
        return this.documents;
      }
    },
  },
  methods: {
    searchList(searchTerm) {
      this.searchTerm = searchTerm.toLowerCase();
    },

    filterList(filterTerm, index) {
        // No filters applied, user clicks a filterItem

        if(!this.isListFiltered){
            this.filterTerm = filterTerm.toLowerCase()
            this.isListFiltered = true
            this.presetFilters.forEach((filterItem, i) => {
                if(i === index) {
                    filterItem["active"] = true;
                }
            })
        } else {
            // If the list is already filtered on users click.

                // Get the index of the active element

            const activeElementIndex = this.presetFilters.findIndex((item) => item.active === true)

            // If the clicked link is the same as the one already selected
            
            if(activeElementIndex === index){
                this.filterTerm = ""
                this.isListFiltered = false
                this.presetFilters.forEach((filterItem) => {
                    filterItem["active"] = false
                })
            } else {
                this.filterTerm = filterTerm.toLowerCase()
                this.isListFiltered = true
                this.presetFilters.forEach((filterItem, i) => {
                    if(i === index) {
                        filterItem["active"] = true;
                    } else {
                        filterItem["active"] = false
                    }
                })
            }
        }
    },

    displaySelectedComponent(view) {
      this.selectedComponent = view;
    },

    showCopyNotification: function () {
      this.isCodeCopied = true;
      setTimeout(() => {
        this.isCodeCopied = false;
      }, 2000);
    },
    getData() {
      let db = firebase
        .firestore()
        .collection("docs")
        .doc("lg")
        .collection("catalog")
        .orderBy("year");
      db.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.documents.push({
            id: doc.id,
            image: doc.data().image,
            title: doc.data().title,
            lang: doc.data().lang,
            fileType: doc.data().fileType,
            url: doc.data().url,
            year: doc.data().year ? doc.data().year : "-",
          });
        });
        this.documents.reverse();
      });
    },
  },

  components: {
    DocumentTableView: DocumentTableView,
    DocumentGridView: DocumentGridView,
    DocumentViewSwitcher: DocumentViewSwitcher,
    accaVueNotification: accaVueNotification,
    DocuementFilterWidget: DocuementFilterWidget,
    PreparedFilter: PreparedFilter,
  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/core/variables.scss";
@import "../../styles/global/tables.scss";
</style>

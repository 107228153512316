<template>
    <div class="main-container">
        <acca-vue-notification v-if="ishrDocSent" message="Belge başarıyla yüklendi."></acca-vue-notification>
                <h2 class="form-title">İK - Belge Yayımla</h2>
                <form class="dashboard-form" @submit.prevent="validateAndPost" >

                <label class="single-line-label" for="title">Belge Adı</label>
                <input
                    required
                    type="text"
                    id="title"
                    class="dashboard-form__input"
                    v-model="hrDoc.title"
                >

                <label for="lang" class="single-line-label">Belgenin Dili</label>
                    <select required v-model="hrDoc.lang" name="lang" id="lang">
                        <option value="tr">Türkçe</option>
                        <option value="en">English</option>
                        <option value="de">Deutsch</option>
                    </select>


                    <!--Dosya Yükleme Kısmı -->

                    <label class="text-2xl font-semibold mb-5" for="images">Dosya
                    </label>

                    <acca-dn-d-upload single-file class="mb-8" @filesChanged="handleFileUpload($event)" />

                    

                    <!--  SON - Kapak görseli Yükleme Kısmı -->

                    <label  class="text-2xl font-semibold mb-5" for="images">Kapak Görseli
                    </label>

                    <acca-dn-d-upload single-file class="mb-8" @filesChanged="handleCoverUpload($event)" />




                    <div class="button-row">
                        <button type="submit" class="dashboard-form__submit">Gönder</button>
                        <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                    </div>
                </form>
    </div>
</template>

<script>

import accaVueNotification from "../reusable/accaVueNotification.vue"
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"
import postData from "../../mixins/firebaseOperations"




export default {
  name: 'HRAddDocument',
  data() {
      return {
          ishrDocSent: false,
          hrDoc: {
              title: "",
              coverImage: "",
              lang: "Türkçe",
              file: "",
              created: new Date(),
          },

      }
  },

  methods:{

        async handleFileUpload(event){
            this.hrDoc.file = await this.uploadSingleToStorage(event, 'hrDocs')
        },
    
        async handleCoverUpload(event){
            this.hrDoc.coverImage = await this.uploadSingleToStorage(event, 'hrDocs/images',)
        },

    goBack(){
          this.$router.go(-1)
      },

      validateAndPost(){
          if(this.hrDoc.coverImage === null || this.hrDoc.file === null){
              alert("Dosya bölümü veya kapak görseli boş olamaz.")
          } else {
              this.postData(this.hrDoc, 'hr', 'docs', 'hrDocs')
              this.sendHRDoc()
          }
      },

      sendHRDoc() {
          this.ishrDocSent = true
          setTimeout(() => {
              this.ishrDocSent = false
              this.goBack()
          }, 3000)
      }
  },

  components: {
       accaVueNotification: accaVueNotification,
       accaDnDUpload: accaDnDUpload,
  },

  mixins: [postData, firebaseOperations]
}
</script>

<style scoped lang="scss">

  .dropzone .dz-message{
    padding: 4em 0;
}

</style>

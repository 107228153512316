<template>
    <div class="main-container">
        <acca-vue-notification v-if="isSurveyResponseSent" message="Belge başarıyla yüklendi."></acca-vue-notification>

                <h2 class="form-title">Anket Sonucu İlet</h2>
                <form class="dashboard-form" @submit.prevent="validateAndPost" >

                <p class="text-2xl border border-gray-600 px-3 py-4 mb-5">Anketin sunucularımızda doğru konuma kaydedilmesi için lütfen anket adını dosya yüklemeden önce belirleyin. Aynı anket için birden fazla dosyayı aynı anda yükleyebilirsiniz.</p>

                <label for="lang" class="single-line-label">Anket</label>
                    <select required v-model="selectedSurvey" name="name" id="name">
                        <option v-for="survey in availableSurveys" :key="survey.id" :value="survey">{{survey.name}}</option>
                    </select>

                    <!--Dosya Yükleme Kısmı -->

                    <label class="text-2xl font-semibold mb-5" for="images">Dosya
                    </label>

                    <acca-dn-d-upload v-if="selectedSurvey.name !== ''" class="mb-8" @filesChanged="updateFiles($event)" />


                    <div class="button-row">
                        <button type="submit" class="dashboard-form__submit">Gönder</button>
                        <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                    </div>
                </form>
    </div>
</template>

<script>

import accaVueNotification from "../reusable/accaVueNotification.vue"
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"
import firebase from '../../firebase'
import 'firebase/storage';
import postData from "../../mixins/firebaseOperations"




export default {
  name: 'ForwardSurveyResponse',
  data() {
      return {
          isSurveyResponseSent: false,
          availableSurveys: [],
          selectedSurvey: {
                idField: "",
                name: "",
          },
          files: [],
          surveyResponse: {
              name: "",
              file: null,
              created: new Date(),
          },
      }
  },

  methods:{
    goBack(){
          this.$router.go(-1)
      },

      async validateAndPost(){
          if(this.selectedSurvey.name === null || this.files.length === 0){
              alert("Lütfen gerekli bölümleri doldurup tekrar deneyin.")
          } else {
              for (let i = 0; i < this.files.length; i++){
                  console.log("Running", i)
                  this.surveyResponse.name = this.selectedSurvey.name
                  const uploadedFile = await this.uploadSingleToStorage(this.files[i], "surveyResponses")
                  this.surveyResponse.file = uploadedFile
                  this.postData(this.surveyResponse, 'surveys', 'responses', this.selectedSurvey.idField)
              }

              this.sendsurveyResponse()
          }
      },

      async fetchAvailableSurveys(){
          let db = firebase.firestore().collection("surveys")
          db
            .get().then((querySnapshot) => {
                console.log("Successfully connected to firestore.")
                querySnapshot.forEach((doc) => {
                    this.availableSurveys.push({
                        id: doc.id,
                        idField: doc.data().id,
                        name: doc.data().name,
                        })
                })
        })
      },

      updateFiles(event) {
          this.files = event
      },

      sendsurveyResponse() {
          this.isSurveyResponseSent = true
          setTimeout(() => {
              this.isSurveyResponseSent = false
              this.goBack()
          }, 3000)
      }
  },

  components: {
       accaVueNotification: accaVueNotification,
       accaDnDUpload: accaDnDUpload,

  },

  mixins: [postData, firebaseOperations],

  created(){
      this.fetchAvailableSurveys()
  }
}
</script>

<style scoped lang="scss">
</style>

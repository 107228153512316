import { ref, computed, Ref } from 'vue';

export const formatDate = (string) => {
    const date = new Date(string);
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: 'long', day: 'numeric' };
    return date.toLocaleDateString('tr-TR', options);
}

export const toggleScrollOnModalOpen = (modalOpen) => {

    if (modalOpen) {
        document.body.classList.add("disable-scroll");
    } else {
        document.body.classList.remove("disable-scroll");
    }

    window.scroll(0,0)
}

export const forceRemoveModalStyles = () => {
    document.body.classList.remove("disable-scroll");
}

export const modalClosed = function(){
    const container = document.querySelector(".main-container")
    if(container){
        container.classList.remove("has-added-blur")
    }
}

export const blurBackground = function(){
    const container = document.querySelector(".main-container")
    if(container){
        container.classList.add("has-added-blur")
    }

    // Bring window scroll to top
    window.scrollTo(0, 0)
}



/* 

This is a LOCAL pagination composable. 

It should only be used in cases where ALL of the data is already fetched from the server. 
And thus, it should be used sparingly -- when the data is small enough.

In larger cases, we should use our actual pagination composable which also handles fetching the data 
from the server in chunks...

*/

export const useLocalPagination = (items: Ref<any>, itemsPerPage = 10) => {
    const currentPage = ref(1);
    const totalItems = computed(() => items.value.length);
    const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage));
  
    const paginatedItems = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return items.value.slice(start, end);
    });
  
    function setPage(pageNumber: number) {
      currentPage.value = pageNumber;
    }
  
    function nextPage() {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    }
  
    function previousPage() {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    }
  
    return { currentPage, totalPages, paginatedItems, setPage, nextPage, previousPage };
  }
  
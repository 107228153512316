<template>
    <div class="links-container w-full col-start-1 col-end-13">

        <acca-modal @modalClosed="isInfoShown = false" class="col-start-4 col-end-9" v-if="isInfoShown">
          <span v-html="selectedText"></span>
        </acca-modal>

        <external-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin' " is-drive class="grid-order-1" href="https://docs.google.com/spreadsheets/d/12c_mv0qFkcOp1Hke90cLnCxTGbdDuJPd_fWiEzEPxwo" title="Çek - Senet - DBS Tablosu" imgURL="spreadsheet.png"></external-navigation>
        <external-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin'" is-drive class="grid-order-2" href="https://docs.google.com/spreadsheets/d/1N8sTdlucWnklJZI4WbYwkGSVjlSNgE3udab8nvmzTEc/" title="Fatura Gelir - Gider Toplamları" imgURL="cash-flow.png"></external-navigation>
        <external-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin'" is-drive class="grid-order-3" href="https://docs.google.com/spreadsheets/d/1Z7-5d414YZWvjrY7dFD6JsXupiID0SQvKr1TGpVm49c" title="Hakediş Tamamlama Yüzdesi" imgURL="percentage-cash.png"></external-navigation>
        <external-navigation is-drive class="grid-order-1" href="https://docs.google.com/spreadsheets/d/1S4p4afF3kP9UHhDgWlN0W4urxiboMkU7/edit#gid=187030216" title="Genel Proje Takip Dosyası" imgURL="project-tracking.png"></external-navigation>
        <external-navigation is-drive class="grid-order-2" href="https://docs.google.com/spreadsheets/d/1MM8VovmZiQnGmP_vWiAX5e4pj6VLcjWA/edit#gid=1892232814" title="Yıllık Bakım Ajandası" imgURL="yearly-support.png"></external-navigation>
        <external-navigation is-drive class="grid-order-3" href="https://docs.google.com/spreadsheets/d/1vBhI5BsH01ijhxqzPVnYcpsHp6l0AzVel3A2l-I5zK0/edit#gid=0" title="Toplantı Notları" imgURL="meeting-notes.png"></external-navigation>
        <external-navigation is-drive class="grid-order-4" href="https://docs.google.com/spreadsheets/d/1AJ0Upw8SRFIWh6jBc7Ck-RHV7hRSTrW5/edit#gid=913804840" title="Satın Alma  - Tedarikçi Listesi" imgURL="suppliers.png"></external-navigation>
        <external-navigation is-drive class="grid-order-1" href="https://docs.google.com/spreadsheets/d/1RFL9tckqzZPcd-rbgHCWv7QlSRrLkAC4/edit#gid=793627710" title="Servis Yönetimi" imgURL="service-management.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('abs')" class="grid-order-2" href="https://abs.arcelik.com/" title="Arçelik ABS Giriş"  imgURL="abs-logo.png"></external-navigation>
        <external-navigation class="grid-order-3" href="https://arcelik.okta-emea.com/login/login.htm?fromURI=%2Fapp%2Farcelika_aron_1%2Fexk3216j1mnAJBqTh0i7%2Fsso%2Fsaml" title="Aron Giriş" imgURL="aron-logo.png"></external-navigation>
        <external-navigation class="grid-order-4" href="https://dansuk.ritmaflex.com/Login.aspx?ReturnURL=%2fdefault.aspx%3f" title="Ritma CRM Giriş" imgURL="ritma-logo.png"></external-navigation>
        <external-navigation class="grid-order-1" href="http://luca.com.tr/" title="Luca Giriş" imgURL="luca-logo.png"></external-navigation>
        <external-navigation class="grid-order-2" href="https://www.trello.com" title="Trello" imgURL="trello-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('drive')" class="grid-order-3" href="https://drive.google.com" title="Google Drive" imgURL="googledrive-logo.png"></external-navigation>
        <external-navigation class="grid-order-4 has-smaller-icon" href="https://dansuk.com.tr/servis-talepleri" title="Servis Talepleri" imgURL="qr-code.png"></external-navigation>
        <external-navigation class="grid-order-1" href="https://www.lg.com/global/support/cedoc/cedoc" title="LG Sertifika Bilgileri" imgURL="lg-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('clz')" class="grid-order-2" href="https://connect.collectorz.com/books" title="Kütüphane Organizasyon Programı" imgURL="clz-books.png"></external-navigation>
        <external-navigation class="grid-order-3" href="https://erp.dansuk.iesyazilim.com.tr/" title="LOGO Giriş" imgURL="logo-logo.png"></external-navigation>
        <external-navigation hasInfo @infoClicked="showAdditionalInformation('untes')" class="grid-order-4" href="https://untes.hvaccalculator.net/" title="HVAC Calculator" imgURL="hvac-calculator.png" ></external-navigation>
    </div>
</template>

<script>

import ExternalNavigation from "../ExternalNavigation.vue"
import accaModal from "../reusable/accaModal.vue"
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralLinks',
  data(){
    return {
      isInfoShown: false,
      selectedText: "",
      additionalInformation: {
        abs: "<strong>Kullanıcı Adı: </strong> R21834066.0 <br> <strong>Şifre: </strong> YGHP7BEG",
        drive: "<strong>Kullanıcı Adı: </strong> dansukengineering@dansuk.com.tr <br> <strong>Şifre: </strong> Dansuk.2121",
        clz: "<strong>Kullanıcı Adı: </strong> info@dansuk.com.tr <br> <strong>Şifre: </strong> 4e2ee134",
        untes: "<strong>Kullanıcı Adı: </strong>emine.guven <br> <strong>Şifre</strong> 12345"
      }
    }
  },
  components: {
      ExternalNavigation: ExternalNavigation,
      accaModal: accaModal
  },

  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },


  methods:{
    showAdditionalInformation(req){
      this.isInfoShown = true
      this.selectedText = this.additionalInformation[req]
    }
  }
}
</script>

<style lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

  .links-container{
    display: grid;
    grid-template-columns: inherit;
    column-gap: inherit;
    row-gap: inherit;
  }


  .has-smaller-icon img{
    width: 3rem;
  }

  @media screen and (max-width: 850px){
    .links-container{
      grid-template-columns: repeat(2, 1fr);
    }
  }


</style>

<script setup lang="ts">

import { defineProps, defineEmits, ref, Ref } from "vue"

const props = defineProps(["meta", "existingMeta", "hasColor", "hasDesc"])

const newName: Ref<string> = ref(props.existingMeta ? props.existingMeta.name : "")
const newColor: Ref<string> = ref(props.existingMeta ? props.existingMeta.color ? props.existingMeta.color : "" : "")
const newDesc = ref<string>(props.existingMeta ? props.existingMeta.desc : "")

const emit = defineEmits(["addNewMeta", "updatedMeta"])

function handleSubmit() {
    const emittedData = { name: newName.value };

    // Add color if it has a value
    if (newColor.value) {
        emittedData.color = newColor.value;
    }

    // Add desc if it has a value
    if (newDesc.value) {
        emittedData.desc = newDesc.value;
    }

    // Emit the appropriate event
    if (props.existingMeta) {
        emit("updatedMeta", emittedData);
    } else {
        emit("addNewMeta", emittedData);
    }
}

</script>   


<template>
    <form @submit.prevent class="ac-main-form">
        <div class="ac-form-control">
            <h2 class="subtitle my-4">Başlık</h2>
            <div class="flex border pl-2 items-center">
                <input v-model="newName" type="text" required class="w-full title-field p-3">
            </div>
        </div>

        <div v-if="props.meta != 'taskStages' || hasColor" class="ac-form-control">
            <h2 class="subtitle my-4">Renk</h2>
            <div class="flex items-center">
                <input v-model="newColor" type="color" required class="w-2/4">
            </div>
        </div>

        <div v-if="hasDesc" class="ac-form-control">
            <label for="description" class="subtitle my-4">Açıklama</label>
            <div class="flex items-center">
                <textarea v-model="newDesc" class="w-full"></textarea>
            </div>
        </div>
        <div class="button-row w-full items-center justify-center">
            <button type="button" @click="handleSubmit" class="ac-primary-button text-2xl mt-8">Gönder</button>
        </div>

    </form>
</template>

<style lang="scss">
@import "../../../styles/global/forms.scss";
@import "../../../styles/global/buttons.scss";

</style>
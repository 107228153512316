<template>
    <div class="training-container">
        <div class="go-back-container">
        </div>
        <iframe id="video" :src="selectedVideo" class="w-full h-full object-cover" frameborder="0"></iframe>
        <aside class="training-list-container w-full lg:w-2/5 bg-white h-full border-t-2 lg:overflow-y-scroll">
          <div class="flex w-full justify-around items-center">
              <h3 @click="getData()" class="font-sans text-3xl py-5 px-1 cursor-pointer font-semibold border-b-2 border-black">
                  Dashboard Eğitimleri
              </h3>
          </div>
          <div class="px-3 flex flex-col mt-8 space-y-5 lg:overflow-y-scroll">
              <div class="cursor-pointer" v-for="training, index in dashboardTrainings" :key="training.id" @click="changeSRC(index)">
                  <h4 class="font-semibold mb-23 text-3xl">
                      {{training.title}}
                  </h4>
                  <p class="text-2xl py-4 text-gray-500 border-b-2">
                      {{training.description}}
                  </p>
              </div>
          </div>
        </aside>
    </div>
  </template>
  
  <script>
  
  import firebase from '../../firebase'
  
  export default {
    name: 'DashboardTraining',
    data(){
        return {
            selectedVideo: 0,
            dashboardTrainings: []
        }
    },
            
    methods: {
        changeSRC: function(index){
            this.selectedVideo = this.dashboardTrainings[index].src
        },
  
    getData(){
              let db = firebase.firestore().collection("training").doc("dash").collection("trainings").orderBy("order")
              db
              .get().then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      this.dashboardTrainings.push({
                          id: doc.id,
                          title: doc.data().title,
                          order: doc.data().order,
                          src: doc.data().src,
                          description: doc.data().description
                      })
                  })
  
                  this.selectedVideo = this.dashboardTrainings[0].src
              });
          },
    },
  
   created(){
      this.getData()
    },
  
  }
  </script>
  
  <style scoped lang="scss">
    @import "../../styles/modules/training.scss";
  
  </style>
<script setup lang="ts">
import accaFileDisplayer from "./accaFileDisplayer.vue"
import { ref, reactive, watch } from "vue"

// This is yet another component for a drag and drop area for uploading files.
// It differs from the fileUploadField.vue component in that it has no button, and from the fileUploadField.vue component in that it is more flexible.

import { RemoteFile } from "@/types"

import { useDropzone } from "vue3-dropzone"


const props = defineProps({
    singleFile: {
        type: Boolean,
        default: false,
    },
    allow: {
        type: Array,
        default: () => [],
    },
})

const filePreview = ref([])

const uploads = reactive({
    files: [],
})

function onDrop(acceptFiles, rejectReasons) {
    console.log('acceptFiles ', acceptFiles);
    console.log('rejectreasons ', rejectReasons);

    if(rejectReasons.length > 0 && props.singleFile){
        alert("Lütfen bu alana sadece bir dosya yükleyin.")
        return
    }

    uploads.files = acceptFiles;
    console.log(uploads)
    previewFile(acceptFiles)

}

const options = {
    onDrop,
    accept: props.allow.length > 0 ? props.allow : '', // Use an empty string to allow all file types
    multiple: !props.singleFile
}

const { getRootProps, getInputProps, isDragActive, } = useDropzone(options);


function previewFile(files: File[]){
    
    // Loop over each file in the files argument and add it to the filePreview array
    
    files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
            const filePreviewObject: RemoteFile = {
                url: reader.result,
                name: file.name,
                type: file.type,
            }
            filePreview.value?.push(filePreviewObject)
        }
    })
}

const emits = defineEmits(["filesChanged"])

// Emit the filesChanged event when the files change.

watch(uploads, () => {
    emits("filesChanged", uploads.files)
})

function removeLocalFile(index: number){
    // Remove the file from the filePreview array
    filePreview.value?.splice(index, 1)
    // Find the index of the file in the uploads.files array
    // Remove the file from the array
    uploads.files.splice(index, 1)

}


</script>

<template>

<section class="upload-area">

<div v-if="uploads.files.length > 0">
    <h2 class="text-2xl underline"> Yüklenecek Dosyalar </h2>
    <transition-group class="grid grid-cols-3  my-4 gap-4" type="animation"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut" tag="section">
        <div v-for="(file, index) in filePreview" :key="file.name" class="file pl-2 ">
            <acca-file-displayer class="" :file="file" />
            <button type="button" @click="removeLocalFile(index)"
                class="text-xl text-red-400 hover:underline">Kaldır</button>
        </div>
    </transition-group>
</div>


<div v-bind="getRootProps()" class="dropzone w-full border ac-form-dropzone bg-gray-100 text-center ">
    <input v-bind="getInputProps()" />
    <div class="ac-upload-area" v-if="isDragActive">
        <img src="../../assets/icons/upload-active.png" alt="Dosya Yükleme">
        <p>Eklemek için bırakın.</p>
    </div>
    <div v-else class="ac-upload-area text-2xl">
        <img src="../../assets/icons/upload-inactive.png" alt="Dosya Yükleme">
        <p>Dosyaları sürükleyip bırakabilir veya buraya tıklayabilirsiniz.</p>
    </div>
</div>

</section>



</template>
<template>
      <nav class="col-start-3 col-end-11 steps-container border db-has-standard-bg py-4">
          <div class="step-navigation-container flex justify-center w-full">
            <div v-for="(step, index) in stepDetails" :key="index" class="flex step-navigation-container__step items-center">
                <div class="step-container flex flex-col items-center">
                    <div :class="{'bg-green-200' : currentStep >= index}" class="step bg-gray-200 px-10 py-8 text-3xl font-black rounded-full">
                        {{index + 1}}
                    </div>
                <div class="text-2xl mt-5">{{step}}</div>
            </div>
                <div v-if="index < 2" class="divider flex-grow border-b-4 mb-14 border-gray-300 h-10"></div>
        </div>
          </div>
      </nav>
</template>
<script>
export default {
    data(){
        return{
        }
    },

    methods: {
        completeBackground(){
            const dividers = document.getElementsByClassName("divider")
            console.log(dividers)
            if(this.currentStep === 1){
                dividers[0].classList.add("border-green-200")
            } else if(this.currentStep === 2){
                dividers[1].classList.add("border-green-200")
            } else {
                dividers.forEach((divider) => {
                    divider.classList.remove("border-green-200")
                })
            }
        }
    },

    props: ["stepDetails", "currentStep"]
}
</script>

<style scoped>

.divider{
    width: 20rem;
}

</style>
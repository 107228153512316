<template>

    <div class="main-container">
        <div class="col-span-full w-full">
            <div class="grid grid-flow-row grid-cols-4 gap-10">
                <a v-for="doc in documents" :key="doc.id" class="flex-col mb-5 cursor-pointer relative z-1 transition duration-200 transform hover:scale-105"
                    target="_blank"
                    :href="doc.url"
                    :download="doc.title">
                    
                    <svg v-if="userProfile.allowsEdit" @click.prevent="deleteDocument(doc.id)" class="deleter cursor-pointer absolute -right-8 -top-8 xl:-right-4 2xl:right-5 2xl:-top-8 transform z-10 hover:scale-110" @click="hrDoc.file = null;" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg"><g><path d="m256 24c127.9 0 232 104.1 232 232s-104.1 232-232 232-231.94-104.1-231.94-232 104.04-232 231.94-232zm-256 232c0 141.16 114.84 256 256 256s256-114.84 256-256-114.84-256-256-256-256 114.84-256 256z"/><path d="m382 172.72-42.71-42.71-83.29 83.28-83.28-83.28-42.71 42.71 83.28 83.28-83.28 83.28 42.71 42.72 83.28-83.29 83.29 83.28 42.71-42.71-83.29-83.28z"/></g></svg>

                    <img loading="lazy" class="acca-v-cover-image mb-5" :src="doc.coverImage" width="400" alt="document-cover">
                    <div class="flex items-center justify-start">
                        <img class="mr-5" width="30px" :src="require(`../../assets/decors/flag-${doc.lang}.png`)" alt="flag">
                        <h5 class="text-2xl text-white">{{doc.title}}</h5>
                    </div>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import firebase from "../../firebase"
import { mapGetters } from 'vuex'


export default {


  name: 'HRDocuments',
  data(){
      return {
          documents: []
      }
  },
    computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },
  methods: {
      displaySelectedComponent(view){
          this.selectedComponent = view
      },
      getData(){
          let db = firebase.firestore().collection("hr").doc("docs").collection("hrDocs").orderBy('title')
          db
            .get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.data())
                    this.documents.push({
                        id: doc.id,
                        lang: doc.data().lang,
                        coverImage: doc.data().coverImage,
                        title: doc.data().title,
                        url: doc.data().file,
                        }
                    )
                }
                )
                this.documents.reverse()
            });

      },

      deleteDocument(id){
          const targetedDocument = id
          let approved = confirm("Bu doküman sistemden silinecektir. Bu işlem geri alınamaz. Dosyayı silmek istediğinize emin misiniz?")
          if(approved){
              console.log("Deleting document")
              console.log(id)
              let db = firebase.firestore().collection("hr").doc("docs").collection("hrDocs")
              db.doc(id).delete().then(() => {
                  alert("Dosya silindi.")
                  this.documents = this.documents.filter( function(item){
                      return item.id !== targetedDocument
                  })
                  this.$forceUpdate();
                  }).catch((error) => {
                      alert('Dosya silinemedi:',  error)
                  })
              }
          }
  },

  mounted(){
      this.getData()
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";

    svg.deleter{
        fill: #cc4040;
      }

    svg.deleter:hover{
        background: #cc4040;
        fill: white;
        border-radius: 100%;
    }


</style>

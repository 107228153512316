<template>
    <button :style='`background-color: ${bgcolor}`' class="small-icon-button" @click="action">
        <img :src="require(`../../../assets/icons/${icon}.png`)"  alt="Button Action Image">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "smallIconButton",
    props: ["action", "bgcolor", "icon"]
}
</script>

<style lang="scss" scoped>
.small-icon-button{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6rem;
    height: 100%;
    padding: .5rem 1rem .5rem 1rem;
    border: 1px solid #d3d3d3;

    &:hover{
        filter: brightness(.9);
    }

    & img {
        margin-right: .5rem;
        max-width: 1.6rem;
    }
}
</style>


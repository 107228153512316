<template>
    <div class="main-container">
    <acca-vue-notification v-if="isLinkedinSuggestionSent" message="Paylaşım öneriniz oluşturuldu, teşekkür ederiz!"></acca-vue-notification>

    <h2 class="form-title">LinkedIn Mesajı Öner</h2>
    <form class="dashboard-form" @submit.prevent="sendlinkedinSuggestion()" >

    <label class="single-line-label" for="title">Paylaşım Konusu
        <vue-tooltip explanation="Paylaşımın temel konusu hakkında kısa bir başlık girebilirsiniz">?</vue-tooltip>    
    </label>
                            

    <input
        required
        type="text"
        id="title"
        class="dashboard-form__input"
        v-model="linkedinSuggestion.title"
    >

    <label class="text-3xl font-semibold mb-5" for="date">Paylaşım için bir tarih sınırlamamız var mı? 
        <vue-tooltip explanation="Bu paylaşımın yapılması için beklememiz gereken önemli bir tarih var mı? Örneğin, bir projenin tamamlanması veya özel bir gün kutlaması gibi.">?</vue-tooltip>    
    </label>
    
    <div class="flex mb-5">
        <div class="flex items-center">
            <input v-model="linkedinSuggestion.isDateLimited" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="true" name="true" value="true">
            <label class="text-3xl mr-7" for="true">Evet</label>
        </div>

        <div class="flex items-center">
            <input v-model="linkedinSuggestion.isDateLimited" class="flex text-xl mr-3 dashboard-form__input-radio" type="radio" id="false" name="false" value="false">
            <label class="text-3xl" for="false">Hayır</label>
        </div>
    </div>

    <div v-if="linkedinSuggestion.isDateLimited != 'false'">
        <label class="single-line-label" for="date">Yayına Alabileceğimiz Tarih
            <vue-tooltip explanation="Paylaşımı yapabileceğimiz en uygun tarihi, yaklaşık olarak seçebilirsiniz.">?</vue-tooltip>    
        </label>
        <div class="w-full flex space-between">
            <input
                type="date"
                id="date"
                class="dashboard-form__input w-full"
                v-model="linkedinSuggestion.date"
            >
        </div>
    </div>



            <label class="single-line-label" for="body">Açıklama
                <vue-tooltip explanation="Paylaşacağımız mesajla ilgili kısa bir açıklama yazabilir, veya uygun bir paylaşım metni girebilirsiniz.">?</vue-tooltip>    
            </label>
    
            <textarea
                    id="message"
                    rows="5"
                    class="medium-message mb-10"
                    v-model="linkedinSuggestion.body">
            </textarea>
        

            <label class="text-3xl font-semibold mb-5" for="images">Görseller
                <vue-tooltip explanation="Elinizde bu paylaşım için kullanabileceğimiz görseller veya örnek dosyalar varsa, buradan iletebilirsiniz.">?</vue-tooltip>    
            </label>

            <acca-dn-d-upload class="mb-8"  @filesChanged="uploadFilesToStorage($event, 'digital/linkedinSuggestions', linkedinSuggestion.images)" />


        <div class="button-row">
            <button type="submit" @click="postData(linkedinSuggestion, 'digital', 'linkedin', 'linkedinSuggestions')" class="dashboard-form__submit">Gönder</button>
            <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
        </div>
    </form>
    </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import VueTooltip from '../reusable/VueTooltip.vue'
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"


export default {
  name: 'LinkedInPostSuggestion',
  data() {
      return {
          isLinkedinSuggestionSent: false,
          linkedinSuggestion: {
              title: "",
              isDateLimited: 'false',
              date: "",
              body: "",
              created: new Date(),
              images: [],
          },
      }
  },

  methods: {


      goBack(){
          this.$router.go(-1)
      },

      sendlinkedinSuggestion() {
          this.isLinkedinSuggestionSent = true
          setTimeout(() => {
              this.isLinkedinSuggestionSent = false
              this.goBack()
          }, 3000)
      }
  },
    components: {
      accaVueNotification: accaVueNotification,
      accaDnDUpload: accaDnDUpload,
      VueTooltip: VueTooltip,

  },

  mixins: [postData, firebaseOperations],
} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";


  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<script setup>
    import { computed, onBeforeMount, defineEmits } from 'vue';
    import { useStore } from 'vuex';

    const props = defineProps(["isApproved", "positiveVotes", "negativeVotes", "isRejected", "status", "currentUserVote"])
    
    const emits = defineEmits(["post-approved", "post-rejected", "post-voted"])

    const store = useStore()
    const userProfile = computed(() => store.getters['user/userProfile']);

    const emitPostApproved = () => {
        emits('post-approved')
    }

    const emitPostRejected = () => {
        emits('post-rejected')
    }

    const emitPostVoted = (direction) => {
        if(props.currentUserVote === direction) return
        emits('post-voted', direction)
    }

    const upvoters = computed(() => {
    const upVoters = props.positiveVotes.map(vote => vote.user).join('\n');
    return upVoters ? `${upVoters}` : 'Henüz hiç oy yok.';
    });

    const downvoters = computed(() => {
    const downvoters = props.negativeVotes.map(vote => vote.user).join('\n');
    return downvoters ? `${downvoters}` : 'Henüz hiç oy yok.';
    });



    onBeforeMount(() => {
        console.log(props)
    })
</script>

<template>
    <div class="actions-container flex items-center">
        <div class="user-actions flex items-center">
            <div :title="upvoters" class="vote action mr-4">
                <div :class="{'!cursor-not-allowed': currentUserVote.direction === 'up'}" @click="emitPostVoted('up')" class="vote-img">
                    <img :src="require('../../../assets/icons/thumb-up.png')">
                </div>
                <span v-if="positiveVotes.length > 0">
                    {{positiveVotes.length}}
                </span>
            </div>
            <div :title="downvoters" class="vote vote-down action mr-4">
                <div :class="{'!cursor-not-allowed': currentUserVote.direction === 'down'}" @click="emitPostVoted('down')" class="vote-img">
                    <img :src="require('../../../assets/icons/thumb-up.png')">
                </div>
                <span v-if="negativeVotes.length > 0">
                    {{negativeVotes.length}}
                </span>
            </div>

        </div>
        <div v-if="userProfile.allowsEdit"  class="admin-actions">
            <div class="message-content__data flex select-none text-center text-3xl">
                <span @click="emitPostApproved" :class="{'bg-green-400': status === 'approved'}" class="action cursor-pointer mr-4 hover:bg-green-500 hover:text-green-300 rounded-full">&#10003;</span>
                <span @click="emitPostRejected" :class="{'bg-red-400': status === 'rejected'}" class="cursor-pointer action hover:text-red-300 hover:bg-red-500">&#10007;</span>
            </div>
        </div>

    </div>
</template>

<style>

.action{
    border-radius: 50%;
    min-width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: #f2efef;
    border: 1px solid rgba(0, 0, 0, .1);
    user-select: none;

}

.action:has(span){
    border-radius: 8px;
}

.action:has(span) .vote-img{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.action > span::before{
    content: "|";
    font-size: 1.4rem;
    color: #bbbbbb;
    margin-right: 1rem;
}

.action > span{
    display: flex;
    font-size: 1.4rem;
    color: #929292;
    padding-right: 1rem;

}

.vote-img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.action:hover .vote-img{
    background-color: #d8d8d8;
    border-radius: 50%;
}
.action:hover:has(span) .vote-img{
    background-color: #d8d8d8;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 0;
}


.vote img{
    width: 1.4rem;
}

.vote-down img{
    transform: rotate(180deg);
}

</style>
export default{
    methods: {
        blurBackground(){
            const container = document.querySelector(".main-container")
            container.classList.add("has-added-blur")
        },
        
        modalClosed() {              
            const container = document.querySelector(".main-container")
            container.classList.remove("has-added-blur")
            this.$emit('modalClosed')
        }
    }
}


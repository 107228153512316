<script setup lang="ts">

    import { onBeforeMount, ref } from "vue";
    import textToggler from "../reusable/textToggler.vue";
    import { useGetElectricityPrices, updateElectricityPrice } from "../../composables/omPlatformFunctions";

    const selectedYear = ref<string>(new Date().getFullYear().toString());

    const { prices, getPrices } = useGetElectricityPrices(selectedYear.value);

    const yearOptions = [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
    ]
    
    const months = [
    { id: "01", monthName: "Ocak" },
    { id: "02", monthName: "Şubat" },
    { id: "03", monthName: "Mart" },
    { id: "04", monthName: "Nisan" },
    { id: "05", monthName: "Mayıs" },
    { id: "06", monthName: "Haziran" },
    { id: "07", monthName: "Temmuz" },
    { id: "08", monthName: "Ağustos" },
    { id: "09", monthName: "Eylül" },
    { id: "10", monthName: "Ekim" },
    { id: "11", monthName: "Kasım" },
    { id: "12", monthName: "Aralık" }
];


    const commitPriceChange = async (month: string, newPrice: string) => {

        const newPriceNumber = parseFloat(newPrice)

        if(isNaN(newPriceNumber)){
            alert("Lütfen geçerli bir fiyat girdiğinizden emin olun.")
            return
        }

        try {
            await updateElectricityPrice(selectedYear.value, month, newPriceNumber)
            prices.value[month] = newPrice
        } catch (error) {
            alert("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.")
        }
    }


    onBeforeMount(() => {

    })

</script>

<template>
  
    <div class="main-container mt-8 !items-start !content-start ">
      <div class="actions-row flex items-center justify-end col-start-1 col-end-13">
            <select @change="getPrices(selectedYear)" class="outline-none px-8 py-2 rounded-lg bg-white text-2xl" name="year" id="year" v-model="selectedYear">
                <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
            </select>
     
      </div>
      <table class="alt-table mobile-preserved fixed-column-1 border-collapse col-start-1 col-end-13 max-w-full">
        <thead>
          <tr>
            <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Ay </th>
            <th class="font-bold text-2xl py-4 px-8 whitespace-nowrap text-left"> Fiyat (₺) </th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="month in months" :key="month.id">
                <td class="py-4 px-8 whitespace-nowrap text-left">{{ month.monthName }}</td>
                <td class="py-4 px-8 whitespace-nowrap text-left"> 
                    <text-toggler edited-field="index" :text="prices[month.id] ? prices[month.id] : '0.00'" @complete-edit="commitPriceChange(month.id, $event)" />
                </td>
            </tr>
        </tbody>
      </table>
  


  
    </div>
  </template>
import type { EventCategory } from "@/types"
import { getMetas } from "../mixins/modularFirebaseOperations"
import firebase from "firebase"
import { Ref, ref } from "vue"
import eventCategoriesVue from "@/components/calendar/eventCategories.vue"


export const getAllEvents = async (eventsArray: any[], isLoading: Ref<boolean>) => {
    isLoading.value = true
    const eventsRef = firebase.firestore().collection("events")

    eventsRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            eventsArray.push(
                {
                    id: doc.id,
                    title: doc.data().title,
                    time: doc.data().time,
                    description: doc.data().description,
                    // with: doc.data().with,
                    colorScheme: doc.data().category,
                    isEditable: doc.data().isEditable,
                }
            )

            isLoading.value = false

        });
    })
}


export const mapEventCatsToColorSchemes = async function (eventCats: Ref<EventCategory[]>, calendarConfig: any, isLoadingEventCats: Ref<boolean>) {
    await getMetas("metas/calendar/eventCats", eventCats.value, isLoadingEventCats)

        // Directly map over the eventCats array
        const colorSchemes = Object.fromEntries(
            eventCats.value.map(cat => [cat.name, {
                "backgroundColor": cat.color,
                "color": "#fff"
            }])
        );
    
        calendarConfig.style.colorSchemes = colorSchemes;
}

export const useGetEmployeeBirthdays = () => {
    const birthdays = ref([])
    const isLoadingBirthdays = ref(false)

    const convertBirthDateToCalendarTime = (birthDate: string) => {
        const birthDateArray = birthDate.split(".")

        const birthDateDay = birthDateArray[0]
        const birthDateMonth = birthDateArray[1]
        const year = new Date().getFullYear()

        const birthDateCalendarTime = (`${year}-${birthDateMonth}-${birthDateDay}`)
       

        return birthDateCalendarTime
    }

    const getBirthdays = async () => {
        isLoadingBirthdays.value = true
        const birthdaysRef = firebase.firestore().collection("team")
        birthdaysRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                birthdays.value.push(
                    {
                        id: doc.id,
                        title: doc.data().name.first + " " + doc.data().name.last + " Doğum günü",
                        time: {
                            start: convertBirthDateToCalendarTime(doc.data().birthday),
                            end: convertBirthDateToCalendarTime(doc.data().birthday),
                        },                        
                        isEditable: false,
                        colorScheme: "Doğum günleri",
                    }
                )
            });
        })
        isLoadingBirthdays.value = false
    }



    getBirthdays()

    return { birthdays, isLoadingBirthdays }


}
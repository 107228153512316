<template>
        <div class="main-container relative">

            <acca-vue-notification v-if="isMessageSent" message="Mesajınız başarıyla gönderildi."></acca-vue-notification>

            <div v-if="isMessageModalOpen"

                class="absolute left-1/4 top-20 z-10 w-1/2 py-10 px-10 border-gray-400 border-2 bg-white rounded-3xl">

                <h3 class="text-4xl font-bold mb-5">
                    Mesaj Ekle
                </h3>

                <p class="text-2xl mb-5">
                    Bu bölümden, oluşturulan toplantı talebi ile ilgili bir mesaj ekleyebilirsiniz. (Örneğin, toplantı için gereken ön hazırlık, toplantı konumu, toplantı talebi reddetme nedeniniz, vs.) <br> <br> Bu mesaj, yalnızca talebi oluşturulan kullanıcıya gösterilecektir. 
                </p>

                <textarea class="border-gray-400 border w-full mb-5 px-4 py-4 text-2xl focus:outline-black" name="responseMessage" v-model="responseMessage" cols="30" rows="10"></textarea>

                <div class="flex flex-start w-1/2 m-auto">
                    <button class="bg-green-400 rounded-l text-white px-10 mr-10 py-4 hover:bg-green-500 text-2xl" @click="addMessage(messageId)">Mesaj Ekle</button>
                    <button class="border border-gray-600 rounded-l px-10 py-4 text-2xl" @click="isMessageModalOpen = false">İptal</button>
                </div>
            </div>


            <table class="table-auto border-collapse w-full col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Oluşturulma Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Talep Eden </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Konu </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Toplantı Zamanı</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Katılımcılar </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Açıklama</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Online?</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Durum</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Mesaj</th>
                    </tr>
                </thead>

                <tr v-for="request in requestData" :key="request.id">
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{request.created}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.user}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.title}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.date}} - {{request.time}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">
                            <p v-for="participant, index in request.participants" :key="participant">
                                {{index + 1}} - {{participant}}
                            </p>
                        </td>
                        <td class="message-content__data py-4 px-8 text-left text-2xl">{{request.body}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-5xl">{{request.isOnline}}</td>
                        <td v-if="request.status === undefined || request.status === '-'" class="py-4 px-8 text-left text-2xl items-center align-middle h-200">
                            <div class="flex">
                                <button @click="respondToRequest(request.id, 'Approved')" class="bg-green-400 text-white px-10 py-3 rounded-2xl hover:bg-green-500 mr-5">Onayla</button>
                                <button @click="respondToRequest(request.id, 'Rejected')" class="bg-red-400 text-white px-10 py-3 rounded-2xl hover:bg-red-500">Reddet</button>
                            </div>
                        </td>
                        <td v-else-if="request.status === 'Approved'" class="py-4 px-8 text-center bg-green-200 text-2xl text-green-500 ">
                            Onaylandı.
                        </td>
                        <td v-else-if="request.status === 'Rejected'" class="py-4 px-8 relative bg-red-200 text-center justify-center h-full text-2xl text-red-500">
                            Reddedildi
                        </td>

                        <td @click="openMessageModal(request.id)" class="message-content__data py-4 px-8 text-center cursor-pointer text-5xl hover:bg-gray-100 active:bg-gray-200">
                            <svg class="m-auto" enable-background="new 0 0 512 512" width="20" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m256 0c-141.17 0-256 114.83-256 256s114.83 256 256 256 256-114.85 256-256-114.83-256-256-256zm0 472.34c-119.28 0-216.34-97.046-216.34-216.34s97.066-216.34 216.34-216.34 216.34 97.046 216.34 216.34-97.046 216.34-216.34 216.34z"/>
                                    <path d="m355.15 234.39h-79.318v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83 8.884-19.83 19.83v79.318h-79.318c-10.966 0-19.83 8.884-19.83 19.83s8.864 19.83 19.83 19.83h79.318v79.318c0 10.946 8.864 19.83 19.83 19.83s19.83-8.884 19.83-19.83v-79.318h79.318c10.966 0 19.83-8.884 19.83-19.83s-8.864-19.83-19.83-19.83z"/>
                            </svg>

                        </td>

                </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'
import accaVueNotification from "../reusable/accaVueNotification.vue"


export default {
    
    name: 'MeetingRequestsAdmin',
    data() {
        return {
            requestData: [],
            messageId: "",
            isMessageModalOpen: false,
            responseMessage: "",
            isMessageSent: false

        }
    },

    components: {
        accaVueNotification: accaVueNotification,
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("requests").doc("meeting").collection("meetingRequests").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.requestData.push(
                {
                id: doc.id,
                body: doc.data().body,
                title: doc.data().title,
                participants: doc.data().participants,
                date: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().date)),
                time: doc.data().time,
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                isOnline: (doc.data().isOnline) ? "✅" : "❌",
                status: doc.data().status,
                responseMessage: doc.data().responseMessage
                    })
                })
            });
        },

        respondToRequest(id, response){
            let db = firebase.firestore().collection("requests").doc("meeting").collection("meetingRequests")

                db.doc(id).update({
                    status: response
                })
                .then(() => {
                    console.log("Document successfully written!");
                    let approvedRequest = this.requestData.find(request => request.id === id)
                    approvedRequest.status = response
                })
                .catch((error) => {
                    console.error("Error writingocument: ", error);
                });
        },

        addMessage(id){
            let db = firebase.firestore().collection("requests").doc("meeting").collection("meetingRequests")
                db.doc(id).update({
                    responseMessage: this.responseMessage
                })
                .then(() => {
                    console.log("Document successfully written!");
                    this.isMessageModalOpen = false
                    this.rejectionId = ""
                    this.isMessageSent = true
                    setTimeout(() => {
                        this.isMessageSent = false
                    }, 1700)
                })
                .catch((error) => {
                    console.error("Error writingocument: ", error);
                });
        },

        openMessageModal(id){
            console.log(id)
            this.isMessageModalOpen = true
            this.messageId = id
        },
      },

      mounted(){
          this.getData()
      }
  }

</script>

<template>
        <div class="main-container">
            <acca-vue-notification v-if="isLinkedinPostSent" message="Post oluşturuldu!"></acca-vue-notification>

            <h2 class="form-title">LinkedIn Postu Planla</h2>
            <form class="dashboard-form" @submit.prevent="sendlinkedinPost()" >

            <label class="single-line-label" for="title">Paylaşım Konusu</label>
            <input
                required
                type="text"
                id="title"
                class="dashboard-form__input"
                v-model="linkedinPost.title"
            >


            <label class="single-line-label" for="date">Planlanan Yayın Tarihi</label>
            <div class="w-full flex space-between">
                <input
                    @input="calculateDate"
                    type="date"
                    id="date"
                    class="dashboard-form__input w-full"
                    v-model="unformattedDate"
                >
            </div>


                    <label class="single-line-label" for="body">Paylaşım Metni
                    </label>
           
                    <textarea
                            id="message"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="linkedinPost.body">
                    </textarea>
                    
                    <label class="single-line-label" for="explanation">Açıklama
                    </label>
           
                    <textarea
                            id="explanation"
                            rows="5"
                            class="medium-message mb-10"
                            v-model="linkedinPost.explanation">
                    </textarea>

                    <label class="text-2xl font-semibold mb-5" for="images">Görseller
                    </label>

                    <acca-dn-d-upload class="mb-8" @filesChanged="uploadFilesToStorage($event, 'digital/linkedinPosts', linkedinPost.images)" />


                <div class="button-row">
                    <button type="submit" @click="postData(linkedinPost, 'digital', 'linkedin', 'linkedinPosts')" class="dashboard-form__submit">Gönder</button>
                    <button type="button" @click="goBack" class="dashboard-form__button-secondary button__cancel">İptal</button>
                </div>
            </form>
            </div>
</template>

<script>
import postData from "../../mixins/firebaseOperations"
import accaVueNotification from "../reusable/accaVueNotification.vue"
import firebaseOperations from "../../mixins/firebaseOperations"
import accaDnDUpload from "../reusable/accaDnDUpload.vue"


export default {
  name: 'LinkedInPosts',
  data() {
      return {
          isLinkedinPostSent: false,
          unformattedDate: "",
          linkedinPost: {
              title: "",
              date: "",
              body: "",
              created: new Date(),
              images: [],
              explanation: "",
              comments: []
          },
      }
  },

  methods: {

      goBack(){
          this.$router.go(-1)
      },

      calculateDate(){
          this.linkedinPost.date = new Date(this.unformattedDate).getTime()
      },

      sendlinkedinPost() {
          this.isLinkedinPostSent = true
          setTimeout(() => {
              this.isLinkedinPostSent = false
              this.goBack()
          }, 3000)
      }
  },
    components: {
      accaVueNotification: accaVueNotification,
      accaDnDUpload: accaDnDUpload,
  },

  mixins: [postData, firebaseOperations],
} 

</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/global/forms.scss";

.dropzone .dz-message{
    padding: 4em 0;
}



  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: .5rem;
    margin-left: .5rem;
}


</style>

<script setup lang="ts">
    import { ref } from "vue"
    import { useMultiSelectEMails } from "@/composables/crmBasedFunctions";
    import { EMailTag } from "@/types";

    import textToggler from "../reusable/textToggler.vue";
    import statusChanger from "../reusable/statusChanger.vue";
    import tagViewer from "../reusable/tagViewer.vue";

    const props = defineProps(['email', 'currentTagToggledEmail', 'tagList'])
    const emits = defineEmits(['tag-added', 'tag-deleted', "edit", "single-field-update"])

    const { selectedEMails } = useMultiSelectEMails()

    const handleSelection = (id: string) => {
        if(!selectedEMails.value.includes(id)){
            selectedEMails.value.push(id)
        }
        else{
            selectedEMails.value.splice(selectedEMails.value.indexOf(id), 1)
        }
    }

    const isAddingTag = ref(false)

    const emitTagAdded = (tag: EMailTag) => {
        emits('tag-added', tag)
        isAddingTag.value = false
    }

    const emitTagDeleted = (tag: EMailTag) => {
        emits('tag-deleted', tag)
    }

    const emitEdit = () => {
        emits('edit', props.email)
    }

    // Reverse the tags, so that the last added tag appears first.

    const getReversedTags = (tags: EMailTag[]) => {
        return [...tags].reverse();
    }

    const emitSingleFieldUpdate = (field: string, value: string) => {
        emits('single-field-update', { field, value })
    }


</script>


<template>
    <tr>
        <td @click.stop>
            <input :checked="selectedEMails.includes(email.id)" @change="handleSelection(email.id)"  type="checkbox" class="table-checkbox !w-4" /> 
        </td>
        <td class="message-content__data py-4 px-8 text-2xl text-left text-overflow !max-w-[25vw]">{{ email.email }}</td>
        <td class="message-content__data font-bold py-4 px-8 text-2xl text-lef hover:text-gray-400">
            <text-toggler class="text-black" edited-field="firstName" :text="email.firstName"
                @complete-edit="emitSingleFieldUpdate('firstName', $event)" />
        </td>
        <td class="message-content__data font-bold py-4 px-8 text-2xl text-left hover:text-gray-400">
            <text-toggler class="text-black" edited-field="lastName" :text="email.lastName"
                @complete-edit="emitSingleFieldUpdate('lastName', $event)" />
        </td>
        <td class="message-content__data py-4 px-8 text-2xl text-left hover:text-gray-400">
            <text-toggler class="text-black" edited-field="industry" :text="email.industry"
                @complete-edit="emitSingleFieldUpdate('industry', $event)" />
        </td>
        <td @click.exact="isAddingTag = true"
            class="scrolling-table-field hover:bg-gray-200 cursor-pointer relative">
            <div class="status-change-container">
                <status-changer v-if="isAddingTag" :existing-statuses="email.tags"
                    :status-list="tagList" :displays-triangle="true" @updated="emitTagAdded($event)"
                    @cancelled="isAddingTag = false" title="Etiket Ekle"
                    class="status-changer absolute !w-[20vw] top-[70%] left-[-25%] !z-50" />
            </div>
            <div class="tag-area-container">
                <div v-if="email.tags?.length > 0" class="existing-tags-displayer flex gap-2">
                    <tag-viewer @delete-tag="emitTagDeleted($event)" :removable="true"
                        v-for="tag in getReversedTags(email.tags)" :key="tag.id" :tag="tag" />
                </div>
                <div v-else class="no-tags text-2xl italic text-gray-400">
                    Etiket yok.
                </div>
            </div>

        </td>
        <td class="message-content__data py-4 px-8 text-left text-2xl text-overflow !max-w-[25vw]">
            <a v-if="email.companyUrl" target="_blank" :href="email.companyUrl" class="text-blue-500">
                {{ email.company }}
            </a>
            <text-toggler class="!overflow-x-scroll no-scrollbars" v-else edited-field="company" :text="email.company" @complete-edit="emitSingleFieldUpdate('company',$event)" />
        </td>
        <td class="message-content__data py-4 px-8 text-2xl text-left hover:text-gray-400">
            <text-toggler class="text-black" edited-field="phone" :text="email.phone"
                @complete-edit="emitSingleFieldUpdate('phone', $event)" />
        </td>

        <td>
            <button @click="emitEdit" class="table-button">Düzenle</button>
        </td>
    </tr>
</template>
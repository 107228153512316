<template>
    <table class="table-auto w-full border-1 border-gray-200  shadow-sm bg-white">
        <thead>
            <tr class="bg-gray-500">
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Talebi Oluşturan </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Oluşturulma Tarihi </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> İzin Başlangıç Tarihi </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> İzin Bitiş Tarihi</th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Durum </th>
                <th class="font-bold text-white text-2xl py-4 px-8 whitespace-nowrap text-center"> Mesaj</th>
            </tr>
        </thead>

        <tr class="hover:bg-gray-100" v-for="request in requests" :key="request.id">
                <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">
                    {{request.user}}
                </td>

                <td class="message-content__data py-4 px-8 text-2xl text-center">
                    {{request.created}}
                </td>
                <td class="message-content__data py-4 px-8 text-center text-2xl">
                    {{request.startDate}}
                </td>
                <td class="message-content__data py-4 px-8 text-center text-2xl">
                    {{request.endDate}}
                </td>                                
               
                    <td v-if="request.status === 'Approved' " class="message-content__data bg-green-200 text-green-500 py-4 px-8 text-left text-xl">
                        Onaylandı
                    </td>                
                    <td v-else-if="request.status === 'Rejected' " class="message-content__data bg-red-200 text-red-500 py-4 px-8 text-left text-xl">
                        Reddedildi
                    </td>
                    <td v-else class="message-content__data py-4 px-8 text-left text-xl">
                        Onay Bekleniyor
                    </td>

                <td class="message-content__data py-4 px-8 text-left text-xl">
                    {{request.responseMessage}}
                </td>                
        </tr>
    </table>
</template>

<script>



export default {
    
    name: 'LeaveRequestStatus',

    props:{
        requests: Array
    },

    methods: {
    },

}

</script>
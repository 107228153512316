<template>
    <div class="main-container !content-between">
        <div class="w-full col-start-1 col-end-13 row-start-1 row-end-2 pt-12 !self-start">

            <div v-if="isAuthenticatedForBMS" class="info-message success">
                Hesabınız BMS için yetkilendirilmiş. Bu bölümde yapacağınız değişiklikler anlık olarak yansıtılacaktır.
            </div>
            <div v-else class="info-message error">
                Hesabınız BMS için yetkilendirilmemiş gibi gözüküyor. Lütfen bu bölümde herhangi bir değişiklik yapmadan önce, aşağıdaki butona tıklayarak hesabınızı yetkilendirin.
                <button v-if="!isAuthenticatedForBMS" @click="BMSLogin">
                <span>Yetkilendir</span>
            </button>
            </div>

        </div>
        {{ isAuthenticatedForBMS }}

        <inner-navigation class="grid-order-1 row-start-2" link="/om-platform/projects" title="Projeler" imgURL="email-list.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2 row-start-2" link="/om-platform/electricity-price" title="Elektrik Fiyatları" imgURL="new-email.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>

import InnerNavigation from "../InnerNavigation.vue"
import { mapGetters } from 'vuex'
import initializeBMS from "../../mixins/initializeOtherFirebase"
import bmsMethods from '../../mixins/bms/bmsMethods'

export default {
  name: 'OMHome',
  components: {
      InnerNavigation: InnerNavigation,

  },
  mixins: [initializeBMS, bmsMethods],

  methods: {
    BMSLogin(){
      this.$store.dispatch("user/BMSLogin")
    },
    },
    computed: {
      ...mapGetters('user', {
          userProfile: 'userProfile',
          isAuthenticatedForBMS: "isAuthenticatedForBMS"

      })
},
created(){
    this.checkBMSAuth()

}
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

   .info-message{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.6rem;
        border-radius: 1rem;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
   }

    .info-message button{
          padding: 1rem 2rem;
          border-radius: 3px;
          background-color: white;
          color: rgb(255, 99, 132);
          font-size: 1.3rem;
          font-weight: bold;
          border: 1px solid rgb(255, 99, 132);
          cursor: pointer;
          box-shadow: 0 0 15px rgba(0,0,0,0.2);
    }

    .info-message button:hover{
          text-decoration: underline;
    }

   .info-message.success{
        background-color: rgb(87, 221, 132);
   }
   .info-message.error{
        background-color: rgb(255, 99, 132, 0.9);
   }
</style>

<template>
        <div class="main-container">
            <table class="table-auto border-collapse w-full col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Oluşturulma Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Gönderen </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Başlık </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Açıklama</th>
                    </tr>
                </thead>

                <tr v-for="suggestion in suggestionData" :key="suggestion.id">
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{suggestion.created}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{suggestion.user}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{suggestion.title}}</td>
                        <td class="message-content__data py-4 px-8 text-left text-2xl">{{suggestion.body}}</td>                
                    </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'


export default {
    
    name: 'PanelSuggestionsAdmin',
    data() {
        return {
            suggestionData: [],
        }
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("suggestions").doc("panel").collection("panelSuggestions").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            this.suggestionData.push(
                {
                id: doc.id,
                body: doc.data().body,
                title: doc.data().title,
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                    })
                })
            });
        },

      },
      mounted(){
          this.getData()
      }
  }

</script>

<template>
    <div class="main-container">
        <inner-navigation class="grid-order-1" link="tools/msg" title="Mail İmza Oluştur" imgURL="digital-signature.png" navStyle=1></inner-navigation>
        <inner-navigation class="grid-order-2" link="tools/dg-msg" title="D&G Mail İmza Oluştur" imgURL="digital-signature.png" navStyle=1></inner-navigation>
    </div>
</template>

<script>
import InnerNavigation from "../InnerNavigation.vue"

export default {
  name: 'ToolsHome',
  components: {
      InnerNavigation: InnerNavigation
  }
}
</script>

<style scoped lang="scss">
  
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/global/background.scss";
  @import "../../styles/global/helpers.scss";

</style>

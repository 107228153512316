<template>
     <div class="main-container mt-10">
        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__flipInX "
            leave-active-class="animate__animated animate__flipOutX animate__fast"
            mode="out-in"
        >
          <component :is="unitsCountOverride" class="grid-order-1 row-start-1 row-end-2"></component>
        </transition>

        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__flipInX "
            leave-active-class="animate__animated animate__flipOutX animate__fast"
            mode="out-in"
        >
            <component @componentClicked="changeWeatherComponent" :is="selectedWeather" class="grid-order-2 cursor-pointer row-start-1 row-end-2" />
        </transition>

        <transition
            appear
            type="animation"
            enter-active-class="animate__animated animate__flipInX "
            leave-active-class="animate__animated animate__flipOutX animate__fast"
            mode="out-in"
        >
          <component @componentClicked="changeMetalComponent" :is="selectedMetal" class="grid-order-1 row-start-2 row-end-3"></component>
        </transition>

        <inner-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin'" class="grid-order-2 row-start-2 row-end-3" link="/stats" title="Şirket Verileri" imgURL="stats.png"></inner-navigation>
        <inner-navigation v-else class="grid-order-2" link="/useful-links" title="Faydalı Bağlantılar" imgURL="main-links.png"></inner-navigation>

        
        <summary-calendar class="col-start-7 col-end-13 row-start-1 row-end-3"></summary-calendar>


        <home-messages class=" row-span-2 col-start-1 col-end-13 hidden md:block"></home-messages>
        <inner-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin'" class="grid-order-1" link="/useful-links" title="Faydalı Bağlantılar" imgURL="main-links.png"></inner-navigation>
        <inner-navigation v-else class="grid-order-1" link="/crm" title="CRM" imgURL="customers.png"></inner-navigation>
        <inner-navigation class="grid-order-2" link="/documents" title="Belgeler" imgURL="main-documents.png"></inner-navigation>
        <inner-navigation class="grid-order-3" link="/training" title="Eğitimler" imgURL="main-trainings.png"></inner-navigation>
        <inner-navigation class="grid-order-4" link="/digital" title="Dijital" imgURL="main-digital.png"></inner-navigation>
        <inner-navigation class="grid-order-1" link="/surveys" title="Anketler" imgURL="main-surveys.png"></inner-navigation>
        <inner-navigation class="grid-order-2" link="/human-resources" title="İnsan Kaynakları" imgURL="hr.png"></inner-navigation>
        <inner-navigation class="grid-order-3" link="/suggestions" title="Öneriler" imgURL="main-suggestions.png"></inner-navigation>
        <inner-navigation class="grid-order-4" link="/goals" title="Hedefler" imgURL="main-links.png"></inner-navigation>
        <inner-navigation class="grid-order-1" link="/online-monitoring" title="Online Monitoring" imgURL="online-monitoring.png"></inner-navigation>
        <inner-navigation class="grid-order-2" link="/tools" title="Araçlar" imgURL="solution.png"></inner-navigation>
        <inner-navigation v-if="userProfile.role === 'admin' || userProfile.role === 'superAdmin'" class="grid-order-3" link="/crm" title="CRM" imgURL="customers.png"></inner-navigation>
        <inner-navigation class="grid-order-4" link="/om-platform/" title="Dansuk Online Monitoring Platformu" imgURL="dansuk-om.png"></inner-navigation>
      </div>
</template>

<script>

import InnerNavigation from './InnerNavigation.vue'
import HomeMessages from "./HomeMessages.vue"
import MetalValue from "./api-components/MetalValue.vue"
import MetalGraph from "./api-components/MetalGraph.vue"
import UnitsCount from "./api-components/UnitsCount.vue"
import WeatherToday from "./api-components/WeatherToday.vue"
import WeatherForecast from './api-components/WeatherForecast.vue'
import SummaryCalendar from './calendar/summaryCalendar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DansukHome',
  data(){
    return {
      selectedWeather: "WeatherForecast",
      selectedMetal: "MetalValue",
      unitsCountOverride: "UnitsCount"
    }
  },

  methods: {



    changeWeatherComponent: function(){
      this.selectedWeather = (this.selectedWeather === "WeatherForecast") ? "WeatherToday" : "WeatherForecast"
    },
    changeMetalComponent: function(){
      this.selectedMetal = (this.selectedMetal === "MetalValue") ? "MetalGraph" : "MetalValue"
    },
  },

  computed:{
    ...mapGetters(
      'user', {
        userProfile: 'userProfile'
      }
    )
  },

  components: {
      InnerNavigation: InnerNavigation,
      HomeMessages: HomeMessages,
      MetalValue: MetalValue,
      MetalGraph: MetalGraph,
      WeatherToday: WeatherToday,
      WeatherForecast: WeatherForecast,
      UnitsCount: UnitsCount,
      SummaryCalendar: SummaryCalendar
  },


}
</script>

<style lang="scss">
  
  @import "../styles/core/variables.scss";
  @import "../styles/core/resets.scss";
  @import "../styles/global/background.scss";
  @import "../styles/global/helpers.scss";

</style>

<template>
        <div class="main-container">
            <table class="table-auto border-collapse w-full col-start-1 col-end-13 bg-white mt-20">
                <thead>
                    <tr class="bg-gray-700">
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Başlık </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Dosya Türü </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Hedef Kitle </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Açıklama </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Oluşturulma Tarihi </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Talep Eden </th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Teslim Tarihi</th>
                        <th class="font-bold text-gray-300 text-2xl py-4 px-8 whitespace-nowrap text-center"> Görseller</th>
                    </tr>
                </thead>

                <tr v-for="request in requestData" :key="request.id">
                        <td class="message-content__data font-bold py-4 px-8 text-2xl text-center">{{request.title}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl text-center">{{request.documentType}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{request.documentAudience}}</td>
                        <td class="message-content__data py-4 px-8 text-2xl">{{request.body}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">{{request.created}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">{{request.user}}</td>
                        <td class="message-content__data py-4 px-8 text-center text-2xl">{{request.deadline}}</td>
                        <td class="message-content__data py-4 px-8">
                            <a class="text-2xl text-blue-300 list-item list-none" v-for="imageLink in request.images" :key="imageLink" :href="imageLink.src">İndir</a>
                        </td>
                </tr>
            </table>
        </div>
</template>

<script>
import firebase from '../../firebase'


export default {
    
    name: 'DocumentRequestsAdmin',
    data() {
        return {
            requestData: [],
        }
    },

    methods: {
        getData(){
            let db = firebase.firestore().collection("requests").doc("document").collection("documentRequests").orderBy('created')
            db
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data())
            this.requestData.push(
                {
                id: doc.id,
                title: doc.data().title,
                documentType: doc.data().documentType,
                documentAudience: doc.data().documentAudience,
                body: doc.data().body,
                deadline: doc.data().deadline,
                user: doc.data().user,
                created: new Intl.DateTimeFormat('tr-TR').format(doc.data().created.toDate()),
                images: doc.data().images
                    })
                })
            });
        },

      },

      mounted(){
          this.getData()
      }
  }


</script>

